import React from 'react'
import styled from '@xstyled/styled-components'
import { x, th } from '@xstyled/styled-components'
import { Trans } from '@lingui/react'
import { Text } from '@mailstep/design-system/ui/Elements/Typography'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  color: ${th('colors.textTertiary')};
`

const NoEshopSelected = () => {
  return (
    <Wrapper>
      <x.div mb={1}>
        <Text variant="semiBold">
          <Trans id="settings.baselinker.noResults" message="No results to display" />
        </Text>
      </x.div>
      <Text variant="medium" fontSize={1}>
        <Trans id="settings.baselinker.selectEshop" message="Select eshop to view results." />
      </Text>
    </Wrapper>
  )
}

export default NoEshopSelected
