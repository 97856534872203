import { useCallback } from 'react'
import { GridSelectorsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import buildCriteria from '@utils/queries/buildCriteria'
import { useDispatch } from 'react-redux'
import { ExpeditionAsNested } from '@typings/entities/Expedition'
import { FileSubmit, OnDownloadTemplate } from '@typings/file'
import { formatApiErrorToFormikErrors } from '@utils/formik/formatApiToFormikErrors'
import { actions as uiActions } from '@store/ui'
import { useEvent } from '@hooks/useEvent'
import { ApiError } from '@typings/entities/Error'

export const getOrganisationFilters = (organisation?: string, orgCriteriaName = 'organisation'): any => {
  return organisation ? { [orgCriteriaName]: { in: [organisation] } } : {}
}

export const getProductOrganisationFilters = (
  organisation?: string,
  product?: string,
  orgCriteriaName = 'product.organisation',
  productCriteriaName = 'product',
): any => {
  const criteria = getOrganisationFilters(organisation, orgCriteriaName)
  if (product) {
    criteria[productCriteriaName] = { eq: product }
  }
  return criteria
}

type onExportFunc = (range: 'filtered' | 'selected', format: string, extraProps?: { [name: string]: string }) => void

export const useOnExport = (gridSelectors: GridSelectorsType, containerFilters: object, exportAction: Function): onExportFunc => {
  const dispatch = useDispatch()
  const onExport: onExportFunc = useEvent((range, format, extraProps) => {
    let criteria
    if (range == 'filtered') {
      const filterValues = gridSelectors.filter || {}
      criteria = {
        ...containerFilters,
        ...buildCriteria(filterValues),
      }
    } else if (range == 'selected') {
      const checkedRows = gridSelectors.uxState?.checkedRows || []
      const selectedItems = Object.keys(checkedRows) || []
      // we know there is at least one because we check it in export popup
      criteria = { id: { in: selectedItems } }
    } else return

    dispatch(
      exportAction({
        criteria,
        format: format || 'csv',
        queryProps: extraProps,
      }),
    )
  })
  return onExport
}

export const useExpeditionLink = () => {
  const dispatch = useDispatch()

  return useCallback(
    (id: string) => {
      if (id) {
        dispatch(uiActions.openQuickAccessDialog('expedition', id))
      }
    },
    [dispatch],
  )
}

type UseImportReturnProps = {
  onImportSubmit: FileSubmit
  onTemplateDownloadSubmit: OnDownloadTemplate
}

export const useImportProps = (
  importAction: Function,
  downloadTemplateAction: Function,
  organisation: string | undefined,
): UseImportReturnProps => {
  const dispatch = useDispatch()
  return {
    onImportSubmit: useCallback(
      (file) => dispatch(importAction({ organisation: organisation, file })).promise,
      [dispatch, importAction, organisation],
    ),
    onTemplateDownloadSubmit: useCallback(
      (values, formikBag) => {
        return dispatch(downloadTemplateAction({ ...values, orgId: organisation }))
          .promise.then(() => {
            formikBag.setSubmitting(false)
          })
          .catch((error: ApiError) => formikBag.setErrors(formatApiErrorToFormikErrors(error)))
      },
      [dispatch, downloadTemplateAction, organisation],
    ),
  }
}
