import { t } from '@lingui/macro'
import { type TabDefinition } from '@mailstep/design-system/ui/Blocks/Tabs'

type Props = {
  canReadNotifications: boolean
  notificationCount?: number
  warningsCount?: number
}

export const tabsDefinitions = ({ canReadNotifications, notificationCount, warningsCount }: Props): TabDefinition[] =>
  canReadNotifications
    ? [
        {
          label: t({ id: 'notifications.tabs.notifications', message: 'Notifications' }),
          badgeCount: notificationCount || undefined,
          default: true,
        },
        {
          label: t({ id: 'notifications.tabs.warnings', message: 'Warnings' }),
          badgeCount: warningsCount || undefined,
        },
      ]
    : [
        {
          label: t({ id: 'notifications.tabs.warnings', message: 'Warnings' }),
          badgeCount: warningsCount || undefined,
        },
      ]
