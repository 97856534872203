import gql from 'graphql-tag'

export const organisationDetailFields = `
  id
  name
  registrationNumber
  vatNumber
  addressLine1
  addressLine2
  addressLine3
  primaryCountry
  owner
  countriesOfOperation
  users
  admins
  stockAdviceCloseInterval
  active
  changedAt
  eshops
  warehouses
  wmses
  carriers
  suppliers
  code
  mailstageProductToAllEshops
  changedAt
  ignoreAddressValidation
  automaticExchangeEnabled
  strictFifo
`

const organisationGetQuery = gql`
  query organisationGet($id: String!) {
    organisationGet(id: $id) @rest(type: "Organisation", method: "GET", path: "organisation/{args.id}") {
      ${organisationDetailFields}
    }
  }
`

export default organisationGetQuery
