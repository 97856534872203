import React from 'react'
import CommonGrid from '@mailstep/design-system/ui/Blocks/CommonGrid'
import GridExport from '@components/blocks/GridExport'
import { GridProps } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { useModal } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useModal'
import useShallowRouting from './useShallowRouting'

type Props = Omit<GridProps, 'onExport'> & {
  onExport: (range: 'filtered' | 'selected', format: string) => void
}
const StyledCommonGrid = ({ onExport, gridSelectors, gridActions, ...props }: Props): JSX.Element => {
  const { onOpen: onExportClick, isOpen: exportFormVisible, onClose: hideExport } = useModal()

  const { changePathOrSetPage, rowsPerPageValue } = useShallowRouting(gridActions, gridSelectors)

  return (
    <>
      <CommonGrid
        processCheckedValues={onExportClick}
        gridActions={gridActions}
        gridSelectors={gridSelectors}
        queryRowsParam={rowsPerPageValue}
        customPaginationHandler={changePathOrSetPage}
        {...props}
      />
      {!!onExport && exportFormVisible && <GridExport onClose={hideExport} onExport={onExport} gridSelectors={gridSelectors} />}
    </>
  )
}

export default StyledCommonGrid
