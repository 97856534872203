/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import Button from '@mailstep/design-system/ui/Elements/Button'
import FaIcon from '@mailstep/design-system/ui/Elements/Icon'
import { Trans } from '@lingui/react'
import { x } from '@xstyled/styled-components'
import { History } from 'history'
import React from 'react'

interface DialogBodyProps {
  text?: string
  onClose: () => void
  redirectTo?: string
  history: History
  actionLabel?: string
  confirmLabel?: string
}

export const DialogBody = ({ text, onClose, redirectTo, history, actionLabel, confirmLabel }: DialogBodyProps): JSX.Element => {
  const onActionClick = React.useCallback(() => {
    redirectTo && history.push(redirectTo)
    onClose()
  }, [onClose, history, redirectTo])
  return (
    <>
      {text || null}
      <x.div display="flex" justifyContent="space-between" mt={4} flexDirection="column" alignItems="flex-start">
        <Button onClick={onClose} type="button" iconLeft={confirmLabel && <FaIcon icon="check" />}>
          {confirmLabel || <Trans id="cornerDialog.buttonOkThankYou" message="Confirm" />}
        </Button>
        {actionLabel && redirectTo && (
          <Button onClick={onActionClick} variant="default" appearance="secondary" type="button">
            {actionLabel}
          </Button>
        )}
      </x.div>
    </>
  )
}
