import React, { FormEvent, useMemo } from 'react'
import ButtonsStrip from '@components/blocks/ButtonsStrip'
import HidePrint from '@mailstep/design-system/ui/Blocks/HidePrint/HidePrint'
import { TransferStatus } from '@typings/entities/Transfer'
import { createButtonsDefinition } from '../buttonsDefinition'
import { x } from '@xstyled/styled-components'

interface ActionsProps {
  isLoading: boolean
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  onCancel: () => void
  isEditable: boolean
  status: TransferStatus
}

const TransferActions = ({ isLoading, status, onSubmit, onCancel, isEditable }: ActionsProps): JSX.Element => {
  const buttonsDefinition = useMemo(
    () => createButtonsDefinition(onSubmit, onCancel, isEditable, status),
    [onSubmit, onCancel, isEditable, status],
  )

  return (
    <x.div w="100%" display="flex" justifyContent="flex-end" mb={4}>
      <HidePrint>
        <ButtonsStrip mainButtons={buttonsDefinition} isLoading={isLoading} buttonsDisabled={isLoading} wrap />
      </HidePrint>
    </x.div>
  )
}

export default TransferActions
