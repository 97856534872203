import gql from 'graphql-tag'

const productStocksGetQuery = gql`
  query productStocksGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    productStocksGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: true })
      @rest(type: "ProductStockResult", method: "POST", path: "product-stock/list") {
      results
      paging
    }
  }
`

export const productStocksGetFiltersQuery = gql`
  query productcStocksGetFilters($path: String) {
    productStocksGetFilters(path: $path)
      @rest(type: "ProductStocksResultFilter", method: "GET", path: "codetable/expedition-filtering{args.path}") {
      warehouses
    }
  }
`

export default productStocksGetQuery
