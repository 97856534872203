import React from 'react'
import { ExpeditionDetailGridItem } from '@typings/entities/Expedition'
import { Trans } from '@lingui/react'
import { ModalProps } from '@components/blocks/BatchActionModal'
import ActionDialogContentWrapper from '@designSystem/ActionDialogContentWrapper'

type Props = ModalProps<ExpeditionDetailGridItem>

const getTransLabel = (label: string): { action: string } => ({ action: label })

const ActionsModalContent = ({ selectedActionItem, actionRows }: Props): JSX.Element => {
  return (
    <ActionDialogContentWrapper
      subtitle={
        <Trans
          id="dataGrid.actionsModal.text"
          message={'Do you want to perform action ”{action}” on these items?'}
          values={getTransLabel(selectedActionItem.label)}
        />
      }
    >
      {actionRows?.map((item: ExpeditionDetailGridItem, i: number) => (
        <div key={i}>{`${item.product.name} (${item.product.productSku})`}</div>
      ))}
    </ActionDialogContentWrapper>
  )
}

export default ActionsModalContent
