import React, { useMemo, memo } from 'react'
import { Trans } from '@lingui/react'
import { ProductFormTypes } from '../types'
import styled, { x } from '@xstyled/styled-components'
import { Input } from '@mailstep/design-system/ui/Forms/Input'
import { FormGrid } from '@components/blocks/FormGrid'
import { H5, H6 } from '@mailstep/design-system/ui/Elements/Typography'
import { withReduxActions } from '@mailstep/design-system/ui/Blocks/CommonGrid'
import { ColumnDefinition } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import sumBy from 'lodash/sumBy'
import { ResponsiveRowWrap } from '@components/forms/styles'

const ConnectedFormGrid = withReduxActions('ProductDetailStocks')(FormGrid)

const StyledSubtitle = styled(H6)`
  color: typoPrimary;
`

const StyledSubtitle2 = styled(H5)`
  color: red1;
  font-weight: medium;
  text-transform: uppercase;
  margin-top: 4;
  @media (min-width: 768px) {
    margin-top: 6;
  }
`

const columnsDefinitions: ColumnDefinition[] = [
  {
    name: 'wms.name',
    title: <Trans id="dataGrid.columnWmsName.title" message="Physical warehouse" />,
    flexBasis: 160,
    filtering: true,
    sorting: true,
    systemFilter: 'wms',
  },
  {
    name: 'warehouse.name',
    title: <Trans id="dataGrid.columnWarehouseName.title" message="Virtual warehouse" />,
    flexBasis: 160,
    filtering: true,
    sorting: true,
    systemFilter: 'warehouse',
  },
  {
    name: 'available',
    title: <Trans id="dataGrid.columnQtyAvailable.title" message="QTY available" />,
    filtering: true,
    filteringType: 'number',
    sorting: true,
    flexBasis: 80,
  },
  {
    name: 'booked',
    title: <Trans id="dataGrid.columnQtyReserved.title" message="QTY reserved" />,
    filtering: true,
    filteringType: 'number',
    sorting: true,
    flexBasis: 80,
  },
  {
    name: 'quantity',
    title: <Trans id="dataGrid.columnQtyStock.title" message="QTY stock" />,
    filtering: true,
    filteringType: 'number',
    sorting: true,
    flexBasis: 80,
  },
  {
    name: 'incoming',
    title: <Trans id="dataGrid.columnAwaitingInbound.title" message="Awaiting inbound" />,
    filtering: true,
    filteringType: 'number',
    sorting: true,
    flexBasis: 80,
  },
]

export type Props = {
  productStocks: ProductFormTypes['productStocks']
}

export const ProductStocksOverview = ({ productStocks }: Props): JSX.Element => {
  const filteredRows = useMemo(
    () => productStocks.filter((stock) => stock.available || stock.booked || stock.quantity || stock.incoming),
    [productStocks],
  )
  const availableTotal = sumBy(productStocks, 'available') || 0
  const bookedTotal = sumBy(productStocks, 'booked') || 0
  const quantityTotal = sumBy(productStocks, 'quantity') || 0
  const incomingTotal = sumBy(productStocks, 'incoming') || 0

  return (
    <x.div>
      <StyledSubtitle mb={2} mt={4}>
        <Trans id="product.stockOverview.headerTotal" message="Total values for all warehouse" />
      </StyledSubtitle>
      <ResponsiveRowWrap>
        <Input
          type="number"
          defaultValue={availableTotal}
          label={<Trans id="dataGrid.columnQtyAvailable.title" message="QTY available" />}
          disabled
        />
        <Input
          type="number"
          defaultValue={bookedTotal}
          label={<Trans id="dataGrid.columnQtyReserved.title" message="QTY reserved" />}
          disabled
        />
        <Input
          type="number"
          defaultValue={quantityTotal}
          label={<Trans id="dataGrid.columnQtyStock.title" message="QTY stock" />}
          disabled
        />
        <Input
          type="number"
          defaultValue={incomingTotal}
          label={<Trans id="dataGrid.columnAwaitingInbound.title" message="Awaiting inbound" />}
          disabled
        />
      </ResponsiveRowWrap>
      <StyledSubtitle2 mb={4}>
        <Trans id="product.stockOverview.headerPerWarehouse" message="Values per warehouses" />
      </StyledSubtitle2>
      <ConnectedFormGrid minColumnWidth={10} items={filteredRows} columnsDefinitions={columnsDefinitions} />
    </x.div>
  )
}

export default memo(ProductStocksOverview)
