import React from 'react'
import { ButtonsDefinition } from '@components/blocks/ButtonsStrip/types'
import { Trans } from '@lingui/react'
import { EditableStatuses, Status } from '@typings/enums/StockAdvice'

export const createButtonsDefinition = (
  onSubmit: (event: any) => void,
  onOpen: () => void,
  onClose: () => void,
  onDelete: () => void,
  isFormEditable: boolean,
  stockAdviceStatus?: keyof typeof Status,
): ButtonsDefinition => {
  const canDelete = isFormEditable && !!stockAdviceStatus && EditableStatuses.includes(stockAdviceStatus)
  const canSave = isFormEditable && (!stockAdviceStatus || EditableStatuses.includes(stockAdviceStatus))
  const canSend = isFormEditable && (stockAdviceStatus === 'new' || stockAdviceStatus === 'on_hold')
  const canClose = stockAdviceStatus === 'partially_finished' || stockAdviceStatus === 'waiting'
  const canOpen = stockAdviceStatus === 'closed'

  const onPrint = (): void => window.print()

  return [
    {
      name: 'print',
      label: <Trans id="form.buttonPrintAdvice" message="Print advice" />,
      isVisible: true,
      onClick: onPrint,
      appearance: 'secondary',
    },
    {
      name: 'delete',
      label: <Trans id="form.buttonDeleteAdvice" message="Delete advice" />,
      isVisible: canDelete,
      onClick: onDelete,
      appearance: 'secondary',
    },
    {
      name: 'save',
      label: <Trans id="form.buttonSave" message="Save" />,
      isVisible: canSave,
      onClick: onSubmit,
    },
    {
      name: 'saveAndSend',
      label: <Trans id="form.buttonSaveAndSend" message="Save &amp; send" />,
      isVisible: canSave && canSend,
      onClick: onSubmit,
    },
    {
      name: 'close',
      label: <Trans id="form.buttonClose" message="Close" />,
      isVisible: canClose,
      onClick: onClose,
    },
    {
      name: 'open',
      label: <Trans id="form.buttonOpen" message="Open" />,
      isVisible: canOpen,
      onClick: onOpen,
    },
  ]
}
