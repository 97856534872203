import { AccountAsNested } from '@typings/entities/User'
import { ComplaintReasonTrans, ComplaintResolutionTrans, ComplaintStatusTrans } from '@typings/enums/Complaint'
import { Expedition } from '@typings/entities/Expedition'

export enum QuickFilter {
  all = 'all',
  mine = 'mine',
  unassigned = 'unassigned',
}

export type QuickFilterType = keyof typeof QuickFilter

export const waitingStatuses = [
  'waiting_for_reporter',
  'waiting_for_resolver',
  'waiting_for_resolver_log',
  'waiting_for_cs',
  'waiting_for_ms',
  'waiting_for_carrier',
]

export type IComplaint = {
  actionIds?: string[]
  attachmentIds?: string[]
  changedAt: string
  createdAt: string
  createdBy: AccountAsNested
  currency: string
  id: string
  description: string
  internalId: string
  expedition?: Expedition
  organisationId: string
  reason: keyof typeof ComplaintReasonTrans
  reporter: AccountAsNested
  resolution: keyof typeof ComplaintResolutionTrans
  resolutionDetail: string
  resolver?: AccountAsNested
  status: keyof typeof ComplaintStatusTrans
  value?: string
}

export type IComplaintListItem = {
  changedAt: string
  createdAt: string
  createdBy: AccountAsNested
  currency: string
  id: string
  internalId: string
  order: {
    carrier: {
      id: string
      name: string
    }
    carrier_service: {
      id: string
      name: string
    }
    delivery_country: string
    eshop: {
      id: string
      name: string
    }
    id: string
    items: Array<{
      ean: string
      id: string
      sku: string
    }>
    number: string
    status: keyof typeof ComplaintStatusTrans
    tracking_number: string
    wms: {
      // dcCodeOfOrigin
      distribution_center: {
        code: string
        id: string
      }
      id: string
      name: string
    }
  }
  organisationId: string
  reason: keyof typeof ComplaintReasonTrans
  reporter: AccountAsNested
  resolution: keyof typeof ComplaintResolutionTrans
  resolver: AccountAsNested
  status: keyof typeof ComplaintStatusTrans
  value: number
}

export type IComplaintAttachment = {
  changedAt?: string
  complaintActionId?: string
  complaintId: string
  createdAt: string
  description: string
  id: string
  mimeType: string
  name: string
  originalName: string
  originalNameUpload: string
  size: number
  uploadedAt: string
  uploadedBy: AccountAsNested
  url: string
}

export const ComplaintActionType = {
  workflow: 'workflow',
  comment: 'comment',
}

export type IComplaintAction = {
  attachmentIds: string | null
  changedAt: string
  complaintId: string
  createdAt: string
  createdBy: AccountAsNested
  deleted: boolean
  id: string
  internal: boolean
  newStatus: keyof typeof ComplaintStatusTrans
  note: string | null
  previousStatus: keyof typeof ComplaintStatusTrans | null
  type: keyof typeof ComplaintActionType
}

export type ComplaintActionComment = {
  complaint?: string
  note?: IComplaintAction['note']
  internal?: IComplaintAction['internal']
  wait?: boolean
}

export type ComplaintDetailsUpdate = {
  id: string
  reporter?: string
  resolver?: string
  reason: keyof typeof ComplaintReasonTrans
  description?: string
  resolutionDetail?: string
  currency?: string
  value?: number
}
