import React, { useCallback } from 'react'
import { FieldArray } from 'formik'
import ExpeditionItemsField from './ExpeditionItemsField'
import { ExpeditionDetail } from '@typings/entities/Expedition'
import { formikCallback } from '@typings/Formik'
import { GridSelectorsType, GridActionsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { ProductApiRead } from '@typings/entities/Product'
import { ProductStockApi } from '@typings/entities/ProductStock'
import { StockAdviceItemApi } from '@typings/entities/StockAdvice'
import { FileSubmit, OnDownloadTemplate } from '@typings/file'

type Props = {
  loadProducts: (fulltext: string) => Promise<ProductApiRead[]>
  isEditable?: boolean
  isPairingEditable?: boolean
  setFieldValue: formikCallback
  warehouse: string
  wms: string
  loadProductStocks: (productIds: string[], warehouse: string, wms: string) => Promise<ProductStockApi[]>
  bookedData?: ExpeditionDetail['bookedData']
  itemsGridSelectors: GridSelectorsType
  itemsGridActions: GridActionsType
  lotsGridSelectors: GridSelectorsType
  lotsGridActions: GridActionsType
  pairingGridSelectors: GridSelectorsType
  pairingGridActions: GridActionsType
  isExpedited: boolean
  loadAdviceItems: (productId: string, warehouse: string, wms: string) => void
  adviceItems: StockAdviceItemApi[]
  adviceItemsIsLoading: boolean
  onOpenAdvice: (id: string) => void
  onImportItems: FileSubmit<{ product: ProductApiRead; quantity: number }[]>
  onDownloadImportTemplate: OnDownloadTemplate
  virtualItems: ExpeditionDetail['virtualItems']
  handleItemsReposition: () => void
  hasDuplicatePositions?: boolean
  canReposition: boolean
}

const ExpeditionItems = ({
  isEditable,
  isPairingEditable,
  loadProducts,
  setFieldValue,
  warehouse,
  wms,
  bookedData,
  loadProductStocks,
  itemsGridSelectors,
  itemsGridActions,
  lotsGridSelectors,
  lotsGridActions,
  pairingGridSelectors,
  pairingGridActions,
  isExpedited,
  loadAdviceItems,
  adviceItems,
  adviceItemsIsLoading,
  onOpenAdvice,
  onImportItems,
  onDownloadImportTemplate,
  virtualItems,
  handleItemsReposition,
  hasDuplicatePositions,
  canReposition,
}: Props): JSX.Element => {
  const ExpeditionItemsFieldRenderer = useCallback(
    (props) => (
      <ExpeditionItemsField
        {...props}
        isEditable={isEditable}
        isPairingEditable={isPairingEditable}
        loadProducts={loadProducts}
        setFieldValue={setFieldValue}
        warehouse={warehouse}
        wms={wms}
        bookedData={bookedData}
        loadProductStocks={loadProductStocks}
        gridSelectors={itemsGridSelectors}
        gridActions={itemsGridActions}
        lotsGridSelectors={lotsGridSelectors}
        lotsGridActions={lotsGridActions}
        pairingGridSelectors={pairingGridSelectors}
        pairingGridActions={pairingGridActions}
        isExpedited={isExpedited}
        loadAdviceItems={loadAdviceItems}
        adviceItems={adviceItems}
        adviceItemsIsLoading={adviceItemsIsLoading}
        onOpenAdvice={onOpenAdvice}
        onImportItems={onImportItems}
        onDownloadImportTemplate={onDownloadImportTemplate}
        virtualItems={virtualItems}
        handleItemsReposition={handleItemsReposition}
        hasDuplicatePositions={hasDuplicatePositions}
        canReposition={canReposition}
      />
    ),
    [
      isEditable,
      isPairingEditable,
      loadProducts,
      setFieldValue,
      warehouse,
      wms,
      bookedData,
      loadProductStocks,
      itemsGridSelectors,
      itemsGridActions,
      lotsGridSelectors,
      lotsGridActions,
      pairingGridSelectors,
      pairingGridActions,
      isExpedited,
      loadAdviceItems,
      adviceItems,
      adviceItemsIsLoading,
      onOpenAdvice,
      onImportItems,
      onDownloadImportTemplate,
      virtualItems,
      handleItemsReposition,
      hasDuplicatePositions,
      canReposition,
    ],
  )
  return <FieldArray name="items" render={ExpeditionItemsFieldRenderer} />
}

export default ExpeditionItems
