import reduceReducers from 'reduce-reducers'
import { getOr } from 'lodash/fp'
import produce from 'immer'
import createFetchModule from '@utils/store/createFetchModule'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'
import { FetchActionsWith } from '@typings/generic'

export const moduleName = 'eshops'

const fetchModule = createFetchModule('@store/Eshops', 'LOAD', moduleName)
const loadList = createFetchSubmodule('@store/Eshops', 'LOAD_LIST', moduleName)
const loadDetail = createFetchSubmodule('@store/Eshops', 'LOAD_DETAIL', moduleName)
const createSubmodule = createFetchSubmodule('@store/Eshops', 'CREATE', moduleName)
const updateSubmodule = createFetchSubmodule('@store/Eshops', 'UPDATE', moduleName)
const deleteSubmodule = createFetchSubmodule('@store/Eshops', 'DELETE', moduleName)
const createByznysUserSubmodule = createFetchSubmodule('@store/Eshops', 'CREATE_BYZNYS_USER', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  loadDetail: loadDetail.actionTypes,
  create: createSubmodule.actionTypes,
  update: updateSubmodule.actionTypes,
  delete: deleteSubmodule.actionTypes,
  createByznysUser: createByznysUserSubmodule.actionTypes, // TODO this action should be in byznysUser store
  loadList: loadList.actionTypes,
  setSelectedEshop: `@store/Eshops/SET_ESHOP`,
}

const ownReducer = (state: State, action: Action): State => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.setSelectedEshop:
        draft.eshop = action?.payload
        break
    }
  })
}

export default reduceReducers(fetchModule.reducer, loadDetail.reducer, loadList.reducer, ownReducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
  loadDetail: loadDetail.actions.run,
  create: createSubmodule.actions.run,
  update: updateSubmodule.actions.run,
  delete: deleteSubmodule.actions.run,
  createByznysUser: createByznysUserSubmodule.actions.run,
  loadList: loadList.actions.run,
  setSelectedEshop: (eshop: string) => ({ type: actionTypes.setSelectedEshop, payload: eshop }),
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
  ...loadDetail.selectors,
  ...loadList.selectors,
  // ... ownSelectors
  getSelectedEshop: (state) => getOr('', `eshop`, state?.eshops),
}
