import React from 'react'
import { Trans } from '@lingui/react'
import { ExchangeRate } from '@typings/entities/ExchangeRate'
import { ColumnDefinition } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'

export const createColumnDefinitions = (exchangeRates: ExchangeRate[]): ColumnDefinition[] => {
  return [
    {
      name: 'country',
      title: <Trans id="dataGrid.columnCountry.title" message="Country" />,
      filtering: true,
      sorting: true,
      flexBasis: 160,
      filterExtraProps: { isMulti: true },
      filterOptions: exchangeRates.map((v) => ({ value: v.country, label: v.country })),
    },
    {
      name: 'currency',
      title: <Trans id="dataGrid.columnCurrency.title" message="Currency" />,
      filtering: true,
      sorting: true,
      flexBasis: 90,
    },
    {
      name: 'code',
      title: <Trans id="dataGrid.columnCode.title" message="Code" />,
      filtering: true,
      sorting: true,
      flexBasis: 60,
      filterExtraProps: { isMulti: true },
      filterOptions: exchangeRates.map((v) => ({ value: v.code, label: v.code })),
    },
    {
      name: 'amount',
      title: <Trans id="dataGrid.columnAmount.title" message="Amount" />,
      filtering: true,
      sorting: true,
      flexBasis: 60,
      filteringType: 'number',
    },
    {
      name: 'course',
      title: <Trans id="dataGrid.columnRate.title" message="Rate" />,
      filtering: false,
      sorting: true,
      flexBasis: 160,
      filteringType: 'number',
    },
  ]
}

export default createColumnDefinitions
