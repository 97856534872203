import gql from 'graphql-tag'

const adminPasswordResetQuery = gql`
  query adminPasswordReset($email: String!) {
    adminPasswordReset(input: { email: $email, phone: $phone })
      @rest(type: "AdminPasswordReset", method: "POST", path: "request-reset-password/admin") {
      foo
    }
  }
`

export default adminPasswordResetQuery
