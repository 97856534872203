import { useField } from 'formik'
import { ButtonOption } from '@components/blocks/ButtonsStrip/types'

/**
 * Formik helper hook which helps you set active button inside forms
 * @returns selectedButton - active form button
 *          setSelectedButton - set active form button
 */
const useFormikButtonField = <ButtonValue = ButtonOption['name']>() => {
  const [, meta, helpers] = useField<ButtonValue>('button')
  const { setValue: setSelectedButton } = helpers
  const { value: selectedButton } = meta

  return { selectedButton, setSelectedButton }
}

// Helper to prevent unknown type in button value
const useAnyButtonField = () => useFormikButtonField<any>()
export type FormikButtonFieldType = ReturnType<typeof useAnyButtonField>

export default useFormikButtonField
