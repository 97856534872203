import gql from 'graphql-tag'
import { organisationDetailFields } from './organisationGetQuery'

const organisationCreateQuery = gql`
  query organisationCreate(
    $name: String
    $registrationNumber: String!
    $vatNumber: String!
    $primaryCountry: String!
    $owner: String!
    $active: Boolean!
    $addressLine1: String!
    $addressLine2: String!
    $addressLine3: String!
    $code: String
    $stockAdviceCloseInterval: Int
    $ignoreAddressValidation: Boolean
    $strictFifo: Boolean
    $automaticExchangeEnabled: Boolean
  ) {
    organisationCreate(
      input: {
        name: $name
        registrationNumber: $registrationNumber
        vatNumber: $vatNumber
        primaryCountry: $primaryCountry
        owner: $owner
        active: $active
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        addressLine3: $addressLine3
        code: $code
        stockAdviceCloseInterval: $stockAdviceCloseInterval
        ignoreAddressValidation: $ignoreAddressValidation
        automaticExchangeEnabled: $automaticExchangeEnabled
        strictFifo: $strictFifo
      }
    ) @rest(method: "POST", path: "organisation") {
      ${organisationDetailFields}
    }
  }
`

export default organisationCreateQuery
