import gql from 'graphql-tag'

const notificationList = gql`
  query notificationList($limit: Int, $filter: Object) {
    notificationList(input: { limit: $limit, filter: $filter })
      @rest(type: "NotificationsResults", method: "POST", path: "notification/list", endpoint: "gateway") {
      notifications
    }
  }
`

export default notificationList
