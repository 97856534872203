import reduceReducers from 'reduce-reducers'
import createFetchModule from '@utils/store/createFetchModule'
import { FetchActionsWith } from '@typings/generic'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'

export const moduleName = 'webhooksGrid'

const fetchModule = createFetchModule('@store/WebhooksGrid', 'LOAD', moduleName)
const exportSubmodule = createFetchSubmodule('@store/WebhooksGrid', 'EXPORT', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  export: exportSubmodule.actionTypes,
}

export default reduceReducers(fetchModule.reducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
  export: exportSubmodule.actions.run,
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
}
