import reduceReducers from 'reduce-reducers'
import produce from 'immer'
import getOr from 'lodash/fp/getOr'
import { CornerDialogState, CornerDialogType, ProcessDialog, LightBoxType, QuickAccessDialogs } from '@typings/UI'

export const actionTypes = {
  setLeftMenuCompact: `@store/UI/SET_LEFT_MENU_COMPACT`,
  setSettingsLeftMenuCompact: `@store/UI/SET_SETTINGS_LEFT_MENU_COMPACT`,
  pushDialog: `@store/UI/SET_CORNER_DIALOG`,
  pushProcessDialog: `@store/UI/SET_CORNER_PROCESS_DIALOG`,
  setCookieBannerHidden: `@store/UI/SET_COOKIE_BANNER_HIDDEN`,
  setQuickAccessDialogs: `@store/UI/SET_QUICK_ACCESS_DIALOGS`,
  pushQuickAccessDialogs: `@store/UI/PUSH_QUICK_ACCESS_DIALOGS`,
  setLightBox: `@store/UI/SET_LIGHT_BOX`,
  resetLightBox: `@store/UI/RESET_LIGHT_BOX`,
  setIncorrectExpeditions: `@store/UI/SET_INCORRECT_EXPEDITIONS`,
  setReservationsOnExpedited: `@store/UI/SET_RESERVATIONS_ON_EXPEDITED`,
  setComplaintWarnings: `@store/UI/SET_COMPLAINT_WARNINGS`,
  setUnseenNotifications: `@store/UI/SET_UNSEEN_NOTIFICATIONS`,
  setWarningsCount: `@store/UI/SET_WARNINGS_COUNT`,
  setInternalStockChanges: `@store/UI/SET_INTERNAL_STOCK_CHANGES`,
  setCameToSettingsFromPage: `@store/UI/SET_CAME_TO_SETTINGS_FROM_PAGE`,
}

const initialState: State = {
  leftMenuCompact: true,
  settingsLeftMenuCompact: false,
  quickAccessDialogs: null,
  incorrectExpeditions: false,
  warningsCount: 0,
  isCookieBannerHidden: false,
}

const initialLighBoxState: State = {
  lightbox: {
    isOpen: false,
    photoIndex: 0,
    images: [],
  },
}

// TODO add some generalize value storage generator

const ownReducer = (state: State = initialState, action: Action): State => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.setLeftMenuCompact:
        draft.leftMenuCompact = action?.payload
        break
      case actionTypes.setSettingsLeftMenuCompact:
        draft.settingsLeftMenuCompact = action?.payload
        break
      case actionTypes.pushDialog:
        draft.cornerDialog = action?.payload
        break
      case actionTypes.pushProcessDialog:
        draft.processDialog = action?.payload
        break
      case actionTypes.setCookieBannerHidden:
        draft.isCookieBannerHidden = action?.payload
        break
      case actionTypes.setQuickAccessDialogs:
        draft.quickAccessDialogs = action?.payload
        break
      case actionTypes.pushQuickAccessDialogs:
        draft.quickAccessDialogs = draft.quickAccessDialogs ? [...draft.quickAccessDialogs, action?.payload] : [action?.payload]
        break
      case actionTypes.setIncorrectExpeditions:
        draft.incorrectExpeditions = action?.payload
        break
      case actionTypes.setReservationsOnExpedited:
        draft.reservationsOnExpedited = action?.payload
        break
      case actionTypes.setComplaintWarnings:
        draft.waitingForReporter = action?.payload?.waitingForReporter
        draft.waitingForResolver = action?.payload?.waitingForResolver
        break
      case actionTypes.setUnseenNotifications:
        draft.unseenNotifications = action?.payload
        break
      case actionTypes.setWarningsCount:
        draft.warningsCount = action?.payload
        break
      case actionTypes.setInternalStockChanges:
        draft.internalStockChanges = action?.payload
        break
      case actionTypes.setCameToSettingsFromPage:
        draft.cameToSettingsFromPage = action?.payload
        break
    }
  })
}

const lighBoxReducer = (state: State = initialLighBoxState, action: Action): State => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.resetLightBox:
        draft.lightbox = initialLighBoxState
        break
      case actionTypes.setLightBox:
        draft.lightbox = { ...draft.lightbox, ...action?.payload }
        break
    }
  })
}

export default reduceReducers(ownReducer, lighBoxReducer)

export const actions = {
  setLeftMenuCompact: (compact: boolean) => ({ type: actionTypes.setLeftMenuCompact, payload: compact }),
  setSettingsLeftMenuCompact: (compact: boolean) => ({ type: actionTypes.setSettingsLeftMenuCompact, payload: compact }),
  pushDialog: (data: CornerDialogState | null) => ({ type: actionTypes.pushDialog, payload: data }),
  pushProcessDialog: (data: ProcessDialog | null) => ({ type: actionTypes.pushProcessDialog, payload: data }),
  setCookieBannerHidden: (hidden: boolean) => ({ type: actionTypes.setCookieBannerHidden, payload: hidden }),
  setQuickAccessDialogs: (dialogs: QuickAccessDialogs) => ({
    type: actionTypes.setQuickAccessDialogs,
    payload: dialogs,
  }),
  openQuickAccessDialog: (dialog: string, id?: string, extra?: any) => ({
    type: actionTypes.pushQuickAccessDialogs,
    payload: { dialog, id, extra },
  }),
  setLightBox: (data: LightBoxType) => ({ type: actionTypes.setLightBox, payload: data }),
  resetLightBox: () => ({ type: actionTypes.resetLightBox }),
  setIncorrectExpeditions: (count: number | null) => ({ type: actionTypes.setIncorrectExpeditions, payload: count }),
  setReservationsOnExpedited: (count: number | null) => ({ type: actionTypes.setReservationsOnExpedited, payload: count }),
  setComplaintWarnings: (counts: { waitingForReporter: number | null; waitingForResolver: number | null }) => ({
    type: actionTypes.setComplaintWarnings,
    payload: counts,
  }),
  setUnseenNotifications: (count: number | null) => ({ type: actionTypes.setUnseenNotifications, payload: count }),
  setWarningsCount: (count: number | null) => ({ type: actionTypes.setWarningsCount, payload: count }),
  setInternalStockChanges: (count: number | null) => ({ type: actionTypes.setInternalStockChanges, payload: count }),
  setCameToSettingsFromPage: (path: string) => ({ type: actionTypes.setCameToSettingsFromPage, payload: path }),
}

export const selectors = {
  default: (state: State, path?: string) => getOr(null, `${path}`, state?.ui), // todo delete when possible
  lightbox: (state: State) => getOr({}, `lightbox`, state?.ui),
  incorrectExpeditions: (state: State): number | null => getOr(null, `incorrectExpeditions`, state?.ui),
  reservationsOnExpedited: (state: State): number | null => getOr(null, `reservationsOnExpedited`, state?.ui),
  waitingForReporter: (state: State): number | null => getOr(null, `waitingForReporter`, state?.ui),
  waitingForResolver: (state: State): number | null => getOr(null, `waitingForResolver`, state?.ui),
  unseenNotifications: (state: State): number | null => getOr(null, `unseenNotifications`, state?.ui),
  warningsCount: (state: State): number | null => getOr(null, `warningsCount`, state?.ui),
  quickAccessDialogs: (state: State): QuickAccessDialogs => getOr(null, `quickAccessDialogs`, state?.ui),
  cornerDialog: (state: State): CornerDialogType | null => getOr(null, `cornerDialog`, state?.ui),
  processDialog: (state: State): ProcessDialog | null => getOr(null, `processDialog`, state?.ui),
  leftMenuCompact: (state: State): boolean => getOr(null, `leftMenuCompact`, state?.ui),
  settingsLeftMenuCompact: (state: State): boolean => getOr(false, `settingsLeftMenuCompact`, state?.ui),
  internalStockChanges: (state: State): number | null => getOr(null, `internalStockChanges`, state?.ui),
  isCookieBannerHidden: (state: State): boolean => getOr(null, `isCookieBannerHidden`, state?.ui),
  cameToSettingsFromPage: (state: State): string => getOr(null, `cameToSettingsFromPage`, state?.ui),
}
