import { t } from '@lingui/macro'

// TODO: fix product types (nested vales)

export type ChildrenProduct = {
  productId: string
  quantity: number
}

export type ProductType = 'virtual' | 'physical' | 'bundle'

type BareProduct = {
  id: string
  productSku: string
  internalSku: string
  eshops: string[]
  localisation: string[]
  organisation: string
  name: string
  weight: number | null
  height: number | null
  width: number | null
  length: number | null
  packagingType: string
  workAroundSnIn: boolean
  workAroundLot: boolean
  active: boolean
  productGroup: string
  hsCode?: string
  countryOfOrigin?: string
  description?: string
  type: ProductType
  childrenProducts?: ChildrenProduct[]
  canToggleLotEvidence?: boolean
  requiresAdditionalWork: boolean
  requiresQualitativeReceiving: boolean
}

export type ProductApiImage = {
  createdAt: string
  description: string | null
  dimensions: string[]
  id: string
  mimeType: string
  originalName: string | null
  size: number
  uploadedAt: string
  url: string
  urlLarge: string
  urlMedium: string
  urlSmall: string
}

export type ProductImage = {
  image?: Blob // base64 encoded form image to upload
  createdAt?: ProductApiImage['createdAt']
  id?: ProductApiImage['id']
  originalName?: ProductApiImage['originalName']
  urlSmall?: ProductApiImage['urlSmall']
  urlLarge?: ProductApiImage['urlLarge']
}

// todo fix type
export type Product = BareProduct & {
  images?: ProductImage[]
  intImages?: ProductImage[]
  intWeight: number | null
  intHeight: number | null
  intWidth: number | null
  intLength: number | null
  referenceNumbers: string[]
  inStock?: number | string
}

// todo fix type
export type ProductApiRead = BareProduct & {
  images?: ProductApiImage[]
  intImages?: ProductApiImage[]
  intWeight: number | null
  intHeight: number | null
  intWidth: number | null
  intLength: number | null
  referenceNumbers: string[]
  booked: number
  inStock: number
  type: ProductType
  quantityToStock: number
}

export type ProductApiWrite = BareProduct & {
  images?: ProductImage[]
  referenceNumbers: string[]
}

export const PackagingType = {
  A: 'Classic',
  B: 'Oversize',
}

export const PackagingTypeTrans = {
  A: () => t({ id: 'packagingType.A', message: `Classic` }),
  B: () => t({ id: 'packagingType.B', message: `Oversize` }),
}

export const ProductTypeTrans = {
  virtual: () => t({ id: 'productType.Virtual', message: `Virtual` }),
  physical: () => t({ id: 'productType.Physical', message: `Physical` }),
  bundle: () => t({ id: 'productType.Bundle', message: `Bundle` }),
}

export type ProductDetailItem = {
  id: string
  quantity: number
  product: {
    id?: Product['id']
    productSku?: Product['productSku']
    internalSku?: Product['internalSku']
    name?: Product['name']
    referenceNumbers?: Product['referenceNumbers']
    eshops?: Product['eshops']
  }
}

export type ProductDetail = Omit<Product, 'childrenProducts'> & {
  childrenProducts: ProductDetailItem[]
}
