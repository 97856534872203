import { useMemo } from 'react'
import { Option as SelectOption } from '@mailstep/design-system/ui/Elements/Select/types'
import { Carrier, CarrierService } from '@typings/entities/Carrier'
import { Country } from '@typings/entities/Country'
import { WarehouseNested } from '@typings/entities/Warehouse'
import { Partner } from '@typings/entities/Partner'
import { EshopApi } from '@typings/entities/Eshop'
import { ExpeditionDetail } from '@typings/entities/Expedition'
import { isExpeditionEditable } from '@utils/expedition'
import { type TabDefinition } from '@mailstep/design-system/ui/Blocks/Tabs'
import { t } from '@lingui/macro'

export const createOnCarrierServiceChange =
  (carrierServices: CarrierService[], setFieldValue: Function, setServiceFieldsMandatory?: Function) =>
  (selectedService = ''): void => {
    if (setServiceFieldsMandatory) {
      const service = carrierServices.find((service) => service.id === selectedService)
      setServiceFieldsMandatory({
        pickupPlace: !!service?.pickupPlaceMandatory,
        email: !!service?.emailMandatory,
        phone: !!service?.phoneMandatory,
        name: !!service?.nameMandatory,
      })
    }
    setFieldValue('carrierPickupPlace', '', false)
    setFieldValue('externalCarrierPickupPlace', '', false)
  }

export const createOnCountryChange =
  (countries: Country[], setFieldValue: Function, differentDeliveryAddress: boolean, partner: string) =>
  (addressType: 'billing' | 'delivery') =>
  (selectedCountry = ''): void => {
    const country = countries.find((country) => country.code === selectedCountry)
    // remove selected parner to avoid overwriting the address data from BE
    if (partner && addressType === 'billing') {
      setFieldValue('partner', null, false)
    }
    // keep currency from delivery part
    if (differentDeliveryAddress && addressType === 'billing') {
      return
    }
    if (country) {
      setFieldValue('currency', country.currencyCode, false)
      setFieldValue('codCurrency', country.currencyCode, false)
    }
  }

const mapPartnerToField = {
  billingFirstName: 'firstName',
  billingLastName: 'lastName',
  billingDegree: 'degree',
  billingCompany: 'companyName',
  billingEmail: 'email',
  billingPhone: 'phone',
  billingStreet: 'street',
  billingHouseNr: 'houseNr',
  billingCity: 'city',
  billingZip: 'zip',
  billingCountry: 'country',
  billingVatNumber: 'vatNumber',
  billingRegistrationNumber: 'registrationNumber',
}

export const createOnPartnerChange =
  (partners: Partner[], countries: Country[], setFieldValue: Function) =>
  (partnerId: string): void => {
    const partner = partners.find((partner) => partner.id === partnerId)
    if (partner) {
      Object.keys(mapPartnerToField).forEach((fieldItem) => {
        setFieldValue(fieldItem, partner[mapPartnerToField[fieldItem]])
      })
      const country = countries.find((country: Country) => country.code === partner.country)
      if (country) {
        setFieldValue('currency', country.currencyCode)
        setFieldValue('codCurrency', country.currencyCode)
      }
    } else {
      // partner deselected
      Object.keys(mapPartnerToField).forEach((fieldItem) => {
        setFieldValue(fieldItem, '')
      })
      setFieldValue('currency', '')
      setFieldValue('codCurrency', '')
    }
  }

export const createCarrierOptions = (carriers: Carrier[], selectedCountry = ''): SelectOption[] => {
  return carriers
    .filter((carrier) => {
      return carrier.countriesOfOperation?.includes(selectedCountry)
    })
    .map((carrier) => ({ value: carrier.id, label: carrier.name }))
}

export const createCarrierServicesOptions = (
  carrierServices: CarrierService[],
  selectedCarrier = '',
  selectedCountry = '',
): SelectOption[] => {
  return carrierServices
    .filter((service) => {
      return service.carrier == selectedCarrier && service.countriesOfOperation?.includes(selectedCountry)
    })
    .map((service) => ({ value: service.id, label: service.name, pickupType: service.carrierPickupPlace }))
}

export const createPartnerOptions = (partners: Partner[], selectedEshop = ''): SelectOption[] => {
  return partners
    .filter((partner) => partner.eshop === selectedEshop)
    .map((partner) => ({
      value: partner.id,
      label: partner.name,
    }))
}

export const createWarehouseOptions = (warehouses: WarehouseNested[], eshops: EshopApi[], selectedEshop = ''): SelectOption[] => {
  const currentWarehouses = eshops.find((eshop) => eshop.id === selectedEshop)?.warehouses || []
  return warehouses
    .filter((warehouse) => currentWarehouses.find((id) => id === warehouse.id))
    .map((warehouse) => ({
      value: warehouse.id,
      label: warehouse.name,
    }))
}

export type SelectOptionCrossdock = SelectOption[]

export const createWmsOptions = (warehouses: WarehouseNested[], selectedWarehouse = ''): SelectOptionCrossdock => {
  let wmsOptions: SelectOptionCrossdock = []
  const currentWarehouse = warehouses.find((warehouse) => warehouse.id === selectedWarehouse)
  if (currentWarehouse) {
    wmsOptions = currentWarehouse.wmses.map((wms) => ({
      value: wms.id,
      label: wms.name,
    }))
  }
  return wmsOptions
}

export const useIsEditable = (
  isEditPermitted: boolean,
  isCreatingNew: boolean,
  eshops: EshopApi[],
  values: ExpeditionDetail,
): boolean =>
  useMemo(() => {
    if (!isEditPermitted) return false
    if (isCreatingNew) return true
    const eshop = eshops.find((eshop) => eshop.id == values.eshop)
    return isExpeditionEditable(
      {
        status: values.status,
        editBeforeProcessing: values.editBeforeProcessing,
        waitBeforeProcessing: values.waitBeforeProcessing,
      },
      eshop,
    )
  }, [
    isEditPermitted,
    isCreatingNew,
    eshops,
    values.status,
    values.editBeforeProcessing,
    values.waitBeforeProcessing,
    values.eshop,
  ])

export const tabsDefinitions: (isCreatingNew: boolean) => TabDefinition[] = (isCreatingNew) => {
  if (isCreatingNew) {
    return [
      {
        icon: 'info',
        label: t({ id: 'form.heading.expeditionDetail', message: 'Expedition details' }),
        default: true,
      },
    ]
  } else
    return [
      {
        icon: 'info',
        label: t({ id: 'form.heading.expeditionDetail', message: 'Expedition details' }),
        default: true,
      },
      {
        icon: 'clock',
        label: t({ id: 'form.heading.expeditionHistory', message: 'History' }),
      },
    ]
}
