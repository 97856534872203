import styled from '@xstyled/styled-components'
import Logo from '@mailstep/design-system/ui/Elements/Logo'
import { th } from '@xstyled/styled-components'

export const Wrapper = styled.div`
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: end;
  justify-content: center;
`

export const BannerContainer = styled.div`
  margin-bottom: 20px;
  background-color: bgLightGray;
  width: 90%;
  border-radius: ml;
  height: fit-content;
  box-shadow: ${th('shadows.dropShadow')};
`

export const Header = styled.div`
  border-bottom: 2px solid ${th('colors.lightGray3')};
  display: flex;
  padding: 15px 20px;
`

export const MainLogo = styled(Logo)`
  width: 15%;
  height: auto;
  @media (min-width: 1024px) {
    width: 7%;
  }
`

export const TextContent = styled.div`
  padding: 10px 20px 0;
  line-height: 1.5;
`

export const ToggleWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`

export const ButtonsWrap = styled.div`
  display: flex;
  & > :not(:last-child) {
    margin-right: 15px;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px 20px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`
