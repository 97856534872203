import React from 'react'
import { x } from '@xstyled/styled-components'
import get from 'lodash/fp/get'
import isArray from 'lodash/isArray'
import Tooltip from '@mailstep/design-system/ui/Elements/Tooltip'

interface Props {
  rowData?: any
  path?: string | string[]
}

const TextCellWithPopup = ({ rowData, path }: Props): JSX.Element | null => {
  const value = path ? (isArray(path) ? path.map((key) => get(key, rowData)) : get(path, rowData)) : null

  const valueToDisplay = isArray(value) ? value.filter(Boolean).join(' | ') : value

  if (!valueToDisplay) return null

  return (
    <Tooltip subtitle={valueToDisplay}>
      <x.div cursor="pointer" w="100%">
        {valueToDisplay}
      </x.div>
    </Tooltip>
  )
}
export default TextCellWithPopup
