import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useApolloClient } from '@apollo/client'
import eshopBaselinkerSyncProducts from '@queries/eshopBaselinkerSyncProducts'
import eshopBaselinkerSyncProductStocks from '@queries/eshopBaselinkerSyncProductStocks'
import { actions as baselinkerBatchActions } from '@store/baselinkerBatch'
import { displayToast } from '@utils/toast'

// @TODO move this all to store/baselinkerBatch/sagas
export const useBaselinkerProductsSyncQuery = (): any => {
  const client = useApolloClient()
  const dispatch = useDispatch()

  const syncProducts = useCallback(
    async (eshopId: string, inventoryId: string) => {
      try {
        dispatch(baselinkerBatchActions.prepareSyncBatchProduct(true))
        const { data } = await client.query({
          query: eshopBaselinkerSyncProducts,
          variables: { eshopId, inventoryId },
        })
        const batchId = data?.eshopBaselinkerSyncProduct?.id
        if (batchId) {
          dispatch(baselinkerBatchActions.syncBatchProduct(batchId))
        } else {
          dispatch(baselinkerBatchActions.prepareSyncBatchProduct(false))
        }
        return { data }
      } catch (error) {
        console.error(error)
        dispatch(baselinkerBatchActions.prepareSyncBatchProduct(false))
        displayToast({ type: 'error', title: String(error) || 'error' })
        return { error }
      }
    },
    [client, dispatch],
  )

  const syncProductStocks = useCallback(
    async (warehouseId: string) => {
      try {
        dispatch(baselinkerBatchActions.prepareSyncBatchProductStock(true))
        const { data } = await client.query({
          query: eshopBaselinkerSyncProductStocks,
          variables: { warehouseId },
        })
        const batchId = data?.eshopBaselinkerSyncProductStocks?.id
        if (batchId) {
          dispatch(baselinkerBatchActions.syncBatchProductStock(batchId))
        } else {
          throw new Error('The sync request failed due to a missing ID.')
        }
        return { data }
      } catch (error) {
        console.error(error)
        dispatch(baselinkerBatchActions.prepareSyncBatchProductStock(false))
        displayToast({ type: 'error', title: String(error) || 'error' })
        return { error }
      }
    },
    [client, dispatch],
  )

  return { syncProducts, syncProductStocks }
}
