import { ApolloClient } from '@apollo/client'
import { call, put, getContext } from 'redux-saga/effects'

const promiseControlSymbol = Symbol.for('promiseControl')

export const createStartedAction = (moduleName: string): Action => ({ type: `${moduleName}_FETCH_STARTED` })

export const createSuccessAction = (moduleName: string, action: Action, response: any): Action => ({
  type: `${moduleName}_FETCH_SUCCEEDED`,
  [promiseControlSymbol]: action[promiseControlSymbol],
  ...response,
})

export const createFailedAction = (moduleName: string, action: Action, e: any): Action => ({
  ...action,
  [promiseControlSymbol]: action[promiseControlSymbol],
  type: `${moduleName}_FETCH_FAILED`,
  error: e && (e.message || e.toString()),
  errorCode: e?.networkError?.statusCode,
  networkError: e?.networkError?.result,
  violations: e?.violations,
})

const createFetchSaga = (moduleName: string, fetch: any) =>
  function* doFetch(action: Action) {
    const client: ApolloClient<any> = yield getContext('@apolloClient')
    yield put(createStartedAction(moduleName))
    try {
      const response = yield call(fetch, client, action)
      yield put(createSuccessAction(moduleName, action, response))
    } catch (e) {
      yield put(createFailedAction(moduleName, action, e))
    }
  }

export default createFetchSaga
