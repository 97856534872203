import wmsesGetQuery from '@queries/wmsesGetQuery'
import { Wms } from '@typings/entities/Wms'
import { useQuery } from '@apollo/client'
import { ApiHookResultWithReload } from '@typings/ApiHooks'

export const useLoadWMSList = (selectedOrganisation?: string): ApiHookResultWithReload<Wms[]> => {
  const {
    data,
    loading: isLoading,
    error,
    refetch: reload,
  } = useQuery(wmsesGetQuery, {
    variables: { criteria: { organisation: selectedOrganisation } },
    skip: !selectedOrganisation,
    fetchPolicy: 'no-cache',
  })

  return {
    data: data?.wmsesGet?.results,
    isLoading,
    error,
    reload,
  }
}
