import reduceReducers from 'reduce-reducers'
import produce from 'immer'
import jwtDecode from 'jwt-decode'
import createFetchModule from '@utils/store/createFetchModule'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'
import getOr from 'lodash/fp/getOr'
import { TokenData } from '@typings/UI'

export const moduleName = 'user'

const fetchModule = createFetchModule('@store/User', 'LOAD', moduleName)
const loginSubmodule = createFetchSubmodule('@store/User', 'LOGIN', moduleName)
const initCompleteSubmodule = createFetchSubmodule('@store/User', 'INIT_COMPLETE', moduleName)
const updateSubmodule = createFetchSubmodule('@store/User', 'UPDATE_USER', moduleName)
const updateMenuPreferencesSubmodule = createFetchSubmodule('@store/User', 'UPDATE_USER_MENU_PREFERENCES', moduleName)
const passwordResetSubmodule = createFetchSubmodule('@store/User', 'PASSWORD_RESET', moduleName)
const passwordResetConfirmSubmodule = createFetchSubmodule('@store/User', 'PASSWORD_RESET_CONFIRM', moduleName)

export const actionTypes = {
  load: fetchModule.actionTypes,
  login: loginSubmodule.actionTypes,
  logout: `@store/User/LOGOUT`,
  setAuth: `@store/User/SET_AUTH`,
  update: updateSubmodule.actionTypes,
  updateMenuPreferences: updateMenuPreferencesSubmodule.actionTypes,
  passwordReset: passwordResetSubmodule.actionTypes,
  passwordResetConfirm: passwordResetConfirmSubmodule.actionTypes,
  setInitComplete: initCompleteSubmodule.actionTypes.set,
}

const ownReducer = (state: State, action: Action): State =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.setAuth:
        let decoded: object & { sub: string }
        try {
          decoded = jwtDecode(action.payload.token)
        } catch (e) {
          console.error('Token could not be decoded', e, action.payload.token)
          return
        }
        draft.auth = {
          ...decoded,
          id: decoded?.sub,
          token: action.payload.token,
        }
        break
      case actionTypes.logout:
        draft.data = undefined
        draft.login = undefined
        draft.auth = undefined
        break
      case actionTypes.updateMenuPreferences.fetchSucceeded: {
        const preferences = getOr(null, 'data.userUpdateMenuPreferences.preferences', action)
        if (preferences) {
          draft.data = { ...draft.data, preferences }
        }
        break
      }
      case actionTypes.setInitComplete:
        draft.initComplete = {
          data: true,
        }
        break
    }
  })

export default reduceReducers(fetchModule.reducer, loginSubmodule.reducer, ownReducer)

export const actions = {
  load: fetchModule.actions.run,
  login: loginSubmodule.actions.run,
  logout: (): Action => ({ type: actionTypes.logout }),
  setAuth: ({ token, exp }: TokenData): Action => ({ type: actionTypes.setAuth, payload: { token, exp } }),
  update: updateSubmodule.actions.run,
  updateMenuPreferences: updateMenuPreferencesSubmodule.actions.run,
  passwordReset: passwordResetSubmodule.actions.run,
  passwordResetConfirm: passwordResetConfirmSubmodule.actions.run,
  setInitComplete: initCompleteSubmodule.actions.set,
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
  ...loginSubmodule.selectors,
  ...initCompleteSubmodule.selectors,
  getAuth: (state: State) => getOr(null, 'auth', state.user),
  getInitComplete: (state: State) => getOr(false, 'initComplete.data', state.user),
  getMenuPreferences: (state: State) => getOr(false, 'data.preferences', state.user) || [],
  isUserLogedIn: (state: State) => getOr(false, 'data.id', state.user),
}
