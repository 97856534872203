import React, { useCallback, useState, useRef, memo } from 'react'
import { t } from '@lingui/macro'
import styled, { css } from '@xstyled/styled-components'
import { th, ThemeGetterFunc } from '@xstyled/system'
import { Popper } from 'react-popper'
import { useClickOutside } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useClickOutside'
import { Plus1 } from '@mailstep/design-system/ui/Elements/Icon/icons/Plus1'
import { Portal } from '@mailstep/design-system/ui/Elements/Portal'
import useOutSideHover from '@hooks/useOutsideHover'
import { QuickMenuItem } from '../createMenuItems'
import { Paragraph3 } from '@mailstep/design-system/ui/Elements/Typography'

const ButtonWrapper = styled.div<{
  $isCompact: boolean
}>`
  position: relative;
  padding: 10px 0;
  ${({ $isCompact }) =>
    $isCompact &&
    css`
      display: flex;
      justify-content: center;
    `}
`

const StyledButton = styled.button<{
  $isActive: boolean
  $isCompact: boolean
}>`
  display: flex;
  align-items: center;
  min-width: 40px;
  width: ${({ $isCompact }) => ($isCompact ? '' : '100%')};
  height: 40px;
  justify-content: ${({ $isCompact }) => ($isCompact ? 'center' : 'flex-start')};
  background-color: ${({ $isActive }): ThemeGetterFunc => ($isActive ? th.color('red1') : th.color('blue3'))};
  border: 2px solid ${th.color('red1')};
  border-radius: 9px;
  cursor: pointer;
  gap: 2px;
  padding: ${({ $isCompact }) => ($isCompact ? 'inherit' : '9px 10px')};

  &:hover {
    background-color: ${th.color('red1')};
  }
`

const StyledSpan = styled(Paragraph3)`
  display: inline-block;
  margin-left: 13px;
  line-height: 14px;
  text-align: left;
  color: ${th.color('white')};
`

const ItemsWrapper = styled.div`
  position: absolute;
  inset: 0px auto auto 0px;
  background-color: neutral500;
  border-radius: 10px;
  transform: translateY(-50%);
  z-index: 5;
  &::before {
    content: '';
    position: absolute;
    border-radius: 10px;
    z-index: -1;
    width: calc(100% + 50px);
    height: calc(100% + 100px);
    left: 0;
    top: -50px;
  }
`

const Item = styled.div`
  padding: 10px 18px;
  letter-spacing: 0.4px;
  color: ${th.color('white')};
  font-size: 14px;
  line-height: 140%;
  font-weight: 500;
  cursor: pointer;
  :last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  :first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  :not(:last-child) {
    border-bottom: 0.5px solid ${th('colors.textTertiary')};
  }

  &:hover {
    background-color: ${th.color('blue2')};
  }
`

type Props = {
  isCompact: boolean
  items: QuickMenuItem[]
}
const CreateButton = ({ isCompact, items }: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false)

  const wrapperRef = useRef(null)
  const outsideClickRef = useClickOutside({ onClose: () => setIsExpanded(false) })
  const { ref: mouseOverRef, isHovering, handleMouseOver } = useOutSideHover({ parentRef: wrapperRef })

  const expand = useCallback(() => {
    if (!isCompact) {
      setIsExpanded(!isExpanded)
    }
  }, [isExpanded, isCompact])

  const handleItemClick = (onClick: unknown): void => {
    expand()

    if (typeof onClick === 'function') {
      onClick()
    }
  }

  return (
    <ButtonWrapper ref={wrapperRef} $isCompact={isCompact}>
      <StyledButton onClick={expand} onMouseOver={handleMouseOver} $isActive={isExpanded || isHovering} $isCompact={isCompact}>
        <Plus1 />
        {!isCompact && <StyledSpan variant="semiBold">{t({ id: 'menu.createBtn', message: 'Create' })}</StyledSpan>}
      </StyledButton>
      {((isHovering && isCompact) || (isExpanded && !isCompact)) && (
        <Portal>
          <Popper referenceElement={wrapperRef.current} innerRef={mouseOverRef} placement="right">
            {({ ref, style }): JSX.Element => (
              <ItemsWrapper ref={ref} style={style}>
                <div ref={outsideClickRef}>
                  {items.map((item, index: number) => (
                    // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
                    <Item key={index} onClick={(): void => handleItemClick(item.onClick)}>
                      {item.title}
                    </Item>
                  ))}
                </div>
              </ItemsWrapper>
            )}
          </Popper>
        </Portal>
      )}
    </ButtonWrapper>
  )
}

export default memo(CreateButton)
