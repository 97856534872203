import gql from 'graphql-tag'

const notificationUpdateQuery = gql`
  query notificationUpdate($id: String, $seen: Boolean, $flag: Boolean) {
    notificationUpdate(id: $id, input: { seen: $seen, flag: $flag })
      @rest(type: "Notification", method: "PUT", path: "notification/{args.id}", endpoint: "gateway") {
      id
      type
      seen
      flag
      createdAt
      text
      entityId
      userId
      organisationId
    }
  }
`

export default notificationUpdateQuery
