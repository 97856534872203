import React from 'react'
import { ExtraControlButton } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { Trans } from '@lingui/react'
import ButtonMore from '@mailstep/design-system/ui/Blocks/CommonGrid/components/ButtonMore'
import { Add } from '@mailstep/design-system/ui/Elements/Icon/icons/Add'
import styled from 'styled-components'

const StyledIcon = styled(Add)`
  margin-right: 8px;
`

export const getCreateButton = (onCreate: () => void, canCreate: boolean, disabled?: boolean): ExtraControlButton => ({
  label: <Trans id="dataGrid.actionOptions.createNew" message="Create new" />,
  onClick: onCreate,
  hide: !canCreate,
  iconLeft: <StyledIcon />,
  disabled,
})

export const getImportButton = (onImport: () => void, canImport: boolean, disabled?: boolean): ExtraControlButton => ({
  label: <Trans id="form.buttonImport" message="Import" />,
  onClick: onImport,
  hide: !canImport,
  disabled,
})

export const getMoreButton = (
  onExport: () => void,
  onImport?: () => void,
  canImport?: boolean,
  onExportWithLot?: () => void,
): ExtraControlButton => {
  // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
  const items = []

  if (onImport && canImport) {
    items.push({
      title: <Trans id="form.buttonImport" message="Import" />,
      onClick: onImport,
      link: '#',
      name: 'moreActionImportBtn',
    })
  }

  items.push({
    title: <Trans id="dataGrid.actionOptions.export" message="Export" />,
    onClick: onExport,
    link: '#',
    name: 'moreActionExportBtn',
  })

  if (onExportWithLot) {
    items.push({
      title: <Trans id="dataGrid.actionOptions.exportWithLot" message="Export with LOT" />,
      onClick: onExportWithLot,
      link: '#',
      name: 'moreActionExportWithLotBtn',
    })
  }

  return {
    node: <ButtonMore items={items} />,
  }
}
