import React from 'react'
import { Link } from 'react-router-dom'
import { Label } from '@mailstep/design-system/ui/Elements/Label'
import styled from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import { Trans } from '@lingui/react'
import { InboundReceipt } from '@typings/entities/InboundReceipt'
import { DateTime } from 'luxon'
import routes from '@pages/index'

const Wrapper = styled.div`
  margin-top: 0;
  width: 100%;
  label {
    font-size: 12px;
  }
  @media (min-width: 768px) {
    margin-left: 3;
    margin-top: 2;
  }
`

const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  & > * {
    margin-top: 1;
    margin-bottom: 1;
    color: ${th.color('typoPrimary')};
    font-size: 12px;
    font-weight: 700;
    text-decoration: underline;
  }
  border-radius: lg;
`

interface Props {
  list?: InboundReceipt[]
}

const Receipts = ({ list = [] }: Props): JSX.Element => {
  return (
    <Wrapper>
      <Label>
        <Trans id="form.receiptList.heading" message="List of Receipts" />
      </Label>
      <StyledWrap>
        {list.map((item, index) => (
          <Link key={`${item}-${index}`} to={routes.sharedInboundReceipt.route.toUrl({ id: item.id })}>
            <div>
              {DateTime.fromISO(item.createdAt).toFormat('dd. MM. yyyy')} -{' '}
              {item.wmsInternalId || <Trans id="form.receiptList.errorMissing" message="WMS id missing" />}
            </div>
          </Link>
        ))}
      </StyledWrap>
    </Wrapper>
  )
}
export default Receipts
