import React from 'react'
import { Wrapper } from './styles'
import { H1, H3 } from '@mailstep/design-system/ui/Elements/Typography'
import { Trans } from '@lingui/react'
import FaIcon from '@mailstep/design-system/ui/Elements/Icon'
import Button from '@mailstep/design-system/ui/Elements/Button'
import { x } from '@xstyled/styled-components'
import useCallbackWithInterval from '@hooks/useCallbackWithInterval'

const reload = () => window.location.reload()

const NewVersionError = () => {
  const { time } = useCallbackWithInterval({ initialTime: 15, callback: reload })

  return (
    <Wrapper>
      <x.div backgroundColor="rgba(255,255,255,0.8)" p="40px" borderRadius="10px">
        <FaIcon icon="info" size="80px" fill="red2" />
        <H1 mt="20">
          <Trans id="common.newVersionError.title" message="New version of our app has been released!" />
        </H1>
        <H3>
          <Trans id="common.newVersionError.description" message="It is necessary to reload the page in the browser." />
        </H3>
        <x.div display="flex" justifyContent="center" mt="30">
          <Button onClick={reload}>
            <Trans id="buttons.clickForReload" message="Click here for reload ({time})" values={{ time }} />
          </Button>
        </x.div>
      </x.div>
    </Wrapper>
  )
}

export default NewVersionError
