import { StockAdviceApi, StockAdviceDetail, StockAdviceDetailItem } from '@typings/entities/StockAdvice'
import { DateTime } from 'luxon'
import pick from 'lodash/pick'
import sumBy from 'lodash/sumBy'
import { Option } from '@mailstep/design-system/ui/Elements/Select/types'
import toSelectOption from '@utils/toSelectOption'
import { ApolloClient } from '@apollo/client'
import suppliersGetQuery from '@queries/suppliersGetQuery'

// TODO fix types

export const formatToRead = (initialValues: StockAdviceApi): StockAdviceDetail => ({
  ...initialValues,
  supplier: initialValues?.supplier || '',
  expectedAt: initialValues.expectedAt && new Date(initialValues.expectedAt),
  changedAt: initialValues.changedAt && new Date(initialValues.changedAt),
  createdAt: initialValues.createdAt && new Date(initialValues.createdAt),
  note: initialValues.note || '',
  items: (initialValues.items || []).map((item) => ({
    ...item,
    bookStockAdvices: item.bookStockAdvices || [],
    bookedStockAdvices: item.bookStockAdvices || [],
    bookAdviceTotal: sumBy(item?.bookStockAdvices || [], 'quantity') || 0,
    bookedAdviceTotal: sumBy(item?.bookStockAdvices || [], 'quantity') || 0,
  })),
})

export const formatToWrite = (values: StockAdviceDetail): StockAdviceApi => {
  const stockAdvice = { ...values }
  delete stockAdvice.changedAt
  delete stockAdvice.createdAt

  stockAdvice.supplier = stockAdvice.supplier || null

  const expectedAt = DateTime.fromJSDate(stockAdvice.expectedAt)
  if (expectedAt.isValid) {
    stockAdvice.expectedAt = expectedAt.toISO({ suppressMilliseconds: true })
  }

  stockAdvice.items = (stockAdvice.items || []).map((item: StockAdviceDetailItem) => ({
    product: item.product.id,
    quantity: item.quantity,
    unitPurchasePrice: item.unitPurchasePrice || null,
    unitPurchasePriceCurrency: !!item.unitPurchasePrice ? item.unitPurchasePriceCurrency : null,
    bookStockAdvices: item.bookStockAdvices?.map((booking) => pick(booking, ['expeditionItem', 'quantity'])) || [],
  }))

  return stockAdvice
}

export const onSearchSuppliers =
  (client: ApolloClient<any>, organisation?: string) =>
  async (text: string, byId: boolean): Promise<Option<string>[]> => {
    const criteria = byId
      ? { id: { eq: text } }
      : {
          organisation: { eq: organisation },
          ...(text !== '' ? { name: { like: `%${text}%` } } : {}),
        }
    return client
      .query({
        query: suppliersGetQuery,
        variables: { criteria },
      })
      .then(({ data }) => {
        return (data?.suppliersGet?.results || []).map(toSelectOption('id', 'name'))
      })
  }
