import gql from 'graphql-tag'

const stockMovementsGetQuery = gql`
  query stockMovementsGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    stockMovementsGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: true })
      @rest(type: "StockMovementsResult", method: "POST", path: "stock-movement/list") {
      results
      paging
    }
  }
`

export const stockMovementsGetFiltersQuery = gql`
  query stockMovementsGetFilters($path: String) {
    stockMovementsGetFilters(path: $path)
      @rest(type: "StockMovementsResultFilter", method: "GET", path: "codetable/stock-movement-filtering{args.path}") {
      warehouses
      wmses
    }
  }
`

export default stockMovementsGetQuery
