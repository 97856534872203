import styled from '@xstyled/styled-components'

export const RoundedBackground = styled.div`
  background-color: neutral20;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: lightGray7;
  }
`
