import styled, { useTheme } from '@xstyled/styled-components'
import React, { ReactText } from 'react'
import { th } from '@xstyled/system'
import { flagAppearanceToColorMap, flagAppearanceToFontColorMap, FlagAppearanceType } from '@constants/FlagAppearance'

const StyledHighlightBox = styled.div<{
  $bgcolor: ReactText
  $fontColor: ReactText
}>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: ml;
  font-size: 12px;
  text-align: center;
  line-height: 19.2px;
  min-width: 0;
  font-weight: semiBold;
  .default {
    height: 24px;
    padding: 2px 8px;
  }
  .small {
    height: 18px;
    padding: 1px 6px;
  }
  .wide {
    height: 2.75em;
    display: flex;
    flex-flow: row wrap;
    width: 11rem;
    align-content: center;
    line-height: 14px;
    padding: 0.5px 12px;
  }
  .fitContent {
    width: fit-content;
    height: auto;
    padding: 4px 5px;
    font-size: 13px;
    line-height: 15px;
    font-weight: 400;
  }
  background-color: ${({ $bgcolor }): ReactText => $bgcolor};
  color: ${({ $fontColor }): ReactText => $fontColor};
`

const OverflowWrap = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`

type HighlightBoxType = {
  children: React.ReactNode
  appearance: FlagAppearanceType
  size?: 'default' | 'small' | 'wide' | 'fitContent'
  prefix?: string
}

const HighlightBox = ({ children, appearance, size = 'default', prefix }: HighlightBoxType): JSX.Element => {
  const theme = useTheme()
  const bgColor = th.color(appearance ? flagAppearanceToColorMap[appearance] : 'transparent')({ theme })
  const fontColor = th.color(appearance ? flagAppearanceToFontColorMap[appearance] : 'typoPrimary')({ theme })

  return (
    <StyledHighlightBox $bgcolor={bgColor} $fontColor={fontColor}>
      <OverflowWrap className={size}>{children}</OverflowWrap>
    </StyledHighlightBox>
  )
}

export default HighlightBox
