import React, { useCallback } from 'react'
import styled from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import { useAcl } from '@components/blocks/Acl'
import { useUpdateNotification } from '@hooks/apiHooks/notifications/useUpdateNotification'
import { useGoToDetailHandler } from '@pages/SharedModule/Notifications/utils'
import { Spinner } from '@mailstep/design-system/ui/Elements/Spinner'
import { Trans } from '@lingui/react'
import FaIcon from '@mailstep/design-system/ui/Elements/Icon'
import { useGetNotificationList } from '@hooks/apiHooks/notifications/useGetNotificationList'
import NotificationBody from '@pages/SharedModule/Notifications/components/NotificationBody'
import Link from '@mailstep/design-system/ui/Elements/Link'

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 25px;
`

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 8px;

  a {
    font-size: 14px;
    color: ${th.color('blue2')};
    font-weight: 600;
  }
`

export const ArrowIcon = styled(FaIcon)`
  color: ${th.color('blue2')};
  font-size: 14px;

  svg {
    margin-left: 8px;
  }
`

type Props = {
  onClose: () => void
}

const NotificationsList = ({ onClose }: Props) => {
  const canRead = useAcl().can('read', 'Notifications')

  const { data: notifications, isLoading } = useGetNotificationList(!canRead, 3)

  const setNotificationFlags = useUpdateNotification()

  const closePopup = useCallback(() => {
    onClose()
  }, [onClose])

  const goToDetail = useGoToDetailHandler()

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    )
  }

  const notificationsSlice = notifications?.slice(0, 3)

  return (
    <div>
      {isLoading}
      {(notificationsSlice || []).map((notification) => (
        <NotificationBody
          key={notification?.id}
          notification={notification}
          setNotificationFlags={setNotificationFlags}
          goToDetail={goToDetail}
          minSpacing
        />
      ))}
      <LinkWrapper>
        <Link to="/user/notifications" onClick={closePopup}>
          <Trans id="notifications.showAll" message="Show all notifications" />
          <ArrowIcon icon="goDown" />
        </Link>
      </LinkWrapper>
    </div>
  )
}

export default NotificationsList
