import { useCallback } from 'react'
import { getFailHandler, savedMessage } from '@utils/containers/advancedFormHelpers'
import { FormikHelpers } from 'formik'
import { ApolloClient } from '@apollo/client'
import { TypedDocumentNode } from '@apollo/client'
import { displayToast } from '@utils/toast'

export type ChainedDataSubmit<ENT extends { id?: string }> = (
  values: ENT,
  formikHelpers: FormikHelpers<ENT>,
  isChained?: boolean,
) => Promise<string | void>

export const useAdvancedSubmit = <ENT extends { id?: string }>(
  client: ApolloClient<any>,
  createQuery: [TypedDocumentNode, string],
  editQuery: [TypedDocumentNode, string],
  onSuccess: (id: string) => void,
  formatValues?: (values: ENT) => any,
): ChainedDataSubmit<ENT> => {
  return useCallback(
    (values, formikHelpers, isChained) => {
      const handleFail = getFailHandler(formikHelpers, true)
      const formattedValues = formatValues ? formatValues(values) : values

      const query = values?.id ? editQuery[0] : createQuery[0]
      const result = values?.id ? editQuery[1] : createQuery[1]
      return client
        .query({ query, variables: formattedValues })
        .then((response) => {
          if (!isChained) {
            formikHelpers.setSubmitting(false)
            displayToast({ type: 'success', text: savedMessage() })
            onSuccess && onSuccess(response?.data?.[result]?.id)
          }
          return response?.data?.[result]?.id
        })
        .catch(handleFail)
    },
    [client, editQuery, createQuery, formatValues, onSuccess],
  )
}
