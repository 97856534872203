import styled from '@xstyled/styled-components'

// todo check if needed
export const StyledText = styled.span`
  font-weight: bold;
  font-size: 0.875rem;
  color: typoPrimary;
`

export const StyledSubTitle = styled.span`
  color: red1;
  font-size: 16px;
  font-weight: medium;
  text-transform: uppercase;
  margin-bottom: 1rem;
  display: block;
  width: fit-content;
  position: sticky;
  left: 24px;
`

// todo check if needed
export const StyledSubLabel = styled.span`
  font-weight: semiBold;
  font-size: 0.625rem;
  color: gray;
`
