import React, { ReactNode, FormEvent, useCallback } from 'react'
import { Trans } from '@lingui/react'
import Button from '@mailstep/design-system/ui/Elements/Button'
import { Ability } from '@casl/ability'
import { withAcl } from '@components/blocks/Acl'
import { ProductType } from '@typings/entities/Product'
import { formikCallback } from '@typings/Formik'
import Tabs from '@mailstep/design-system/ui/Blocks/Tabs'
import { TabContent } from '@mailstep/design-system/ui/Blocks/Tabs/TabContent'
import { useTabs } from '@mailstep/design-system/ui/Blocks/Tabs/hooks/useTabs'
import { stockTabsDefinitions } from './utils/tabsDefinitions'
import { useCheckDeviceWidth } from '@components/layouts/BaseLayout/utils'
import { ButtonsContainer } from './StocksTabWrap'

export type Props = {
  children: ReactNode
  ability: Ability
  isSubmitting: boolean
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  setType: React.Dispatch<React.SetStateAction<ProductType>>
  type: ProductType
  setFieldValue: formikCallback
}

export const StocksTabWrap = ({
  type,
  setFieldValue,
  setType,
  children,
  ability,
  isSubmitting,
  handleSubmit,
}: Props): JSX.Element => {
  const toggleType = useCallback(() => {
    setFieldValue('type', 'physical')
    setType('physical')
  }, [setType, setFieldValue])

  const { activeTab, onTabSwitch } = useTabs(stockTabsDefinitions())
  const { isMobile } = useCheckDeviceWidth()

  return (
    <>
      <Tabs mb="24px" tabsDefinition={stockTabsDefinitions()} activeTab={activeTab} onTabSwitch={onTabSwitch} />
      <TabContent activeTab={activeTab} value={0}>
        {children}
      </TabContent>

      <ButtonsContainer>
        {ability.can('edit', 'Product') && type !== 'virtual' && (
          <Button fullWidth={isMobile} variant="default" appearance="secondary" onClick={toggleType} type="button">
            <Trans id="form.buttonTransformToProduct" message="Transform to product" />
          </Button>
        )}
        {ability.can('edit', 'Product') && (
          <Button fullWidth={isMobile} type="submit" isLoading={isSubmitting} icon="check" onClick={handleSubmit}>
            <Trans id="form.edit.buttonSubmit" message="Submit" />
          </Button>
        )}
      </ButtonsContainer>
    </>
  )
}
export default withAcl(StocksTabWrap)
