import gql from 'graphql-tag'

const organisationActivateQuery = gql`
  query organisationActivate($id: String!) {
    organisationActivate(id: $id, input: {})
      @rest(type: "OrganisationActivate", method: "PUT", path: "organisation/activate/{args.id}") {
      status
    }
  }
`

export default organisationActivateQuery
