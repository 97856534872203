import React from 'react'
import { DataCellProps } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { t } from '@lingui/macro'
import getEnumOptions from '@utils/mapEnumToOptions'
import { Trans } from '@lingui/react'
import { get } from 'lodash/fp'
import { EnumInCell } from '@mailstep/design-system/ui/Blocks/CommonGrid'
import { statusToVariant, ItemReservationStatusTrans } from '@typings/entities/Reservation'

type CellProps = DataCellProps<{}>

export const getReservationStatusOptions = (includePaired?: boolean) => [
  { value: '', label: t({ id: 'dataGrid.columnReservationStatus.filterOptions.all', message: 'All' }) },
  ...getEnumOptions(ItemReservationStatusTrans, (key) => includePaired || key != 'paired'),
]

const ReservationStatus = ({ rowData, column }: CellProps): JSX.Element => {
  const value = get(column.name, rowData)

  if (rowData?.product?.type === 'virtual') {
    return <Trans id="dataGrid.columnLot.NA" message="N/A" />
  }

  return <EnumInCell value={value} appearanceMap={statusToVariant} enumObj={ItemReservationStatusTrans} />
}

export default ReservationStatus
