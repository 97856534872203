import { useSelectEshop } from '@hooks/useSelectEshop'
import * as baselinkerCredentialsStore from '@store/baselinkerCredentials'
import { BaselinkerCredentials, Eshop } from '@typings/entities/Eshop'
import { BaselinkerOrderStateListApi } from '@typings/entities/OrderSynchronization'
import { useFetchModuleSelector } from '@utils/containers/useFetchModule'
import { useBaselinkerConfig } from './useBaselinkerConfig'

type ModuleType<T> = { data: T; isLoading: boolean; error?: string; errorCode?: number; loadedAt?: number }
type ReturnType = [ModuleType<BaselinkerCredentials | undefined>, Eshop | undefined, BaselinkerOrderStateListApi]

export const useBaselinkerData = (): ReturnType => {
  const { validEshopSelected: selectedEshop } = useSelectEshop()

  const baselinkerCredentials = useFetchModuleSelector<BaselinkerCredentials>(baselinkerCredentialsStore, null)

  const baselinkerConfig = useBaselinkerConfig(selectedEshop, baselinkerCredentials.data?.syncDetailToken)

  return [baselinkerCredentials, selectedEshop, baselinkerConfig]
}
