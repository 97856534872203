import { THREE_DECIMAL_CURRENCIES, WHOLE_NUMBER_CURRENCIES } from '@constants/currencyDecimals'

export const getDecimalForCurrency = (value: string): number => {
  if (THREE_DECIMAL_CURRENCIES.includes(value)) {
    return 3
  } else if (WHOLE_NUMBER_CURRENCIES.includes(value)) {
    return 0
  } else {
    return 2
  }
}
