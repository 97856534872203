import React from 'react'
import { FormikContext } from 'formik'
import useFormikButtonField from '@hooks/useFormikButtonField'

const withFormikButtonField =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: Omit<P, 'selectedButton' | 'setSelectedButton'>): JSX.Element => {
    const formikContext = React.useContext(FormikContext)
    if (formikContext) {
      const { selectedButton, setSelectedButton } = useFormikButtonField()
      const pass = {
        ...props,
        selectedButton,
        setSelectedButton,
      }
      return <Component {...(pass as P)} />
    }

    return <Component {...(props as P)} />
  }

export default withFormikButtonField
