import React, { useCallback, useMemo } from 'react'
import styled, { x } from '@xstyled/styled-components'
import { FormikProps, FormikValues } from 'formik'
import getOr from 'lodash/fp/getOr'
import { FieldLabel } from '@mailstep/design-system/ui/Elements/Label'
import { ProductFormTypes } from '@components/forms/ProductEditForm/types'
import { ProductImage } from '@typings/entities/Product'
import ImageField from './ImageField'
import Image from '@mailstep/design-system/ui/Elements/Image'
import { useDropzone } from 'react-dropzone'
import FileDropZone from '@components/elements/FileDropZone'
import { Trans } from '@lingui/react'

export const ImageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1 0 1 1;

  .btn {
    margin-top: 1;
  }
`

type Props = {
  remove: Function
  push: Function
  name: string
  form: FormikProps<FormikValues>
  disabled?: boolean
  label?: string | JSX.Element
  openLightbox: ProductFormTypes['openLightbox']
  onDownload: (id: string, fileName?: string) => Promise<void>
}

const FullWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1;
  padding: 8px 3px;
`

const PreviewBox = styled.div`
  height: 140px;

  & > * {
    height: 100%;
  }
`

const PreviewImage = styled.div`
  padding: 12px;
  background-color: lightGray1;
  border: slim;
  border-color: lightGray3;
  border-radius: 8px;

  & > * {
    height: 100%;
  }

  display: flex;
  justify-content: center;
  color: gray;
  font-weight: medium;
  align-items: center;
`

const ImageList = styled.div`
  display: flex;
  flex-flow: wrap;
  margin: 12px;
`

const MAX_IMAGES = 4

const ImageArrayField = ({ remove, push, name, form, disabled, label, openLightbox, onDownload }: Props): JSX.Element => {
  const images: ProductImage[] = getOr([], name, form?.values)
  const errors = getOr(null, `errors.${name}`, form)

  const handleOpenLightbox = useCallback(
    (index: number) => {
      return openLightbox(images, index)
    },
    [openLightbox, images],
  )

  const handleRemoveClick = useCallback(
    (index: number) => {
      return remove(index)
    },
    [remove],
  )

  const previewImage = useMemo(() => (images?.length ? images[0] : null), [images])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openPicker,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles) => {
      const droppedFile = acceptedFiles?.[0]
      const newProductImage: ProductImage = {
        id: '',
        image: droppedFile, // todo fix typing
        urlSmall: URL.createObjectURL(droppedFile), // TODO add effect to clean up memory
        urlLarge: URL.createObjectURL(droppedFile),
        originalName: droppedFile?.name,
      }
      push(newProductImage)
    },
  })

  const isMaxedOut = !disabled && images.length >= MAX_IMAGES
  const transValuesMaximum = useMemo(() => ({ count: MAX_IMAGES }), [])

  return (
    <x.div w="100%">
      {label && <FieldLabel htmlFor={name}>{label}</FieldLabel>}
      <FullWrap>
        {!disabled ? (
          <PreviewBox>
            {isMaxedOut ? (
              <PreviewImage>
                <Trans
                  id="form.productImages.maximumReached"
                  message={'Maximum of {count} images reached'}
                  values={transValuesMaximum}
                />
              </PreviewImage>
            ) : (
              <FileDropZone
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                isDragActive={isDragActive}
                onOpen={openPicker}
                type="image"
                isCompact
              />
            )}
          </PreviewBox>
        ) : (
          <PreviewBox>
            <PreviewImage>
              <Image src={previewImage?.urlLarge} />
            </PreviewImage>
          </PreviewBox>
        )}
        <ImageList>
          {images.map((image, index: number) => (
            <ImageField
              key={index}
              index={index}
              image={image}
              openLightbox={handleOpenLightbox}
              onDelete={handleRemoveClick}
              onDownload={onDownload}
              disabled={disabled}
              error={errors?.[index]?.image || null}
            />
          ))}
        </ImageList>
      </FullWrap>
    </x.div>
  )
}

export default ImageArrayField
