/* eslint-disable react/display-name */
import React from 'react'
import { Field } from 'formik'
import InputField from '@components/forms/components/InputField'
import { ColumnDefinition, RowValueType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { Trans } from '@lingui/react'
import { ProductDetailItem } from '@typings/entities/Product'
import { RemoveCell } from '@mailstep/design-system/ui/Blocks/CommonGrid'

export const createColumnDefinitions = (
  isEditable: boolean,
  getIndex: (item: ProductDetailItem) => number,
): ColumnDefinition[] => {
  const columns = [
    {
      name: 'index',
      title: '#',
      sorting: true,
      flexBasis: 40,
      flexGrow: 0,
      flexShrink: 0,
      formatRowValue: (row: ProductDetailItem): RowValueType => `${getIndex(row) + 1}`,
    },
    {
      name: 'product.productSku',
      title: <Trans id="dataGrid.columnProductSku.title" message="Product SKU" />,
      filtering: true,
      sorting: true,
      flexBasis: 180,
    },
    {
      name: 'product.internalSku',
      title: <Trans id="dataGrid.columnInternalSku.title" message="Internal SKU" />,
      filtering: true,
      sorting: true,
      flexBasis: 180,
    },
    {
      name: 'product.name',
      title: <Trans id="dataGrid.columnProductName.title" message="Product name" />,
      filtering: true,
      sorting: true,
      flexBasis: 260,
    },
    {
      name: 'product.referenceNumbers',
      title: <Trans id="dataGrid.columnReferenceNumbers.title" message="Reference numbers" />,
      filtering: true,
      sorting: true,
      flexBasis: 220,
    },
    {
      name: 'suppliedQuantity',
      title: <Trans id="dataGrid.columnReceivedQty.title" message="Received QTY" />,
      filtering: true,
      sorting: true,
      filteringType: 'number',
      flexBasis: 120,
      flexShrink: 0,
      flexGrow: 0,
    },
    {
      name: 'quantity',
      title: <Trans id="dataGrid.columnTotalQty.title" message="Total quantity" />,
      formatRowValue: (row: ProductDetailItem): RowValueType => {
        return isEditable ? (
          <Field
            name={`childrenProducts[${getIndex(row)}][quantity]`} // todo the key in these should not be hardcoded
            type="number"
            component={InputField}
            min={1}
            errorAppearance="tooltip"
            appearance="grid"
          />
        ) : (
          row.quantity
        )
      },
      sorting: true,
      filtering: true,
      filteringType: 'number',
      fixedSize: isEditable,
      flexBasis: 140,
      flexShrink: 0,
      flexGrow: 0,
    },
    {
      name: 'awaitingQuantity',
      title: <Trans id="dataGrid.columnAwaitingQty.title" message="Awaiting quantity" />,
      filtering: true,
      filteringType: 'number',
      sorting: true,
      flexBasis: 120,
      flexShrink: 0,
      flexGrow: 0,
    },
  ]
  if (isEditable) {
    columns.push({
      name: 'buttons',
      title: '',
      cellComponent: RemoveCell,
      alwaysOn: true,
      flexBasis: 80,
      flexShrink: 0,
      flexGrow: 0,
    })
  }
  return columns
}
