import React from 'react'
import { H3 } from '@mailstep/design-system/ui/Elements/Typography'
import styled from '@xstyled/styled-components'
import { Trans } from '@lingui/react'
import { Spinner } from '@mailstep/design-system/ui/Elements/Spinner'

const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`

const LoadingScreen = (): JSX.Element => (
  <Wrapper>
    <H3 mb={3}>
      <Trans id="loading.heading" message="Loading ..." />
    </H3>
    <Spinner />
  </Wrapper>
)

export default LoadingScreen
