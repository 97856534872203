import React from 'react'
import styled, { x } from '@xstyled/styled-components'
import { Field } from 'formik'
import PartnerInputField from './PartnerInputField'
import { Trans } from '@lingui/react'
import { wrapperStyle } from '../styles'
import Col from '@designSystem/Grid/Col'
import SelectField from '@components/forms/components/SelectField'
import { Option as SelectOption } from '@mailstep/design-system/ui/Elements/Select/types'
import AutocompleteAddressInputField from './AutocompleteAddressInputField'
import { t } from '@lingui/macro'
import ErrorMessage from '@mailstep/design-system/ui/Elements/ErrorMessage'
import { ResponsiveRowWrap } from '@components/forms/styles'

const Wrapper = styled(x.div)`
  ${wrapperStyle}
`

type Props = {
  name: string
  countries?: SelectOption[]
  onCountryChange: Function
  isEditable?: boolean
  lockAddress?: boolean
}

const AddressField = ({ name, countries, onCountryChange, isEditable = false, lockAddress }: Props): JSX.Element => {
  return (
    <Wrapper>
      <ResponsiveRowWrap>
        <Col size={4}>
          <Field
            component={PartnerInputField}
            name={`${name}FirstName`}
            label={<Trans id="form.firstName.label" message="First name" />}
            spaceAround
          />
        </Col>
        <Col size={4}>
          <Field
            component={PartnerInputField}
            name={`${name}LastName`}
            label={<Trans id="form.lastName.label" message="Last name" />}
            spaceAround
          />
        </Col>
        <Col size={4}>
          <Field
            component={PartnerInputField}
            name={`${name}Degree`}
            label={<Trans id="form.degree.label" message="Degree" />}
            spaceAround
          />
        </Col>
      </ResponsiveRowWrap>
      <ResponsiveRowWrap>
        <Col size={4}>
          <Field
            component={PartnerInputField}
            name={`${name}Company`}
            label={<Trans id="form.company.label" message="Company" />}
            spaceAround
          />
        </Col>
        <Col size={4}>
          <Field
            component={PartnerInputField}
            name={`${name}Email`}
            label={<Trans id="form.email.label" message="Email address" />}
            spaceAround
          />
        </Col>
        <Col size={4}>
          <Field
            component={PartnerInputField}
            name={`${name}Phone`}
            label={<Trans id="form.phoneNumber.label" message="Phone number" />}
            spaceAround
            disabled={!isEditable}
            isPrefixOptional={true}
          />
        </Col>
      </ResponsiveRowWrap>
      <ResponsiveRowWrap>
        <Col size={8}>
          <Field
            component={AutocompleteAddressInputField}
            name={`${name}Street`}
            label={<Trans id="form.street.label" message="Street" />}
            spaceAround
            disabled={lockAddress}
          />
          {lockAddress && (
            <ErrorMessage>{t({ id: 'form.country.errorEmpty', message: 'Please, select the country.' })}</ErrorMessage>
          )}
        </Col>
        <Col size={4}>
          <Field
            component={PartnerInputField}
            name={`${name}HouseNr`}
            label={<Trans id="form.houseNr.label" message="House nr." />}
            spaceAround
            disabled={lockAddress}
          />
          {lockAddress && (
            <ErrorMessage>{t({ id: 'form.country.errorEmpty', message: 'Please, select the country.' })}</ErrorMessage>
          )}
        </Col>
      </ResponsiveRowWrap>
      <ResponsiveRowWrap>
        <Col size={4}>
          <Field
            component={PartnerInputField}
            name={`${name}City`}
            label={<Trans id="form.city.label" message="City" />}
            spaceAround
            disabled={lockAddress}
          />
          {lockAddress && (
            <ErrorMessage>{t({ id: 'form.country.errorEmpty', message: 'Please, select the country.' })}</ErrorMessage>
          )}
        </Col>
        <Col size={4}>
          <Field
            component={PartnerInputField}
            name={`${name}Zip`}
            label={<Trans id="form.zip.label" message="ZIP" />}
            spaceAround
            disabled={lockAddress}
          />
          {lockAddress && (
            <ErrorMessage>{t({ id: 'form.country.errorEmpty', message: 'Please, select the country.' })}</ErrorMessage>
          )}
        </Col>
        <Col size={4}>
          <Field
            component={SelectField}
            name={`${name}Country`}
            label={<Trans id="form.country.label" message="Country" />}
            options={countries}
            spaceAround
            onValueChange={onCountryChange(name)}
            disabled={!isEditable}
          />
        </Col>
      </ResponsiveRowWrap>
    </Wrapper>
  )
}

export default AddressField
