import { Notification } from '@typings/entities/Notification'
import routes from '@pages/index'
import { useHistory } from 'react-router'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions as uiActions } from '@store/ui'

type GoToDetailFn = (notification: Notification) => void

export const useGoToDetailHandler = (): GoToDetailFn => {
  const history = useHistory()
  const dispatch = useDispatch()
  return useCallback(
    (notification: Notification): void => {
      if (notification.type === 'ProductStockMinCountLeft') {
        const productId = notification.entityId
        history.push(routes.sharedStocksProduct.route.toUrl({ productId }))
      } else if (notification.type === 'ProductGroupStockMinCountLeft') {
        const productId = notification.entityId
        history.push(routes.sharedStocksProduct.route.toUrl({ productId }))
      } else if (notification.type === 'StockAdviceStatusChange') {
        const id = notification.entityId
        dispatch(uiActions.openQuickAccessDialog('advice', id))
      } else if (notification.type === 'InboundNew') {
        const id = notification.entityId
        history.push(routes.sharedInboundReceipt.route.toUrl({ id }))
      } else if (notification.type === 'ComplaintStatusChange') {
        const id = notification.entityId
        history.push(routes.sharedComplaint.route.toUrl({ id }))
      } else {
        console.error('UNHANDLED NOTIFICATION DETAIL ACTION')
      }
    },
    [dispatch, history],
  )
}
