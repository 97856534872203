import gql from 'graphql-tag'

const carrierPickupPlacesGetQuery = gql`
  query carrierPickupPlacesGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    carrierPickupPlacesGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: $nested })
      @rest(type: "CarrierPickupPlaces", method: "POST", path: "carrier-pickup-place/list") {
      results
      paging
    }
  }
`

export default carrierPickupPlacesGetQuery
