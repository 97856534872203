import React from 'react'
import GridPageHeader from '@components/blocks/GridPageHeader'
import { ExtraControlButton } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { useCurrentPage } from '@hooks/useCurrentPage'

type Props = {
  heading?: JSX.Element | string
  description?: JSX.Element | string
  breadcrumbsItems?: string
  eshopId?: string
  error?: Error
  icon?: JSX.Element
  children?: React.ReactNode
  itemsCount?: number
  $flexCol?: boolean
  extraControlButtons?: ExtraControlButton[]
}

/**
 * Container which extracts data from current main menu
 * @returns header with Heading, heading detail & related tabs as links
 */
const MenuPageHeader = ({ heading, description, error, extraControlButtons, eshopId, ...props }: Props): JSX.Element => {
  const currentPage = useCurrentPage(eshopId)

  return (
    <GridPageHeader
      heading={heading ?? <>{currentPage?.title || ''}</>}
      description={description ?? (currentPage?.description || '')}
      error={error}
      extraControlButtons={extraControlButtons}
      eshopId={eshopId}
      {...props}
    />
  )
}

export default MenuPageHeader
