import gql from 'graphql-tag'
import { expeditionDetailFields } from './expeditionGetQuery'

const expeditionRepositionQuery = gql`
  query expeditionReposition($id: String!) {
    expeditionReposition(input: {}, id: $id)
      @rest(type: "ExpeditionsListResult", method: "PUT", path: "expedition/{args.id}/reposition") {
        ${expeditionDetailFields}
    }
  }
`

export default expeditionRepositionQuery
