import React, { FormEvent, useCallback, useMemo } from 'react'
import { FormikHelpers, FormikValues } from 'formik'
import { FormikHandleSubmit } from '@typings/Formik'
import { Status } from '@typings/enums/StockAdvice'
import ButtonsStrip from '@components/blocks/ButtonsStrip'
import HidePrint from '@mailstep/design-system/ui/Blocks/HidePrint/HidePrint'
import { createButtonsDefinition } from '../buttonsDefinition'
import { x } from '@xstyled/styled-components'

interface ActionsProps {
  onOpen: FormikHandleSubmit
  onClose: FormikHandleSubmit
  onDelete: FormikHandleSubmit
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  setSubmitting: FormikHelpers<FormikValues>['setSubmitting']
  isEditable: boolean
  isSubmitting: boolean
  values: FormikValues
  status?: keyof typeof Status
}

const StockAdviceActions = ({
  handleSubmit,
  onOpen,
  onClose,
  onDelete,
  isEditable,
  isSubmitting,
  setSubmitting,
  values,
  status,
}: ActionsProps): JSX.Element => {
  const partialFormikHelpers = useMemo(() => {
    return { setSubmitting } as FormikHelpers<{}>
  }, [setSubmitting])

  const handleOpen = useCallback(() => {
    onOpen(values, partialFormikHelpers)
  }, [onOpen, values, partialFormikHelpers])

  const handleClose = useCallback(() => {
    onClose(values, partialFormikHelpers)
  }, [onClose, values, partialFormikHelpers])

  const handleDelete = useCallback(() => {
    onDelete(values, partialFormikHelpers)
  }, [onDelete, values, partialFormikHelpers])

  const buttonsDefinition = useMemo(
    () => createButtonsDefinition(handleSubmit, handleOpen, handleClose, handleDelete, isEditable, status),
    [handleSubmit, handleOpen, handleClose, handleDelete, isEditable, status],
  )

  return (
    <x.div w="100%" display="flex" justifyContent="flex-end" mb={4}>
      <HidePrint>
        <ButtonsStrip mainButtons={buttonsDefinition} isLoading={isSubmitting} wrap />
      </HidePrint>
    </x.div>
  )
}

export default StockAdviceActions
