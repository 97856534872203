import React from 'react'
import { ArrowForward } from '@mailstep/design-system/ui/Elements/Icon/icons/ArrowForward'
import { Link } from 'react-router-dom'
import styled, { css, useTheme } from '@xstyled/styled-components'
import { th } from '@xstyled/system'

const StyledText = css`
  font-size: 12px;
  line-height: 160%;
  font-weight: semiBold;
`

const StyledLink = styled(Link)`
  color: red60;
  ${StyledText}
`

const StyledSpan = styled.span`
  color: ${th.color('gray1')};
  ${StyledText}
`

const StyledArrow = styled(ArrowForward)`
  margin: 0 7px;
  vertical-align: middle;
`

type BreadcrumbItemProps = {
  isLast?: boolean
  link?: string
  title: string
}

const BreadcrumbItem = ({ title, link, isLast }: BreadcrumbItemProps): JSX.Element => {
  const theme = useTheme()
  const lightGrayColor = th.color('lightGray5')({ theme }) as string

  const RenderedComponent: React.ElementType = isLast ? StyledSpan : StyledLink

  return (
    <>
      <RenderedComponent to={link}>{title}</RenderedComponent>
      {!isLast && <StyledArrow fill={lightGrayColor} />}
    </>
  )
}

export default BreadcrumbItem
