import { Expedition, EditableStatuses, MetaLotOptionsTrans } from '@typings/entities/Expedition'
import { Eshop, EshopApi } from '@typings/entities/Eshop'

export const isExpeditionEditable = (
  expedition: Pick<Expedition, 'status' | 'waitBeforeProcessing' | 'editBeforeProcessing'>,
  eshop?: Pick<Eshop | EshopApi, 'waitBeforeProcessing' | 'editBeforeProcessing'>,
): boolean => {
  if (EditableStatuses.includes(expedition.status)) {
    return true
  }
  if (expedition.status == 'stock_ok') {
    const editBeforeProcessing =
      expedition.editBeforeProcessing !== null ? expedition.editBeforeProcessing : eshop?.editBeforeProcessing
    const waitBeforeProcessing =
      expedition.waitBeforeProcessing !== null ? expedition.waitBeforeProcessing : eshop?.waitBeforeProcessing
    return !!(editBeforeProcessing && waitBeforeProcessing)
  }
  return false
}

export const isMetaLot = (lot?: string): boolean => {
  if (!lot) return false
  return MetaLotOptionsTrans()
    .map((lotOption) => lotOption.value)
    .includes(lot)
}
