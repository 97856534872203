import { ApolloClient, ApolloQueryResult } from '@apollo/client'
import { push } from 'connected-react-router'
import { selectors as routerSelectors } from '@store/connectedRouter'
import { t } from '@lingui/macro'
import { takeLatest, put, call, fork, select } from 'redux-saga/effects'
import { actionTypes, actions } from './index'
import { actions as organisationsActions } from '@store/organisations'
import { actions as countriesActions } from '@store/countries'
import { actions as adminsActions } from '@store/admins'
import adminGetQuery from '@queries/adminGetQuery'
import adminUpdateQuery from '@queries/adminUpdateQuery'
import adminUpdateMenuPreferencesQuery from '@queries/adminUpdateMenuPreferencesQuery'
import adminPasswordResetConfirmQuery from '@queries/adminPasswordResetConfirmQuery'
import adminPasswordResetQuery from '@queries/adminPasswordResetQuery'
import organisationActivateQuery from '@queries/organisationActivateQuery'
import organisationCreateQuery from '@queries/organisationCreateQuery'
import organisationDeactivateQuery from '@queries/organisationDeactivateQuery'
import organisationUpdateQuery from '@queries/organisationUpdateQuery'
import loginQuery from '@queries/adminLoginQuery'
import createFetchSaga from '@utils/store/createFetchSaga'
import pages from '@pages/index'
import jwtDecode from 'jwt-decode'
import { displayToast } from '@utils/toast'
import { DecodedToken } from '@typings/UI'
import { setTokenToLocalStorage } from '@utils/getAuthorizationToken'

const fetchMe = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  // need to use token because storage is not fast enough
  try {
    const currentToken = localStorage?.adminToken
    const decoded = jwtDecode<DecodedToken>(currentToken)
    const adminId = decoded?.sub
    return client
      .query({
        query: adminGetQuery,
        variables: { id: adminId },
      })
      .then(({ data }) => ({ data: data?.adminGet }))
  } catch (e) {}
}

const fetchUpdateAdmin = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: adminUpdateQuery, variables: { ...action.payload } })
}

const fetchUpdateAdminMenuPreferences = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const currentToken = localStorage?.adminToken
  const decoded = jwtDecode<DecodedToken>(currentToken)
  const adminId = decoded?.sub
  const { preferences } = action.payload

  if (adminId && preferences) {
    try {
      const res = await client.query({ query: adminUpdateMenuPreferencesQuery, variables: { id: adminId, preferences } })
      displayToast({
        type: 'success',
        text: t({ id: 'form.alertChangesSuccessfullySaved', message: 'Changes have been successfully saved.' }),
      })
      return res
    } catch (error) {
      displayToast({
        type: 'error',
        text: t({ id: 'form.alertChangesNotSaved', message: 'Changes have not been saved.' }),
      })
    }
  }
}

function* loadAdmin(): Generator {
  yield put(actions.load())
  yield put(countriesActions.loadList())
  yield put(organisationsActions.run())
  yield put(adminsActions.run())
}

const fetchLogin = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({
      query: loginQuery,
      variables: action.payload,
      context: {
        suppressError: () => true,
      },
    })
    .then(({ data }) => ({ data: data?.login }))
}

function* onLoginSuccess(action: Action): Generator {
  yield call([window.localStorage, window.localStorage.setItem], 'adminRefreshToken', action?.data?.refreshToken)
  yield put(actions.setAuth({ token: action?.data?.token, exp: action?.data?.exp }))
  yield call(loadAdmin)
  yield put(push(pages.adminHome.route.toUrl()))
}

export function* onInitAdminSuccess(): Generator {
  yield call(loadAdmin)
  const path = (yield select(routerSelectors.pathnameSelector)) as string

  if (path == pages.userLogin.route.toUrl()) {
    yield put(push(pages.userHome.route.toUrl()))
  }
}

function* onSetAuth(action: Action): Generator {
  setTokenToLocalStorage('admin', action?.payload?.token)
}

function* onLogout(): Generator {
  yield call([window.localStorage, window.localStorage.removeItem], 'adminToken')
  yield call([window.localStorage, window.localStorage.removeItem], 'adminRefreshToken')
  const path = (yield select(routerSelectors.pathnameSelector)) as string
  if (!/^\/admin\/login/.test(path)) yield put(push(pages.adminLogin.route.toUrl()))
}

const fetchPasswordResetQuery = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: adminPasswordResetQuery, variables: action.payload })
}

const fetchPasswordResetConfirmQuery = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: adminPasswordResetConfirmQuery, variables: action.payload })
}

// TODO!! - organisation actions should be in organistaion/saga At least becasue users use them too

const fetchUpdateOrganisation = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: organisationUpdateQuery, variables: action.payload })
}

const fetchCreateOrganisation = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: organisationCreateQuery, variables: action.payload })
}

const fetchActivateOrganisation = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: organisationActivateQuery, variables: action.payload }).then(() => {
    window.location.reload()
  })
}

const fetchDeactivateOrganisation = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: organisationDeactivateQuery, variables: action.payload }).then(() => {
    window.location.reload()
  })
}

function* fetchOrgChangeSuccess(action: Action): Generator {
  yield put(organisationsActions.run())
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return // dont run on SSR
  yield takeLatest(actionTypes.load.run, createFetchSaga(actionTypes.load.run, fetchMe))
  yield takeLatest(actionTypes.login.run, createFetchSaga(actionTypes.login.run, fetchLogin))
  yield takeLatest(actionTypes.login.fetchSucceeded, onLoginSuccess)
  yield takeLatest(actionTypes.logout, onLogout)
  yield takeLatest(actionTypes.setAuth, onSetAuth)
  yield takeLatest(actionTypes.update.run, createFetchSaga(actionTypes.update.run, fetchUpdateAdmin))
  yield takeLatest(
    actionTypes.updateMenuPreferences.run,
    createFetchSaga(actionTypes.updateMenuPreferences.run, fetchUpdateAdminMenuPreferences),
  )
  yield takeLatest(actionTypes.editOrganisation.run, createFetchSaga(actionTypes.editOrganisation.run, fetchUpdateOrganisation))
  yield takeLatest(actionTypes.editOrganisation.fetchSucceeded, fetchOrgChangeSuccess)
  yield takeLatest(
    actionTypes.createOrganisation.run,
    createFetchSaga(actionTypes.createOrganisation.run, fetchCreateOrganisation),
  )
  yield takeLatest(actionTypes.createOrganisation.fetchSucceeded, fetchOrgChangeSuccess)
  yield takeLatest(
    actionTypes.activateOrganisation.run,
    createFetchSaga(actionTypes.activateOrganisation.run, fetchActivateOrganisation),
  )
  yield takeLatest(
    actionTypes.deactivateOrganisation.run,
    createFetchSaga(actionTypes.deactivateOrganisation.run, fetchDeactivateOrganisation),
  )
  yield takeLatest(actionTypes.passwordReset.run, createFetchSaga(actionTypes.passwordReset.run, fetchPasswordResetQuery))
  yield takeLatest(
    actionTypes.passwordResetConfirm.run,
    createFetchSaga(actionTypes.passwordResetConfirm.run, fetchPasswordResetConfirmQuery),
  )
}
