import { getLanguage, setLanguage } from '@utils/language'

type ReturnProps = {
  activeLanguage?: string
  onLanguageChange: (value: string) => void
}

const useLanguageSwitch = (): ReturnProps => {
  const activeLanguage = getLanguage()
  const onLanguageChange = setLanguage

  return { activeLanguage, onLanguageChange }
}

export default useLanguageSwitch
