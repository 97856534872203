import styled from '@xstyled/styled-components'
import Button from '@mailstep/design-system/ui/Elements/Button'

export const TemplateRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const StatusWrap = styled.div`
  width: 100%;
  min-height: 34px;
  padding: 0 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 6px;
`

export const StatusUpload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 5px 0;
`

export const InnerWrap = styled.div`
  & > :not(:last-child) {
    margin-bottom: 2;
  }

  & > :first-child {
    margin-bottom: 4;
    align-self: flex-start;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
`
