import React from 'react'
import { useFormikContext } from 'formik'
import Alert from '@mailstep/design-system/ui/Elements/Alert'
import LoginErrorAlert from '@designSystem/LoginErrorAlert'
import { getCommonError, getFormErrors, getInitialCommonErrors } from '@utils/formik/getFieldError'
import { ExpeditionFieldNameTrans } from '@typings/entities/Expedition'
import ErrorMessage from '@mailstep/design-system/ui/Elements/ErrorMessage'
import { Trans } from '@lingui/react'
import { getSubmitCount } from '@utils/formik/common'
import isNumber from 'lodash/isNumber'
import { x } from '@xstyled/styled-components'

type GeneralErrorProps = {
  visibleBeforeSubmit?: boolean
}

// TODO figure out if we still need this
/**
 * Formik helper for general errors
 * @param param0 - should be used only if validation is enabled on mount (field errors are displayed before touch or submit)
 */
export const GeneralErrorField = ({ visibleBeforeSubmit = false }: GeneralErrorProps) => {
  const form = useFormikContext()
  const errors = getFormErrors({ form })
  const submitCount = getSubmitCount({ form })

  const errorsAvailable = errors && Object.keys(errors).length !== 0
  const visibleBySubmitCount = isNumber(submitCount) && (visibleBeforeSubmit ? submitCount >= 0 : submitCount > 0)

  return errorsAvailable && visibleBySubmitCount ? (
    <x.div display="flex" flexDirection="column">
      <ErrorMessage>
        <Trans id="form.generalError.title" message="Form contains errors, see form fields for detail." />
      </ErrorMessage>
      {/* {Object.entries(errors as object).map(([key, value]) => (
        <p key={key}>
          <ErrorMessage>{value}</ErrorMessage>
        </p>
      ))} */}
    </x.div>
  ) : null
}

export const InitialCommonErrorField = (): JSX.Element | null => {
  const form = useFormikContext()
  const errors = getInitialCommonErrors({ form })
  if (!errors) return null
  return (
    <>
      {errors.map((error) => (
        <Alert
          key={error.code}
          intent="danger"
          title={`${ExpeditionFieldNameTrans[error.propertyPath]?.()}:`}
          text={`${error.message}`}
        />
      ))}
    </>
  )
}

export const LoginErrorField = (): JSX.Element | null => {
  const form = useFormikContext()
  const error = getCommonError({ form })
  if (!error) return null
  return <LoginErrorAlert>{error.message}</LoginErrorAlert>
}

const CommonErrorField = (): JSX.Element | null => {
  const form = useFormikContext()
  const error = getCommonError({ form })
  if (!error) return null
  return <ErrorMessage>{error.message}</ErrorMessage>
}

export default CommonErrorField
