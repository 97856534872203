import get from 'lodash/fp/get'

type RouterData = {
  pathname: string
  search: string
  hash: string
}

const locationSelector = (state: State): string => get('router.location', state)

const pathnameSelector = (state: State): string => get('router.location.pathname', state)

const searchSelector = (state: State): string => get('router.location.search', state)

const hashSelector = (state: State): string => get('router.location.hash', state)

const routerSelector = (state: State): RouterData => ({
  pathname: pathnameSelector(state),
  search: searchSelector(state),
  hash: hashSelector(state),
})

export const selectors = {
  locationSelector,
  pathnameSelector,
  searchSelector,
  hashSelector,
  routerSelector,
}
