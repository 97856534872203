import React from 'react'
import { t } from '@lingui/macro'
import routes from '@pages/index'
import { Truck, TwoUsers, ShoppingBag, Company, Integrations, Profile } from '@mailstep/design-system/ui/Elements/Icon/icons'

export type SiteMapItem = {
  title: string
  description?: string
  icon?: JSX.Element
  link?: string
  id: string
  separator?: boolean
  appearance?: 'gray' | 'default'
}

type SiteMapProps = { eshopId?: string; type: 'Admin' | 'User' | undefined }

interface SiteMap {
  ({ eshopId, type }: SiteMapProps): Record<string, SiteMapItem>
}

const siteMap: SiteMap = ({ eshopId, type }) => ({
  eshops: {
    title: t({ id: 'heading.appMenu.eshops', message: 'E-shops' }),
    description: t({
      id: 'heading.appMenu.eshops.desription',
      message: 'Manage your eshops and their associated accounts in the Business system',
    }),
    icon: <ShoppingBag height="22px" width="22px" />,
    link: routes.sharedEshops.route.toUrl(),
    id: 'eshops',
    separator: true,
  },
  suppliers: {
    title: t({ id: 'heading.appMenu.suppliers', message: 'Suppliers' }),
    description: t({ id: 'heading.appMenu.suppliers.desription', message: 'Manage your suppliers' }),
    icon: <TwoUsers height="22px" width="22px" />,
    link: routes.sharedSuppliers.route.toUrl(),
    id: 'suppliers',
  },
  partners: {
    title: t({ id: 'heading.appMenu.partners', message: 'Partners' }),
    description: t({ id: 'heading.appMenu.partners.desription', message: 'Manage your partners and their activities' }),
    icon: <TwoUsers height="22px" width="22px" />,
    link: routes.sharedPartners.route.toUrl(),
    id: 'partners',
  },
  users: {
    title: t({ id: 'heading.appMenu.users', message: 'Users' }),
    description: t({ id: 'heading.appMenu.users.desription', message: 'Manage your users and their activity' }),
    link: routes.adminUsers.route.toUrl(),
    icon: <TwoUsers height="22px" width="22px" />,
    id: 'users',
  },
  organisations: {
    title: t({ id: 'heading.appMenu.organizations', message: 'Organizations' }),
    description: t({
      id: 'heading.appMenu.organizations.desription',
      message: 'Manage organisations, their DC logins, warehouses and carriers',
    }),
    link: routes.sharedOrganisations.route.toUrl(),
    icon: <Company height="22px" width="22px" />,
    id: 'organisations',
  },
  carriers: {
    title: t({ id: 'heading.appMenu.carriers', message: 'Carriers' }),
    description: t({ id: 'heading.appMenu.carriers.desription', message: 'Carriers overview' }),
    icon: <Truck height="22px" width="22px" />,
    link: routes.sharedCarriers.route.toUrl(),
    id: 'carriers',
  },
  baselinker: {
    title: t({ id: 'user.mainMenu.baselinker', message: 'BaseLinker' }),
    description: t({ id: 'user.mainMenu.baselinker.desription', message: 'BaseLinker management' }),
    link: routes.sharedBaselinker.route.toUrl(),
    id: 'baselinker',
  },
  webhooks: {
    title: t({ id: 'user.mainMenu.webhooks', message: 'Webhooks' }),
    description: t({ id: 'user.mainMenu.webhooks.desription', message: 'Webhooks management' }),
    link: routes.sharedWebhooks.route.toUrl(),
    id: 'webhooks',
  },
  byznys: {
    title: t({ id: 'user.mainMenu.byznysCredentials', message: 'Byznys credentials' }),
    link: routes.sharedByznys.route.toUrl(),
    id: 'byznys',
  },
  configuration: {
    title: t({ id: 'baselinker.configuration.title', message: 'Configuration' }),
    description: t({
      id: 'baselinker.configuration.desription',
      message: 'Setting up a synchronization token and creating a Mailship token',
    }),
    link: `${routes.sharedBaselinkerConfiguration.route.toUrl({ eshopId })}`,
    id: 'configuration',
  },
  synchronization: {
    title: t({ id: 'baselinker.synchronization.title', message: 'Synchronization' }),
    description: t({
      id: 'baselinker.synchronization.desription',
      message: 'Overview and management of order and product synchronization',
    }),
    link: `${routes.sharedBaselinkerSynchronization.route.toUrl({ eshopId })}`,
    id: 'synchronization',
  },
  warehouses: {
    title: t({ id: 'baselinker.warehouses.title', message: 'Warehouses' }),
    description: t({ id: 'baselinker.warehouses.desription', message: 'Warehouse management systems' }),
    link: `${routes.sharedBaselinkerWarehouses.route.toUrl({ eshopId })}`,
    id: 'warehouses',
  },
  expeditionStatuses: {
    title: t({ id: 'baselinker.expeditionStatuses.title', message: 'Expedition statuses' }),
    description: t({ id: 'baselinker.expeditionStatuses.desription', message: 'Expedition statuses management' }),
    link: `${routes.sharedBaselinkerExpeditionStatuses.route.toUrl({ eshopId })}`,
    id: 'expeditionStatuses',
  },
  baselinkerCarriers: {
    title: t({ id: 'baselinker.couriers.title', message: 'Couriers' }),
    description: t({ id: 'baselinker.couriers.desription', message: 'Creating and pairing couriers and pickup points' }),
    link: `${routes.sharedBaselinkerCarriers.route.toUrl({ eshopId })}`,
    id: 'baselinkerCarriers',
  },
  mailship: {
    title: t({ id: 'heading.appMenu.home', message: 'Mailship' }),
    link: type === 'Admin' ? routes.adminHome.route.toUrl() : routes.userHome.route.toUrl(),
    id: 'mailship',
  },

  integrations: {
    title: t({ id: 'user.appMenu.integrations', message: 'Integrations' }),
    icon: <Integrations height="22px" width="22px" />,
    separator: true,
    link: routes.sharedBaselinker.route.toUrl(),
    id: 'integrations',
  },
  personalSettings: {
    title: t({ id: 'heading.mainMenu.personalSettings', message: 'Personal settings' }),
    link: type === 'Admin' ? routes.adminEditUser.route.toUrl() : routes.userEditUser.route.toUrl(),
    id: 'personalSettings',
  },
  security: {
    title: t({ id: 'heading.mainMenu.security', message: 'Password settings' }),
    link: type === 'Admin' ? routes.adminEditUserSecurity.route.toUrl() : routes.userEditUserSecurity.route.toUrl(),
    id: 'security',
  },
  menuSettings: {
    title: t({ id: 'heading.mainMenu.menuSettings', message: 'Menu settings' }),
    description: t({
      id: 'heading.mainMenu.menuSettings.desription',
      message: 'Setting the visibility of items in the left menu',
    }),
    link: routes.sharedMenuSettings.route.toUrl(),
    id: 'menuSettings',
  },
  profile: {
    title: t({ id: 'heading.userMenu.profile', message: 'Profile' }),
    icon: <Profile height="22px" width="22px" />,
    link: type === 'Admin' ? routes.adminEditUser.route.toUrl() : routes.userEditUser.route.toUrl(),
    id: 'profile',
  },
})

export default siteMap
