import { ApiHookResult } from '@typings/ApiHooks'
import { CarrierService } from '@typings/entities/Carrier'
import { useQuery } from '@apollo/client'
import carrierServicesGetQuery from '@queries/carrierServicesGetQuery'

export const useLoadCarrierServicesList = (ids: string[]): ApiHookResult<CarrierService[]> => {
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(carrierServicesGetQuery, {
    variables: { criteria: { carrier: { in: ids } } },
    skip: !ids?.length,
  })
  return {
    data: data?.carrierServicesGet?.results,
    error,
    isLoading,
  }
}
