import { DateTime } from 'luxon'
import jwtDecode from 'jwt-decode'
import { DecodedToken } from '@typings/UI'
import { routesWithoutToken } from '@pages/index'
import { matchPath } from 'react-router'

const isBrowser = typeof window !== 'undefined'
export type TokenType = 'user' | 'admin'
export type RefreshToken = 'adminRefreshToken' | 'userRefreshToken'

/**
 * On client side we prefer localStorage over redux, because initialization into takes some time
 * and therefore may occur a moment, when we encounter request with no token initialized
 */
export const getTokenFromLocalStorage = (type: TokenType): string | undefined => {
  if (type === 'user') return localStorage?.userToken
  if (type === 'admin') return localStorage?.adminToken
  throw new Error(`Unknown token type "${type}"`)
}

export const getRefreshTokenFromLocalStorage = (type: TokenType): string | undefined => {
  if (type === 'user') return localStorage?.userRefreshToken
  if (type === 'admin') return localStorage?.adminRefreshToken
  throw new Error(`Unknown token type "${type}"`)
}

export const setTokenToLocalStorage = (type: TokenType, token: string) => {
  if (type === 'user') window.localStorage.setItem('userToken', token)
  if (type === 'admin') window.localStorage.setItem('adminToken', token)
}

export const getTokenFromState = (type: TokenType, state?: State): string | undefined => {
  if (type === 'user') return state?.user?.auth?.token
  if (type === 'admin') return state?.admin?.auth?.token
  throw new Error(`Unknown token type "${type}"`)
}

const getAuthorizationToken = (type?: TokenType, state?: State): string | undefined => {
  let token: string | undefined
  if (isBrowser) {
    const tokenType = type ? type : window?.location?.pathname.includes('/admin') ? 'admin' : 'user'
    token = getTokenFromLocalStorage(tokenType)
  }
  if (!token) {
    const tokenType = type ? type : state?.admin?.login ? 'admin' : 'user'
    token = getTokenFromState(tokenType, state)
  }
  return token
}

export const isTokenExpired = (tokenType: TokenType, seconds = 0) => {
  const currentToken = tokenType ? getTokenFromLocalStorage(tokenType) : undefined

  if (!currentToken) {
    return true
  }

  const decoded = jwtDecode<DecodedToken>(currentToken)
  const expired = DateTime.fromSeconds(decoded?.exp).minus({ seconds }) // seconds before refreshToken expires

  const currentTime = DateTime.local()
  if (expired.valueOf() < currentTime.valueOf()) {
    return true
  }

  return false
}

export const isPageWithoutToken = (path: string) =>
  !!Object.entries(routesWithoutToken).find(([, { route }]) => {
    return !!matchPath(path, { path: route?.path })
  })

export default getAuthorizationToken
