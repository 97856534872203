import React from 'react'
import Icon from '@mailstep/design-system/ui/Elements/Icon'
import { x } from '@xstyled/styled-components'
import styled from 'styled-components'

const rotate = {
  left: 0,
  top: '90deg',
  right: '180deg',
  bottom: '-90deg',
}

interface WithArrowProps {
  children: JSX.Element | string | number
  direction?: 'left' | 'top' | 'right' | 'bottom'
  position?: 'left' | 'right' | 'both'
}
const Wrapper = styled.div<{ rotateValue: string | number }>`
  transform: ${({ rotateValue }) => `rotate(${rotateValue})`};
`

export const WithArrow = ({ children, direction = 'left', position = 'right' }: WithArrowProps): JSX.Element => {
  const showRight = position === 'right' || position === 'both'
  const showLeft = position === 'left' || position === 'both'
  return (
    <>
      {showLeft && (
        <Wrapper rotateValue={rotate[direction]}>
          <Icon icon="arrowLeft" />
        </Wrapper>
      )}
      <x.div mr={showRight && '12px'} ml={showLeft && '12px'}>
        {children}
      </x.div>
      {showRight && (
        <Wrapper rotateValue={rotate[direction]}>
          <Icon icon="arrowLeft" />
        </Wrapper>
      )}
    </>
  )
}
