import React, { useCallback, useMemo } from 'react'
import { x } from '@xstyled/styled-components'
import { FormikProps, FormikValues } from 'formik'
import { GridActionsType, GridSelectorsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { ProductApiRead, ProductDetailItem } from '@typings/entities/Product'
import { formikCallback } from '@typings/Formik'
import { Trans } from '@lingui/react'
import getOr from 'lodash/fp/getOr'
import { createColumnDefinitions } from './TableDefinition'
import { AddProductItem, FormGrid, useFormGridChange } from '@components/blocks/FormGrid'
import { nanoid } from 'nanoid'
import FormGridTitle from '@components/elements/FormGridTitle'
import { duplicateItemMerge } from '@components/forms/utils/FormItems'

interface SetItemsFieldProps {
  form: FormikProps<FormikValues>
  loadProducts: (fulltext: string) => Promise<ProductApiRead[]>
  gridSelectors: GridSelectorsType
  gridActions: GridActionsType
  isEditable?: boolean
  isLoading?: boolean
  setFieldValue: formikCallback
}

const createEmptyItem = (qty: number, product: ProductApiRead): ProductDetailItem => ({
  id: `${nanoid()}`,
  quantity: qty,
  product: product,
})

const SetItemsField = ({
  form,
  loadProducts,
  gridSelectors,
  gridActions,
  isEditable,
  isLoading,
  setFieldValue,
}: SetItemsFieldProps): JSX.Element => {
  const itemsRaw: ProductDetailItem[] = getOr([], 'values.childrenProducts', form) // todo the key in these should not be hardcoded
  const error = getOr(null, 'errors.childrenProducts', form)

  const allItems = useMemo(
    () =>
      itemsRaw?.map((item) => {
        return { ...item }
      }),
    [itemsRaw],
  )

  const setItems = useCallback((items: ProductDetailItem[]) => setFieldValue('childrenProducts', items, false), [setFieldValue])
  const { onAdd, onRemove, getIndex } = useFormGridChange<ProductDetailItem>({
    gridSelectors,
    gridActions,
    allItems,
    itemIdentifier: 'product.id',
    setItems,
    duplicateItemMerge,
  })

  const onRowAction = useCallback(
    (id: string, field: string, value: any): void => {
      if (field === 'remove') onRemove([value])
    },
    [onRemove],
  )
  const columnsDefinitions = createColumnDefinitions(!!isEditable, getIndex)

  const errorString = error && typeof error === 'string' && form.submitCount > 0 ? error : null

  return (
    <>
      <FormGridTitle title={<Trans id="form.setItems.heading" message="Set items" />} error={errorString} />

      {isEditable && (
        <AddProductItem<ProductDetailItem>
          onAdd={onAdd}
          loadProducts={loadProducts}
          formatGridItem={createEmptyItem}
          error={errorString}
        />
      )}

      <x.div mt={3} mb={3}>
        <FormGrid
          isLoading={isLoading}
          items={allItems}
          columnsDefinitions={columnsDefinitions}
          gridSelectors={gridSelectors}
          gridActions={gridActions}
          onRowAction={onRowAction}
        />
      </x.div>
    </>
  )
}

export default SetItemsField
