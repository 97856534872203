import React, { FormEvent, useMemo } from 'react'
import { Trans } from '@lingui/react'
import { ExpeditionDetail } from '@typings/entities/Expedition'
import { Ability } from '@components/blocks/Acl'
import HidePrint from '@mailstep/design-system/ui/Blocks/HidePrint/HidePrint'
import ButtonsStrip from '@components/blocks/ButtonsStrip'
import { createButtonsDefinition } from '../buttonsDefinition'
import { useModal } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useModal'
import ConfirmDialog from '@components/blocks/Dialogs/ConfirmDialog'
import { x } from '@xstyled/styled-components'

interface ActionsProps {
  isLoading: boolean
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  onConfirm: () => void
  onRollback: () => void
  onCancel: () => void
  onComplaintOpen: () => void
  onCloneInvoice: () => void
  isCreatingNew: boolean
  isEditable: boolean
  isEditPermitted: boolean
  ability: Ability
  status?: ExpeditionDetail['status']
}

const ExpeditionActions = ({
  onSubmit,
  onConfirm,
  onRollback,
  onCancel,
  onComplaintOpen,
  onCloneInvoice,
  isCreatingNew,
  isEditable,
  isEditPermitted,
  isLoading,
  ability,
  status,
}: ActionsProps): JSX.Element => {
  const { onOpen: openConfirmDialog, onClose: closeConfirmDialog, isOpen: confirmDialogVisible } = useModal<string | null>()

  const buttonsDefinition = useMemo(
    () =>
      createButtonsDefinition({
        onSubmit,
        onRollback,
        onConfirm,
        onCancel: openConfirmDialog,
        onCreateComplaint: onComplaintOpen,
        onDuplicate: onCloneInvoice,
        isCreatingNew,
        isEditPermitted,
        isEditable,
        status,
        ability,
      }),
    [
      onSubmit,
      onRollback,
      onConfirm,
      openConfirmDialog,
      onComplaintOpen,
      onCloneInvoice,
      isCreatingNew,
      isEditPermitted,
      isEditable,
      status,
      ability,
    ],
  )

  return (
    <>
      <x.div w="100%" display="flex" justifyContent="flex-end" mb={4}>
        <HidePrint>
          <ButtonsStrip mainButtons={buttonsDefinition} isLoading={isLoading} wrap />
        </HidePrint>
      </x.div>

      {confirmDialogVisible && (
        <ConfirmDialog
          onClose={closeConfirmDialog}
          onConfirm={onCancel}
          title={<Trans id="cornerDialog.warning.title" message="Warning" />}
          text={<Trans id="form.confirmDialog.cancel.text" message="Do you really want to cancel your order?" />}
        />
      )}
    </>
  )
}

export default React.memo(ExpeditionActions)
