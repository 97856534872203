import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const StyledContainer = styled(ToastContainer)`
  .Toastify__toast {
    box-shadow: none;
    background-color: transparent;
  }
`
export default StyledContainer
