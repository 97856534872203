export const FileOptions = [
  {
    value: 'csv',
    label: 'CSV',
  },
  {
    value: 'xls',
    label: 'XLS',
  },
]

export const ImportFileOptions = [
  ...FileOptions,
  {
    value: 'xlsx',
    label: 'XLSX',
  },
]
