import React, { useCallback, useState } from 'react'
import styled from '@xstyled/styled-components'
import { x } from '@xstyled/styled-components'
import { ExpeditionDetail } from '@typings/entities/Expedition'
import { DateTime } from 'luxon'
import FaIcon from '@mailstep/design-system/ui/Elements/Icon'
import { Trans } from '@lingui/react'
import { Spinner } from '@mailstep/design-system/ui/Elements/Spinner'

// todo - change to field and add error

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Title = styled.div`
  color: typoPrimary;
  font-weight: bold;
`

const DateLbl = styled.span`
  color: gray;
  font-weight: semiBold;
`

const NoFileLbl = styled.span`
  color: gray;
  font-weight: bold;
`

const NewLbl = styled.span`
  color: darkGreen;
  font-weight: bold;
  margin-top: 0.25rem;
`

const FileWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  font-size: 14px;
`

const MainIconBox = styled(x.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  flex-shrink: 0;
`

const IconBox = styled(x.div)`
  color: gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 4rem;
  flex-shrink: 0;
  margin-left: 2rem;
  & > * {
    cursor: pointer;
  }
`

type Props = {
  isDisabled: boolean
  onInvoiceDownload: () => Promise<any>
  deliveryPdfFile: ExpeditionDetail['deliveryPdfFile']
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const InvoiceAttachment = ({ isDisabled, onInvoiceDownload, deliveryPdfFile, setFieldValue }: Props): JSX.Element | null => {
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false)
  const onInvoiceDownloadClick = useCallback(() => {
    setIsInvoiceLoading(true)
    onInvoiceDownload().finally(() => setIsInvoiceLoading(false))
  }, [onInvoiceDownload])

  const onRemoveClick = useCallback(() => {
    setFieldValue('deliveryPdfFile', null, false)
  }, [setFieldValue])

  const newFile = !!(deliveryPdfFile?.constructor?.name == 'File')

  let InfoLine: JSX.Element
  let MainIcon: JSX.Element | null
  if (!deliveryPdfFile) {
    MainIcon = null
    InfoLine = (
      <NoFileLbl>
        <Trans id="form.file.noAttachment" message="No file attached" />
      </NoFileLbl>
    )
  } else if (newFile) {
    MainIcon = <FaIcon icon="check" fill="darkGreen" />
    InfoLine = (
      <NewLbl>
        <Trans id="form.file.newFile" message="New file" />
      </NewLbl>
    )
  } else {
    const createdAtReadable = deliveryPdfFile?.createdAt && DateTime.fromISO(deliveryPdfFile?.createdAt).toFormat('dd. MM. yyyy')
    MainIcon = null
    InfoLine = <DateLbl>{createdAtReadable}</DateLbl>
  }

  return (
    <FileWrapper>
      <MainIconBox>{MainIcon}</MainIconBox>
      <Info>
        <Title>{deliveryPdfFile?.name}</Title>
        {InfoLine}
      </Info>
      <IconBox>
        {!newFile && deliveryPdfFile && (
          <div onClick={onInvoiceDownloadClick}>
            {isInvoiceLoading ? <Spinner variant="sm" /> : <FaIcon icon="download" size="1.1rem" />}
          </div>
        )}
        {!isDisabled && deliveryPdfFile && (
          <div onClick={onRemoveClick}>
            <FaIcon icon="deleteX" />
          </div>
        )}
      </IconBox>
    </FileWrapper>
  )
}

export default InvoiceAttachment
