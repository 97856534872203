export const Phone = /^(\+\d{1,4} )?(\d{3,14})$/
export const PhoneWithDial = /^(\+\d{1,4}) (\d{3,14})$/
export const PhoneNumber = /^(\d{3,14})$/

export const Password = /^[^\s].+[^\s]$/
export const removeOptionalEnd = /\?$/

// Street 12a/3b
export const AddressLine1 = /^([\dA-Za-zÀ-ÖØ-öø-ȳΑ-Ωα-ωίϊΐόάέύϋΰήώ]+\.?)([\s\/\-]?[\dA-Za-zÀ-ÖØ-öø-ȳΑ-Ωα-ωίϊΐόάέύϋΰήώ]+\.?)*$/
export const AddressLine2 =
  /^([\dA-Z]+)([\s.\/\-][\dA-Z]+)*,? [\dA-Za-zÀ-ÖØ-öø-ȳΑ-Ωα-ωίϊΐόάέύϋΰήώ]+([\s.\/\-][\dA-Za-zÀ-ÖØ-öø-ȳΑ-Ωα-ωίϊΐόάέύϋΰήώ]+)*$/ // 123 45, Town
export const Country = /^([\dA-Za-zÀ-ÖØ-öø-ȳΑ-Ωα-ωίϊΐόάέύϋΰήώ\s]{2,})$/ // AddressLine3
export const Street = /^([\dA-Za-zÀ-ÖØ-öø-ȳΑ-Ωα-ωίϊΐόάέύϋΰήώ\s.]+)$/
export const City = /^([\dA-Za-zÀ-ÖØ-öø-ȳΑ-Ωα-ωίϊΐόάέύϋΰήώ\s]+)$/

export const DocumentId = /^[\dA-Za-z][\dA-Za-z_\/-]*$/ // orderNumber, internalId
export const CustomId = /^[\dA-Za-z]+$/
export const Sku = /^([A-z\d +-?/\\#$*&()@_"':,.])+$/ // internalSku, productSku

export const WholeNumber = /^\d+$/
export const DecimalNumber = /^(\d{1,8})(,\d{0,2})?$/ // codValue, value of expedition (from 0 to 2 decimal digits)
export const DecimalNumberWithComma = /\,\d+$/ // codValue (without a given number of digits)
export const NumberWithoutDots = /^[0-9,]+$/

// Expedition simple validations
export const PhoneSimple = /^(\+)?([\d\s\-()]{3,19})$/
export const ipv4 =
  /^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$/

export const DatePattern = /(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|[1][0-2])\.([0-9]{4})+/i
export const DatePatternWithTime =
  /(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|[1][0-2])\.[0-9]+ (0?[0-9]|1[0-9]|2[0-3]):(0?[0-9]|[1-5][0-9])/i
export const LongDatePattern = /(^[^)]{3}|^[^)]{2}) [^)]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \([^)]*\)$/
