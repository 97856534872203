import gql from 'graphql-tag'

const organisationDeactivateQuery = gql`
  query organisationDeactivate($id: String!) {
    organisationDeactivate(id: $id, input: {})
      @rest(type: "OrganisationDeactivate", method: "PUT", path: "organisation/deactivate/{args.id}") {
      status
    }
  }
`

export default organisationDeactivateQuery
