import React from 'react'
import { Ability } from '@casl/ability'
import { ButtonsDefinition } from '@components/blocks/ButtonsStrip/types'
import { Trans } from '@lingui/react'
import { EditableStatuses, ExpeditionStatusTrans } from '@typings/entities/Expedition'

const cancelStatuses = [...EditableStatuses, 'awaiting_processing', 'stock_ok']
const rollbackStatuses = ['awaiting_processing']
const sendStatuses = ['on_hold', 'incorrect', 'waiting_for_goods', 'awaiting_reservation']
const confirmStatuses = ['stock_ok']
const saveAndStopStatuses = ['waiting_for_goods', 'ready_for_asn', 'waiting_for_asn', 'awaiting_reservation']

type CreateParams = {
  onSubmit: (event: any) => void
  onCancel: () => void
  onRollback: () => void
  onConfirm: () => void
  onDuplicate: () => void
  onCreateComplaint: () => void
  isEditPermitted: boolean
  isEditable: boolean
  isCreatingNew: boolean
  status?: keyof typeof ExpeditionStatusTrans
  ability: Ability
}

export const createButtonsDefinition = ({
  onSubmit,
  onCancel,
  onRollback,
  onConfirm,
  onDuplicate,
  onCreateComplaint,
  isEditPermitted,
  isEditable,
  isCreatingNew,
  status,
  ability,
}: CreateParams): ButtonsDefinition => {
  const resolveVisibility = (options: Array<string>, isNewExpedition?: boolean) => {
    // New expedition doesnt yet have a status - ''
    const newExpValidation = !status || options.includes(status)
    const baseExpValidation = !!status && options.includes(status)
    const statusCheck = isNewExpedition ? newExpValidation : baseExpValidation
    return isEditPermitted && statusCheck
  }

  const displayComplaint = !isCreatingNew && ability.can('edit', 'Complaint')
  const displayDuplicate = !isCreatingNew
  const displayCancel = resolveVisibility(cancelStatuses)
  const displayRollback = resolveVisibility(rollbackStatuses)
  const displaySave = isEditable // resolved in parent because it makes inputs enabled
  const displaySaveAndSend = displaySave && resolveVisibility(sendStatuses, true)
  const displaySend = !displaySave && resolveVisibility(sendStatuses, true)
  const displayConfirm = resolveVisibility(confirmStatuses)
  const displaySaveAndStop = status && saveAndStopStatuses.includes(status)

  return [
    {
      name: 'complaint',
      appearance: 'secondary',
      label: <Trans id="form.buttonCreateComplaint" message="Create complaint" />,
      isVisible: displayComplaint,
      onClick: onCreateComplaint,
    },
    {
      name: 'duplicate',
      appearance: 'secondary',
      label: <Trans id="form.buttonDuplicate" message="Duplicate" />,
      isVisible: displayDuplicate,
      onClick: onDuplicate,
    },
    {
      name: 'rollback',
      label: <Trans id="form.buttonRollbackOrder" message="Rollback order" />,
      isVisible: displayRollback,
      onClick: onRollback,
    },
    {
      name: 'cancel',
      label: <Trans id="form.buttonCancelOrder" message="Cancel order" />,
      isVisible: displayCancel,
      onClick: onCancel,
    },
    {
      name: 'save',
      label: displaySaveAndStop ? (
        <Trans id="form.buttonSaveAndStop" message="Save and stop" />
      ) : (
        <Trans id="form.buttonSave" message="Save" />
      ),
      isVisible: displaySave,
      onClick: onSubmit,
    },
    {
      name: 'saveAndSend',
      label: <Trans id="form.buttonSaveAndSend" message="Save &amp; send" />,
      isVisible: displaySaveAndSend,
      onClick: onSubmit,
    },
    {
      name: 'send',
      label: <Trans id="form.buttonSend" message="Send" />,
      isVisible: displaySend,
      onClick: onSubmit,
    },
    {
      name: 'confirm',
      label: <Trans id="form.buttonConfirm" message="Confirm" />,
      isVisible: displayConfirm,
      onClick: onConfirm,
    },
  ]
}
