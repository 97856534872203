import { useCheckDeviceWidth } from '@components/layouts/BaseLayout/utils'
import { getPercentValue } from '@utils/styling'
import styled, { BoxProps } from '@xstyled/styled-components'
import { x } from '@xstyled/styled-components'
import React from 'react'

const MAX_COLS = 12

const resolveColWidth = (size: number): string => getPercentValue(size / MAX_COLS)

type BaseColProps = {
  gridSize?: number
  size?: number
  gutter?: number
  verticalGutter?: number
  justifyContent?: BoxProps['justifyContent']
  flexGrow?: BoxProps['flexGrow']
  flexDirection?: BoxProps['flexDirection']
  alignItems?: BoxProps['alignItems']
  debug?: boolean
  children?: React.ReactNode
}

const BaseCol = ({
  size,
  gridSize = 0,
  gutter,
  verticalGutter,
  justifyContent,
  flexGrow = 1,
  flexDirection = 'column',
  alignItems,
  ...props
}: BaseColProps): JSX.Element => {
  const width = React.useMemo(() => resolveColWidth(size || gridSize), [size, gridSize])
  const needFlex = !!(justifyContent || alignItems)
  const display: 'flex' | 'block' = needFlex ? 'flex' : 'block'
  const { isMobile } = useCheckDeviceWidth()
  return (
    <x.div
      {...props}
      px={gutter}
      py={verticalGutter}
      display={display}
      flexDirection={flexDirection}
      flexGrow={flexGrow}
      alignItems={alignItems}
      justifyContent={justifyContent}
      w={isMobile ? '100%' : width}
    />
  )
}

type ColProps = BaseColProps

export const Col = styled(BaseCol)`
  box-sizing: border-box;
  outline: ${(props: ColProps): string => (props.debug ? '1px dashed deeppink' : 'none')};
`

export default Col
