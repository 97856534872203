import React, { useCallback } from 'react'
import Alert from '@mailstep/design-system/ui/Elements/Alert'
import { x } from '@xstyled/styled-components'
import Message from '@typings/entities/Message'

const StatusRow = ({
  id,
  message,
  intent,
  inline,
  onClick,
  onClose,
  text,
  actionType,
  actionLabel,
  desc,
}: Message): JSX.Element => {
  const handleOnClose = useCallback(() => {
    onClose?.(id)
  }, [id, onClose])

  return (
    <x.div mb={2}>
      <Alert
        intent={intent}
        inline={inline}
        onClick={onClick}
        onClose={onClose ? handleOnClose : undefined}
        title={message}
        text={text}
        actionType={actionType}
        actionLabel={actionLabel}
        desc={desc}
      />
    </x.div>
  )
}

export default StatusRow
