import gql from 'graphql-tag'

const exportQuery = gql`
  query export($entity: String, $format: String, $criteria: Object, $sorting: Object, $queryProps: String) {
    exportResult(entity: $entity, format: $format, queryProps: $queryProps, input: { criteria: $criteria, sort: $sorting })
      @rest(type: "Export", method: "POST", path: "{args.entity}/export.{args.format}?{args.queryProps}") {
      id
      filename
      fileType
    }
  }
`

export default exportQuery
