import { H1, H3 } from '@mailstep/design-system/ui/Elements/Typography'
import React, { FC } from 'react'
import { Trans } from '@lingui/react'
import FaIcon from '@mailstep/design-system/ui/Elements/Icon'
import NewVersionError from './NewVersionError'
import { LoggedOutWrapper } from '@designSystem/LoggedOutWrap/styles'
import { Wrapper, Error } from './styles'
import { getErrorText, isNewVersionError } from './utils'

type Props = {
  error?: Error
}

const Error500Page: FC<Props> = ({ error }: Props) => {
  const errorText = getErrorText(error)

  console.log(error)

  if (isNewVersionError(error)) {
    return (
      <LoggedOutWrapper>
        <NewVersionError />
      </LoggedOutWrapper>
    )
  }

  return (
    <Wrapper>
      <div>
        <FaIcon icon="faceMeh" size="90px" fill="red2" />
        <H1>
          <Trans id="common.unexpectedError.title" message="We're sorry, but an unexpected error has occurred." />
        </H1>
        <H3>
          <Trans
            id="common.unexpectedError.promisse"
            message="The bug has already been logged and we are currently working on fixing it."
          />
        </H3>
        {errorText && <Error>Error: {errorText}</Error>}
      </div>
    </Wrapper>
  )
}

export default Error500Page
