import omitBy from 'lodash/omitBy'
import { DateTime } from 'luxon'
import { Settings } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'

export type Criteria = object

export const buildStringCriterion = (value: any) => {
  if (value.comparator) {
    const prefix = ['like', 'notLike', 'endsWith'].includes(value.comparator) ? '%' : ''
    const sufix = ['like', 'notLike', 'startsWith'].includes(value.comparator) ? '%' : ''
    const comparator = ['startsWith', 'endsWith'].includes(value.comparator) ? 'like' : value.comparator
    const isInComparator = value.comparator === 'in'
    return {
      [comparator]: isInComparator ? [value.value?.trim()] : `${prefix}${value.value?.trim()}${sufix}`,
    }
  } else {
    return { like: `%${value}%` }
  }
}

const buildCriteria = (filter: Settings['filter']): Criteria => {
  const omittedFilter: Settings['filter'] = omitBy(filter, (field) => field?.value === '' || field?.value === null)

  let criteria = {} as any
  for (const key of Object.keys(omittedFilter)) {
    const { value, filterProps } = omittedFilter[key]
    if (filterProps?.computedValue) {
      const mergeCriteria = filterProps.computedValue
      criteria = {
        ...criteria,
        ...mergeCriteria,
      }
    } else if (filterProps?.type === 'options') {
      const isMulti = filterProps?.filterExtraProps?.isMulti || false
      if (!isMulti) {
        criteria[key] = { eq: `${value}` }
      } else if (value.length > 0) {
        criteria[key] = { in: value }
      }
    } else if (filterProps?.type === 'flag') {
      criteria[key] = { flag: value === '1' }
    } else if (filterProps?.type === 'number') {
      if (value.comparator && (value.value || value.value === 0)) {
        /* @ts-ignore */
        const decimals = filterProps?.format?.decimals || filterProps?.filterExtraProps?.decimals || 0
        criteria[key] = {
          [value.comparator]: Number(value.value).toFixed(decimals),
        }
      }
      /* @ts-ignore */
    } else if (filterProps?.type === 'date' || filterProps?.type === 'dateTime') {
      /* @ts-ignore */
      const isDateTime = filterProps?.type === 'dateTime' || filterProps?.filterExtraProps?.filterTime || false
      const dateFrom = value?.[0] && DateTime.fromJSDate(typeof value?.[0] === 'string' ? new Date(value?.[0]) : value?.[0])
      const dateFromIsValid = dateFrom && dateFrom.isValid
      const dateTo = value?.[1] && DateTime.fromJSDate(typeof value?.[1] === 'string' ? new Date(value?.[1]) : value?.[1])
      const dateToIsValid = dateTo && dateTo.isValid

      // a date input match criteria
      if (dateFromIsValid || dateToIsValid) {
        criteria[key] = {}
      }

      // criteria for limit first day
      if (dateFromIsValid) {
        // start and finish of the one day
        const timeFrom = isDateTime && dateFrom ? dateFrom.toFormat('HH:mm:ss') : '00:00:00'
        criteria[key].gte = `${dateFrom.toFormat('yyyy-MM-dd')}T${timeFrom}+00:00`
        criteria[key].lt = `${dateFrom.plus({ days: 1 }).toFormat('yyyy-MM-dd')}T00:00:00+00:00`
      }

      // criteria for limit last day
      if (dateToIsValid) {
        const timeTo = isDateTime && dateTo ? dateTo.toFormat('HH:mm:ss') : '00:00:00'
        criteria[key].lt = `${dateTo.plus({ days: isDateTime ? 0 : 1 }).toFormat('yyyy-MM-dd')}T${timeTo}+00:00`
      }
    } else {
      if (value.value?.trim()) {
        criteria[key] = buildStringCriterion(value)
      }
    }
  }

  return criteria
}

export default buildCriteria
