import { t } from '@lingui/macro'
import { UserAsNested } from '@typings/entities/User'
import { OrganisationAsNested } from '@typings/entities/Organisation'
import { WarehouseAsNested } from '@typings/entities/Warehouse'
import { WmsAsNested } from '@typings/entities/Wms'
import { ProductApiRead } from '@typings/entities/Product'
import { FlagAppearanceType } from '@constants/FlagAppearance'

export enum TransferStatus {
  canceled = 'canceled',
  created = 'created',
  finished = 'finished',
  waitingForGoods = 'waiting_for_goods',
}

export const TransferStatusTrans = {
  [TransferStatus.created]: () => t({ id: 'transferStatus.created', message: `created` }),
  [TransferStatus.waitingForGoods]: () => t({ id: 'transferStatus.waitingForGoods', message: `waiting for goods` }),
  [TransferStatus.finished]: () => t({ id: 'transferStatus.finished', message: `finished` }),
  [TransferStatus.canceled]: () => t({ id: 'transferStatus.canceled', message: `canceled` }),
}
export const statusToColor: { [value: string]: FlagAppearanceType } = {
  [TransferStatus.created]: 'blueSubtle',
  [TransferStatus.waitingForGoods]: 'orangeSubtle',
  [TransferStatus.finished]: 'greenSubtle',
  [TransferStatus.canceled]: 'redSubtle',
}

export const EditableStatuses = [TransferStatus.created, TransferStatus.waitingForGoods]

export enum TransferType {
  virtual = 'virtual',
}

export const TransferTypeTrans = {
  [TransferType.virtual]: () => t({ id: 'transferResolution.virtual', message: `virtual` }),
}

export const typeToColor: { [value: string]: FlagAppearanceType } = {
  [TransferType.virtual]: 'neutralSubtle',
}

type BareTransferItem = {
  quantity: number
  lot: string
  lifo: boolean
  ref1?: string
  ref2?: string
  ref3?: string
}

export type TransferItemApiRead = BareTransferItem & {
  product: string
  transfer: string
  id: string
  position: number
  booked: number
  missing: number
  assignedLot: string
}

// type TransferItemWrite = BareTransferItem & {
//   product: string
//   book: number
// }

export type TransferDetailItem = BareTransferItem & {
  id: string
  product: ProductApiRead
  booked: number
  book: number
  bookedDetail?: {
    // temporary simple workaround, will be replace with more robust solution with addition of lot
    booked: number
    warehouse: string
    wms: string
  }
  availableStock: number | '?' | null
}

type BareTransfer = {
  id: string
  internalNumber: string
  note: string
  countOfItems: number
  countOfSku: number
  sumOfQuantity: number
  status: TransferStatus
  type: TransferType
  requiredTransferDate: string
  createdAt: string
  changedAt?: string
}

export type TransferNested = BareTransfer & {
  user: UserAsNested
  organisation: OrganisationAsNested
  warehouseFrom: WarehouseAsNested
  warehouseTo: WarehouseAsNested
  wmsFrom: WmsAsNested
  wmsTo: WmsAsNested
}

export type TransferApiRead = BareTransfer & {
  user: string
  organisation: string
  warehouseFrom: string
  warehouseTo: string
  wmsFrom: string
  wmsTo: string
  items: TransferItemApiRead[]
}

// type TransferApiWrite = {
//   id?: string // depending on whether we create or update
//   internalNumber: string
//   note: string
//   type: string
//   requiredTransferDate: string
//   organisation: string
//   warehouseFrom: string
//   warehouseTo: string
//   wmsFrom: string
//   wmsTo: string
//   items: TransferItemWrite[]
// }

export type TransferDetail = Omit<TransferApiRead, 'items'> & {
  items: TransferDetailItem[]
}
