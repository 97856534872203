import gql from 'graphql-tag'

const wmsesGetQuery = gql`
  query wmsesGet($offset: Int, $limit: Int, $criteria: Object) {
    wmsesGet(input: { limit: $limit, offset: $offset, criteria: $criteria, select: $select, nested: true })
      @rest(type: "WmsesGet", method: "POST", path: "wms/list") {
      results
      paging
    }
  }
`

export default wmsesGetQuery
