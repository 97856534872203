import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createActions, createFullSelector } from '@mailstep/design-system/ui/Blocks/CommonGrid'
import { GridSelectorsType, GridActionsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'

export enum GridNames {
  ExchangeRates = 'ExchangeRates',
  InboundReceiptItems = 'InboundReceiptItems',
  OutboundReceiptItems = 'OutboundReceiptItems',
  ReturnReceiptItems = 'ReturnReceiptItems',
  StockChangeItems = 'StockChangeItems',
  InternalStockChangeItems = 'InternalStockChangeItems',
}

type ReturnProps = {
  gridSelectors: GridSelectorsType
  gridActions: GridActionsType
}

export const useGridStore = (gridName: string): ReturnProps => {
  // todo full selector is not actually memoized this way, because it creates new object every time. createFullSelector in grid needs to be fixed
  const selector = useMemo(() => createFullSelector(gridName), [gridName])
  const gridSelectors = useSelector(selector)

  const actions = React.useMemo(() => createActions(gridName), [gridName])
  const dispatch = useDispatch()
  const gridActions = React.useMemo(() => {
    return bindActionCreators(actions, dispatch)
  }, [actions, dispatch])

  return { gridSelectors, gridActions } as any
}
