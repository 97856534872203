import { useOnSubmit } from '@hooks/useOnSubmit'
import { t } from '@lingui/macro'
import eshopBaselinkerEdit from '@queries/eshopBaselinkerEdit'
import { FormikHandleSubmit } from '@typings/Formik'
import { BaselinkerCredentials } from '@typings/entities/Eshop'
import { displayToast } from '@utils/toast'
import { useCallback } from 'react'
import routes from '@pages/index'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { actions as baselinkerCredentialsActions } from '@store/baselinkerCredentials'

export const useBaselinkerSubmit = (eshopId?: string, usedOrganisation?: string) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const onSuccess = useCallback(() => {
    displayToast({
      type: 'success',
      text: t({
        id: 'cornerDialog.saveSuccess.text',
        message: 'Data has been successfully saved.',
      }),
    })

    history.push(routes.sharedBaselinker.route.toUrl({ eshopId }))
    dispatch(baselinkerCredentialsActions.run(eshopId))
  }, [history, eshopId, dispatch])

  const onError = useCallback(() => {
    displayToast({
      type: 'error',
      text: t({
        id: 'cornerDialog.saveError.text',
        message: 'An error occurred while saving the changes.',
      }),
    })
  }, [])

  const onSubmit = useOnSubmit<BaselinkerCredentials & { id: string }>(onSuccess, null, eshopBaselinkerEdit, onError)
  const handleSubmit: FormikHandleSubmit<BaselinkerCredentials> = useCallback(
    (values, formikHelpers) =>
      onSubmit(
        {
          ...values,
          id: eshopId,
          organisation: usedOrganisation,
          expeditionStatusMapping: values.expeditionStatusMapping.map(({ baseLinkerOrderStatusId, ...rest }) => ({
            baseLinkerOrderStatusId: baseLinkerOrderStatusId?.toString()?.trim() || null,
            ...rest,
          })),
          carrierMapping: values.carrierMapping,
        },
        formikHelpers,
      ),
    [eshopId, usedOrganisation, onSubmit],
  )
  return handleSubmit
}
