import get from 'lodash/fp/get'
import withFormikToProps from '@utils/formik/withFormikToProps'
import React, { ChangeEvent, useEffect } from 'react'
import RadioButton from '@mailstep/design-system/ui/Forms/RadioButton'
import { RadioProps } from '@mailstep/design-system/ui/Forms/RadioButton/types'

type Props = RadioProps & {
  shouldUseValueAsInitial?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  form: {
    setFieldValue: Function
  }
}

const RadioFieldWithFormik = ({ checked, shouldUseValueAsInitial, ...rest }: Props): JSX.Element => {
  checked
  const thisOptionValue = rest.value
  const formValue = get('field.value', rest)

  useEffect(() => {
    if (shouldUseValueAsInitial && !formValue) {
      rest.form.setFieldValue(rest.name, rest.value, false)
    }
  }, [shouldUseValueAsInitial, rest.form, rest.name, rest.value, formValue])

  return <RadioButton checked={thisOptionValue === formValue} {...rest} />
}

export default withFormikToProps(RadioFieldWithFormik)
