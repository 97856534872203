import React from 'react'
import { ColumnDefinition, RowValueType, DataCellProps } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { Trans } from '@lingui/react'
import { Input } from '@mailstep/design-system/ui/Forms/Input'
import { ExpeditionItemNested, ExpeditionStatusTrans, MetaLotOptionsTrans } from '@typings/entities/Expedition'
import getEnumOptions from '@utils/mapEnumToOptions'
import { ExpeditionStatusCell } from '@components/elements/gridCells'
import { DateCell, IconButtonInCell } from '@mailstep/design-system/ui/Blocks/CommonGrid'

export type GridItem = ExpeditionItemNested & {
  bookedByOtherAdvices: number
  toBook: number
  itemMaximum: number
}

const PairComponent = ({ rowData, onRowAction }: DataCellProps<GridItem>): JSX.Element | null => {
  const onToBookChange = React.useCallback(
    (event) => {
      const fieldValue = event.target.value
      const number = parseInt(fieldValue)
      const isValid = number >= 0 && number <= rowData.itemMaximum
      if (onRowAction && isValid) onRowAction(fieldValue, 'change', rowData)
    },
    [rowData, onRowAction],
  )

  // TODO MF-2809 pairing on advice temporarily hidden
  return <Input type="number" value={rowData.toBook} onChange={onToBookChange} disabled />
}

export const createColumnDefinitions = (onOpenExpedition: (id: string) => void): ColumnDefinition[] => {
  return [
    {
      name: 'expedition.orderNumber',
      title: <Trans id="dataGrid.columnOrderNumber.title" message="Order number" />,
      filtering: true,
      sorting: true,
      flexBasis: 180,
    },
    {
      name: 'expedition.status',
      title: <Trans id="dataGrid.columnStatus.title" message="Status" />,
      flexBasis: 180,
      filterExtraProps: { isMulti: true },
      filterOptions: getEnumOptions(ExpeditionStatusTrans),
      filtering: true,
      cellComponent: ExpeditionStatusCell,
    },
    {
      name: 'expedition.createdAt',
      title: <Trans id="dataGrid.columnCreatedAt.title" message="Created" />,
      filtering: true,
      filteringType: 'date',
      sorting: true,
      flexBasis: 140,
      cellComponent: DateCell,
    },
    {
      name: 'expedition.requiredExpeditionDate',
      title: <Trans id="dataGrid.columnRequiredDeliveryDate.title" message="Required delivery date" />,
      filteringType: 'date',
      filtering: true,
      sorting: true,
      flexBasis: 140,
      cellComponent: DateCell,
    },
    {
      name: 'lot',
      title: <Trans id="dataGrid.columnLot.title" message="LOT" />,
      flexBasis: 160,
      formatRowValue: (row: GridItem): RowValueType => {
        const metaOption = MetaLotOptionsTrans().find(({ value }) => value == (row.lifo ? '__newest' : '__oldest'))
        return row.assignedLot || row.lot || metaOption?.label
      },
    },
    {
      name: 'quantity',
      title: <Trans id="dataGrid.columnQty.title" message="Quantity" />,
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 100,
      flexGrow: 0,
    },
    {
      name: 'booked',
      title: <Trans id="dataGrid.columnBooked.title" message="Booked" />,
      formatRowValue: (row: GridItem): RowValueType => row.booked || 0,
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 100,
      flexGrow: 0,
    },
    {
      name: 'bookedByOtherAdvices',
      title: <Trans id="dataGrid.columnPairedElsewhere.title" message="Paired Elsewhere" />,
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 100,
      flexShrink: 0,
      flexGrow: 0,
    },
    {
      name: 'itemMaximum',
      title: <Trans id="dataGrid.columnRemaining.title" message="Remaining" />,
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 100,
      flexShrink: 0,
      flexGrow: 0,
    },
    {
      name: 'pair',
      title: <Trans id="dataGrid.columnPair.title" message="Pair" />,
      cellComponent: PairComponent,
      fixedSize: true,
      flexBasis: 110,
      flexShrink: 0,
      flexGrow: 0,
    },
    {
      name: 'link',
      flexBasis: 80,
      // eslint-disable-next-line react/display-name
      formatRowValue: (rowData: GridItem): RowValueType => {
        return (
          // todo why is this not a clickable document number instead ??
          // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
          <IconButtonInCell onClick={(): void => onOpenExpedition(rowData.expedition.id)} icon="findDetail" />
        )
      },
    },
  ]
}

export default createColumnDefinitions
