import gql from 'graphql-tag'

const partnersGetQuery = gql`
  query partnersGet($criteria: Object) {
    partnersGet(input: { criteria: $criteria, sort: $sorting, limit: $limit, offset: $offset, select: $select, nested: false })
      @rest(type: "partnersGetQuery", method: "POST", path: "partner/list") {
      results
      paging
    }
  }
`

export default partnersGetQuery
