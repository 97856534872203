import React from 'react'
import { breakpoints } from '@xstyled/system'
import { createGlobalStyle, css } from '@xstyled/styled-components'

const GlobalStyle = createGlobalStyle`
  /* create consistent base for relative units */
  html {
    font-size: 14px;
    ${breakpoints({
      sm: css`
        font-size: 14px;
      `,
      md: css`
        font-size: 14px;
      `,
      lg: css`
        font-size: 14px;
      `,
    })}
  }
`

export const NormalizeBaseFonts = () => <GlobalStyle />
