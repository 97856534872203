import React from 'react'
import { DataCellProps } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import get from 'lodash/fp/get'
import { ExpeditionDetailStatusTrans, FlagStatusToAppearance as expeditionStatusAppearance } from '@typings/entities/Expedition'
import { statusToColor as adviceStatusColor, StatusTrans as AdviceStatusTrans } from '@typings/enums/StockAdvice'
import { statusToColor as syncStatusColor, orderStatusTrans as SyncStatusTrans } from '@typings/enums/Synchronization'
import { EnumInCell } from '@mailstep/design-system/ui/Blocks/CommonGrid'

type CellProps = DataCellProps<{}>

export const ExpeditionStatusCell = ({ rowData, column }: CellProps): JSX.Element => {
  const value = get(column.name, rowData)
  return <EnumInCell value={value} appearanceMap={expeditionStatusAppearance} enumObj={ExpeditionDetailStatusTrans} />
}

export const AdviceStatusCell = ({ rowData, column }: CellProps): JSX.Element => {
  const value = get(column.name, rowData)
  return <EnumInCell value={value} appearanceMap={adviceStatusColor} enumObj={AdviceStatusTrans} />
}

export const SynchronizationStatusCell = ({ rowData, column }: CellProps): JSX.Element => {
  const value = get(column.name, rowData)
  return <EnumInCell value={value} appearanceMap={syncStatusColor} enumObj={SyncStatusTrans} />
}

const orderStatuses = ['lastStatusSyncResult', 'lastCarrierInfoSyncResult', 'lastOrderSyncResult']
const productStatuses = ['lastProductSyncResult', 'lastStockSyncResult']

type SyncCellProps = CellProps & { type: 'product' | 'order' }

const finalStatus = (paths: string[], pendingType: string, rowData: object) => {
  const containedValues = new Set(paths.map((key) => get(key, rowData)))

  if (containedValues.has('error')) {
    return 'error'
  } else if (containedValues.has('warning')) {
    return 'warning'
  } else if (containedValues.has(pendingType)) {
    return pendingType
  } else return 'finish'
}

const orderStatus = (rowData: object) => finalStatus(orderStatuses, 'start', rowData)
const productStatus = (rowData: object) => finalStatus(productStatuses, 'ongoing', rowData)

export const AggregatedSynchronizationStatusCell = ({ rowData, type = 'order' }: SyncCellProps): JSX.Element => {
  const isProduct = type === 'product'
  const value = isProduct ? productStatus(rowData) : orderStatus(rowData)

  return <EnumInCell value={value} appearanceMap={syncStatusColor} enumObj={SyncStatusTrans} />
}
