import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { useFetchModuleSelector } from '@utils/containers/useFetchModule'
import { Eshop } from '@typings/entities/Eshop'
import * as eshopsStore from '@store/eshops'
import { actions as eshopActions, selectors as eshopsSelectors } from '@store/eshops'
import { useCallback } from 'react'
import getEshopOptions from '@utils/getEshopOptions'
import { Option } from '@designSystem/Select/types'

interface ReturnType {
  selectedEshop: string
  setSelectedEshop: (id: string) => void
  eshops?: Eshop[]
  eshopsIsLoading: boolean
  validEshopSelected?: Eshop
  eshopOptions: Option[]
}

export const useSelectEshop = (): ReturnType => {
  const dispatch = useDispatch()
  const { data: eshops, isLoading: eshopsIsLoading } = useFetchModuleSelector<Eshop[]>(eshopsStore, null) // loaded automatically by org
  const eshopOptions: Option[] = useMemo(() => getEshopOptions(eshops || []), [eshops])

  const selectedEshop = useSelector(eshopsSelectors.getSelectedEshop)
  const setSelectedEshop = useCallback(
    (eshop: string): void => {
      dispatch(eshopActions.setSelectedEshop(eshop))
    },
    [dispatch],
  )

  const validEshopSelected = useMemo(
    () => (selectedEshop ? eshops?.find(({ id }) => id == selectedEshop) : undefined),
    [eshops, selectedEshop],
  )

  useEffect(() => {
    if (selectedEshop && eshops && !validEshopSelected) setSelectedEshop('')
  }, [validEshopSelected, eshops, selectedEshop, setSelectedEshop])

  return { selectedEshop, setSelectedEshop, eshops, eshopsIsLoading, validEshopSelected, eshopOptions }
}
