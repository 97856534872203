import { Option } from '@mailstep/design-system/ui/Elements/Select/types'

const toSelectOption =
  (id: string, name: string, index = 1) =>
  (item: any): Option => ({
    value: item[id],
    label: item[name],
    index: index++,
  })

export default toSelectOption
