import { selectors as userSelectors } from '@store/user'
import { selectors as adminSelectors } from '@store/admin'
import { useSelector } from 'react-redux'
import { AccountData } from '@typings/entities/User'

type UseLoggedAccount = {
  loggedAccount: AccountData
  isMSInternal: boolean
}

const useLoggedAccount = (): UseLoggedAccount => {
  const { userData } = useSelector(userSelectors.user)
  const { adminData } = useSelector(adminSelectors.admin)

  const loggedAccount = userData || adminData

  const domainPattern = /@(inventi|mailship|mailstep)/
  const isMSInternal = domainPattern.test(loggedAccount.email)

  return {
    loggedAccount,
    isMSInternal,
  }
}

export default useLoggedAccount
