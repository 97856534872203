import React from 'react'
import { Field } from 'formik'
import { Col } from '@designSystem/Grid'
import { x } from '@xstyled/styled-components'
import InputField from '@components/forms/components/InputField'
import SelectField from '@components/forms/components/SelectField'
import { FieldLabel } from '@mailstep/design-system/ui/Elements/Label'
import ErrorMessage from '@mailstep/design-system/ui/Elements/ErrorMessage'
import RadioField from '@components/forms/components/RadioField'
import TextAreaField from '@components/forms/components/TextAreaField'
import DatePickerField from '@components/forms/components/DatePickerField'
import Receipts from './Receipts'
import { InboundReceipt } from '@typings/entities/InboundReceipt'
import { Option as SelectOption } from '@mailstep/design-system/ui/Elements/Select/types'
import { Trans } from '@lingui/react'
import { t } from '@lingui/macro'
import { StyledBigField } from '../styles'
import { ResponsiveRowWrap, ResponsiveSectionWrap } from '@components/forms/styles'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchSuppliers: (fulltext: string, byId: boolean) => Promise<any>
  warehouseOptions?: SelectOption[]
  wmsOptions?: SelectOption[]
  hasWarehouse: boolean
  adviceId?: string
  packagingUnit?: string
  errors: { [key: string]: string }
  touched: { [key: string]: boolean }
  isEditable: boolean
  inboundReceipts?: InboundReceipt[]
  withReceipts?: boolean
}

const Main = ({
  searchSuppliers,
  warehouseOptions,
  wmsOptions,
  hasWarehouse,
  adviceId,
  packagingUnit,
  errors,
  touched,
  isEditable,
  inboundReceipts,
  withReceipts,
}: Props): JSX.Element => {
  return (
    <ResponsiveSectionWrap>
      <Col size={6} mr={3}>
        <ResponsiveRowWrap>
          <Field
            component={SelectField}
            name="warehouse"
            label={<Trans id="form.warehouse.label" message="Virtual warehouse" />}
            options={warehouseOptions}
            placeholder={t({ id: 'form.warehouse.placeholder', message: 'Select virtual warehouse...' })}
            spaceAround
            disabled={!isEditable}
          />
        </ResponsiveRowWrap>
        <ResponsiveRowWrap>
          <Field
            component={DatePickerField}
            name="expectedAt"
            label={<Trans id="form.expectedAt.label" message="Expected date & time delivery" />}
            dateFormat="DD.MM.YYYY"
            timeFormat="h:mm"
            spaceAround
            disabled={!isEditable}
          />

          <Field
            component={SelectField}
            name="supplier"
            label={<Trans id="form.supplier.label" message="Supplier" />}
            placeholder={t({ id: 'form.supplier.placeholder', message: 'Select supplier...' })}
            spaceAround
            disabled={!isEditable}
            isClearable
            loadOptions={searchSuppliers}
            preload
            asyncLoadMinChars={3}
          />
        </ResponsiveRowWrap>
        <ResponsiveRowWrap>
          <Col size={1}>
            <FieldLabel htmlFor="packagingUnit">
              <Trans id="form.packagingUnit.label" message="Packaging units" />
            </FieldLabel>
            <x.div display="flex" flexDirection="column">
              <x.div mb={2}>
                <Field
                  name="packagingUnit"
                  component={RadioField}
                  label={<Trans id="packagingUnit.pallet" message="Palette" />}
                  value="pallet"
                  shouldUseValueAsInitial={!packagingUnit}
                />
              </x.div>
              <Field
                name="packagingUnit"
                component={RadioField}
                label={<Trans id="packagingUnit.package" message="Package" />}
                value="package"
              />
            </x.div>
            {errors.packagingUnit && touched.packagingUnit && <ErrorMessage>{errors.packagingUnit}</ErrorMessage>}
          </Col>
          <Col size={1}>
            <StyledBigField
              name="countOfUnits"
              label={<Trans id="form.expectedCount.label" message="Expected count" />}
              component={InputField}
              spaceAround
              type="number"
              min="1"
              initialValue={1}
              big
            />
          </Col>
          <Col size={4}>
            <Field
              name="note"
              label={<Trans id="form.note.label" message="Note / Description" />}
              component={TextAreaField}
              spaceAround
            />
          </Col>
        </ResponsiveRowWrap>
      </Col>
      <Col size={6}>
        <ResponsiveRowWrap>
          <Field
            component={SelectField}
            name="wms"
            label={<Trans id="form.wms.label" message="Physical warehouse" />}
            options={wmsOptions}
            placeholder={t({ id: 'form.wms.placeholder', message: 'Select physical warehouse...' })}
            disabled={!isEditable}
            spaceAround
            isLocked={!hasWarehouse}
            lockedText={<Trans id="form.wms.notReady" message="Select a warehouse first..." />}
          />
        </ResponsiveRowWrap>
        <ResponsiveRowWrap>
          <Field
            component={InputField}
            name="ref1"
            label={<Trans id="dataGrid.columnRef1.title" message="Ref 1" />}
            disabled={!isEditable}
            spaceAround
          />
          <Field
            component={InputField}
            name="ref2"
            label={<Trans id="dataGrid.columnRef2.title" message="Ref 2" />}
            disabled={!isEditable}
            spaceAround
          />
          <Field
            component={InputField}
            name="ref3"
            label={<Trans id="dataGrid.columnRef3.title" message="Ref 3" />}
            disabled={!isEditable}
            spaceAround
          />
        </ResponsiveRowWrap>
        <ResponsiveRowWrap>
          {adviceId ? (
            <>
              <Col size={6}>
                <Field
                  component={DatePickerField}
                  name="createdAt"
                  label={<Trans id="form.createdAt.label" message="Created" />}
                  dateFormat="DD.MM.YYYY"
                  timeFormat="h:mm"
                  spaceAround
                  disabled
                />
                <Field
                  component={DatePickerField}
                  name="changedAt"
                  label={<Trans id="form.changedAt.label" message="Last update" />}
                  dateFormat="DD.MM.YYYY"
                  timeFormat="h:mm"
                  spaceAround
                  disabled
                />
              </Col>
              <Col size={6} justifyContent="flex-start">
                {withReceipts && <Receipts list={inboundReceipts} />}
              </Col>
            </>
          ) : undefined}
        </ResponsiveRowWrap>
      </Col>
    </ResponsiveSectionWrap>
  )
}

export default Main
