import reduceReducers from 'reduce-reducers'
import produce from 'immer'
import jwtDecode from 'jwt-decode'
import createFetchModule from '@utils/store/createFetchModule'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'
import getOr from 'lodash/fp/getOr'
import { TokenData } from '@typings/UI'

export const moduleName = 'admin'

const fetchModule = createFetchModule('@store/Admin', 'LOAD', moduleName)
const submoduleLogin = createFetchSubmodule('@store/Admin', 'LOGIN', moduleName)
const updateSubmodule = createFetchSubmodule('@store/Admin', 'UPDATE_ADMIN', moduleName)
const updateMenuPreferencesSubmodule = createFetchSubmodule('@store/Admin', 'UPDATE_ADMIN_MENU_PREFERENCES', moduleName)
const initCompleteSubmodule = createFetchSubmodule('@store/Admin', 'INIT_COMPLETE', moduleName)
const passwordResetSubmodule = createFetchSubmodule('@store/Admin', 'PASSWORD_RESET', moduleName)
const passwordResetConfirmSubmodule = createFetchSubmodule('@store/Admin', 'PASSWORD_RESET_CONFIRM', moduleName)
const submoduleActivateOrganisation = createFetchSubmodule('@store/Admin', 'ACTIVATE_ORGANISATION', moduleName)
const submoduleDeactivateOrganisation = createFetchSubmodule('@store/Admin', 'DEACTIVATE_ORGANISATION', moduleName)
const submoduleEditOrganisation = createFetchSubmodule('@store/Admin', 'EDIT_ORGANISATION', moduleName)
const submoduleCreateOrganisation = createFetchSubmodule('@store/Admin', 'CREATE_ORGANISATION', moduleName)

export const actionTypes = {
  load: fetchModule.actionTypes,
  login: submoduleLogin.actionTypes,
  logout: `@store/Admin/LOGOUT`,
  setAuth: `@store/Admin/SET_AUTH`,
  unsetLogin: `@store/Admin/LOGIN_UNSET`,
  update: updateSubmodule.actionTypes,
  updateMenuPreferences: updateMenuPreferencesSubmodule.actionTypes,
  passwordReset: passwordResetSubmodule.actionTypes,
  passwordResetConfirm: passwordResetConfirmSubmodule.actionTypes,
  activateOrganisation: submoduleActivateOrganisation.actionTypes,
  deactivateOrganisation: submoduleDeactivateOrganisation.actionTypes,
  editOrganisation: submoduleEditOrganisation.actionTypes,
  createOrganisation: submoduleCreateOrganisation.actionTypes,
  setInitComplete: initCompleteSubmodule.actionTypes.set,
}

const ownReducer = (state: State, action: Action): State =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.setAuth:
        let decoded: object & { sub: string }
        try {
          decoded = jwtDecode(action.payload.token)
        } catch (e) {
          console.error('Token could not be decoded', e, action.payload.token)
          return
        }
        draft.auth = {
          ...decoded,
          id: decoded?.sub,
          token: action.payload.token,
        }
        break
      case actionTypes.logout:
        draft.data = undefined
        draft.login = undefined
        draft.auth = undefined
        break
      case actionTypes.updateMenuPreferences.fetchSucceeded: {
        const preferences = getOr(null, 'data.adminUpdateMenuPreferences.preferences', action)
        if (preferences) {
          draft.data = { ...draft.data, preferences }
        }
        break
      }
      case actionTypes.setInitComplete:
        draft.initComplete = {
          ...draft.initComplete,
          data: true,
        }
        break
    }
  })

export default reduceReducers(fetchModule.reducer, submoduleLogin.reducer, ownReducer)

export const actions = {
  load: fetchModule.actions.run,
  login: submoduleLogin.actions.run,
  logout: () => ({ type: actionTypes.logout }),
  setAuth: ({ token }: Partial<TokenData>): Action => ({ type: actionTypes.setAuth, payload: { token } }),
  update: updateSubmodule.actions.run,
  updateMenuPreferences: updateMenuPreferencesSubmodule.actions.run,
  passwordReset: passwordResetSubmodule.actions.run,
  passwordResetConfirm: passwordResetConfirmSubmodule.actions.run,
  activateOrganisation: submoduleActivateOrganisation.actions.run,
  deactivateOrganisation: submoduleDeactivateOrganisation.actions.run,
  editOrganisation: submoduleEditOrganisation.actions.run,
  createOrganisation: submoduleCreateOrganisation.actions.run,
  setInitComplete: initCompleteSubmodule.actions.set,
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
  ...submoduleLogin.selectors,
  ...initCompleteSubmodule.selectors,
  getAuth: (state: State) => getOr(null, 'auth', state.admin),
  getInitComplete: (state: State) => getOr(false, 'initComplete.data', state.admin),
  getMenuPreferences: (state: State) => getOr(false, 'data.preferences', state.admin) || [],
  isAdminLogedIn: (state: State) => getOr(false, 'data.id', state.admin),
}
