import React from 'react'
import ExchangeRates from '@components/blocks/ExchangeRatesDialog/ExchangeRates'
import { useGetExchangeRatesQuery } from '@hooks/apiHooks/exchangeRates/useGetExchangeRatesQuery'

const Container = (): JSX.Element => {
  const { data, isLoading } = useGetExchangeRatesQuery()

  return <ExchangeRates exchangeRates={data} isLoading={isLoading} />
}

export default Container
