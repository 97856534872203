import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import LoadingOverlay from '@components/blocks/LoadingOverlay'
import { onComplaintSubmit } from './utils'
import getAccountOption from '@utils/getAccountOption'
import ComplaintCreateForm from '@components/forms/ComplaintCreateForm'
import { Trans } from '@lingui/react'
import { useApolloClient } from '@apollo/client'
import { useLoadUserList } from '@hooks/apiHooks/users/useLoadUserList'
import useLoggedAccount from '@hooks/useLoggedAccount'

type Props = {
  expeditionId: string
  organisationId: string
  onSuccess: () => void
  onCloseForm: () => void // passed to dialog
}

const Container = ({ expeditionId, organisationId, onCloseForm }: Props): JSX.Element => {
  const { loggedAccount } = useLoggedAccount()

  const initialValues = useMemo(() => {
    return {
      reporter: loggedAccount?.id,
      organisation: organisationId,
      order: expeditionId,
    }
  }, [expeditionId, loggedAccount?.id, organisationId])

  const { data: users, isLoading } = useLoadUserList(false, { organisations: { eq: organisationId } })
  const userOptions = useMemo(() => (users || []).map(getAccountOption), [users])

  const dispatch = useDispatch()
  const client = useApolloClient()
  const createComplaint = useMemo(() => onComplaintSubmit(client, dispatch, onCloseForm), [client, dispatch, onCloseForm])

  return (
    <LoadingOverlay isLoading={isLoading}>
      <ComplaintCreateForm
        title={<Trans id="form.heading.createComplaint" message="Create complaint" />}
        onSubmit={createComplaint}
        onCancel={onCloseForm}
        reporters={userOptions}
        initialValues={initialValues}
      />
    </LoadingOverlay>
  )
}

export default Container
