import React from 'react'
import FaIcon from '@mailstep/design-system/ui/Elements/Icon'
import { SocialLinksWrapper, SocialLinksBody, SocialFooter, LinkTo } from '@designSystem/LoggedOutWrap/styles'
import { FACEBOOK_LINK, INSTAGRAM_LINK, LINKEDIN_LINK, TWITTER_LINK } from '@constants/links'
import { Trans } from '@lingui/react'

const SocialLinks = () => {
  return (
    <SocialLinksWrapper>
      <SocialLinksBody>
        <LinkTo target="_blank" href={FACEBOOK_LINK}>
          <FaIcon icon="facebook" />
        </LinkTo>
        <LinkTo target="_blank" href={INSTAGRAM_LINK}>
          <FaIcon icon="instagram" />
        </LinkTo>
        <LinkTo target="_blank" href={LINKEDIN_LINK}>
          <FaIcon icon="linkedin" />
        </LinkTo>
        <LinkTo target="_blank" href={TWITTER_LINK}>
          <FaIcon icon="twitter" />
        </LinkTo>
      </SocialLinksBody>
      <SocialFooter>
        <Trans id="card.entrustLogisticsEshopProfessionals" message="Entrust the logistics of your e-shop to professionals." />
      </SocialFooter>
    </SocialLinksWrapper>
  )
}

export default SocialLinks
