import reduceReducers from 'reduce-reducers'
import createFetchModule from '@utils/store/createFetchModule'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'

export const moduleName = 'countries'

const loadDetail = createFetchModule('@store/Countries', 'LOAD', moduleName)
const loadList = createFetchSubmodule('@store/Countries', 'LOAD_LIST', moduleName)

export const actionTypes = {
  loadList: loadList.actionTypes,
}

export default reduceReducers(loadDetail.reducer, loadList.reducer)

export const actions: Record<string, (...args: any) => Action> = {
  loadList: loadList.actions.run,
}

export const selectors: Selectors = {
  ...loadList.selectors,
  // ... ownSelectors
}
