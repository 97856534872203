import React, { useMemo, useCallback, useState } from 'react'
import { DateTime } from 'luxon'
import FaIcon from '@mailstep/design-system/ui/Elements/Icon'
import { ProductImage } from '@typings/entities/Product'
import styled, { x } from '@xstyled/styled-components'
import Image from '@mailstep/design-system/ui/Elements/Image'
import ErrorMessage from '@mailstep/design-system/ui/Elements/ErrorMessage'
import { Spinner } from '@mailstep/design-system/ui/Elements/Spinner'
import { Trans } from '@lingui/react'

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
`

const Title = styled.div`
  font-size: 10px;
  color: blue2;
  font-weight: bold;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`

const DateLbl = styled.span`
  font-size: 10px;
  color: gray;
  font-weight: semiBold;
`

const NewLbl = styled.span`
  font-size: 10px;
  color: darkGreen;
  font-weight: bold;
  margin-top: 0.25rem;
`

const FileWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  margin: 0.25rem;
`

const MainIconBox = styled(x.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  flex-shrink: 0;
`

const ImageWrap = styled.div`
  width: 70px;
  text-align: flex-start;
  flex-shrink: 0;
`

const StyledImage = styled(Image)`
  border: slim;
  border-color: lightGray3;
  border-radius: md;
  max-width: 70px;
  max-height: 40px;
  cursor: pointer;
`

const IconBox = styled(x.div)`
  color: gray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 3rem;
  margin-left: 20px;
  flex-shrink: 0;
`

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  flex-grow: 1;
  width: 220px;
`

const SpinnerWrapper = styled.div`
  margin-right: 2;
`

const DownloadWrapper = styled.div`
  svg {
    cursor: pointer;
  }
`

const DeleteIconWrapper = styled.div`
  margin-left: 2;

  svg {
    cursor: pointer;
  }
`

type Props = {
  index: number
  image: ProductImage
  openLightbox: (index: number) => void
  onDelete: (index: number) => void
  onDownload: (id: string, fileName?: string) => Promise<void>
  disabled?: boolean
  error?: string | null
}

const FileButton = ({ index, image, openLightbox, onDownload, onDelete, disabled, error }: Props): JSX.Element => {
  const [isDownloading, setIsDownloading] = useState(false)

  const handleDownload = React.useCallback(() => {
    const imageUrl = image?.urlLarge ?? image?.urlSmall
    const imgName = image.originalName ?? 'product-image'
    if (imageUrl) {
      setIsDownloading(true)
      onDownload(imageUrl, imgName).finally(() => setIsDownloading(false))
    }
  }, [onDownload, image])

  const handleDelete = useCallback(() => {
    onDelete(index)
  }, [onDelete, index])

  const handleOpenLightbox = useCallback(() => {
    openLightbox(index)
  }, [openLightbox, index])

  const createdAtReadable = useMemo(() => image?.createdAt && DateTime.fromISO(image.createdAt).toFormat('dd. MM. yyyy'), [image])

  const newFile = !image?.id

  let InfoLine: JSX.Element
  let MainIcon: JSX.Element | null
  if (error) {
    MainIcon = <FaIcon icon="warningDual" fill="black" secondaryColor="yellow2" />
    InfoLine = <ErrorMessage appearance="inlineSmall">{error}</ErrorMessage>
  } else if (newFile) {
    MainIcon = <FaIcon icon="check" fill="darkGreen" />
    InfoLine = (
      <NewLbl>
        <Trans id="form.file.newFile" message="New file" />
      </NewLbl>
    )
  } else {
    MainIcon = null
    InfoLine = <DateLbl>{createdAtReadable}</DateLbl>
  }

  return (
    <FileWrapper>
      <ImageWrap onClick={handleOpenLightbox}>
        <StyledImage src={image?.urlLarge} />
      </ImageWrap>
      <MainIconBox>{MainIcon}</MainIconBox>
      <Right>
        <Info>
          <Title>{image?.originalName}</Title>
          {InfoLine}
        </Info>
        <IconBox>
          {isDownloading && (
            <SpinnerWrapper>
              <Spinner variant="sm" />
            </SpinnerWrapper>
          )}
          <DownloadWrapper onClick={handleDownload}>
            <FaIcon icon="download" size="1.1rem" />
          </DownloadWrapper>
          {!disabled && (
            <DeleteIconWrapper onClick={handleDelete}>
              <FaIcon icon="deleteX" />
            </DeleteIconWrapper>
          )}
        </IconBox>
      </Right>
    </FileWrapper>
  )
}

export default FileButton
