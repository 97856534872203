import reduce from 'lodash/reduce'
import get from 'lodash/fp/get'

/**
 * Forms map from array of object
 */
export const constructMap = <VT>(source?: object[], key?: string, value?: string): { [key: string]: VT } => {
  if (!source) return {}
  return reduce(source, (sum, item) => ({ ...sum, [get(key || 'id', item)]: value ? get(value, item) : item }), {})
}
