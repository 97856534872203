import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { camelJoin } from '@utils/string'
import { calculatePaginator } from '@utils/pagination'

export type StoreModule = {
  moduleName: string
  actions: any
  selectors: Selectors
}

type ModuleType<DataType> = {
  data: DataType
  isLoading: boolean
  error?: string
  errorCode?: number
  loadedAt?: number
}

type FullListModuleData<RowType> = {
  results?: RowType[]
  paging?: {
    from: number
    to: number
    total: number
  }
}

// TODO optimize all of with to reselect !!!!

const getFetchModuleSelector =
  <DataType>(selectors: StoreModule['selectors'], moduleName: string, submoduleName?: string | null) =>
  (state: any): ModuleType<DataType> => {
    if (submoduleName) {
      // FetchSubmodule
      const submoduleCamelCase = camelJoin(moduleName, submoduleName)
      return {
        data: selectors[submoduleCamelCase]?.(state)?.data,
        isLoading: selectors[submoduleCamelCase]?.(state)?.isLoading,
        error: selectors[submoduleCamelCase]?.(state)?.error,
        errorCode: selectors[submoduleCamelCase]?.(state)?.errorCode,
        loadedAt: selectors[submoduleCamelCase]?.(state)?.loadedAt,
      }
    } else {
      // FetchModule
      return {
        data: selectors[moduleName]?.(state)?.[moduleName + 'Data'],
        isLoading: selectors[moduleName]?.(state)?.[moduleName + 'IsLoading'],
        error: selectors[moduleName]?.(state)?.[moduleName + 'Error'],
        errorCode: selectors[moduleName]?.(state)?.[moduleName + 'ErrorCode'],
        loadedAt: selectors[moduleName]?.(state)?.[moduleName + 'LoadedAt'],
      }
    }
  }

type UseFetchModuleOptions = {
  submodule?: string | null
  action?: string
}

/**
 * Selector only, does not involve any loading
 */

export const useFetchModuleSelector = <DataType>(
  storeModule: StoreModule,
  submodule: string | null,
): ModuleType<DataType | undefined> => {
  const fetchSelectors = getFetchModuleSelector<DataType>(storeModule.selectors, storeModule.moduleName, submodule)
  return useSelector(fetchSelectors)
}

/**
 * Used to get detail of entity for forms
 */

type DetailReturnProps<E> = ModuleType<E | undefined> & {
  errorCode?: string | number
  reload: () => void
}

export const useDetailFetchModule = <Entity>(
  storeModule: StoreModule,
  entityId?: string | null,
  options?: UseFetchModuleOptions & {
    baselinkerHack?: boolean // TODO improve/fix !! Hack for baselinker form. Do not use elsewhere
  },
): DetailReturnProps<Entity> => {
  const submodule = options?.submodule || null // by default we use main module
  const fetchSelectors = getFetchModuleSelector<Entity>(storeModule.selectors, storeModule.moduleName, submodule)
  const fetchModule = useSelector(fetchSelectors)
  const action = (options?.action && storeModule.actions[options?.action]) || storeModule.actions.loadDetail // by default we use 'loadDetail' action

  const mountedAt: number = useMemo(() => new Date().getTime(), [])

  const dispatch = useDispatch()

  useEffect(() => {
    if (entityId) {
      dispatch(action({ id: entityId }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId])

  const reload = useCallback(() => {
    if (entityId) {
      dispatch(action({ id: entityId }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId])

  let beforeLoading
  if (options?.baselinkerHack) {
    // TODO improve/fix !! Hack for baselinker form. Do not use elsewhere
    beforeLoading = entityId && (!fetchModule?.loadedAt || fetchModule.loadedAt < mountedAt) && !fetchModule.error
  } else {
    beforeLoading = entityId && (!fetchModule.data || (fetchModule.loadedAt && fetchModule.loadedAt < mountedAt)) // id can not be used to check for data, because some entities dont have id (eg. baselinker)
  }

  return {
    data: fetchModule.data && !beforeLoading ? fetchModule.data : undefined,
    isLoading: beforeLoading || fetchModule.isLoading,
    error: fetchModule.error,
    errorCode: fetchModule.errorCode,
    reload,
  }
}

/**
 * Used to get simple list of entity ("dumb" lists, for option selects and etc.)
 * (for list that can or have to list all elements, most of lists that are not for grids)
 */

type ListReturnProps<E> = ModuleType<E[]> & {
  reload: () => void
}

export const useListFetchModule = <Entity>(
  storeModule: StoreModule,
  criteria: { [column: string]: any },
  skip: boolean,
  options?: UseFetchModuleOptions,
): ListReturnProps<Entity> => {
  const submodule = options?.submodule || options?.submodule === null ? options?.submodule : 'loadList' // by default we use 'loadList' submodule
  const fetchSelectors = getFetchModuleSelector<Entity[]>(storeModule.selectors, storeModule.moduleName, submodule)
  const fetchModule = useSelector(fetchSelectors)
  const action = (options?.action && storeModule.actions[options?.action]) || storeModule.actions.loadList // by default we use 'loadList' action

  const mountedAt: number = useMemo(() => new Date().getTime(), [])

  const dispatch = useDispatch()

  const reload = useCallback(() => {
    dispatch(action({ criteria }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(criteria)])

  useEffect(() => {
    if (!skip) {
      dispatch(action({ criteria }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(criteria), skip])

  // TODO refactor fetch module, so that we dont need this
  const oldData: boolean = !skip && !!(!fetchModule.data || (fetchModule.loadedAt && fetchModule.loadedAt < mountedAt))

  const data = useMemo(() => (skip || oldData ? [] : fetchModule.data || []), [fetchModule.data, skip, oldData])

  return { data, isLoading: fetchModule.isLoading || oldData, error: fetchModule.error, errorCode: fetchModule.errorCode, reload }
}

/**
 * Used to get list of entity with meta information and functions
 * (for lists that can not list all elements and need paging and/or sorting)
 */

type LongListReturnProps<E> = ModuleType<E[]> & {
  itemsTotal: number
  pagesTotal: number
  loadedPage: number
  loadPage: (page?: number) => void
}

type UseLongListFetchModule = {
  storeModule: StoreModule
  criteria?: { [column: string]: any }
  rowsPerPage: number | null
  sorting?: any[] | null
  skip: boolean
  options?: UseFetchModuleOptions
  extra?: Record<string, unknown>
}

export const useLongListFetchModule = <Entity>({
  storeModule,
  criteria,
  rowsPerPage,
  sorting,
  skip,
  options,
  extra,
}: UseLongListFetchModule): LongListReturnProps<Entity> => {
  const submodule = options?.submodule || null // by default we use main module
  const fetchSelectors = getFetchModuleSelector<FullListModuleData<Entity>>(
    storeModule.selectors,
    storeModule.moduleName,
    submodule,
  )
  const fetchModule = useSelector(fetchSelectors)
  const action = (options?.action && storeModule.actions[options?.action]) || storeModule.actions.run // by default we use 'run' action

  const { isLoading, error, errorCode, data: fetchData } = fetchModule || {}
  const data = useMemo(() => (skip ? [] : fetchData?.results || []), [fetchData?.results, skip])
  const itemsTotal = fetchData?.paging?.total || 0
  const pagesTotal = rowsPerPage === null ? 1 : Math.ceil(itemsTotal / rowsPerPage)

  const loadedPage = useMemo(() => {
    if (rowsPerPage === null) return 1
    const pageOffset = fetchData?.paging?.from || 0
    return pageOffset / rowsPerPage + 1
  }, [fetchData?.paging?.from])

  const dispatch = useDispatch()

  const loadPage = useCallback(
    (page = 1) => {
      if (skip) return
      dispatch(
        action({
          criteria,
          sorting: sorting,
          extra,
          ...(rowsPerPage ? calculatePaginator(page, rowsPerPage) : {}),
        }),
      ).promise?.catch(() => null)
    },
    [skip, dispatch, action, criteria, sorting, rowsPerPage, extra],
  )

  useEffect(() => {
    loadPage()
  }, [loadPage])

  // reload
  return { data, isLoading, error, errorCode, itemsTotal, pagesTotal, loadedPage, loadPage }
}

/**
 * Used to get list the are loaded as a response to ui action.
 * Criteria are not hook prop, but rather come as prop of callback.
 */

type AsyncListReturnProps<E> = ModuleType<E[]> & {
  /* @ts-ignore */
  load: (criteria: { [column: string]: any }, extraProps: { [name: key]: any }) => void
}

export const useLazyListFetchModule = <Entity>(
  storeModule: StoreModule,
  options?: UseFetchModuleOptions,
): AsyncListReturnProps<Entity> => {
  const submodule = options?.submodule || null // by default we use main module
  const fetchSelectors = getFetchModuleSelector<FullListModuleData<Entity>>(
    storeModule.selectors,
    storeModule.moduleName,
    submodule,
  )
  const fetchModule = useSelector(fetchSelectors)
  const action = (options?.action && storeModule.actions[options?.action]) || storeModule.actions.run // by default we use 'run' action

  const rawData = fetchModule?.data?.results

  const mountedAt: number = useMemo(() => new Date().getTime(), [])

  const dispatch = useDispatch()

  const load: AsyncListReturnProps<Entity>['load'] = useCallback(
    (criteria, extraProps) => {
      dispatch(action({ criteria, ...extraProps }))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [action, dispatch],
  )

  // TODO refactor fetch module, so that we dont need this
  const oldData = !!(rawData && fetchModule.loadedAt && fetchModule.loadedAt < mountedAt)

  const data = useMemo(() => (oldData ? [] : rawData || []), [rawData, oldData])

  return { data, isLoading: fetchModule.isLoading || oldData, error: fetchModule.error, errorCode: fetchModule.errorCode, load }
}
