import produce from 'immer'
import { getOr } from 'lodash/fp'
import reduceReducers from 'reduce-reducers'

const initialState: State = {}

export const actionTypes = {
  setOrganisation: `@store/UI/SET_ORGANISATION`,
}

const ownReducer = (state: State = initialState, action: Action): State => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.setOrganisation:
        draft.selected = action?.payload
        break
    }
  })
}

export default reduceReducers(ownReducer)

export const actions = {
  setOrganisation: (organisationId: string, initOnly?: boolean): Action => ({
    type: actionTypes.setOrganisation,
    payload: organisationId,
    options: { initOnly },
  }),
}

export const selectors = {
  organisation: (state: State): string => getOr(null, 'selected', state?.organisation),
}
