import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import expeditionsRepositionQuery from '@queries/expeditionsRepositionQuery'

export const useExpeditionRepositionQuery = (): any => {
  const client = useApolloClient()

  const reposition = useCallback(
    async (id: string) => {
      try {
        const { data } = await client.query({
          query: expeditionsRepositionQuery,
          variables: { id },
        })
        return { data }
      } catch (error) {
        return { error }
      }
    },
    [client],
  )

  return { reposition }
}
