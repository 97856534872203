import { FormikValues, FormikHelpers, FormikProps } from 'formik'
import { pick } from 'lodash'

// todo deleted and repalce with proper typing
export type formikCallback = (name: string, value: string | number | undefined, shouldValidate?: boolean) => void

// todo make return promise everywhere
export type FormikHandleSubmit<TValues = FormikValues> = (
  values: TValues,
  formikHelpers: FormikHelpers<TValues>,
) => void | Promise<object | void>

// We use them in callback when form is not actually submitted. This could use rework too, but we need to fix large scale form issues first
const FormikHelpersKeys: Array<keyof FormikHelpers<FormikValues>> = [
  'resetForm',
  'setErrors',
  'setFieldError',
  'setFieldTouched',
  'setFieldValue',
  'setFormikState',
  'setStatus',
  'setSubmitting',
  'setTouched',
  'setValues',
  'submitForm',
  'validateField',
  'validateForm',
]
export function getFormikHelpers<TValues = FormikValues>(formikProps: FormikProps<TValues>): FormikHelpers<TValues> {
  return pick(formikProps, FormikHelpersKeys)
}
