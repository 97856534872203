import { Input, InputProps } from '@mailstep/design-system/ui/Forms/Input'
import withFormikToProps from '@utils/formik/withFormikToProps'
import { trimValue } from '@utils/string'
import React, { ChangeEvent, useEffect } from 'react'

type Props = InputProps & {
  initialValue?: string | number
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  form: {
    setFieldValue: Function
  }
}

const InputField = ({ value, initialValue, onChange, ...rest }: Props): JSX.Element => {
  const controlledValue = value || value === 0 ? value : ''
  const shouldSetInitialValue = !!(!controlledValue && initialValue)
  useEffect(() => {
    if (shouldSetInitialValue) rest.form.setFieldValue(rest.name, initialValue, false)
  }, [shouldSetInitialValue]) // eslint-disable-line react-hooks/exhaustive-deps
  return <Input value={trimValue(controlledValue)} onChange={onChange} id={rest.name} {...rest} />
}

export default withFormikToProps(InputField)
