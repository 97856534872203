import createFetchSubmodule from '@utils/store/createFetchSubmodule'

export const moduleName = 'transferImport'

const importTransfersSubmodule = createFetchSubmodule('@store/Transfers', 'IMPORT_TRANSFERS', moduleName)
const downloadImportTemplateSubmodule = createFetchSubmodule('@store/Transfers', 'DOWNLOAD_IMPORT_TEMPLATE', moduleName)

export const actionTypes = {
  downloadImportTemplate: downloadImportTemplateSubmodule.actionTypes,
  importTransfers: importTransfersSubmodule.actionTypes,
}

export const actions = {
  downloadImportTemplate: downloadImportTemplateSubmodule.actions.run,
  importTransfers: importTransfersSubmodule.actions.run,
}
