import React, { memo } from 'react'
import styled, { x } from '@xstyled/styled-components'
import Avatar from '@mailstep/design-system/ui/Elements/Avatar'
import { AccountData } from '@typings/entities/User'
import { Paragraph3, Paragraph4 } from '@mailstep/design-system/ui/Elements/Typography'

type Props = {
  loggedAccount?: AccountData
}

const UserWrapper = styled(x.div)`
  display: flex;
  alignitems: center;
  marginbottom: 15px;
  height: 44px;
`

const TextWrapper = styled(x.div)`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 15px;
`

const NameSpan = styled(Paragraph3)`
  line-height: 22.4px;
`

const EmailSpan = styled(Paragraph4)`
  line-height: 19.2px;
`

const UserInfo = ({ loggedAccount }: Props): JSX.Element => {
  const userName = loggedAccount?.firstName && `${loggedAccount?.firstName} ${loggedAccount?.lastName}`
  const userPhoto = loggedAccount?.photo
  const userEmail = loggedAccount?.email

  return (
    <UserWrapper mb={3}>
      <Avatar src={userPhoto || undefined} size={44} />

      <TextWrapper>
        <NameSpan variant="bold" margin={0} color="textPrimary">
          {userName}
        </NameSpan>
        <EmailSpan variant="normal" margin={0} color="textPrimary">
          {userEmail}
        </EmailSpan>
      </TextWrapper>
    </UserWrapper>
  )
}

export default memo(UserInfo)
