import styled from '@xstyled/styled-components'
import { th, ThemeGetterFunc } from '@xstyled/system'
import { getPercentValue } from '@utils/styling'

export const Item = styled.div`
  display: flex;
  font-size: 13px;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 0.75em 0 0;

  @media (min-width: 1024px) {
    padding: 0 0.75em;
  }
`

export const ItemLabel = styled.span`
  font-weight: semiBold;
  color: gray;
  flex-shrink: 0;
  margin-right: 0.5em;
`

export const HeaderItemsWrap = styled.div<{
  $textOnly?: boolean
  $isCompact?: boolean
  $columnCount: number
}>`
  display: flex;
  flex-flow: row wrap;
  border-radius: 0.5rem;
  ${Item} {
    height: ${({ $textOnly }): string => ($textOnly ? '2rem' : '3em')};
    width: ${({ $columnCount }): string => getPercentValue(1 / $columnCount)};
  }
  ${ItemLabel} {
    width: ${({ $columnCount, $isCompact }): string => ($isCompact || $columnCount >= 3 ? '8.5rem' : '10.5rem')};
  }
  @media (max-width: 768px) {
    flex-flow: column wrap;

    ${Item} {
      width: 100%;
    }
    .padding {
      display: none;
    }
  }
`

export const ItemContent = styled.span<{
  $emphasis?: boolean
}>`
  font-weight: normal;
  flex-grow: 1;
  flex-shrink: 1;
  color: ${({ $emphasis }): ThemeGetterFunc => ($emphasis ? th.color('red1') : th.color('typoPrimary'))};
  font-size: ${({ $emphasis }): string => ($emphasis ? '20px' : '14px')};
`
