import React, { FC, useCallback, useState } from 'react'
import DatePicker from '@mailstep/design-system/ui/Elements/DatePicker'
import { DatetimepickerProps } from '@mailstep/design-system/ui/Elements/DatePicker/Datetime/types'
import getFieldError from '@utils/formik/getFieldError'

type DatePickerProps = DatetimepickerProps & {
  field: {
    name: string
    value: string | Date
    onChange: (value: Date) => void
  }
  allowClear?: boolean
  onClear?: () => void
}

const DatePickerField: FC<DatePickerProps> = (props) => {
  const {
    field,
    form: { setFieldTouched },
    allowClear,
    onClear,
    ...rest
  } = props
  const { onChange, name } = field
  const [datePickerValue, setDatePickerValue] = useState(field.value)

  const handleChange = useCallback(
    (value: Date | string) => {
      onChange({ target: { name, type: 'change', value } })
      setDatePickerValue(value)
      setFieldTouched(name, true, false)
    },
    [setFieldTouched, name, onChange],
  )

  const handleInputChange = useCallback(
    (e) => {
      e.preventDefault()
      onChange({ target: { name, type: 'change', value: e.target.value } })
      setDatePickerValue(e.target.value)
      setFieldTouched(name, true, false)
    },
    [onChange, name, setFieldTouched],
  )

  const error = getFieldError(props)

  const handleClear = useCallback(() => {
    if (typeof onClear === 'function') {
      onClear()
    } else {
      handleChange('')
    }
  }, [allowClear, handleChange, onClear])

  return (
    <DatePicker
      {...rest}
      onClear={allowClear ? handleClear : undefined}
      name={name}
      value={datePickerValue}
      error={error}
      onInputChange={handleInputChange}
      onChange={handleChange}
      showInputLabel
      showCalendarLabel={false}
      closeOnSelect
    />
  )
}

export default DatePickerField
