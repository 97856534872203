import { useCallback } from 'react'
import { useHistory } from 'react-router'

const useChangeUrlPath = (createLink: (params: object) => string) => {
  const history = useHistory()

  const redirectToDetail = useCallback(
    (field: string) => {
      return history.push({
        pathname: `${createLink?.({ id: field })}`,
      })
    },
    [createLink, history],
  )
  return { redirectToDetail }
}

export default useChangeUrlPath
