import withFormikToProps from '@utils/formik/withFormikToProps'
import Toggle from '@mailstep/design-system/ui/Elements/Toggle'
import React from 'react'
import { useFormikContext } from 'formik'
import { useModal } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useModal'
import ConfirmDialog from '@components/blocks/Dialogs/ConfirmDialog'
import { Trans } from '@lingui/react'

type Props = {
  name: string
  value?: boolean | null
  onChange: (event: { target: { [key: string]: string | number | undefined } }) => void
  dialogText?: string | React.ReactNode
}

const SwitchWithDialogField = ({ value, name, onChange, dialogText, ...rest }: Props): JSX.Element => {
  const { setFieldValue } = useFormikContext()
  const { onOpen: openDialog, isOpen: confirmVisible, onClose: closeDialog } = useModal()

  return (
    <>
      <Toggle
        checked={!!value}
        onChange={() => {
          value === true ? setFieldValue(name, !value) : openDialog()
        }}
        name={name}
        variant="grid"
        {...rest}
      />
      {confirmVisible && (
        <ConfirmDialog
          onClose={closeDialog}
          onConfirm={() => setFieldValue(name, !value)}
          title={<Trans id="cornerDialog.warning.title" message="Warning" />}
          text={dialogText}
          onConfirmButtonText={<Trans id="cornerDialog.buttonOkThankYou" message="Confirm" />}
          onCloseButtonText={<Trans id="form.buttonBack" message="Back" />}
        />
      )}
    </>
  )
}

export default withFormikToProps(SwitchWithDialogField)
