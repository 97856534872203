import React from 'react'
import styled, { x, css } from '@xstyled/styled-components'
import { Field } from 'formik'
import InputField from '@components/forms/components/InputField'
import { Trans } from '@lingui/react'
import { camelJoin } from '@utils/string'
import { StyledSubTitle } from '@components/elements/Typography/lib'

const wrapper = css`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }

  & > * {
    margin-top: 1;

    margin-right: 3;
  }

  & > :not(:last-child) {
    margin-bottom: 2;
  }
`

const StyledOuterBox = styled(x.div)`
  ${wrapper};
`

type Props = {
  name?: string
  title?: string | JSX.Element
  isDisabled?: boolean
}

const DimensionsField = ({ name, title, isDisabled }: Props): JSX.Element => {
  return (
    <StyledOuterBox>
      <StyledSubTitle>{title}</StyledSubTitle>
      <Field
        component={InputField}
        type="number"
        label={<Trans id="form.weight.labelGrams" message="Weight [g]" />}
        name={camelJoin(name, 'weight')}
        disabled={isDisabled}
      />
      <Field
        component={InputField}
        type="number"
        label={<Trans id="form.height.labelMm" message="Height [mm]" />}
        name={camelJoin(name, 'height')}
        disabled={isDisabled}
      />
      <Field
        component={InputField}
        type="number"
        label={<Trans id="form.width.labelMm" message="Width [mm]" />}
        name={camelJoin(name, 'width')}
        disabled={isDisabled}
      />
      <Field
        component={InputField}
        type="number"
        label={<Trans id="form.length.labelMm" message="Length [mm]" />}
        name={camelJoin(name, 'length')}
        disabled={isDisabled}
      />
    </StyledOuterBox>
  )
}

export default DimensionsField
