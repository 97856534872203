import React from 'react'
import styled from '@xstyled/styled-components'
import Link from '@mailstep/design-system/ui/Elements/SimpleLink'
import { DefaultItem as MenuItem } from '@mailstep/design-system/ui/Elements/DropdownMenu/types'

const ItemWrap = styled.div`
  padding: 6px 0;
`

const StyledLink = styled(Link)`
  display: block;
  border-radius: md;
  :hover > * {
    color: textPrimary;
    background-color: bgLightGray1;
    border-radius: md;
  }
`

const Label = styled.span`
  flex-grow: 1;
`

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 16px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
`

const Count = styled.div`
  font-weight: bold;
  margin-left: 16px;
`

const ItemIcon = styled.div`
  font-size: 12px;
  margin-right: 16px;
`

export type WarningItem = Omit<MenuItem, 'hasSeparator'> & { count: number | null }

type Props = {
  warningItems?: WarningItem[]
  onClose: () => void
}

const WarningsList = ({ warningItems, onClose }: Props) => {
  return (
    <div>
      {(warningItems || []).map((item, index) => (
        <ItemWrap key={index}>
          <StyledLink to={item.link} onClick={onClose}>
            <StyledListItem>
              <ItemIcon>{item.icon}</ItemIcon>
              <Label>{item.title}</Label>
              <Count>{item.count}</Count>
            </StyledListItem>
          </StyledLink>
        </ItemWrap>
      ))}
    </div>
  )
}

export default WarningsList
