import { Form, FormikProps, FormikValues } from 'formik'
import React, { FormEvent, useCallback, useState } from 'react'
import styled, { x } from '@xstyled/styled-components'
import Main from './components/Main'
import TransferItems from './components/TransferItems'
import Header from './components/Header'
import TransferActions from './components/TransferActions'
import { Wms } from '@typings/entities/Wms'
import { ProductApiRead } from '@typings/entities/Product'
import { ProductStockApi } from '@typings/entities/ProductStock'
import { TransferStatus } from '@typings/entities/Transfer'
import { GridActionsType, GridSelectorsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { useAcl } from '@components/blocks/Acl'
import { FileSubmit, OnDownloadTemplate } from '@typings/file'
import ConfirmDialog from '@components/blocks/Dialogs/ConfirmDialog'
import { useModal } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useModal'
import { Trans } from '@lingui/react'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'

const ItemsWrap = styled.div`
  border-top: slim;
  border-bottom: slim;
  border-color: lightGray3;
  padding: 4 0;
  margin: 4 0;
`

export type ExternalFormProps = {
  onBack: () => void
  wmses?: Wms[]
  loadProducts: (fulltext: string) => Promise<ProductApiRead[]>
  loadProductStocks: (productIds: string[], warehouse: string, wms: string) => Promise<ProductStockApi[]>
  itemsGridSelectors: GridSelectorsType
  itemsGridActions: GridActionsType
  onImportItems: FileSubmit<{ product: ProductApiRead; quantity: number }[]>
  onDownloadImportTemplate: OnDownloadTemplate
  title: string | JSX.Element
  titleIcon: string
}

type Props = ExternalFormProps &
  FormikProps<FormikValues> & {
    onCancel: () => void
    isCreatingNew: boolean
  }

const TransferForm = ({
  isSubmitting,
  handleSubmit,
  onBack,
  onCancel,
  wmses,
  loadProducts,
  loadProductStocks,
  itemsGridSelectors,
  itemsGridActions,
  onImportItems,
  onDownloadImportTemplate,
  values,
  setFieldValue,
  title,
  titleIcon,
  isCreatingNew,
}: Props): JSX.Element => {
  const ability = useAcl()
  const status: TransferStatus = values.status
  const isEditable = ability.can('edit', 'Transfer') && status !== TransferStatus.finished && status !== TransferStatus.canceled
  const canAdminEdit = ability.can('adminEdit', 'Transfer')

  // warning dialog when wms on virtual transfer don't match
  // TODO - figure out generic solution trough sagas
  const { onOpen: setConfirmDialog, isOpen: confirmVisible, onClose: closeConfirm } = useModal()
  const [confirmEvent, setConfirmEvent] = useState<FormEvent>()

  const testSubmit = useCallback(
    (event: FormEvent) => {
      const { type, wmsTo, wmsFrom } = values
      if (type === 'virtual' && wmsFrom != wmsTo) {
        setConfirmEvent(event)
        setConfirmDialog()
      } else {
        handleSubmit(event)
      }
    },
    [handleSubmit, setConfirmDialog, values],
  )

  const confirmSubmit = useCallback(() => {
    handleSubmit(confirmEvent)
  }, [confirmEvent, handleSubmit])

  return (
    <Modal
      hasFooter={false}
      title={title}
      cancelLabel={<Trans id="form.buttonCancel" message="Cancel" />}
      titleIcon={titleIcon}
      width="wide"
      isLoading={isSubmitting}
      onCancel={onBack}
    >
      <Form onSubmit={handleSubmit}>
        <x.div display="flex" mb={2}>
          <Header status={status} isEditable={isEditable} isLoading={isSubmitting} />
        </x.div>

        <x.div display="flex" mb={2}>
          <Main
            wmses={wmses}
            isFormEditable={isEditable}
            values={values}
            setFieldValue={setFieldValue}
            canAdminEdit={canAdminEdit}
            isCreatingNew={isCreatingNew}
          />
        </x.div>

        <ItemsWrap>
          <TransferItems
            isEditable={isEditable}
            loadProducts={loadProducts}
            loadProductStocks={loadProductStocks}
            warehouse={values.warehouseFrom}
            wms={values.wmsFrom}
            itemsGridSelectors={itemsGridSelectors}
            itemsGridActions={itemsGridActions}
            setFieldValue={setFieldValue}
            onImportItems={onImportItems}
            onDownloadImportTemplate={onDownloadImportTemplate}
          />
        </ItemsWrap>

        <TransferActions
          status={status}
          isEditable={isEditable}
          isLoading={isSubmitting}
          onCancel={onCancel}
          onSubmit={testSubmit}
        />
      </Form>
      {confirmVisible && (
        <ConfirmDialog
          onClose={closeConfirm}
          onConfirm={confirmSubmit}
          title={<Trans id="form.differentWmsTransferAlert.header" message="Warning" />}
          text={<Trans id="form.differentWmsTransferAlert.message" message="You are moving items between physical warehouses." />}
          onCloseButtonText={<Trans id="form.buttonCloseDialog" message="Close" />}
        />
      )}
    </Modal>
  )
}

export default TransferForm
