import gql from 'graphql-tag'

const transferSendQuery = gql`
  query transferSend($id: String!) {
    transferSend(id: $id, input: {}) @rest(method: "PUT", path: "transfer/{args.id}/send") {
      id
      user
      organisation
      warehouseFrom
      warehouseTo
      wmsFrom
      wmsTo
      internalNumber
      note
      requiredTransferDate
      type
      status
      items
      countOfItems
      countOfSku
      sumOfQuantity
      createdAt
      changedAt
    }
  }
`

export default transferSendQuery
