import { useLazyQuery, useQuery } from '@apollo/client'
import transferGetQuery from '@queries/transferGetQuery'
import { TransferItemApiRead } from '@typings/entities/Transfer'
import productsGetQuery from '@queries/productsGetQuery'
import { useEffect, useMemo } from 'react'
import { ProductApiRead } from '@typings/entities/Product'
import { ApiHookResult } from '@typings/ApiHooks'
import { TransferIn } from '@containers/ConnectedTransferForm/formUtils'

export const useLoadTransferDetails = (id?: string): ApiHookResult<TransferIn> => {
  const {
    data,
    error: dataError,
    loading,
  } = useQuery(transferGetQuery, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'no-cache',
  })

  const transfer = data?.transferGet

  const productIds = useMemo(() => (transfer?.items || []).map((item: TransferItemApiRead) => item.product), [transfer])

  const [loadProducts, { data: productsData, error: productError, loading: productLoading }] = useLazyQuery(productsGetQuery, {
    variables: {
      select: ['id', 'productSku', 'internalSku', 'name', 'referenceNumbers', 'workAroundLot', 'active'],
      criteria: {
        id: { in: productIds },
      },
      nested: true,
      limit: 1000,
    },
    fetchPolicy: 'no-cache',
  })
  const products = productsData?.productsGet?.results

  const error = dataError || productError
  const isLoading = loading || productLoading

  useEffect(() => {
    transfer && productIds?.length && loadProducts()
  }, [productIds, transfer, loadProducts])

  const newArr = []

  if (productIds && transfer && products) {
    for (const index of transfer?.items.keys()) {
      const item = transfer.items[index]
      const product = products?.find((product: ProductApiRead) => product.id === item.product)

      newArr.push({ ...item, product: product })
    }
  }

  return {
    data: { ...transfer, items: newArr },
    error,
    isLoading,
  }
}
