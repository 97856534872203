import gql from 'graphql-tag'

const eshopBaselinkerSyncProductStocks = gql`
  query eshopBaselinkerSyncProductStocks($warehouseId: String!) {
    eshopBaselinkerSyncProductStocks(input: { warehouseId: $warehouseId })
      @rest(type: "EshopBaselinkerSyncProductStocksResult", method: "POST", path: "baselinker/eshop/sync/product-stocks") {
      id
      warehouseId
    }
  }
`

export default eshopBaselinkerSyncProductStocks
