import React, { ReactNode, useCallback, useState, cloneElement, ReactElement } from 'react'
import styled, { x } from '@xstyled/styled-components'
import Dropdown from '@mailstep/design-system/ui/Elements/Dropdown'
import { DefaultItem as MenuItem } from '@mailstep/design-system/ui/Elements/DropdownMenu/types'
import MenuList from '@mailstep/design-system/ui/Elements/DropdownMenu/components/MenuList'
import { useClickOutside } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useClickOutside'
import { AccountData } from '@typings/entities/User'
import MenuLanguageSwitch from './MenuLanguageSwitch'
import DropdownItem from './DropdownItem'
import UserInfo from './UserInfo'
import { th } from '@xstyled/system'
import OrganisationSelect from './OrganisationSelect'
import { t } from '@lingui/macro'
import { Organisation } from '@typings/entities/Organisation'

type Props = {
  menuItems?: MenuItem[]
  activeLanguage?: string
  onLanguageChange?: (value: string) => void
  loggedAccount?: AccountData
  children: ReactElement
  footer?: ReactNode
  displayUserInfo?: boolean
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  organisations?: Organisation[]
  activeOrganisation?: string
  onChangeOrganisation?: (value: string) => void
}

const DropdownContentWrap = styled(x.div)`
  width: 295px;
  background-color: white;
  box-shadow: dropShadow;
  padding: 24px 12px;
  color: gray1;
  border: 1px solid ${th.color('lightGray6')};
  border-radius: 8px;
`

const DropdownPositionWrap = styled(x.div)`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  :hover {
    color: typoPrimary;
  }
`

const OrganisationSelectContainer = styled(x.div)`
  display: block;
  @media (min-width: 1024px) {
    display: none;
  }
`

const Header = styled(x.div)`
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 10px;
  margin-bottom: 0;
  padding-left: 4px;
  text-transform: uppercase;
  @media (min-width: 1024px) {
    margin-bottom: 12px;
  }
`

const Footer = styled(x.div)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 10px;
`

const MenuDropdown = ({
  menuItems,
  children,
  footer,
  activeLanguage,
  onLanguageChange,
  loggedAccount,
  displayUserInfo,
  setIsOpen,
  activeOrganisation,
  onChangeOrganisation,
  organisations,
}: Props): JSX.Element => {
  const hasItems = !!menuItems?.length
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)

  const toggleDropdownMenu = useCallback(() => {
    hasItems && setShowDropdownMenu((prev) => !prev)
    setIsOpen && setIsOpen((prev) => !prev)
  }, [hasItems, setIsOpen])

  const onClose = useCallback(() => {
    setShowDropdownMenu(false)
    setIsOpen && setIsOpen(false)
  }, [setIsOpen])

  const ref = useClickOutside({ onClose })

  return (
    <DropdownPositionWrap ref={ref}>
      {cloneElement(children, { onClick: toggleDropdownMenu })}
      <Dropdown show={showDropdownMenu} verticalShift>
        <DropdownContentWrap>
          <Header>
            {onLanguageChange && <MenuLanguageSwitch activeLanguage={activeLanguage} onChange={onLanguageChange} />}
          </Header>
          {displayUserInfo && (
            <>
              <OrganisationSelectContainer mb="32px">
                <OrganisationSelect
                  activeOrganisation={activeOrganisation}
                  onChangeOrganisation={onChangeOrganisation}
                  organisations={organisations}
                  label={t({ id: 'select.organisation', message: 'Select organisation' })}
                />
              </OrganisationSelectContainer>
              <UserInfo loggedAccount={loggedAccount} />
            </>
          )}
          <MenuList<MenuItem> items={menuItems} ItemComponent={DropdownItem} onClose={onClose} />
          {footer ? <Footer>{footer}</Footer> : null}
        </DropdownContentWrap>
      </Dropdown>
    </DropdownPositionWrap>
  )
}

export default MenuDropdown
