import React, { useCallback } from 'react'
import styled, { x } from '@xstyled/styled-components'
import Select from '@mailstep/design-system/ui/Elements/Select'
import { t } from '@lingui/macro'
import { useSelectEshop } from '@hooks/useSelectEshop'
import { Option } from '@mailstep/design-system/ui/Elements/Select/types'

const EshopSelectContainer = styled(x.div)`
  width: 150px;
  @media (min-width: 768px) {
    width: 170px;
  }
`

const EshopSelect = (): JSX.Element | null => {
  const { selectedEshop, setSelectedEshop, eshopOptions } = useSelectEshop()
  const handleEshopChange = useCallback(
    (eshopOption: Option) => {
      setSelectedEshop?.(eshopOption?.value)
    },
    [setSelectedEshop],
  )

  if (eshopOptions?.length <= 1) return null
  return (
    <EshopSelectContainer>
      <Select
        name="eshop"
        style="heading"
        options={eshopOptions}
        isClearable
        value={selectedEshop || ''}
        onChange={handleEshopChange}
        disabled={!eshopOptions?.length}
        placeholder={t({ id: 'products.eshopFilterPlaceholder', message: 'Select eshop...' })}
      />
    </EshopSelectContainer>
  )
}

export default EshopSelect
