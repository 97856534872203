import React from 'react'
import { BannerContainer, Wrapper, Header, MainLogo, TextContent, Footer, ToggleWrapper, ButtonsWrap } from './styles'
import { Trans } from '@lingui/react'
import { H6, Paragraph3 } from '@mailstep/design-system/ui/Elements/Typography'
import Button from '@mailstep/design-system/ui/Elements/Button'
import Toggle from '@mailstep/design-system/ui/Elements/Toggle'
import { t } from '@lingui/macro'
import { x } from '@xstyled/styled-components'
import { useCookieBanner } from './hooks'

export type CookieBannerProps = {
  onClose: () => void
}

const CookieBanner = ({ onClose }: CookieBannerProps) => {
  const { isChecked, handleToggle, handleConfirm, handleCancel } = useCookieBanner({ onClose })
  return (
    <Wrapper>
      <BannerContainer>
        <Header>
          <MainLogo brand="mailstep" variant="black" />
        </Header>
        <TextContent>
          <H6>
            <Trans id="cookieBanner.title" message="This website uses cookies" />
          </H6>
          <Paragraph3 variant="normal">
            <Trans
              id="cookieBanner.text"
              message="We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share
    information about your use of our site with our social media, advertising and analytics partners who may combine it with
    other information that you’ve provided to them or that they’ve collected from your use of their services."
            />
          </Paragraph3>
        </TextContent>
        <Footer>
          <ToggleWrapper>
            <x.div pr="30px">
              <Toggle
                variant="grid"
                defaultChecked
                label={t({ id: 'form.cookieBanner.necessary', message: 'Necessary' })}
                disabled
                spaceAround
              />
            </x.div>
            <x.div>
              <Toggle
                onChange={handleToggle}
                checked={isChecked}
                variant="grid"
                label={t({ id: 'form.cookieBanner.statistics', message: 'Statistics' })}
                spaceAround
              />
            </x.div>
          </ToggleWrapper>
          <ButtonsWrap>
            <Button type="button" variant="default" appearance="secondary" onClick={handleCancel}>
              <Trans id="form.buttonReject" message="Reject" />
            </Button>
            <Button type="button" onClick={handleConfirm}>
              <Trans id="form.buttonAlloweSelection" message="Allow selection" />
            </Button>
          </ButtonsWrap>
        </Footer>
      </BannerContainer>
    </Wrapper>
  )
}

export default CookieBanner
