import gql from 'graphql-tag'

const warehouseGetQuery = gql`
  query warehouseGet($id: String!) {
    warehouseGet(id: $id) @rest(type: "Warehouse", method: "GET", path: "warehouse/{args.id}") {
      id
      name
      customId
    }
  }
`

export default warehouseGetQuery
