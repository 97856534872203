import { useLazyQuery } from '@apollo/client'
import notificationUpdateQuery from '@queries/notificationUpdateQuery'
import { useCallback } from 'react'

type Params = { id: string; seen: boolean; flag: boolean }

export type SetNotificationFlags = (params: Params) => Promise<any>

export const useUpdateNotification = (): SetNotificationFlags => {
  const [updateNotification] = useLazyQuery(notificationUpdateQuery, { fetchPolicy: 'no-cache' })

  const setNotificationFlags = useCallback(
    ({ id, seen, flag }: Params) => {
      return updateNotification({ variables: { id, seen, flag } })
    },
    [updateNotification],
  )
  return setNotificationFlags
}
