import { DateTime } from 'luxon'
import { DatePattern, DatePatternWithTime } from '@components/forms/utils/RegexValidation'

export const sameDate = (date1: Date, date2: Date): boolean =>
  date1 && date1.getTime && date2 && date2.getTime && date1.getTime() === date2.getTime()

export const toHHmm = (date: string) => DateTime.fromISO(date).toFormat('HH:mm')
export const toFullDateAndtime = (date: string) => DateTime.fromISO(date).toFormat('dd. MM. yyyy HH:mm')
export const toFullDate = (date: string) => DateTime.fromISO(date).toFormat('dd.MM.yyyy')

export const convertToISO = (date: string | Date) => {
  switch (true) {
    case !date:
      return null

    case typeof date === 'string' && DatePatternWithTime.test(date):
      return DateTime.fromFormat(date, 'dd.MM.yyyy HH:mm', { zone: 'utc' }).toISO({
        suppressMilliseconds: true,
      })
    case typeof date === 'string' && DatePattern.test(date):
      return DateTime.fromFormat(date, 'dd.MM.yyyy', { zone: 'utc' }).toISO({
        suppressMilliseconds: true,
      })
    case typeof date === 'object':
      return DateTime.fromJSDate(date).toISO({
        suppressMilliseconds: true,
      })
    default:
      return null
  }
}
