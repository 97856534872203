import { useCallback } from 'react'
import { Dispatch } from 'redux'
import { useDispatch } from 'react-redux'
import { formatApiErrorToFormikErrors } from '@utils/formik/formatApiToFormikErrors'
import { actions as productsActions } from '@store/products'
import { actions as productStocksActions } from '@store/productStocks'
import { ProductApiRead } from '@typings/entities/Product'
import { FileSubmit, OnDownloadTemplate } from '@typings/file'
import { ProductStockApi } from '@typings/entities/ProductStock'

export const ProductItemProps = [
  'id',
  'productSku',
  'internalSku',
  'name',
  'referenceNumbers',
  'workAroundLot',
  'eshops',
  'active',
  'type',
]

type Options = {
  allowBundles?: boolean
}

type ProductItemsHook = {
  onImportItems: FileSubmit<{ product: ProductApiRead; quantity: number }[]>
  onDownloadImportTemplate: OnDownloadTemplate
  loadProducts: (fulltext: string, eshop?: string) => Promise<ProductApiRead[]>
}

export const useProductItems = (organisation: string | undefined, options?: Options): ProductItemsHook => {
  const dispatch = useDispatch()

  const onImportItems = useCallback(
    (file: File) => {
      return dispatch(productsActions.importItems({ organisation, file })).promise
    },
    [dispatch, organisation],
  )

  const onDownloadImportTemplate: OnDownloadTemplate = useCallback(
    (values, formikHelpers) => {
      return dispatch(productsActions.downloadItemsTemplate(values)).promise.catch((error: Error) =>
        formikHelpers.setErrors(formatApiErrorToFormikErrors(error)),
      )
    },
    [dispatch],
  )

  const loadProducts = useCallback(
    async (fulltext: string, eshop?: string) => {
      const allowBundles = !!options?.allowBundles
      const eshopSet = eshop && typeof eshop == 'string'

      const productCriteria = {
        active: { flag: true },
        type: allowBundles ? { in: ['physical', 'bundle'] } : { eq: 'physical' },
        organisation: { eq: organisation },
        ...(eshopSet ? { eshops: { eq: eshop } } : {}),
        OR: [
          { name: { like: `%${fulltext}%` } },
          { productSku: { like: `%${fulltext}%` } },
          { internalSku: { like: `%${fulltext}%` } },
          { referenceNumbers: { like: `%${fulltext}%` } },
        ],
      }

      return dispatch(
        productsActions.loadListDirect({
          criteria: productCriteria,
          nested: true,
          select: ProductItemProps,
        }),
      ).promise.then((response) => response?.data || [])
    },
    [dispatch, options?.allowBundles, organisation],
  )

  return { onImportItems, onDownloadImportTemplate, loadProducts }
}

type LoadProductStocks = (productIds: string[], warehouse: string, wms: string) => Promise<ProductStockApi[]>

export const useLoadProductStocks = (dispatch: Dispatch): LoadProductStocks => {
  return useCallback(
    (productIds, warehouse, wms) => {
      if (productIds.length <= 0) {
        return []
      }

      const stocksCriteria = {
        warehouse: { in: [warehouse] },
        wms: { in: [wms] },
        product: { in: productIds },
      }

      return dispatch(
        productStocksActions.run({
          criteria: stocksCriteria,
        }),
      ).promise.then((response) => response?.data || [])
    },
    [dispatch],
  )
}
