import reduceReducers from 'reduce-reducers'
import createFetchModule from '@utils/store/createFetchModule'
import { QueryData } from '@containers/ConnectedDashboard/widgets/HoCs'

export const moduleName = 'dashboard'

const fetchModule = createFetchModule('@store/Dashboard', 'LOAD_WIDGET_DATA', moduleName)

export const actionTypes = {
  loadWidgetData: fetchModule.actionTypes,
}

export default reduceReducers(fetchModule.reducer)

export const actions = {
  loadWidgetData: (
    config: any,
    query: QueryData,
    setData: (val: number) => void,
    setIsLoading: (l: boolean) => void,
    setError: (e: boolean) => void,
  ) => fetchModule.actions.run({ config, query, setData, setIsLoading, setError }),
}
