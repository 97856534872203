import React from 'react'
import { Formik, Form, FormikBag } from 'formik'
import { Trans } from '@lingui/react'
import { Action } from './types'
import { th } from '@xstyled/system'
import styled, { x } from '@xstyled/styled-components'
import Button from '@mailstep/design-system/ui/Elements/Button'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'

const Title = styled.span`
  padding-left: 12px;
  font-size: 16px;
  font-weight: bold;
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 4;
  margin-bottom: 4;
  border-bottom: 1px solid ${th('colors.white')};
`

const CancelButtonText = styled.span`
  font-weight: 700;
  color: ${th.color('gray')};
`

const ButtonsWrapper = styled(x.div)`
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-end;
  & > :not(:last-child) {
    margin-right: 8px;
  }
`

const defaultInitialValues = {}

type Props = {
  onActionSubmit: Function
  onActionCancel: () => void
  selectedAction?: Action
  modalContentComponent: React.ComponentType
}

export const BatchActionModal = ({
  onActionSubmit,
  onActionCancel,
  modalContentComponent: Content,
  selectedAction,
  ...rest
}: Props): JSX.Element => {
  const handleOnSubmit = React.useCallback(
    (values: any) => {
      if (onActionSubmit) {
        // wait short moment, than close modal
        setTimeout(onActionCancel, 1000)
        return onActionSubmit(values)
      }
    },
    [onActionSubmit, onActionCancel],
  )

  return (
    <>
      {selectedAction && (
        <Formik onSubmit={handleOnSubmit} initialValues={defaultInitialValues}>
          {({ submitForm, isSubmitting }: FormikBag): JSX.Element => (
            <Modal title={<Title>{selectedAction.label}</Title>} onClose={onActionCancel}>
              <x.div mt={4}>
                <Form>
                  <Content selectedActionItem={selectedAction} {...rest} />
                </Form>
                <Separator />
                <ButtonsWrapper>
                  <Button
                    type="button"
                    onClick={onActionCancel}
                    isLoading={isSubmitting}
                    variant="default"
                    appearance="secondary"
                  >
                    <Trans id="form.buttonCancelAndClose" message="Cancel and close" />
                  </Button>
                  <Button type="button" onClick={submitForm} isLoading={isSubmitting}>
                    <Trans id="form.buttonSubmit" message="Submit" />
                  </Button>
                </ButtonsWrapper>
              </x.div>
            </Modal>
          )}
        </Formik>
      )}
    </>
  )
}
