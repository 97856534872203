import React, { useCallback, useMemo } from 'react'
import { Dispatch } from 'redux'
import { actions as uiActions, selectors as uiSelectors } from '@store/ui'
import { selectors as adminSelectors, actions as adminActions } from '@store/admin'
import { selectors as userSelectors, actions as userActions } from '@store/user'
import routes from '@pages/index'
import ConnectedHeading from '@containers/ConnectedHeading'
import { Props as HeadingProps } from '@components/blocks/Heading'
import { Trans } from '@lingui/react'
import { KB_URL, MAILSHIP_CONTACT_URL } from '@constants/links'
import FaIcon, { Desktop, Message } from '@mailstep/design-system/ui/Elements/Icon'
import { useDispatch, useSelector } from 'react-redux'
import { Settings2 } from '@mailstep/design-system/ui/Elements/Icon/icons/Settings2'
import { Puzzle1 } from '@mailstep/design-system/ui/Elements/Icon/icons/Puzzle1'
import { Inventory2 } from '@mailstep/design-system/ui/Elements/Icon/icons/Inventory2'
import { Logout1 } from '@mailstep/design-system/ui/Elements/Icon/icons/Logout1'
import { useHistory, useLocation } from 'react-router'
import useUser from '@hooks/useUser'

const createHelpSectionItems = (): HeadingProps['helpSectionItems'] => [
  {
    title: <Trans id="heading.menu.helpCenter" message="Help center" />,
    link: '#',
    onClick: (): void => window.open(KB_URL, '_blank')?.focus(),
    icon: <Desktop />,
  },
  {
    title: <Trans id="heading.menu.contactUs" message="Contact us" />,
    link: '#',
    onClick: (): void => window.open(MAILSHIP_CONTACT_URL, '_blank')?.focus(),
    icon: <Message />,
  },
]

const createUserMenuItems = (
  dispatch: Dispatch,
  handleSettingsNavigation: () => void,
  isAdmin: boolean,
): HeadingProps['userMenuItems'] => {
  return [
    {
      title: <Trans id="heading.userMenu.settings" message="Settings" />,
      link: isAdmin ? routes.adminEditUser.route.toUrl() : routes.userEditUser.route.toUrl(),
      icon: <Settings2 width="18px" height="18px" stroke="#374151" />,
      onClick: handleSettingsNavigation,
    },
    ...(isAdmin
      ? [
          {
            title: <Trans id="heading.userMenu.exchangeRates" message="Exchange Rates" />,
            link: '#',
            icon: <Puzzle1 width="18px" height="18px" />,
            onClick: (): void => {
              dispatch(uiActions.openQuickAccessDialog('exchangeRates'))
            },
          },
          {
            title: <Trans id="heading.userMenu.logs" message="Logs" />,
            link: routes.sharedAudits.route.toUrl(),
            icon: <Inventory2 width="18px" height="18px" />,
            autoClose: true,
          },
        ]
      : []),
    {
      title: <Trans id="heading.userMenu.logout" message="Logout" />,
      onClick: (): void => {
        dispatch(isAdmin ? adminActions.logout() : userActions.logout())
      },
      link: '#',
      hasSeparator: true,
      icon: <Logout1 width="18px" height="18px" />,
    },
  ]
}

const createWarningItems = (
  incorrectExpeditions: number | null,
  reservationsOnExpedited: number | null,
  waitingForReporter: number | null,
  waitingForResolver: number | null,
  internalStockChanges: number | null,
  isAdmin: boolean,
): HeadingProps['warningItems'] => [
  {
    icon: <FaIcon icon="expedition" />,
    title: <Trans id="heading.warning.incorrectExpeditions" message="Incorrect expeditions" />,
    link: routes.sharedExpeditionsStatus.route.toUrl({ status: 'incorrect' }),
    count: incorrectExpeditions,
  },
  ...(isAdmin
    ? [
        {
          icon: <FaIcon icon="reservation" />,
          title: <Trans id="heading.warning.reservationsExpedited" message="Reservations on expedited expeditions" />,
          link: routes.sharedReservations.route.toUrl({ status: 'expedited' }),
          count: reservationsOnExpedited,
        },
      ]
    : []),
  {
    icon: <FaIcon icon="warning" />,
    title: <Trans id="heading.warning.waitingForReporter" message="Complaints waiting for you (reporter)" />,
    link: routes.sharedComplaintsMine.route.toUrl({ status: 'waiting_for_reporter' }),
    count: waitingForReporter,
  },
  {
    icon: <FaIcon icon="warning" />,
    title: <Trans id="heading.warning.waitingForResolver" message="Complaints waiting for you (resolver)" />,
    link: routes.sharedComplaintsMine.route.toUrl({ status: 'waiting_for_resolver' }),
    count: waitingForResolver,
  },
  {
    icon: <FaIcon icon="transfer" />,
    title: <Trans id="heading.warning.internalStockChanges" message="Pending inventory movements" />,
    link: routes.sharedInternalStockChangesStatus.route.toUrl({ status: 'new' }),
    count: internalStockChanges,
  },
]

type Props = {
  setIsLeftMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const Heading = ({ setIsLeftMenuOpen }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { isAdmin } = useUser()
  const loginLink = isAdmin ? routes.adminLogin.route.toUrl() : routes.userLogin.route.toUrl()
  const homeLink = isAdmin ? routes.adminHome.route.toUrl() : routes.userHome.route.toUrl()

  const handleSettingsNavigation = useCallback(() => {
    dispatch(uiActions.setCameToSettingsFromPage(location.pathname))
    history.push(isAdmin ? routes.adminEditUser.route.toUrl() : routes.userEditUser.route.toUrl())
  }, [dispatch, history, isAdmin, location.pathname])

  const { userData } = useSelector(userSelectors.user)
  const { adminData } = useSelector(adminSelectors.admin)
  const loggedAccount = isAdmin ? adminData : userData

  const helpSectionItems = useMemo(() => createHelpSectionItems(), [])
  const userMenuItems = useMemo(
    () => createUserMenuItems(dispatch, handleSettingsNavigation, isAdmin),
    [dispatch, handleSettingsNavigation, isAdmin],
  )

  const incorrectExpeditions = useSelector(uiSelectors.incorrectExpeditions)
  const reservationsOnExpedited = useSelector(uiSelectors.reservationsOnExpedited)
  const waitingForReporter = useSelector(uiSelectors.waitingForReporter)
  const waitingForResolver = useSelector(uiSelectors.waitingForResolver)
  const internalStockChanges = useSelector(uiSelectors.internalStockChanges)
  const warningItems = useMemo(
    () =>
      createWarningItems(
        incorrectExpeditions,
        reservationsOnExpedited,
        waitingForReporter,
        waitingForResolver,
        internalStockChanges,
        isAdmin,
      ),
    [incorrectExpeditions, reservationsOnExpedited, waitingForReporter, waitingForResolver, internalStockChanges, isAdmin],
  )

  return (
    <ConnectedHeading
      loggedAccount={loggedAccount}
      loginLink={loginLink}
      homeLink={homeLink}
      userMenuItems={userMenuItems}
      helpSectionItems={helpSectionItems}
      warningItems={warningItems}
      setIsLeftMenuOpen={setIsLeftMenuOpen}
    />
  )
}

export default Heading
