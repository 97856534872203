import { appearanceToColorMap, appearanceToFontColorMap } from '@constants/Appearance'
import { useTheme } from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import React, { useCallback } from 'react'
import { ArrowButtonsWrap, ButtonContent, ButtonContentWrap, StyledArrowButton, ButtonLabel } from './styles'

export type ArrowButton = {
  content: React.ReactNode
  label: string | JSX.Element
  value?: string | number
  appearance?: string
  fontColor?: string
}

export type ArrowButtonProps = {
  button: ArrowButton
  onClick?: (value: ArrowButton['value']) => void
}

export type ArrowButtonsProps = {
  onClick: ArrowButtonProps['onClick']
  buttons: ArrowButton[]
}

export const ArrowButton = ({ onClick, button }: ArrowButtonProps): JSX.Element => {
  const theme = useTheme()
  const { content, label, value, appearance } = button
  const bgColor = th.color(appearance ? appearanceToColorMap[appearance] : 'bgLightGray1')({ theme })
  const fontColor = th.color(appearance ? appearanceToFontColorMap[appearance] : 'blue2')({ theme })

  const handleClick = useCallback(() => value && onClick?.(value), [onClick, value])
  return (
    <StyledArrowButton onClick={handleClick} bgColor={bgColor} fontColor={fontColor}>
      <svg width="20" height="48" viewBox="0 0 20 48" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.11776 1.57802C0.648663 0.91576 1.12222 0 1.93379 0H20V48H1.93379C1.12222 48 0.648663 47.0842 1.11776 46.422L14.9529 26.8901C16.1794 25.1586 16.1794 22.8414 14.9529 21.1099L1.11776 1.57802Z" />
      </svg>
      <ButtonContentWrap>
        <ButtonContent>{content || 0}</ButtonContent>
        <ButtonLabel>{label}</ButtonLabel>
      </ButtonContentWrap>
      <svg width="20" height="48" viewBox="0 0 20 48" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.7545 22.2563L4.35272 2.09381C3.41417 0.779828 1.89881 -7.91228e-07 0.284056 -8.61811e-07L1.90735e-06 -8.74228e-07L1.71655e-06 48L0.284054 48C1.89881 48 3.41416 47.2202 4.35272 45.9062L18.7545 25.7437C19.4996 24.7006 19.4996 23.2994 18.7545 22.2563Z" />
      </svg>
    </StyledArrowButton>
  )
}

export const ArrowButtons = ({ onClick, buttons = [] }: ArrowButtonsProps): JSX.Element => {
  return (
    <ArrowButtonsWrap>
      {buttons.map((button, index) => {
        const key = `${index}-${button.value || button.label}`
        return <ArrowButton key={key} button={button} onClick={onClick} />
      })}
    </ArrowButtonsWrap>
  )
}
