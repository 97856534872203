import React, { memo } from 'react'
import Link from '@mailstep/design-system/ui/Elements/SimpleLink'
import styled from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import Badge from '@mailstep/design-system/ui/Elements/Badge'

export type CommandType = {
  icon: React.ReactNode
  title?: string | JSX.Element
  link?: string | { pathname: string }
  onClick?: () => void
  disabled?: boolean
  badgeCount?: number
  size?: string
  verticalAdjustment?: number
}

const StyledCommand = styled(Link)<{
  $size?: string
  $verticalAdjustment?: number
}>`
  display: flex;
  position: relative;
  cursor: pointer;
  color: lightGray4;
  font-size: ${({ $size }) => $size || '0'};
  top: ${({ $verticalAdjustment }) => $verticalAdjustment || '0'}px;

  .title {
    display: none;
    position: absolute;
    font-size 10px;
    font-weight: medium;
    font-family: ${th('fonts.primary')};
    color: typoPrimary;
    border-radius: lg;
    text-align: center;
    background-color: white;
    width: 100px;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 12px);
    z-index: 1;
    padding: 2;
    box-shadow: tooltipBoxShadow;
  }
  :hover {
    color: typoPrimary;
    .title {
      display: block;
    }
  }
`

const RelativeWrap = styled.div`
  position: relative;
`

const Command = ({ icon, link, title, onClick, disabled, badgeCount, verticalAdjustment, size }: CommandType): JSX.Element => {
  return (
    <StyledCommand to={link || '#'} onClick={onClick} disabled={disabled} $size={size} $verticalAdjustment={verticalAdjustment}>
      {title && <div className="title">{title}</div>}
      <RelativeWrap>
        {typeof badgeCount == 'number' && badgeCount > 0 && !disabled && <Badge label={badgeCount} size="small" topPosition />}
        {icon}
      </RelativeWrap>
    </StyledCommand>
  )
}

export default memo(Command)
