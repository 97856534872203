import React from 'react'
import { x } from '@xstyled/styled-components'
import StatusRow from './StatusRow'
import Message from '@typings/entities/Message'
import sortBy from 'lodash/sortBy'

type Props = {
  statusRows?: Message[]
}

const StatusRows = ({ statusRows }: Props): JSX.Element | null => {
  if (!statusRows || statusRows.length === 0) return null
  return (
    <x.div>
      {sortBy(statusRows, ['type']).map((statusRow, i) => (
        <StatusRow key={i} {...statusRow} inline />
      ))}
    </x.div>
  )
}

export default StatusRows
