import { FormEvent, useCallback } from 'react'

type submitType = (event: FormEvent<HTMLFormElement>) => void

type UseEnterSubmitProps = {
  onEnterSubmit: submitType
}

export const useEnterSubmit = (handleSubmit: submitType): UseEnterSubmitProps => {
  const onEnterSubmit = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleSubmit(event)
      }
    },
    [handleSubmit],
  )
  return { onEnterSubmit }
}
