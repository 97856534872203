import gql from 'graphql-tag'

const eshopsGetQueryQuery = gql`
  query eshopsGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object, $nested: boolean) {
    eshopsGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: $nested })
      @rest(type: "EshopsGetQuery", method: "POST", path: "eshop/list") {
      results
      paging
    }
  }
`

export default eshopsGetQueryQuery
