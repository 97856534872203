import React from 'react'

const useEffectChange = (onChange?: Function, deps?: any[]) => {
  const [wasFirstRender, setWasFirstRender] = React.useState(false)
  React.useEffect(() => {
    if (!onChange) return
    if (!wasFirstRender) {
      setWasFirstRender(true)
      return
    }
    onChange()
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useEffectChange
