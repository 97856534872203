import { useCallback, useState } from 'react'
import { Types as CGT } from '@mailstep/design-system/ui/Blocks/CommonGrid'
import { Action } from './types'

type SubmitFunction<T, FV> = (action: string, affectedRows: T[], formValues: FV) => void

// todo improve types here and type modal

type BatchAction<T> = {
  selectedAction: Action
  actionRows: T[]
}

type ReturnProps<T, FV> = {
  onBatchAction: CGT.CommonGridProps['onBatchAction']
  modalProps: {
    onActionSubmit: (formValues: FV) => void
    onActionCancel: () => void
    selectedAction?: BatchAction<T>['selectedAction']
    actionRows?: BatchAction<T>['actionRows']
  }
}

export const useBatchActionModal = <T, FV extends object>(batchActionSubmit: SubmitFunction<T, FV>): ReturnProps<T, FV> => {
  const [batchAction, setBatchAction] = useState<BatchAction<T> | null>(null)

  const onActionCancel = useCallback(() => {
    setBatchAction(null)
  }, [])

  const onBatchAction = useCallback((selectedAction, actionRows) => {
    setBatchAction({ selectedAction, actionRows })
  }, [])

  const onActionSubmit = useCallback(
    (formValues: FV): void => {
      if (batchActionSubmit && batchAction) {
        batchActionSubmit(`${batchAction.selectedAction.value}`, batchAction.actionRows, formValues)
      }
    },
    [batchAction, batchActionSubmit],
  )

  return {
    onBatchAction,
    modalProps: {
      onActionSubmit,
      onActionCancel,
      selectedAction: batchAction?.selectedAction,
      actionRows: batchAction?.actionRows,
    },
  }
}
