import { t } from '@lingui/macro'
import { WarehouseAsNested } from './Warehouse'
import { WmsAsNested } from './Wms'
import { OrganisationAsNested } from '@typings/entities/Organisation'
import { ProductApiRead } from '@typings/entities/Product'
import { FlagAppearanceType } from '@constants/FlagAppearance'
import { BookStockAdvice } from './StockAdvice'
import { ProductStockApi } from './ProductStock'

export const StockChangeTypeTrans = {
  in: () => t({ id: 'stockChangeType.In', message: `In` }),
  out: () => t({ id: 'stockChangeType.Out', message: `Out` }),
}

export const typeToColor: { [value: string]: FlagAppearanceType } = {
  in: 'purpleSubtle',
  out: 'yellowSubtle',
}

export const StockChangeStatusTrans = {
  new: () => t({ id: 'stockChangeStatus.New', message: `New` }),
  waiting_for_goods: () => t({ id: 'stockChangeStatus.WaitingForGoods', message: `Waiting for goods` }),
  ready: () => t({ id: 'stockChangeStatus.Ready', message: `Ready` }),
  processed: () => t({ id: 'stockChangeStatus.Processed', message: `Processed` }),
  failed: () => t({ id: 'stockChangeStatus.Failed', message: `Failed` }),
  canceled: () => t({ id: 'stockChangeStatus.Canceled', message: `Canceled` }),
}

export const statusToColor: { [value: string]: FlagAppearanceType } = {
  new: 'tealSubtle',
  waiting_for_goods: 'orangeSubtle',
  ready: 'blueSubtle',
  processed: 'greenSubtle',
  failed: 'redBold',
  canceled: 'redSubtle',
}

type StockChangeBare = {
  id: string
  wmsInternalId?: string
  receivedAt?: string
  createdAt?: string
  countOfItems: number
  countOfSku: number
  sumOfQuantity: number
  type?: keyof typeof StockChangeTypeTrans
  status: keyof typeof StockChangeStatusTrans
}

export type StockChangeNested = StockChangeBare & {
  wms: WmsAsNested
  warehouse: WarehouseAsNested
  organisation: OrganisationAsNested
}

type StockChangeApiItem = {
  id: string
  position?: number
  stockChange: string
  ref1?: string
  ref2?: string
  ref3?: string
  lot?: string
  assignedLot?: string
  lifo?: boolean
  expedited?: number
  quantity: number
  booked?: number
  missing?: number
  product: string
  bookStockAdvices: BookStockAdvice[]
}

export type StockChangeApi = StockChangeBare & {
  wms: string
  warehouse: string
  organisation: string
  movements?: string[]
  items?: StockChangeApiItem[]
}

export type StockChangeDetailItem = Omit<StockChangeApiItem, 'product'> & {
  product: ProductApiRead
  availableStock: number | '?' | null
  stocksState: 'ready' | 'loading' | 'notReady'
  bookedAdviceTotal: number
  bookedStockAdvices?: BookStockAdvice[]
  stockLots: ProductStockApi['lots']
  bookAdviceTotal: number
}

export type StockChangeDetail = Omit<StockChangeApi, 'items' | 'createdAt' | 'receivedAt'> & {
  receivedAt?: Date
  createdAt?: Date
  items: StockChangeDetailItem[]
  // also warehouse and wms are converted form id to name
}
