import gql from 'graphql-tag'

const userPasswordResetQuery = gql`
  query userPasswordReset($email: String!) {
    userPasswordReset(input: { email: $email, phone: $phone })
      @rest(type: "UserPasswordReset", method: "POST", path: "request-reset-password/user") {
      foo
    }
  }
`

export default userPasswordResetQuery
