import React, { useMemo, useState } from 'react'
import Avatar from '@mailstep/design-system/ui/Elements/Avatar'
import { Trans } from '@lingui/react'
import { AccountData } from '@typings/entities/User'
import { DefaultItem as MenuItem } from '@mailstep/design-system/ui/Elements/DropdownMenu/types'
import MenuDropdown from './MenuDropdown'
import { Organisation } from '@typings/entities/Organisation'
import Tooltip from '@mailstep/design-system/ui/Elements/Tooltip'
import { x } from '@xstyled/styled-components'

type Props = {
  menuItems?: MenuItem[]
  activeLanguage?: string
  onLanguageChange: (value: string) => void
  loggedAccount?: AccountData
  appVersion: string
  loginLink?: string
  organisations?: Organisation[]
  activeOrganisation: string
  onChangeOrganisation: (value: string) => void
}

// TODO login link
// TODO logged stated indicatior
// title: <Trans id="heading.menu.login" message="Login" />,changelogLink

const getLoginItem = (loginLink: string): MenuItem => ({
  title: <Trans id="heading.menu.login" message="Login" />,
  link: loginLink,
})

const UserMenu = ({
  menuItems,
  onLanguageChange,
  activeLanguage,
  loggedAccount,
  appVersion,
  loginLink,
  activeOrganisation,
  onChangeOrganisation,
  organisations,
}: Props): JSX.Element => {
  const userPhoto = loggedAccount?.photo
  const isLoggedIn = !!loggedAccount?.id
  const userName = loggedAccount?.firstName && `${loggedAccount?.firstName} ${loggedAccount?.lastName}`

  const loginItem = useMemo(() => [getLoginItem(loginLink)], [loginLink])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <MenuDropdown
      menuItems={isLoggedIn ? menuItems : loginItem}
      onLanguageChange={onLanguageChange}
      activeLanguage={activeLanguage}
      setIsOpen={setIsDropdownOpen}
      loggedAccount={loggedAccount}
      displayUserInfo={true}
      activeOrganisation={activeOrganisation}
      onChangeOrganisation={onChangeOrganisation}
      organisations={organisations}
      footer={appVersion}
    >
      <x.div cursor="pointer">
        <Tooltip subtitle={userName}>
          <Avatar colorBack={isDropdownOpen ? 'lightGray7' : 'neutral20'} src={userPhoto || undefined} size={34} />
        </Tooltip>
      </x.div>
    </MenuDropdown>
  )
}

export default UserMenu
