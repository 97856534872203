import React from 'react'
import { H3 } from '@mailstep/design-system/ui/Elements/Typography'
import { Can } from '@components/blocks/Acl'
import LoadingPage from '@pages/Loading'
import { Redirect } from 'react-router-dom'
import routes from '@pages/index'
import { useLocation } from 'react-router'

// TODO synchronization of rights checks / form modes / urls needs to be further reworked
type DirectProps = [string, string]
type PermissionGetter = (props: any) => [string, string]
type Props = DirectProps | PermissionGetter

// eslint-disable-next-line react/display-name
const withAcl =
  (hocProps: Props) =>
  <P extends object>(WrappedComponent: React.ComponentType<P>): React.ComponentType<P> =>
  (props): JSX.Element => {
    const location = useLocation()
    const [resource, action] = typeof hocProps == 'function' ? hocProps(props) : hocProps
    const loginUrl = location?.pathname.includes('/admin') ? routes.adminLogin.route.toUrl() : routes.userLogin.route.toUrl()

    // TODO - test login and alternatively redirect to home / display forbidden

    return (
      <>
        <Can not I="aclReady" a="BE">
          <LoadingPage />
        </Can>
        <Can I="aclReady" a="BE">
          <Can not I={action} a={resource}>
            <H3 textAlign="center">You do not have permissions.</H3>
            <Redirect to={loginUrl} />
          </Can>
          <Can I={action} a={resource}>
            <WrappedComponent {...props} />
          </Can>
        </Can>
      </>
    )
  }

export default withAcl
