import React, { useMemo } from 'react'
import { Field } from 'formik'
import InputField from '@components/forms/components/InputField'
import { Trans } from '@lingui/react'
import { TransferStatus, TransferStatusTrans, statusToColor } from '@typings/entities/Transfer'
import SelectField from '@components/forms/components/SelectField'
import getEnumOptions from '@utils/mapEnumToOptions'
import { TransferTypeTrans } from '@typings/entities/Transfer'
import { t } from '@lingui/macro'
import { Col } from '@designSystem/Grid'
import HighlightBox from '@components/elements/HighlightBoxReworked'
import { ResponsiveRowWrap } from '@components/forms/styles'

interface HeaderType {
  isEditable: boolean
  isLoading?: boolean
  status?: TransferStatus
}

const Header = ({ status, isEditable }: HeaderType): JSX.Element => {
  const typeOptions = useMemo(() => getEnumOptions(TransferTypeTrans), [])

  return (
    <Col size={12}>
      <ResponsiveRowWrap>
        <Col>
          <Field
            name="internalNumber"
            component={InputField}
            label={<Trans id="form.transferInternalNumber.label" message="Transfer number" />}
            disabled={!isEditable}
            spaceAround
          />

          {status && (
            <HighlightBox appearance={statusToColor[status]} size="default">
              {TransferStatusTrans[status]?.()}
            </HighlightBox>
          )}
        </Col>

        <Col>
          <Field
            component={SelectField}
            name="type"
            label={<Trans id="form.transferType.label" message="Transfer type" />}
            options={typeOptions}
            placeholder={t({ id: 'form.transferType.placeholder', message: 'Select type of transfer...' })}
            isLoading={false}
            disabled={!isEditable}
            spaceAround
          />
        </Col>
      </ResponsiveRowWrap>
    </Col>
  )
}

export default React.memo(Header)
