import React from 'react'
import { Trans } from '@lingui/react'
import withFormikToProps from '@utils/formik/withFormikToProps'
import SingleSelect, { Props as SelectFieldProps } from '@mailstep/design-system/ui/Elements/SingleSelect/SingleSelect'
import { RowValueType, DataCellProps } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { Field } from 'formik'
import { isMetaLot } from '@utils/expedition'
import { ExpeditionDetailGridItem, MetaLotOptionsTrans } from '@typings/entities/Expedition'
import { t } from '@lingui/macro'

const OTHER = '__other'

type Props = SelectFieldProps & {
  showLotsForm: () => void
}

const SelectFieldWrap = (props: Props): JSX.Element => {
  const { onChange, showLotsForm, ...restProps } = props

  const handleChange: Props['onChange'] = React.useCallback(
    (event): void => {
      if (event.target.value == OTHER) {
        showLotsForm()
      } else {
        onChange?.(event)
      }
    },
    [onChange, showLotsForm],
  )

  return <SingleSelect onChange={handleChange} {...restProps} style="gridFilter" />
}

const LotSelectField = withFormikToProps(SelectFieldWrap)

const filterOption = ({ value }: { value: string }): boolean => isMetaLot(value) || value == OTHER

const LotComponent = ({ rowData, column, onRowAction }: DataCellProps<ExpeditionDetailGridItem>): RowValueType => {
  const value = rowData[column.name]
  const { isEditable, getIndex } = column.passProps // little hacks to pass extra values in render component

  const onShowLotsForm = React.useCallback(() => {
    onRowAction?.(rowData.id, 'choseLot', rowData)
  }, [rowData, onRowAction])

  const options = React.useMemo(() => {
    const currentOption = value && !isMetaLot(value) && rowData.stockLots?.find((stockLot) => stockLot.lot == value)
    return [
      ...MetaLotOptionsTrans(),
      ...(rowData.stockLots?.length ? [{ value: OTHER, label: t({ id: 'dataGrid.columnLot.other', message: 'Other' }) }] : []),
      ...(currentOption ? [{ value: currentOption.lot, label: `${currentOption.lot} | ${currentOption.date || ''}` }] : []),
    ]
  }, [rowData.stockLots, value])

  if (!rowData.product.workAroundLot || rowData.product.type === 'virtual') {
    return <Trans id="dataGrid.columnLot.NA" message="N/A" />
  } else if (!isEditable) {
    return `${rowData.lot || ''}`
  }

  return (
    <Field
      name={`items[${getIndex(rowData)}][lot]`}
      component={LotSelectField}
      options={options}
      filterOption={filterOption}
      isSearchable={false}
      disabled={rowData.stocksState !== 'ready'}
      isLoading={rowData.stocksState == 'loading'}
      showLotsForm={onShowLotsForm}
    />
  )
}

export default LotComponent
