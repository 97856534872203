import reduceReducers from 'reduce-reducers'
import createFetchModule from '@utils/store/createFetchModule'
import { FetchActionsWith } from '@typings/generic'

export const moduleName = 'carriersGrid'

const fetchModule = createFetchModule('@store/CarriersGrid', 'LOAD', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
}

export default reduceReducers(fetchModule.reducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
}
