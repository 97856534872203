import React, { memo, useMemo } from 'react'
import { HeaderItemsWrap, Item, ItemLabel, ItemContent } from './styles'
import { withAcl } from '@components/blocks/Acl'
import every from 'lodash/fp/every'

type HeaderItem = {
  label: string | JSX.Element
  component?: string | JSX.Element
  emphasis?: boolean
  isSmall?: boolean
}

type Props = {
  items: HeaderItem[][] | HeaderItem[] // single column of items or split into arrays by columns
  compact?: boolean
}

const HeaderItem = ({ item }: { item: HeaderItem | undefined }): JSX.Element =>
  item ? (
    <Item>
      <ItemLabel>{item.label}</ItemLabel>
      <ItemContent $emphasis={!!item.emphasis}>{item.component || null}</ItemContent>
    </Item>
  ) : (
    <Item className="padding" />
  )

const InfoHeader = ({ items = [], compact = false }: Props): JSX.Element => {
  // covert to uniform shape - array of columns
  const uniformItems = useMemo(() => (Array.isArray(items[0]) ? items : [items]), [items]) as HeaderItem[][]

  const textOnly = useMemo(() => {
    return every((columnItems) => {
      return every((item) => item.isSmall || ['string', 'number', 'undefined'].includes(typeof item.component), columnItems)
    }, uniformItems)
  }, [uniformItems])

  const columnCount = uniformItems.length
  const helperArrayColumns = useMemo(() => Array(columnCount).fill(null), [columnCount])

  let rowCount = 0 // = length of longest column
  uniformItems.forEach((columnItems) => {
    rowCount = Math.max(rowCount, columnItems.length)
  })
  const helperArrayRows = useMemo(() => Array(rowCount).fill(null), [rowCount])

  return (
    <HeaderItemsWrap $isCompact={compact} $columnCount={columnCount} $textOnly={textOnly}>
      {helperArrayRows.map((v, rowIndex) =>
        helperArrayColumns.map((v, columnIndex) => (
          <HeaderItem key={`${columnIndex}_${rowIndex}`} item={uniformItems[columnIndex][rowIndex]} />
        )),
      )}
    </HeaderItemsWrap>
  )
}

export default withAcl(memo(InfoHeader))
