import { useQuery } from '@apollo/client'
import partnersGetQuery from '@queries/partnersGetQuery'
import { ApiHookResult } from '@typings/ApiHooks'
import { Partner } from '@typings/entities/Partner'

export const useLoadPartnersList = (eshopIds: string[], skip: boolean): ApiHookResult<Partner[]> => {
  const {
    data,
    error,
    loading: isLoading,
  } = useQuery(partnersGetQuery, {
    variables: { criteria: { eshop: { in: eshopIds } } },
    skip,
  })
  return {
    data: data?.partnersGet?.results,
    isLoading,
    error,
  }
}
