import { DateTime } from 'luxon'
import { isValid } from 'date-fns'
import { DatePattern, LongDatePattern } from '@components/forms/utils/RegexValidation'

export const validateDateFormat = (value?: string | null): boolean => {
  if (!value) {
    return true
  }

  if (LongDatePattern.test(value)) {
    const date = new Date(value)

    if (isValid(date)) {
      const formatedDate = DateTime.fromJSDate(date).toFormat('dd.MM.yyyy')
      return DateTime.fromFormat(formatedDate, 'dd.MM.yyyy').isValid
    }

    return false
  }

  if (DatePattern.test(value)) {
    const dateTime = DateTime.fromFormat(value, 'dd.MM.yyyy')
    return dateTime.isValid
  }

  return false
}
