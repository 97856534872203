import React, { useCallback, useMemo } from 'react'
import * as unsyncedProductsGridStore from '@store/unsyncedProductsGrid'
import usePageGridLoading from '@utils/containers/usePageGridLoading'
import { ProductSynchronization } from '@typings/entities/ProductsSynchronization'
import { OrderSynchronization } from '@typings/entities/OrderSynchronization'
import { Trans } from '@lingui/react'
import UnsyncedProductsList from './UnsyncedProductsList'
import { ExtraControlButton } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { useSyncProductRetry } from '@hooks/apiHooks/baselinkerSynchronization/useSyncProductRetry'
import { BaselinkerSyncDialogs } from '@pages/SharedModule/Baselinker/components/BaselinkerSyncDialogs'
import { useSyncProductDialogs } from '@pages/SharedModule/Baselinker/hooks/useSyncProductDialogs'
import { WithArrow } from '@components/elements/WithArrow/WithArrow'
import useIsSyncRunning from '@hooks/useIsSyncRunning'
import { useSelectEshop } from '@hooks/useSelectEshop'

type Props = {
  eshopId?: string
  syncDetailToken?: string
  onClose: () => void
  type: 'product' | 'stock'
}
const gridProductName = 'unsyncedProductsGrid'
const gridStockName = 'unsyncedStocksGrid'

const Container = ({ eshopId, syncDetailToken, onClose, type }: Props): JSX.Element => {
  const isProductSync = type === 'product'
  const containerFilters = useMemo(() => {
    if (eshopId && syncDetailToken) {
      return {
        eshopId,
        syncDetailToken,
        [isProductSync ? 'lastProductSyncResult' : 'lastStockSyncResult']: { in: ['error'] },
      }
    }
    return {}
  }, [eshopId, syncDetailToken, isProductSync])
  const { validEshopSelected: selectedEshop } = useSelectEshop()

  const { data, gridIsLoading, error, allRowsCount, gridActions, gridSelectors, reloadData } = usePageGridLoading<
    ProductSynchronization | OrderSynchronization
  >({
    gridName: isProductSync ? gridProductName : gridStockName,
    storeModule: unsyncedProductsGridStore,
    containerFilters: containerFilters,
    skip: !eshopId,
  })
  const {
    handleSynchronizeProductsClick,
    handleSynchronizeProductStocksClick,
    closeSyncProductStocksDialog,
    closeSyncProductsDialog,
    syncProductsDialogVisible,
    syncProductStocksDialogVisible,
  } = useSyncProductDialogs()

  const [isProductSyncRunning, isProductStockSyncRunning] = useIsSyncRunning()
  const isSyncRunning = isProductSync ? isProductSyncRunning : isProductStockSyncRunning

  const extraControlButtons: ExtraControlButton[] = useMemo(
    () => [
      {
        label: (
          <WithArrow direction={isProductSync ? 'bottom' : 'top'}>
            {isProductSync ? (
              <Trans id="dataGrid.actionOptions.synchronize" message="Synchronize" />
            ) : (
              <Trans id="dataGrid.actionOptions.synchronizeProduct" message="Synchronize product stocks" />
            )}
          </WithArrow>
        ),
        disabled: isSyncRunning,
        position: 'top-right',
        style: 'primary',
        onClick: isProductSync ? handleSynchronizeProductsClick : handleSynchronizeProductStocksClick,
      },
    ],
    [handleSynchronizeProductsClick, handleSynchronizeProductStocksClick, isProductSync, isSyncRunning],
  )

  const productRetry = useSyncProductRetry()
  const onRowAction = useCallback(
    (id: string, field: string, value: ProductSynchronization): void => {
      if (field === 'sync_product') {
        productRetry({
          baseLinkerProductId: value.baseLinkerProductId,
          inventoryId: value.inventoryId,
        }).then(() => reloadData())
      }
    },
    [productRetry, reloadData],
  )

  // generate list of unique set of inventoryIds to passdown into Select
  const inventoriesList = Array.from(
    new Set((data as ProductSynchronization[]).map(({ inventoryId }) => inventoryId)),
    (inventoryId) => ({ inventoryId }),
  )

  return (
    <>
      <UnsyncedProductsList
        isProductSync={isProductSync}
        data={data}
        errorMessage={error}
        isLoading={gridIsLoading}
        extraControlButtons={extraControlButtons}
        gridActions={gridActions}
        gridSelectors={gridSelectors}
        onClose={onClose}
        onRowAction={onRowAction}
        totalRowsCount={allRowsCount}
        error={error}
      />
      <BaselinkerSyncDialogs
        eshopId={eshopId}
        eshopName={selectedEshop?.name}
        inventoriesList={inventoriesList}
        closeSyncProductStocksDialog={closeSyncProductStocksDialog}
        closeSyncProductsDialog={closeSyncProductsDialog}
        syncProductsDialogVisible={syncProductsDialogVisible}
        syncProductStocksDialogVisible={syncProductStocksDialogVisible}
      />
    </>
  )
}

export default Container
