import styled from '@xstyled/styled-components'
import React from 'react'

type GridProps = {
  children: React.ReactNode
  size?: number
  gutter?: number | string
  verticalGutter?: number
  reverse?: boolean
  justifyContent?: string
  verticalAlign?: string
  alignItems?: string
  debug?: boolean
  className?: string
}

const StyledGrid = styled.div`
  width: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props: GridProps): string => (props.reverse ? 'row-reverse' : 'row')};
  outline: ${(props: GridProps): string => (props.debug ? '1px dashed darkturquoise' : 'none')};
  justify-content: ${(props: GridProps): string | undefined => props.justifyContent};
  align-self: ${(props: GridProps): string | undefined => props.verticalAlign};
  align-items: ${(props: GridProps): string | undefined => props.alignItems};
  outline-offset: 5px;
`

export const Grid = (props: GridProps): JSX.Element => {
  return (
    <StyledGrid {...props}>
      {React.Children.map(props.children, (child) => {
        return React.cloneElement(child, {
          gridSize: props.size || 12 / React.Children.count(props.children),
          gutter: props.gutter || child.props.gutter,
          verticalGutter: props.verticalGutter || child.props.verticalGutter,
          debug: props.debug,
        })
      })}
    </StyledGrid>
  )
}

export default Grid
