import React, { ReactNode, FormEvent, useCallback } from 'react'
import { Trans } from '@lingui/react'
import ProductStocksOverview from './ProductStocksOverview'
import { ProductFormTypes } from '../types'
import Button from '@mailstep/design-system/ui/Elements/Button'
import { Ability } from '@casl/ability'
import { withAcl } from '@components/blocks/Acl'
import { ProductType } from '@typings/entities/Product'
import { formikCallback } from '@typings/Formik'
import styled, { x } from '@xstyled/styled-components'
import Tabs from '@mailstep/design-system/ui/Blocks/Tabs'
import { TabContent } from '@mailstep/design-system/ui/Blocks/Tabs/TabContent'
import { useTabs } from '@mailstep/design-system/ui/Blocks/Tabs/hooks/useTabs'
import { tabsDefinitions } from './utils/tabsDefinitions'
import { useCheckDeviceWidth } from '@components/layouts/BaseLayout/utils'

export const ButtonsContainer = styled(x.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3;
  & > :not(:last-child) {
    margin-bottom: 2;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: flex-end;
    & > :not(:last-child) {
      margin-right: 3;
    }
  }
`

export type Props = {
  children: ReactNode
  createView: ProductFormTypes['createView']
  productStocks: ProductFormTypes['productStocks']
  ability: Ability
  isSubmitting: boolean
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  setFieldValue: formikCallback
  setType: React.Dispatch<React.SetStateAction<ProductType>>
}

export const StocksTabWrap = ({
  createView,
  children,
  productStocks,
  ability,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  setType,
}: Props): JSX.Element => {
  const toggleType = useCallback(() => {
    setFieldValue('type', 'bundle')
    setType('bundle')
  }, [setType, setFieldValue])

  const { activeTab, onTabSwitch } = useTabs(tabsDefinitions())
  const { isMobile } = useCheckDeviceWidth()

  return (
    <>
      {!createView && <Tabs mb="24px" tabsDefinition={tabsDefinitions()} activeTab={activeTab} onTabSwitch={onTabSwitch} />}
      <TabContent activeTab={activeTab} value={0}>
        {children}
      </TabContent>
      <TabContent activeTab={activeTab} value={1}>
        <ProductStocksOverview productStocks={productStocks} />
      </TabContent>
      <ButtonsContainer>
        {ability.can('edit', 'Product') && (
          <Button variant="default" appearance="secondary" onClick={toggleType} type="button" fullWidth={isMobile}>
            <Trans id="form.buttonTransformToProductSet" message="Transform to set" />
          </Button>
        )}
        {ability.can('edit', 'Product') && (
          <Button type="submit" isLoading={isSubmitting} iconLeft="check" onClick={handleSubmit} fullWidth={isMobile}>
            <Trans id="form.edit.buttonSubmit" message="Submit" />
          </Button>
        )}
      </ButtonsContainer>
    </>
  )
}
export default withAcl(StocksTabWrap)
