import React from 'react'
import styled from '@xstyled/styled-components'

interface FieldsetProps {
  disabled?: boolean
  children: React.ReactNode
  fullWidth?: boolean
}

const StyledFieldset = styled.fieldset<{
  fullWidth?: boolean
}>`
  border: 0;
  padding: 0;
  margin: 0;
  ${({ fullWidth }): string => (fullWidth ? `width: 100%;` : '')};
  ${({ fullWidth }): string => (fullWidth ? `min-inline-size: auto;` : '')};
`

const Fieldset = ({ disabled, children, fullWidth }: FieldsetProps): JSX.Element => (
  <StyledFieldset disabled={disabled} fullWidth={fullWidth}>
    {children}
  </StyledFieldset>
)

export default Fieldset
