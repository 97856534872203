import gql from 'graphql-tag'

const transferCancelQuery = gql`
  query transferCancel($id: String!) {
    transferCancel(id: $id, input: {}) @rest(method: "PUT", path: "transfer/{args.id}/cancel") {
      id
      user
      organisation
      warehouseFrom
      warehouseTo
      wmsFrom
      wmsTo
      internalNumber
      note
      requiredTransferDate
      type
      status
      items
      countOfItems
      countOfSku
      sumOfQuantity
      createdAt
      changedAt
    }
  }
`

export default transferCancelQuery
