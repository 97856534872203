import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'

type WritableFormikRecord = Record<string, string | null | Record<string, string>>
type ReadableFormikRecord<T> = { [P in keyof T]: NonNullable<T[P]> }

export const formatEmptyValuesToRead = <T extends object>(object: T): ReadableFormikRecord<T> => {
  const formattedObject = {} as ReadableFormikRecord<T>
  Object.entries(object || {}).forEach(([key, value]) => {
    formattedObject[key as keyof T] = value ?? ''
  })
  return formattedObject
}

export const formatValuesToWrite = <K extends Extract<keyof WritableFormikRecord, string>>(
  object: Record<string, string>,
  properties: K[],
): Pick<WritableFormikRecord, K> => {
  const formattedObject = pick(cloneDeep(object), properties) as Pick<WritableFormikRecord, K>

  for (const [key, value] of Object.entries(formattedObject)) {
    if (value === '') {
      formattedObject[key as keyof typeof formattedObject] = null
    }
  }

  return formattedObject
}

export const formatAllValuesToWrite = (object: Record<string, string>): WritableFormikRecord => {
  return formatValuesToWrite(object, Object.keys(object)) as WritableFormikRecord
}
