import { useEffect, useState } from 'react'
import { ProductStockApi } from '@typings/entities/ProductStock'

export type GridItem<T> = T & {
  index: number
  reservationStatus: 'reserved' | 'partially' | 'notReserved'
}

type ReturnProps<T> = {
  allItems: GridItem<T>[]
  isLoading: boolean
}

const shouldReloadStocks = (
  productIds: string[],
  warehouse?: string,
  wms?: string,
  previousLoad?: { productIds: string[]; warehouseId: string; wmsId: string },
): boolean => {
  if (!warehouse || !wms || !productIds.length) return false
  if (!previousLoad || previousLoad.warehouseId !== warehouse || wms !== previousLoad.wmsId) return true
  return !!productIds.find((id) => !previousLoad.productIds.includes(id))
}

export const useItemsQuantity = <T>(
  items: T[],
  warehouseId: string | undefined,
  wmsId: string | undefined,
  setFieldValue: Function,
  loadProductStocks: (productIds: string[], warehouse: string, wms: string) => Promise<ProductStockApi[]>,
): ReturnProps<T> => {
  const [productStocks, setProductStocks] = useState<ProductStockApi[] | null>([])
  const [lastStockLoadProps, setStockLoadProps] = useState<object | undefined>()
  const isLoading = items.length > 0 && productStocks === null

  const productIds: string[] = (items || []).map((item) => item.product?.id).filter(Boolean)

  useEffect(() => {
    if (shouldReloadStocks(productIds, warehouseId, wmsId, lastStockLoadProps)) {
      setProductStocks(null)

      const fetchData = async (): Promise<void> => {
        const stocks = await loadProductStocks(productIds, warehouseId, wmsId)
        setStockLoadProps({ productIds, warehouseId, wmsId })
        setProductStocks(stocks)
      }
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, wmsId, productIds.length])

  let needItemsUpdate = false

  const allItems = (items || []).map((item, index) => {
    let availableStock
    let stockLots
    if (!warehouseId || !wmsId) {
      availableStock = '?'
    } else if (productStocks === null) {
      availableStock = null
    } else {
      const foundStock = productStocks.find((stock) => stock.product.id === item.product.id)
      availableStock = foundStock?.available || 0
      if (!item.product.workAroundLot) {
        stockLots = []
      } else {
        stockLots = foundStock?.lots || []
      }
    }
    let book = item.book
    if (book && item.quantity < book) {
      book = item.quantity
    }

    let stocksState = 'ready'
    if (isLoading) {
      stocksState = 'loading'
    } else if (!warehouseId || !wmsId) {
      stocksState = 'notReady'
    }

    if ((availableStock !== item.availableStock && availableStock !== null) || book !== item.book) {
      needItemsUpdate = true
    }

    return {
      ...item,
      index,
      availableStock,
      book,
      stockLots,
      stocksState,
      reservationStatus:
        item.booked === item.quantity ? 'reserved' : item.booked && item.booked >= 1 ? 'partially' : 'notReserved',
    }
  })

  if (needItemsUpdate) {
    setFieldValue('items', allItems, false)
  }

  return { allItems, isLoading }
}
