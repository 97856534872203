import React from 'react'
import ConnectedProductForm from '@containers/ConnectedProductForm'
import ConnectedTransferForm from '@containers/ConnectedTransferForm'
import ConnectedAdviceForm from '@containers/ConnectedAdviceForm'
import ConnectedExpeditionForm from '@containers/ConnectedExpeditionForm'
import ConnectedComplaintCreate from '@containers/ConnectedComplaintCreate'
import { Trans } from '@lingui/react'
import ExchangeRatesContainer from '@containers/ConnectedExchangeRates/ExchangeRatesContainer'
import { NewComplaintQuickAccessDialog, QuickAccessDialog, SynchronizationResultsDialog } from '@typings/UI'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'
import ConnectedSynchronizationResults from '@containers/ConnectedSynchronizationResults'

type Props = {
  openedDialog: QuickAccessDialog
  closeDialog: () => void
}

const QuickAccessDialogs = ({ openedDialog, closeDialog }: Props): JSX.Element => {
  return (
    <>
      {openedDialog?.dialog == 'product' && (
        <ConnectedProductForm
          id={openedDialog?.id}
          onSuccess={closeDialog}
          onCancel={closeDialog}
          createTitle={<Trans id="form.heading.createProduct" message="Create product" />}
          editTitle={<Trans id="form.heading.editProduct" message="Edit product" />}
        />
      )}
      {openedDialog?.dialog == 'transfer' && (
        <ConnectedTransferForm
          id={openedDialog?.id}
          onSuccess={closeDialog}
          onCloseForm={closeDialog}
          createTitle={<Trans id="form.transfer.headingNew" message="New transfer" />}
          editTitle={<Trans id="form.transfer.headingDetail" message="Transfer detail" />}
          titleIcon="transfer"
        />
      )}
      {openedDialog?.dialog == 'advice' && (
        <ConnectedAdviceForm
          id={openedDialog?.id}
          onSuccess={closeDialog}
          onCloseForm={closeDialog}
          createTitle={<Trans id="form.stockAdviceNew.heading" message="New advice" />}
          editTitle={<Trans id="form.stockAdviceDetail.heading" message="Advice details" />}
          titleIcon="inbound"
        />
      )}
      {openedDialog?.dialog == 'expedition' && (
        <ConnectedExpeditionForm
          id={openedDialog?.id}
          onSuccess={closeDialog}
          onCloseForm={closeDialog}
          createTitle={<Trans id="form.heading.expeditionNew" message="New expedition" />}
          editTitle={<Trans id="form.heading.expeditionDetail" message="Expedition details" />}
          titleIcon="info"
        />
      )}
      {openedDialog?.dialog == 'createComplaint' && (
        <ConnectedComplaintCreate
          expeditionId={(openedDialog as any as NewComplaintQuickAccessDialog).extra.expeditionId}
          organisationId={(openedDialog as any as NewComplaintQuickAccessDialog).extra.organisationId}
          onSuccess={closeDialog}
          onCloseForm={closeDialog}
        />
      )}
      {openedDialog?.dialog == 'exchangeRates' && (
        <Modal
          width="medium"
          title={<Trans id="form.heading.exchangeRates" message="Exchange rates" />}
          cancelLabel={<Trans id="form.buttonCancel" message="Cancel" />}
          confirmLabel={<Trans id="form.buttonConfirm" message="Confirm" />}
          onCancel={closeDialog}
          onConfirm={closeDialog}
          isLoading={false}
          hasFooter={false}
        >
          <ExchangeRatesContainer />
        </Modal>
      )}
      {openedDialog?.dialog == 'synchronizationResults' && (
        <ConnectedSynchronizationResults
          eshopId={(openedDialog as unknown as SynchronizationResultsDialog).extra.eshopId}
          syncDetailToken={(openedDialog as unknown as SynchronizationResultsDialog).extra.syncDetailToken}
          type={(openedDialog as unknown as SynchronizationResultsDialog).extra.type}
          onClose={closeDialog}
        />
      )}
    </>
  )
}

export default QuickAccessDialogs
