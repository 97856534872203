import { Option } from '@mailstep/design-system/ui/Elements/Select/types'
import { EnumObject } from '@typings/generic'

const getEnumOptions = (enumObject: EnumObject, optionsFilter?: (key: string) => boolean): Option[] => {
  return Object.entries(enumObject)
    .filter(([k, v]) => (optionsFilter ? optionsFilter(k) : true))
    .map(([k, v]) => ({ value: k, label: v?.() }))
}

export default getEnumOptions
