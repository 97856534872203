import React, { memo, useCallback } from 'react'
import { selectors as organisationsSelectors } from '@store/organisations'
import { selectors as uiSelectors } from '@store/ui'
import { actions as organisationActions, selectors as organisationSelectors } from '@store/organisation'
import Heading, { Props as HeadingProps } from '@components/blocks/Heading'
import { useDispatch, useSelector } from 'react-redux'
import useLanguageSwitch from '@hooks/useLanguageSwitch'
import getRuntimeConfig from '@utils/getRuntimeConfig'
import routes from '@pages/index'
import { useLocation } from 'react-router'

type Props = Pick<HeadingProps, 'loggedAccount' | 'loginLink' | 'homeLink'> &
  Partial<Pick<HeadingProps, 'warningItems' | 'userMenuItems' | 'helpSectionItems' | 'setIsLeftMenuOpen'>>

const Container = (props: Props): JSX.Element => {
  const dispatch = useDispatch()

  const activeOrganisation = useSelector(organisationSelectors.organisation)
  // TODO organisations are special case of loading data, needs to be reworked
  const organisations = useSelector((state) => organisationsSelectors.organisations(state)?.organisationsData)
  const onlyActiveOrganisations = organisations?.filter((organisation: any) => organisation.active)

  const onChangeOrganisation = useCallback(
    (organisation: string): void => {
      dispatch(organisationActions.setOrganisation(organisation))
    },
    [dispatch],
  )

  const notificationCount = useSelector(uiSelectors.unseenNotifications) || 0
  const warningsCount = useSelector(uiSelectors.warningsCount) || 0
  const location = useLocation()
  const isOnSettingsPage = location.pathname.includes('settings')

  return (
    <Heading
      organisations={onlyActiveOrganisations}
      activeOrganisation={activeOrganisation}
      onChangeOrganisation={onChangeOrganisation}
      {...useLanguageSwitch()}
      appVersion={getRuntimeConfig('TAG_VERSION')}
      {...props}
      notificationsLink={routes.sharedNotifications.route.toUrl()}
      notificationCount={notificationCount}
      warningsCount={warningsCount}
      isOnSettingsPage={isOnSettingsPage}
    />
  )
}

export default memo(Container)
