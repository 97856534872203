export const THREE_DECIMAL_CURRENCIES = ['BHD', 'TND', 'KWD']
export const WHOLE_NUMBER_CURRENCIES = [
  'AFN',
  'CLP',
  'COP',
  'CZK',
  'HUF',
  'IDR',
  'ISK',
  'JPY',
  'KRW',
  'NOK',
  'PYG',
  'RSD',
  'SEK',
  'TWD',
  'XPF',
  'ZWD',
]
