import reduceReducers from 'reduce-reducers'
import produce from 'immer'
import getOr from 'lodash/fp/getOr'
import createFetchModule from '@utils/store/createFetchModule'
import { FetchActionsWith } from '@typings/generic'

export const moduleName = 'partnersGrid'

const fetchModule = createFetchModule('@store/PartnersGrid', 'LOAD', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  setSelectedEshop: `@store/PartnersGrid/SET_ESHOP`,
}

const ownReducer = (state: State, action: Action): State => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.setSelectedEshop:
        draft.eshop = action?.payload
        break
    }
  })
}

export default reduceReducers(fetchModule.reducer, ownReducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
  setSelectedEshop: (eshop: string) => ({ type: actionTypes.setSelectedEshop, payload: eshop }),
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
}
