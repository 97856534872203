import React from 'react'
import { DataCellProps } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { actions as uiActions } from '@store/ui'
import InternalSkuLink from '@components/elements/gridCells/InternalSkuLink'
import { useDispatch } from 'react-redux'

type Data = {
  product: {
    id: string
    internalSku: string
  }
}

type Props = DataCellProps<Data>

const ConnectedInternalSkuCell = ({ onRowAction, ...props }: Props): JSX.Element | null => {
  const dispatch = useDispatch()
  const handleOnRowAction = React.useCallback(
    (id: string, field: string, value: any) => {
      if (field == 'edit_product') {
        dispatch(uiActions.openQuickAccessDialog('product', value?.product?.id))
      } else {
        if (onRowAction) onRowAction(id, field, value)
      }
    },
    [dispatch, onRowAction],
  )

  return <InternalSkuLink onRowAction={handleOnRowAction} {...props} />
}

export default ConnectedInternalSkuCell
