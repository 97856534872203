import React, { ChangeEvent, useEffect } from 'react'
import styled, { x } from '@xstyled/styled-components'
import InputField from '@components/forms/components/InputField'
import { FieldLabel } from '@mailstep/design-system/ui/Elements/Label'
import SpaceAroundWrap, { SpaceAround } from '@mailstep/design-system/ui/Elements/SpaceAround'
import SelectField from '@components/forms/components/SelectField'
import { DialCodes } from '@components/forms/assets/dialCodes'
import ErrorMessage from '@mailstep/design-system/ui/Elements/ErrorMessage'
import { PhoneNumber } from '@components/forms/utils/RegexValidation'
import { th } from '@xstyled/system'

type Props = {
  name?: string
  onChange?: Function
  onBlur?: Function
  label: string
  value?: string
  error?: string | boolean
  placeholder?: string
  spaceAround?: SpaceAround
  className?: string
  disabled?: boolean
  isPrefixOptional?: boolean
}

const Wrapper = styled(x.div)`
  display: flex;
  align-items: flex-start;
  border-width: 1px;
  border-style: solid;
  border-color: ${th.color('lightGray3')};
  background-color: white;
  border-radius: 8px;

  &:focus-within {
    border-color: ${th.color('blue2')};
  }

  & > :first-child {
    max-width: 76px;
    flex-grow: 1;

    & div {
      border: none;

    }
  }

  & > :last-child {
    flex-grow: 3;

    & input {
      border: none;
      padding-left: 10px;

      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}
`

const deformatPhone = (phone?: string): string[] => {
  const split = phone ? phone.split(' ') : ['+420', '']
  return split.length == 2 ? split : ['', split[0]]
}

const formatPhone = (dial: string, number: string): string => (dial ? `${dial} ${number}` : number)

const PhoneInputField = ({
  name,
  label,
  onChange,
  onBlur,
  value,
  error,
  placeholder,
  spaceAround = false,
  className,
  disabled = false,
  isPrefixOptional,
}: Props): JSX.Element => {
  const idx = { dial: 0, number: 1 }
  const phoneArray = deformatPhone(value)

  const [dial, setDial] = React.useState(phoneArray[idx.dial])
  const [number, setNumber] = React.useState(phoneArray[idx.number])

  useEffect(() => {
    setDial(phoneArray[idx.dial])
    setNumber(phoneArray[idx.number])
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = React.useCallback(
    (dial, number): void => {
      if (onChange) onChange(formatPhone(dial, number))
    },
    [onChange],
  )

  const handleBlur = React.useCallback((): void => {
    if (onBlur) onBlur(formatPhone(dial, number))
  }, [dial, number, onBlur])

  const onDialChange = React.useCallback(
    (event: ChangeEvent<HTMLSelectElement>): void => {
      const newDial = `${event.target.value || ''}`
      setDial(newDial)
      handleChange(newDial, number)
    },
    [handleChange, number],
  )

  const onNumberChange = React.useCallback(
    (event: ChangeEvent<HTMLSelectElement>): void => {
      const newNumber = `${event.target.value}`
      setNumber(newNumber)
      handleChange(dial, newNumber)
    },
    [handleChange, dial],
  )

  return (
    <SpaceAroundWrap spaceAround={spaceAround} className={className}>
      {label && <FieldLabel htmlFor={name}>{label}</FieldLabel>}
      <Wrapper id={name}>
        <SelectField
          options={DialCodes}
          value={dial}
          onChange={onDialChange}
          onBlur={handleBlur}
          isInvalid={!!error && !dial && !isPrefixOptional}
          disabled={disabled}
        />
        <InputField
          name={name}
          value={number}
          onChange={onNumberChange}
          onBlur={handleBlur}
          type="tel"
          isInvalid={error && (!number || !PhoneNumber.test(number))}
          placeholder={placeholder}
          disabled={disabled}
        />
      </Wrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </SpaceAroundWrap>
  )
}

export default PhoneInputField
