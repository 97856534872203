import { selectors } from '@store/baselinkerBatch'
import { useSelector } from 'react-redux'

const useIsSyncRunning = (): [boolean, boolean] => {
  const baseLinkerProductSyncStatus = useSelector(selectors.baseLinkerProductSyncStatus)
  const baseLinkerProductStockSyncStatus = useSelector(selectors.baseLinkerProductStockSyncStatus)
  const baseLinkerPreparingProductSyncStatus = useSelector(selectors.baseLinkerPreparingProductSyncStatus)
  const baseLinkerPreparingProductStockSyncStatus = useSelector(selectors.baseLinkerPreparingProductStockSyncStatus)
  return [
    baseLinkerProductSyncStatus || baseLinkerPreparingProductSyncStatus,
    baseLinkerProductStockSyncStatus || baseLinkerPreparingProductStockSyncStatus,
  ]
}

export default useIsSyncRunning
