import gql from 'graphql-tag'

const transferGetQuery = gql`
  query transferGet($id: String!) {
    transferGet(id: $id) @rest(type: "TransferResult", method: "GET", path: "transfer/{args.id}") {
      id
      user
      organisation
      warehouseFrom
      warehouseTo
      wmsFrom
      wmsTo
      internalNumber
      note
      requiredTransferDate
      type
      status
      items
      countOfItems
      countOfSku
      sumOfQuantity
      createdAt
      changedAt
    }
  }
`

export default transferGetQuery
