import React, { useCallback } from 'react'
import styled, { x } from '@xstyled/styled-components'
import { Field, FieldArray, Form, FormikProps, FormikValues } from 'formik'
import SelectField from '@components/forms/components/SelectField'
import { Trans } from '@lingui/react'
import TextAreaField from '@components/forms/components/TextAreaField'
import AttachmentField from '@components/forms/components/AttachmentField'
import { ComplaintReasonTrans } from '@typings/enums/Complaint'
import { Option } from '@mailstep/design-system/ui/Elements/Select/types'
import InputField from '@components/forms/components/InputField'
import getEnumOptions from '@utils/mapEnumToOptions'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'
import { Col, Grid } from '@designSystem/Grid'

export type ExternalFormProps = {
  title: string | JSX.Element
  reporters?: Option[]
  onCancel: () => void
}
type Props = ExternalFormProps & FormikProps<FormikValues>

const TextareaWrap = styled.div`
  margin: 0 12px;

  & textarea {
    resize: none;
  ]
`

const createReasonOptions = (): Option[] => getEnumOptions(ComplaintReasonTrans)

const emptyReporters: Option[] = []

const ComplaintCreateForm = ({
  values,
  handleSubmit,
  isSubmitting,
  reporters = emptyReporters,
  title,
  onCancel,
  submitForm,
}: Props): JSX.Element => {
  const AttachmentFieldRenderer = useCallback((props) => <AttachmentField {...props} />, [])

  return (
    <Modal
      width="medium"
      onConfirm={submitForm}
      titleIcon="plus"
      title={title}
      cancelLabel={<Trans id="form.buttonCancel" message="Cancel" />}
      confirmLabel={<Trans id="form.buttonConfirm" message="Confirm" />}
      onCancel={onCancel}
      isLoading={isSubmitting}
    >
      <Form onSubmit={handleSubmit}>
        <Grid gutter="12px" alignItems="center">
          <Col size={6}>
            <Field
              component={InputField}
              name="internalId"
              label={<Trans id="form.internalId.label" message="Internal ID" />}
              options={reporters}
              spaceAround
            />
          </Col>
          <Col size={6}>
            <Field
              component={SelectField}
              name="reporter"
              label={<Trans id="form.reporter.label" message="Reporter" />}
              options={reporters}
              spaceAround
            />
          </Col>
        </Grid>

        <Grid gutter="12px" alignItems="center">
          <Col size={6}>
            <Field
              component={SelectField}
              name="reason"
              label={<Trans id="form.reason.label" message="Reason" />}
              options={createReasonOptions()}
              spaceAround
            />
          </Col>
          <Col size={6}>
            <Field
              component={InputField}
              name="value"
              label={<Trans id="form.claimValue.label" message="Claim value" />}
              spaceAround
              suffix={values?.currency}
            />
          </Col>
        </Grid>

        <TextareaWrap>
          <Field
            component={TextAreaField}
            label={<Trans id="form.description.label" message="Description" />}
            name="description"
            spaceAround
          />
        </TextareaWrap>

        <x.div px="12px" mt={4}>
          <FieldArray name="attachments" render={AttachmentFieldRenderer} />
        </x.div>
      </Form>
    </Modal>
  )
}

export default ComplaintCreateForm
