import React from 'react'
import { DataCellProps } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { LinkInCell } from '@mailstep/design-system/ui/Blocks/CommonGrid'

type Data = {
  product: {
    id: string
    internalSku: string
  }
}

type Props = DataCellProps<Data>

const InternalSkuLink = ({ rowData, onRowAction }: Props): JSX.Element | null => {
  const handleOnClick = React.useCallback(() => {
    if (onRowAction) onRowAction(rowData.product?.id, 'edit_product', rowData)
  }, [onRowAction, rowData])

  if (!rowData.product) return null
  return (
    <LinkInCell variant="medium" onClick={handleOnClick} as="span">
      {rowData.product.internalSku}
    </LinkInCell>
  )
}

export default InternalSkuLink
