import gql from 'graphql-tag'

const exchangeRatesGetQuery = gql`
  query exchangeRatesGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    exchangeRatesGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting })
      @rest(type: "exchangeRatesResult", method: "POST", path: "exchange/list") {
      results
      paging
    }
  }
`

export default exchangeRatesGetQuery
