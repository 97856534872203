import React, { createContext, useCallback, useMemo, useState } from 'react'

type LeftMenuContextType = {
  expandedItem: string | null
  expandItem: (item: string | null) => void
}

export const LeftMenuContext = createContext<LeftMenuContextType | null>(null)

type LeftMenuProviderProps = {
  children: React.ReactNode
  defaultItemSelected?: string | null
}

export const LeftMenuProvider = ({ children, defaultItemSelected = null }: LeftMenuProviderProps) => {
  const [expandedItem, setExpandedItem] = useState(defaultItemSelected)

  const expandItem = useCallback((item: string | null) => {
    setExpandedItem(item)
  }, [])

  const value = useMemo(
    () => ({
      expandedItem,
      expandItem,
    }),
    [expandedItem, expandItem],
  )
  return <LeftMenuContext.Provider value={value}>{children}</LeftMenuContext.Provider>
}
