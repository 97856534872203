import React from 'react'
import { ColumnDefinition, DataCellProps, RowValueType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { Trans } from '@lingui/react'
import { Input } from '@mailstep/design-system/ui/Forms/Input'
import { StockAdviceItemApi } from '@typings/entities/StockAdvice'
import { PairingStatuses, StatusTrans } from '@typings/enums/StockAdvice'
import { AdviceStatusCell } from '@components/elements/gridCells'
import { DateCell, IconButtonInCell } from '@mailstep/design-system/ui/Blocks/CommonGrid'

export type GridItem = StockAdviceItemApi & {
  toBook: number
  awaitingQuantity: number
  bookedByOtherExpeditions: number
  availableTotal: number
  availableStock: number
}

const PairComponent = ({ rowData, onRowAction }: DataCellProps<GridItem>): JSX.Element | null => {
  const onToBookChange = React.useCallback(
    (event) => {
      const fieldValue = event.target.value
      const number = parseInt(fieldValue)
      const isValid = number >= 0 && number <= rowData.availableTotal
      if (onRowAction && isValid) onRowAction(fieldValue, 'change', rowData)
    },
    [rowData, onRowAction],
  )
  return <Input type="number" value={rowData.toBook} onChange={onToBookChange} />
}

export const createColumnDefinitions = (onOpenAdvice: (id: string) => void): ColumnDefinition[] => {
  return [
    {
      name: 'stockAdvice.internalId',
      title: <Trans id="dataGrid.columnInternalId.title" message="Internal number" />,
      filtering: true,
      sorting: true,
      flexBasis: 180,
    },
    {
      name: 'stockAdvice.status',
      title: <Trans id="dataGrid.columnStatus.title" message="Status" />,
      filtering: true,
      filterExtraProps: { isMulti: true },
      filterOptions: Object.entries(StatusTrans)
        .filter(([k, v]) => PairingStatuses.includes(k))
        .map(([k, v]) => ({ value: k, label: v })),
      cellComponent: AdviceStatusCell,
      flexBasis: 180,
    },
    {
      name: 'stockAdvice.createdAt',
      title: <Trans id="dataGrid.columnCreatedAt.title" message="Created" />,
      filteringType: 'date',
      flexBasis: 140,
      filtering: true,
      sorting: true,
      cellComponent: DateCell,
    },
    {
      name: 'stockAdvice.expectedAt',
      title: <Trans id="dataGrid.columnExpectedAt.title" message="Expected" />,
      filteringType: 'date',
      filtering: true,
      sorting: true,
      flexBasis: 140,
      cellComponent: DateCell,
    },
    {
      name: 'awaitingQuantity',
      title: <Trans id="dataGrid.columnAwaitingQty.title" message="Awaiting quantity" />,
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 100,
      flexShrink: 0,
      flexGrow: 0,
    },
    {
      name: 'bookedByOtherExpeditions',
      title: <Trans id="dataGrid.columnPairedElsewhere.title" message="Paired Elsewhere" />,
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 100,
      flexShrink: 0,
      flexGrow: 0,
    },
    {
      name: 'availableTotal',
      title: <Trans id="dataGrid.columnAvailableTotal.title" message="Available total" />,
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 100,
      flexShrink: 0,
      flexGrow: 0,
    },
    {
      name: 'availableStock',
      title: <Trans id="dataGrid.columnAvailableQty.title" message="Available QTY" />,
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 100,
      flexShrink: 0,
      flexGrow: 0,
    },
    {
      name: 'pair',
      title: <Trans id="dataGrid.columnPair.title" message="Pair" />,
      cellComponent: PairComponent,
      fixedSize: true,
      flexBasis: 110,
      flexShrink: 0,
      flexGrow: 0,
    },
    {
      name: 'link',
      flexBasis: 70,
      fixedSize: true,
      // eslint-disable-next-line react/display-name
      formatRowValue: (rowData: GridItem): RowValueType => {
        return (
          // todo why is this not a clickable document number instead ??
          // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
          <IconButtonInCell onClick={() => onOpenAdvice(rowData.stockAdvice.id)} icon="findDetail" />
        )
      },
    },
  ]
}

export default createColumnDefinitions
