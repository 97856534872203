import React, { useCallback } from 'react'
import Button from '@mailstep/design-system/ui/Elements/Button'
import { x } from '@xstyled/styled-components'
import { Trans } from '@lingui/react'
import { CornerDialog } from '../CornerDialog'

export type ConfirmDialogProps = {
  onClose: () => void
  onConfirm: (value: true) => void
  onConfirmButtonText?: string | React.ReactNode
  onCloseButtonText?: string | React.ReactNode
  title: JSX.Element
  text?: string | React.ReactNode
}

const ConfirmDialog = ({
  onClose,
  onConfirm,
  onConfirmButtonText,
  onCloseButtonText,
  title,
  text,
}: ConfirmDialogProps): JSX.Element => {
  const handleOnClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleonConfirm = useCallback(() => {
    onConfirm(true)
    onClose()
  }, [onConfirm, onClose])

  return (
    <CornerDialog title={title} icon="warning" onClose={onClose}>
      <>
        {text && <x.div mb={4}>{text}</x.div>}
        <x.div display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={handleonConfirm} type="button">
            {onConfirmButtonText || <Trans id="form.buttonConfirm" message="Confirm" />}
          </Button>
          <Button onClick={handleOnClose} variant="default" appearance="secondary" type="button">
            {onCloseButtonText || <Trans id="form.buttonCancel" message="Cancel" />}
          </Button>
        </x.div>
      </>
    </CornerDialog>
  )
}

export default ConfirmDialog
