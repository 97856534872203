import { ApiHookResult } from '@typings/ApiHooks'
import { InboundReceipt } from '@typings/entities/InboundReceipt'
import { useQuery } from '@apollo/client'
import inboundReceiptsGetQuery from '@queries/inboundReceiptsGetQuery'

export const useLoadInboundReceiptsList = (id?: string): ApiHookResult<InboundReceipt[]> => {
  const {
    data,
    error,
    loading: isLoading,
  } = useQuery(inboundReceiptsGetQuery, {
    variables: { criteria: { stockAdvice: { eq: id } } },
    skip: !id,
  })

  return {
    data: data?.inboundReceiptsGet?.results,
    error,
    isLoading,
  }
}
