import { ApiHookResult } from '@typings/ApiHooks'
import { useQuery } from '@apollo/client'
import expeditionServiceGetQuery from '@queries/expeditionServiceGetQuery'
import { ExpeditionServices } from '@typings/entities/Expedition'

export const useLoadExpeditionServicesList = (organisation?: string): ApiHookResult<ExpeditionServices[]> => {
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(expeditionServiceGetQuery, {
    variables: { criteria: { organisations: organisation, active: true } },
  })
  return {
    data: data?.expeditionServicesGet?.results,
    error,
    isLoading,
  }
}
