import produce from 'immer'
import { Types as CGT } from '@mailstep/design-system/ui/Blocks/CommonGrid'

type State = CGT.GridState

type MultiState = { [key: string]: State }

const actionPrefix = '@store/CommonGrid'

export const actionTypes = {
  clearAllFilers: `${actionPrefix}/Ext/ClearAllFilers`,
}

export default (state: MultiState = {}, action: Action): MultiState => {
  return produce(state, (draft) => {
    if (action.type === actionTypes.clearAllFilers) {
      for (const gridName of Object.keys(draft)) {
        draft[gridName].filter = {}
      }
    }
  })
}

export const actions = {
  clearAllFilers: (): Action => ({ type: actionTypes.clearAllFilers }),
}
