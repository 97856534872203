import axios from 'axios'
import { SyncOrderApi } from './syncOrder'
import { SyncProductApi } from './syncProduct'
import { BatchApi } from './batch'

type ApiReturnType = {
  order: ReturnType<typeof SyncOrderApi>
  product: ReturnType<typeof SyncProductApi>
  batch: ReturnType<typeof BatchApi>
}

export const BaselinkerApi = (token: string): ApiReturnType => {
  const instance = axios.create({
    headers: {
      'X-token': token,
    },
  })
  return {
    order: SyncOrderApi(instance),
    product: SyncProductApi(instance),
    batch: BatchApi(instance),
  }
}
