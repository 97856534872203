import React from 'react'
import SocialLinks from '@designSystem/LoggedOutWrap/SocialLinks'
import { LoggedOutWrapper } from '@designSystem/LoggedOutWrap/styles'

type Props = {
  children: React.ReactNode
}

export const LoggedOutWrap = ({ children }: Props): JSX.Element => {
  return (
    <LoggedOutWrapper>
      {children}
      <SocialLinks />
    </LoggedOutWrapper>
  )
}
