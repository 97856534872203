import gql from 'graphql-tag'

const reportExpeditionGetQuery = gql`
  query reportExpeditionGet($criteria: Object, $select: Object) {
    reportExpeditionGet(input: { select: $select, criteria: $criteria, sort: $sort })
      @rest(type: "ReportExpeditionResult", method: "POST", path: "report/expedition") {
      results
    }
  }
`

export default reportExpeditionGetQuery
