import { useCallback, useMemo } from 'react'
import { GridSelectorsType, GridActionsType, RowProps } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { filterSortRowsBySettings, getItemPage } from './utils'
import get from 'lodash/fp/get'

// TODO improve
// TODO replace getMergeId adn itemComparator outside if onAdd with indexes !!
// TODO fix the indexing hack

type IndexMap = { [mergeId: string]: number }

type GridHooksIn<T> = {
  gridSelectors: GridSelectorsType
  gridActions: GridActionsType
  allItems: T[]
  setItems: (items: T[]) => void
  duplicateItemMerge: (oldItem: T, newItem: T) => T
  itemIdentifier?: string | string[]
  customMergeId?: (item: T) => string
}

type GridHooksOut<T> = {
  onAdd: (items: T | T[]) => void
  onRemove: (items: T[]) => void
  getIndex: (item: T) => number
}

const getMergeId = (itemIdentifier: string | string[], item: object): string => {
  if (typeof itemIdentifier == 'string') return get(itemIdentifier, item)
  else return itemIdentifier.map((subIdentifier) => get(subIdentifier, item)).join('|')
}

export const useFormGridChange = <T extends RowProps>(props: GridHooksIn<T>): GridHooksOut<T> => {
  const { gridSelectors, gridActions, allItems = [], setItems, itemIdentifier = 'id', duplicateItemMerge, customMergeId } = props
  const itemIdFn = useCallback(
    (item: T): string => (customMergeId ? customMergeId(item) : getMergeId(itemIdentifier, item)),
    [customMergeId, itemIdentifier],
  )
  const itemComparator = useCallback((itemA: T, itemB: T): boolean => itemIdFn(itemA) === itemIdFn(itemB), [itemIdFn])

  const onAdd = useCallback(
    (newItems: T | T[]) => {
      let updatedItems = allItems
      const newItemsArray = Array.isArray(newItems) ? newItems : [newItems]
      newItemsArray.forEach((newItem) => {
        const index = updatedItems.findIndex((item) => itemComparator(item, newItem))
        if (index >= 0 && duplicateItemMerge) {
          updatedItems[index] = duplicateItemMerge(updatedItems[index], newItem)
        } else {
          updatedItems = [...updatedItems, newItem]
        }
      })
      setItems(updatedItems)

      if (!Array.isArray(newItems)) {
        // if the new item falls within current filters, change page so it is visible
        const page = getItemPage(updatedItems, gridSelectors, (item) => itemComparator(item, newItems))
        if (page !== null && gridActions.setPage) {
          gridActions.setPage(page)
        }
      }
    },
    [allItems, setItems, duplicateItemMerge, itemComparator, gridSelectors, gridActions],
  )

  const onRemove = useCallback(
    (removedItems: T[]) => {
      const updatedItems = allItems.filter((item) => {
        return !removedItems.find((removedItem) => itemComparator(removedItem, item))
      })
      setItems(updatedItems)

      // if I delete all items on last page, I need to go one page back
      const rowsPerPage = gridSelectors.rowsPerPage || 10
      const page = gridSelectors.page || 1
      const maxPage = !updatedItems.length ? 1 : Math.ceil(updatedItems.length / rowsPerPage)
      if (page > maxPage) {
        if (gridActions.setPage) gridActions.setPage(maxPage)
      }
    },
    [allItems, setItems, gridSelectors.rowsPerPage, gridSelectors.page, itemComparator, gridActions],
  )

  // cache of indexes because getIndex() can be called a lot
  const lotList = allItems.map((item) => item?.lot || '').join('|') // HACK for expeditions - todo fix
  const indexMap = useMemo<IndexMap>(() => {
    const map: IndexMap = {}
    allItems.forEach((item, index) => {
      map[itemIdFn(item)] = index
    })
    return map
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemIdentifier, allItems.length, lotList])
  const getIndex = useCallback((needleItem: T): number => indexMap[itemIdFn(needleItem)], [indexMap, itemIdFn])

  return { onAdd, onRemove, getIndex }
}
