interface SomeObject {
  [key: string]: string | number | boolean
}

/**
 * Safely transform any object to string with URL query format
 * encoded key and value
 */
const objectToURLParams = (object?: SomeObject): string => {
  if (object) {
    return Object.keys(object)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`)
      .join('&')
  } else {
    return ''
  }
}

export default objectToURLParams
