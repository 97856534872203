/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, FormikHelpers, FormikValues } from 'formik'
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Col } from '@designSystem/Grid'
import Fieldset from '@designSystem/Fieldset'
import styled, { x } from '@xstyled/styled-components'
import Main from './components/Main'
import AdviceItems from './components/AdviceItems'
import Header from './components/Header'
import { Option as SelectOption } from '@mailstep/design-system/ui/Elements/Select/types'
import { formikCallback, FormikHandleSubmit } from '@typings/Formik'
import { EditableStatuses, PairingStatuses } from '@typings/enums/StockAdvice'
import { InboundReceipt } from '@typings/entities/InboundReceipt'
import { GridActionsType, GridSelectorsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { useAcl } from '@components/blocks/Acl'
import { ExpeditionItemNested } from '@typings/entities/Expedition'
import ConfirmDialog from '@components/blocks/Dialogs/ConfirmDialog'
import { useModal } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useModal'
import { Trans } from '@lingui/react'
import { ProductApiRead } from '@typings/entities/Product'
import { FileSubmit, OnDownloadTemplate } from '@typings/file'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'
import AdviceActions from './components/AdviceActions'
import { WarehouseNested } from '@typings/entities/Warehouse'
import toSelectOption from '@utils/toSelectOption'
import useEffectChange from '@hooks/useEffectChange'
import { displayToast } from '@utils/toast'
import { warningMessage } from '@utils/containers/advancedFormHelpers'

const ItemsWrap = styled.div`
  border-top: slim;
  border-bottom: slim;
  border-color: lightGray3;
  padding: 4 0 4 0;
  margin: 4 0 4 0;
  @media print {
    border: none;
    padding: 0;
    margin: 0;
    max-width: 100vw;
    height: auto;
  }
`

export interface Props {
  onBack: () => void
  onOpen: FormikHandleSubmit
  onClose: FormikHandleSubmit
  onDelete: FormikHandleSubmit
  loadProducts: (fulltext: string) => Promise<any>
  searchSuppliers: (fulltext: string, byId: boolean) => Promise<any>
  inboundReceipts?: InboundReceipt[]
  warehouses?: WarehouseNested[]
  adviceId?: string
  stockAdviceItemsIsLoading?: boolean
  itemsGridSelectors: GridSelectorsType
  itemsGridActions: GridActionsType
  pairingGridSelectors: GridSelectorsType
  pairingGridActions: GridActionsType
  loadExpeditionItems: (productId: string, warehouse: string, wms: string) => void
  expeditionItems: ExpeditionItemNested[]
  expeditionItemsIsLoading: boolean
  onOpenExpedition: (id: string) => void
  onImportItems: FileSubmit<{ product: ProductApiRead; quantity: number }[]>
  onDownloadImportTemplate: OnDownloadTemplate
  title: string | JSX.Element
  titleIcon: string
}

interface PropsWithFormik extends Props {
  /**
   * all buttons with validation use this handler
   */
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  setFieldValue: formikCallback
  values: FormikValues
  errors: { [key: string]: any }
  touched: { [key: string]: any }
  isSubmitting: boolean
  setSubmitting: FormikHelpers<FormikValues>['setSubmitting']
  isEditable: boolean
  isPairingEditable: boolean
}

/**
 * Create options for field wms (subset of warehouses)
 */
const createWmsOptions = (warehouseValue: string | undefined, warehouses?: WarehouseNested[]): SelectOption[] => {
  const selectedWarehouse = warehouseValue && (warehouses || []).find(({ id }) => id === warehouseValue)
  if (!selectedWarehouse) {
    return []
  }

  if (selectedWarehouse.dmg) {
    displayToast({ type: 'warning', text: warningMessage() })
  }

  return selectedWarehouse.wmses.map(toSelectOption('id', 'name'))
}

const StockAdviceEditForm = ({
  onBack,
  onOpen,
  onDelete,
  onClose,
  loadProducts,
  searchSuppliers,
  handleSubmit,
  inboundReceipts,
  warehouses,
  adviceId,
  values,
  errors,
  touched,
  stockAdviceItemsIsLoading,
  isSubmitting,
  itemsGridSelectors,
  itemsGridActions,
  setFieldValue,
  setSubmitting,
  pairingGridSelectors,
  pairingGridActions,
  loadExpeditionItems,
  expeditionItems,
  expeditionItemsIsLoading,
  onOpenExpedition,
  initialValues,
  onImportItems,
  onDownloadImportTemplate,
  title,
  titleIcon,
  isEditable,
  isPairingEditable,
}: PropsWithFormik): JSX.Element => {
  const warehouseOptions = useMemo(() => (warehouses || []).map(toSelectOption('id', 'name')), [warehouses])
  const wmsOptions = useMemo(() => createWmsOptions(values.warehouse, warehouses), [values.warehouse, warehouses])
  useEffectChange(() => {
    const selectedWarehouse = (warehouses || []).find(({ id }) => id === values.warehouse)
    if (!selectedWarehouse || !selectedWarehouse?.wmses.find((wms) => wms.id === values.wms)) {
      // previously selected wms not found in newly selected warehouse
      const resetWms = selectedWarehouse?.defaultWms?.id || ''
      setFieldValue('wms', resetWms, false)
    }
  }, [setFieldValue, values.warehouse])

  const withReceipts = inboundReceipts && inboundReceipts.length > 0

  const handleLoadExpeditionItems = useCallback(
    (productId: string) => {
      if (!productId || !values?.warehouse || !values?.wms) return
      loadExpeditionItems(productId, values?.warehouse, values?.wms)
    },
    [loadExpeditionItems, values.warehouse, values.wms],
  )

  //confirm dialog for >= 6 pallets
  const [isLargeConfirmed, setLargeConfirmed] = useState(false)
  const { onOpen: setConfirmDialog, isOpen: confirmVisible, onClose: closeConfirm } = useModal()
  useEffect(() => {
    // do not trigger on read :]
    if (!initialValues || initialValues.countOfUnits < 6 || initialValues.packagingUnit !== 'pallet')
      if (!isLargeConfirmed && values.countOfUnits >= 6 && values.packagingUnit == 'pallet') {
        setConfirmDialog()
      }
  }, [values.packagingUnit, values.countOfUnits, isLargeConfirmed, setConfirmDialog, initialValues])

  return (
    <Modal
      hasFooter={false}
      title={title}
      cancelLabel={<Trans id="form.buttonCancel" message="Cancel" />}
      titleIcon={titleIcon}
      width="wide"
      isLoading={isSubmitting}
      onCancel={onBack}
    >
      <Form onSubmit={handleSubmit}>
        <Fieldset disabled={!isEditable}>
          <Header values={values} status={values?.status} adviceId={adviceId} isEditable={isEditable} />

          <x.div display="flex" mb={4}>
            <Col size={withReceipts ? 10 : 12}>
              <Main
                searchSuppliers={searchSuppliers}
                warehouseOptions={warehouseOptions}
                wmsOptions={wmsOptions}
                hasWarehouse={!!values?.warehouse}
                adviceId={adviceId}
                packagingUnit={values.packagingUnit}
                errors={errors}
                touched={touched}
                isEditable={isEditable}
                inboundReceipts={inboundReceipts}
                withReceipts={withReceipts}
              />
            </Col>
          </x.div>
        </Fieldset>

        <ItemsWrap>
          <AdviceItems
            isEditable={isEditable}
            isPairingEditable={isPairingEditable}
            loadProducts={loadProducts}
            isLoading={stockAdviceItemsIsLoading}
            itemsGridSelectors={itemsGridSelectors}
            itemsGridActions={itemsGridActions}
            setFieldValue={setFieldValue}
            pairingGridSelectors={pairingGridSelectors}
            pairingGridActions={pairingGridActions}
            loadExpeditionItems={handleLoadExpeditionItems}
            expeditionItems={expeditionItems}
            expeditionItemsIsLoading={expeditionItemsIsLoading}
            onOpenExpedition={onOpenExpedition}
            onImportItems={onImportItems}
            onDownloadImportTemplate={onDownloadImportTemplate}
          />
        </ItemsWrap>

        <AdviceActions
          onOpen={onOpen}
          onClose={onClose}
          onDelete={onDelete}
          values={values}
          isEditable={isEditable}
          setSubmitting={setSubmitting}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          status={values?.status}
        />

        {confirmVisible && (
          <ConfirmDialog
            onClose={closeConfirm}
            onConfirm={setLargeConfirmed}
            title={<Trans id="form.largeStockAdviceAlert.header" message="Warning" />}
            text={
              <Trans
                id="form.largeStockAdviceAlert.text"
                message="Six or more pallets are selected. Please contact Mailstep customer service: support@mailstep.cz."
              />
            }
            onCloseButtonText={<Trans id="form.buttonCloseDialog" message="Close" />}
          />
        )}
      </Form>
    </Modal>
  )
}

export default StockAdviceEditForm
