import gql from 'graphql-tag'

const userMeGetQuery = gql`
  query userMeGet {
    userMeGet @rest(type: "User", method: "GET", path: "me") {
      id
      active
      email
      firstName
      lastName
      sex
      dateOfBirth
      phone
      addressLine1
      addressLine2
      addressLine3
      photo
      preferredLanguage
      preferences
    }
  }
`

export default userMeGetQuery
