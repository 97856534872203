import styled, { css } from '@xstyled/styled-components'
import { Col } from '@designSystem/Grid'

export const ItemsWrap = styled.div`
  border-top: slim;
  border-bottom: slim;
  border-color: lightGray3;
  padding: 4 0 4 0;
  margin: 4 0 4 0;
`

export const wrapperStyle = css`
  display: flex;
  flex-direction: column;
`

export const StyledCol = styled(Col)`
  display: flex;
  & > * {
    margin-bottom: 2;
  }
`
