import React from 'react'
import styled from '@xstyled/styled-components'
import { Portal } from '@mailstep/design-system/ui/Elements/Portal'

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

const Overlay = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <Portal>
    <Wrapper>{children}</Wrapper>
  </Portal>
)

export default Overlay
