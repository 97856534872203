import React from 'react'
import { x } from '@xstyled/styled-components'
import { FormikProps, FormikValues } from 'formik'
import { GridActionsType, GridSelectorsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { ProductApiRead } from '@typings/entities/Product'
import { ProductStockApi } from '@typings/entities/ProductStock'
import { TransferDetailItem } from '@typings/entities/Transfer'
import { formikCallback } from '@typings/Formik'
import { Trans } from '@lingui/react'
import getOr from 'lodash/fp/getOr'
import { createActionColumnDefinition, createColumnDefinitions } from './TableDefinition'
import { AddProductItem, FormGrid, useFormGridChange } from '@components/blocks/FormGrid'
import { useItemsQuantity } from '@hooks/useItemsQuantity'
import Fieldset from '@designSystem/Fieldset'
import { FileSubmit, OnDownloadTemplate } from '@typings/file'
import FormGridTitle from '@components/elements/FormGridTitle'
import { duplicateItemMerge, createEmptyItem } from '@components/forms/utils/FormItems'

interface TransferItemsFieldProps {
  form: FormikProps<FormikValues>
  loadProducts: (fulltext: string) => Promise<ProductApiRead[]>
  loadProductStocks: (productIds: string[], warehouse: string, wms: string) => Promise<ProductStockApi[]>
  warehouse?: string
  wms?: string
  gridSelectors: GridSelectorsType
  gridActions: GridActionsType
  isEditable?: boolean
  setFieldValue: formikCallback
  onImportItems: FileSubmit<{ product: ProductApiRead; quantity: number }[]>
  onDownloadImportTemplate: OnDownloadTemplate
}

const TransferItemsField = ({
  form,
  loadProducts,
  loadProductStocks,
  warehouse,
  wms,
  gridSelectors,
  gridActions,
  isEditable,
  setFieldValue,
  onImportItems,
  onDownloadImportTemplate,
}: TransferItemsFieldProps): JSX.Element => {
  const itemsRaw: TransferDetailItem[] = getOr([], 'values.items', form)
  const error = getOr(null, 'errors.items', form)

  const { allItems, isLoading: isQtyLoading } = useItemsQuantity<TransferDetailItem>(
    itemsRaw,
    warehouse,
    wms,
    setFieldValue,
    loadProductStocks,
  )

  const setItems = React.useCallback((items: TransferDetailItem[]) => setFieldValue('items', items, false), [setFieldValue])
  const { onAdd, onRemove, getIndex } = useFormGridChange<TransferDetailItem>({
    gridSelectors,
    gridActions,
    allItems,
    itemIdentifier: 'product.id',
    setItems,
    duplicateItemMerge,
  })

  const onRowAction = React.useCallback(
    (id: string, field: string, value: any): void => {
      if (field === 'remove') onRemove([value])
    },
    [onRemove],
  )
  const columnsDefinitions = createColumnDefinitions(!!isEditable, getIndex)

  const errorString = error && typeof error === 'string' && form.submitCount > 0 ? error : null

  return (
    <Fieldset disabled={isQtyLoading} fullWidth>
      <FormGridTitle title={<Trans id="form.tranGridImportDialogems.heading" message="Transfer Items" />} error={errorString} />

      {isEditable && (
        <AddProductItem<TransferDetailItem>
          onAdd={onAdd}
          loadProducts={loadProducts}
          formatGridItem={createEmptyItem}
          banLotProducts
          onImportItems={onImportItems}
          onDownloadImportTemplate={onDownloadImportTemplate}
        />
      )}

      <x.div mt={3} mb={3}>
        <FormGrid
          items={allItems}
          columnsDefinitions={columnsDefinitions}
          actionColumnDefinition={createActionColumnDefinition()}
          gridSelectors={gridSelectors}
          gridActions={gridActions}
          onRowAction={onRowAction}
        />
      </x.div>
    </Fieldset>
  )
}

export default TransferItemsField
