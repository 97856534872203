import React from 'react'
import HtmlHead from '@components/elements/HtmlHead'
import LoadingPage from '@pages/Loading'
import Error500Page from '@pages/Error500'
import { x } from '@xstyled/styled-components'

type Props = {
  children: React.ReactNode
  title?: string
  isLoading?: boolean
  error?: Error | string
}

const PageAdmin = ({ children, title, isLoading, error }: Props): JSX.Element => {
  if (error) return <Error500Page error={error} />
  if (isLoading) return <LoadingPage />

  return (
    <x.div h="100%" display="flex" flexDirection="column">
      <HtmlHead title={title} />
      {children}
    </x.div>
  )
}

export default PageAdmin
