import React from 'react'
import { x } from '@xstyled/styled-components'
import Icon from '@mailstep/design-system/ui/Elements/Icon'
import {
  ExpeditionAudit,
  StatusToIcon,
  ExpeditionDetail,
  ExpeditionDetailStatusTrans,
  FlagStatusToAppearance,
} from '@typings/entities/Expedition'
import { DateTime } from 'luxon'
import { t } from '@lingui/macro'
import { Option as SelectOption } from '@mailstep/design-system/ui/Elements/Select/types'
import { withAcl, Ability } from '@components/blocks/Acl'
import { TimelineHighlightLabel, TimelineItem, TimelineTitleWrapper, TimelineWrapper } from './styles'
import { StyledSubTitle, StyledText } from '@components/elements/Typography/lib'
import { Trans } from '@lingui/react'
import HighlightBox from '@components/elements/HighlightBoxReworked'

type ExtendedAudit = ExpeditionAudit & {
  actualDate?: string
  updatedByName?: string
}

type TimelineRowProps = {
  audit: ExtendedAudit
  showUpdatedBy: boolean
}

type Props = {
  accounts?: SelectOption[]
  audits: ExpeditionDetail['audits']
  deliveredAt: ExpeditionDetail['deliveredAt']
  ability: Ability
}

const TimelineRow = ({ showUpdatedBy, audit }: TimelineRowProps): JSX.Element => {
  const isDelivered = audit.statusTo === 'delivered' || audit.statusTo === 'probably_delivered'
  const createdAt = DateTime.fromISO(audit.createdAt).toFormat('dd. MM. yyyy HH:mm')
  const highlightText = ExpeditionDetailStatusTrans[audit.statusTo]?.() || audit.statusTo

  return (
    <>
      <TimelineItem>
        <HighlightBox appearance={FlagStatusToAppearance[audit.statusTo]} size="wide" prefix="history">
          <TimelineHighlightLabel>
            <Icon icon={StatusToIcon[audit.statusTo]} />
            {highlightText}
          </TimelineHighlightLabel>
        </HighlightBox>

        <TimelineTitleWrapper>
          <x.div w="200px">{createdAt}</x.div>
          {showUpdatedBy && audit?.updatedByName && <span>{audit.updatedByName}</span>}
          {isDelivered && (
            <StyledText>
              {audit.actualDate ? audit.actualDate : t({ id: 'expedition.deliveredAtDateUnknown', message: 'date unknown' })}
            </StyledText>
          )}
        </TimelineTitleWrapper>
      </TimelineItem>
    </>
  )
}

const mapAuditsWithDetails = (audits: ExpeditionAudit[], accounts: SelectOption[], deliveredAt?: string): ExtendedAudit[] =>
  (audits || []).map((audit) => {
    const extendedAudit: ExtendedAudit = { ...audit }

    if (audit.statusTo === 'delivered' || audit.statusTo === 'probably_delivered') {
      extendedAudit.actualDate = deliveredAt && DateTime.fromISO(deliveredAt).toFormat('dd. MM. yyyy HH:mm')
    }

    if (audit.updatedBy) {
      const updatedByAdmin = t({ id: 'expedition.history.changedByAdmin', message: 'by administrator' })
      const responsibleAccount = accounts.find((accountOption) => accountOption.value == audit.updatedBy)
      extendedAudit.updatedByName = responsibleAccount ? responsibleAccount.label : updatedByAdmin
    }

    return extendedAudit
  })

const HistoryTimeline = ({ accounts = [], ability, audits, deliveredAt }: Props): JSX.Element => {
  const canAdminRead = ability.can('adminRead', 'Expeditions')
  const extendedAudits = React.useMemo(() => mapAuditsWithDetails(audits, accounts, deliveredAt), [audits, accounts, deliveredAt])

  return (
    <>
      <StyledSubTitle>
        <Trans id="form.expeditionHistory.heading" message="Expedition history" />
      </StyledSubTitle>
      <TimelineWrapper>
        {(extendedAudits || []).map((extendedAudit) => (
          <TimelineRow key={extendedAudit.id} audit={extendedAudit} showUpdatedBy={canAdminRead} />
        ))}
      </TimelineWrapper>
    </>
  )
}

export default withAcl(HistoryTimeline)
