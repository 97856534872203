import styled from '@xstyled/styled-components'
import { Form } from 'formik'
import { th } from '@xstyled/system'

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`
export const StyledHeader = styled.div`
  display: flex;
  flex-flow: column;
  box-shadow: headerShadow;
  margin-bottom: 3rem;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 100vw;
  position: sticky;
  left: 0;
  @media (min-width: 1024px) {
    width: auto;
  }
`
export const StyledTitle = styled.h2`
  font-family: ${th('fonts.primary')};
  font-weight: bold;
  font-size: 16px;
  color: blue2;
  margin-bottom: 2rem;

  & > *:first-child svg {
    margin-right: 0.5em;
  }
`
