import { useQuery } from '@apollo/client'
import notificationList from '@queries/notificationListQuery'
import { ApiHookResultWithReload } from '@typings/ApiHooks'
import { Notification } from '@typings/entities/Notification'
import { useSelector } from 'react-redux'

export const useGetNotificationList = (skip?: boolean, limit?: number): ApiHookResultWithReload<Notification[]> => {
  const organisationId = useSelector((state: State) => state.organisation.selected)
  const filter = { organisation: { eq: organisationId } }

  const {
    data,
    error,
    loading: isLoading,
    refetch: reload,
  } = useQuery(notificationList, {
    ...(limit ? { variables: { limit, filter } } : { variables: { filter } }),
    skip,
    fetchPolicy: 'no-cache',
  })

  return {
    data: data?.notificationList?.notifications,
    isLoading,
    error,
    reload,
  }
}
