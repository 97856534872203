import { ApiError, NetworkError, ApiInitialError } from '@typings/entities/Error'
import { FormikErrors } from 'formik'

export const fieldNameForCommonError = '@@common'

export type CommonErrorFormikObject = {
  '@@common': {
    code: string
    message: string
  }
}

type trasformationObject<TFormikValues, TApiValues> = {
  [K in keyof TApiValues]?: keyof TFormikValues
}

export const formatApiErrorToFormikErrors = <
  TFormikValues extends Record<string, string>,
  TApiValues extends Record<string, string>,
>(
  err: ApiError,
  apiToFieldNameMap: trasformationObject<TFormikValues, TApiValues> = {},
): FormikErrors<TFormikValues | CommonErrorFormikObject> => {
  const messages: FormikErrors<TFormikValues> = (
    (err?.networkError as NetworkError)?.result?.violations ||
    (err?.networkError as NetworkError)?.violations ||
    err?.violations ||
    []
  ).reduce((errors: FormikErrors<TFormikValues>, { message, propertyPath }: ApiInitialError) => {
    const fieldName = apiToFieldNameMap[propertyPath as keyof TApiValues] || propertyPath
    return {
      ...errors,
      [fieldName]: message,
    }
  }, {})

  if (Object.keys(messages).length > 0) {
    return messages
  }

  const commonErrorMessage = err?.networkError?.message || (err?.error as string)
  const commonErrorCode = err?.errorCode as string
  return {
    [fieldNameForCommonError]: { code: commonErrorCode, message: commonErrorMessage },
  }
}

export const createFormikCommonError = (condition: unknown, errorMessage: string): FormikErrors<CommonErrorFormikObject> => {
  if (typeof condition !== 'undefined' && condition) {
    return { [fieldNameForCommonError]: { code: undefined, message: errorMessage } }
  }
  return {}
}

export const getCommonErrorText = (error: ApiError): string | null | undefined => {
  return error?.networkError?.message || (error?.networkError as NetworkError)?.detail || error?.error
}
