import { useEffect, useState } from 'react'

type HookProps = {
  initialTime: number
  callback: () => void
}

const useCallbackWithInterval = ({ initialTime = 15, callback }: HookProps) => {
  const [time, setTime] = useState(initialTime)

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((time) => {
        if (time <= 1) {
          callback()
        }

        if (time <= 0) {
          return initialTime
        }
        return time - 1
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [callback, initialTime])

  return {
    time,
  }
}

export default useCallbackWithInterval
