import React, { useContext, useEffect } from 'react'
import { x } from '@xstyled/styled-components'
import { FormikProps, FormikValues } from 'formik'
import { GridActionsType, GridSelectorsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { BookStockAdvice, StockAdviceDetailItem } from '@typings/entities/StockAdvice'
import { formikCallback } from '@typings/Formik'
import { Trans } from '@lingui/react'
import getOr from 'lodash/fp/getOr'
import { createActionColumnDefinition, createColumnDefinitions } from './TableDefinition'
import { AddProductItem, FormGrid, useFormGridChange } from '@components/blocks/FormGrid'
import HidePrint from '@mailstep/design-system/ui/Blocks/HidePrint/HidePrint'
import { PrintingContext } from '@components/blocks/Printing'
import { ProductApiRead } from '@typings/entities/Product'
import { nanoid } from 'nanoid'
import PairingOverview from './PairingOverview'
import { ExpeditionItemNested } from '@typings/entities/Expedition'
import { useModal } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useModal'
import sumBy from 'lodash/sumBy'
import { FileSubmit, OnDownloadTemplate } from '@typings/file'
import FormGridTitle from '@components/elements/FormGridTitle'
import { duplicateItemMerge } from '@components/forms/utils/FormItems'

interface AdviceItemsFieldProps {
  form: FormikProps<FormikValues>
  loadProducts: (fulltext: string) => Promise<ProductApiRead[]>
  gridSelectors: GridSelectorsType
  gridActions: GridActionsType
  isEditable?: boolean
  isPairingEditable?: boolean
  isLoading?: boolean
  setFieldValue: formikCallback
  pairingGridSelectors: GridSelectorsType
  pairingGridActions: GridActionsType
  loadExpeditionItems: (productId: string) => void
  expeditionItems: ExpeditionItemNested[]
  expeditionItemsIsLoading: boolean
  onOpenExpedition: (id: string) => void
  replace: (index: number, value: any) => void
  onImportItems: FileSubmit<{ product: ProductApiRead; quantity: number }[]>
  onDownloadImportTemplate: OnDownloadTemplate
}

// TODO fix typing
const createEmptyItem = (qty: number, product: ProductApiRead): StockAdviceDetailItem => ({
  id: `${nanoid()}`,
  quantity: qty,
  product: product,
  suppliedQuantity: 0,
  bookedStockAdvices: [],
  bookAdviceTotal: 0,
  bookedAdviceTotal: 0,
})

const AdviceItemsField = ({
  form,
  replace,
  loadProducts,
  gridSelectors: defaultGridSelectors,
  gridActions,
  isEditable,
  isPairingEditable,
  isLoading,
  setFieldValue,
  pairingGridSelectors,
  pairingGridActions,
  loadExpeditionItems,
  expeditionItems,
  expeditionItemsIsLoading,
  onOpenExpedition,
  onImportItems,
  onDownloadImportTemplate,
}: AdviceItemsFieldProps): JSX.Element => {
  const itemsRaw: StockAdviceDetailItem[] = getOr([], 'values.items', form)
  const isPrinting = useContext(PrintingContext)
  const error = getOr(null, 'errors.items', form)

  // we need field bookAdviceTotal touched as multiple sources can make it invalid
  useEffect(() => {
    itemsRaw.forEach((itemsRaw, index) => {
      if (!form.touched?.items?.[index]?.bookAdviceTotal) {
        form.setFieldTouched(`items[${index}]['bookAdviceTotal']`, true, false)
      }
    })
  }, [form, itemsRaw])

  const allItems = React.useMemo(() => {
    return itemsRaw.map((item) => {
      const suppliedQuantity = item.suppliedQuantity || 0
      return {
        ...item,
        suppliedQuantity,
        awaitingQuantity: Math.max(item.quantity - suppliedQuantity, 0),
      }
    })
  }, [itemsRaw])

  const gridSelectors = isPrinting ? { ...defaultGridSelectors, rowsPerPage: allItems.length } : defaultGridSelectors

  const setItems = React.useCallback((items: StockAdviceDetailItem[]) => setFieldValue('items', items, false), [setFieldValue])
  const { onAdd, onRemove, getIndex } = useFormGridChange<StockAdviceDetailItem>({
    gridSelectors,
    gridActions,
    allItems,
    itemIdentifier: 'product.id',
    setItems,
    duplicateItemMerge,
  })

  const {
    onOpen: setExpeditionsVisible,
    isOpen: expeditionsVisible,
    onClose: closeExpeditions,
    data: expeditionsDialogItem,
  } = useModal<StockAdviceDetailItem | null>()

  const setExpeditionPairingForRow = React.useCallback(
    (bookStockAdvices: BookStockAdvice[]) => {
      if (!expeditionsDialogItem) return
      const bookAdviceTotal = sumBy(bookStockAdvices, 'quantity')
      replace(getIndex(expeditionsDialogItem), { ...expeditionsDialogItem, bookStockAdvices, bookAdviceTotal })
      closeExpeditions()
    },
    [expeditionsDialogItem, closeExpeditions, getIndex, replace],
  )

  const onRowAction = React.useCallback(
    (id: string, field: string, row: any): void => {
      if (field === 'remove') {
        onRemove([row])
      } else if (field === 'pairExpedition') {
        loadExpeditionItems(row.product.id)
        setExpeditionsVisible(row)
      }
    },
    [loadExpeditionItems, onRemove, setExpeditionsVisible],
  )

  const columnsDefinitions = createColumnDefinitions(!!isEditable && !isPrinting, !!isPairingEditable, getIndex)
  const errorString = error && typeof error === 'string' && form.submitCount > 0 ? error : null

  return (
    <>
      <HidePrint>
        <FormGridTitle title={<Trans id="form.adviceItems.heading" message="Advice Items" />} error={errorString} />

        {isEditable && !isPrinting && (
          <AddProductItem<StockAdviceDetailItem>
            onAdd={onAdd}
            loadProducts={loadProducts}
            formatGridItem={createEmptyItem}
            onImportItems={onImportItems}
            onDownloadImportTemplate={onDownloadImportTemplate}
          />
        )}
      </HidePrint>

      <x.div mt={3} mb={3}>
        <FormGrid
          isLoading={isLoading}
          items={allItems}
          columnsDefinitions={columnsDefinitions}
          actionColumnDefinition={createActionColumnDefinition()}
          gridSelectors={gridSelectors}
          gridActions={gridActions}
          onRowAction={onRowAction}
        />
      </x.div>

      {expeditionsVisible && (
        <PairingOverview
          onCancel={closeExpeditions}
          editedAdviceItem={expeditionsDialogItem}
          setExpeditionPairingForRow={setExpeditionPairingForRow}
          expeditionItems={expeditionItems}
          expeditionItemsIsLoading={expeditionItemsIsLoading}
          gridActions={pairingGridActions}
          gridSelectors={pairingGridSelectors}
          onOpenExpedition={onOpenExpedition}
        />
      )}
    </>
  )
}

export default AdviceItemsField
