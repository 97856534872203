import React, { useCallback, useMemo } from 'react'
import * as yup from 'yup'
import { x } from '@xstyled/styled-components'
import { Trans } from '@lingui/react'
import { Formik, Form, Field } from 'formik'
import { t } from '@lingui/macro'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'
import { FieldLabel } from '@mailstep/design-system/ui/Elements/Label'
import { Text } from '@mailstep/design-system/ui/Elements/Typography'
import Button from '@mailstep/design-system/ui/Elements/Button'
import SelectField from '@components/forms/components/SelectField'
import LoadingOverlay from '@components/blocks/LoadingOverlay'
import { useBaselinkerProductsSyncQuery } from '@hooks/apiHooks/baselinker/useBaselinkerSync'
import { useLoadWarehousesList } from '@hooks/apiHooks/werehouses/useLoadWarehousesList'
import toSelectOption from '@utils/toSelectOption'

type Props = {
  onClose: () => void
  eshopId: string
  eshopName?: string
  title: string | JSX.Element
}

const validationSchema = yup.object().shape({
  warehouse: yup
    .string()
    .required(() => t({ id: 'form.warehouse.errorEmpty', message: 'Please, select the virtual warehouse.' })),
})

const initialValues = { warehouse: '' }

const SyncProductStocksDialog = ({ onClose, eshopId, eshopName, title }: Props): JSX.Element => {
  const { data: warehouses, isLoading: warehousesIsLoading } = useLoadWarehousesList(null, eshopId)
  const warehouseOptions = useMemo(() => (warehouses || []).map(toSelectOption('id', 'name')), [warehouses])

  const { syncProductStocks } = useBaselinkerProductsSyncQuery()

  const handleSubmit = useCallback(
    async (values) => {
      const { warehouse } = values
      syncProductStocks(warehouse)
      onClose()
    },
    [syncProductStocks, onClose],
  )

  return (
    <LoadingOverlay isLoading={warehousesIsLoading}>
      <Modal
        width="narrow"
        title={title}
        onCancel={onClose}
        cancelLabel={<Trans id="form.buttonCancel" message="Cancel" />}
        isConfirmDisabled
        hasFooter={false}
        hasHeader={true}
      >
        <x.div mb={3}>
          <FieldLabel>
            <Trans id="form.eshop.label" message="Eshop" />
          </FieldLabel>
          <Text>{eshopName}</Text>
        </x.div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {(formik): JSX.Element => (
            <Form>
              <Field
                component={SelectField}
                label={<Trans id="form.warehouse.label" message="Virtual warehouse" />}
                options={warehouseOptions}
                placeholder={t({ id: 'form.warehouse.placeholder', message: 'Select warehouse...' })}
                name="warehouse"
              />
              <x.div mt={4} display="flex" justifyContent="flex-end">
                <Button type="submit" isLoading={formik.isSubmitting}>
                  <Trans id="form.buttonSynchronize" message="Synchronize" />
                </Button>
              </x.div>
            </Form>
          )}
        </Formik>
      </Modal>
    </LoadingOverlay>
  )
}

export default SyncProductStocksDialog
