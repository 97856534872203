import styled, { css } from '@xstyled/styled-components'
import { th, ThemeGetterFunc } from '@xstyled/system'

export const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  height: 62px;
  color: blue2;
  box-shadow: headerShadowB;
  background-color: bgLightGray;
  border-radius: ml;
`

export const ArrowButtonsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: fit-content;
  flex-wrap: wrap;
`

const borderStyles = css`
  :after {
    content: '';
    width: 1px;
    height: 50%;
    background-color: lightGray3;
    position: absolute;
    top: 25%;
    right: 0px;
  }
`

type StyledSquareButtonProps = { hasValue: boolean; hasBorder: boolean }
export const StyledSquareButton = styled.button<StyledSquareButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  position: relative;
  padding: 0px 20px;
  height: 100%;
  border: none;
  border-radius: ml;
  background-color: bgLightGray;
  :hover {
    cursor: ${({ hasValue }) => (hasValue ? 'pointer' : 'default')};
    background-color: lightGray2;
  }
  ${({ hasBorder }) => hasBorder && borderStyles}
`
export const ButtonContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 4px;

  @media (min-width: 1600px) {
    padding: 6px;
  }
`

export const StyledArrowButton = styled.button`
  display: flex;
  flex-shrink: 0;
  height: 48px;
  margin-bottom: 2px;
  margin-right: -14px;
  font-family: ${th('fonts.primary')};
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s linear 0s;

  @media (min-width: 1600px) {
    margin-right: -12px;
  }

  &:hover {
    opacity: 0.8;
  }

  ${ButtonContentWrap} {
    background: ${({ bgColor }): ThemeGetterFunc => (bgColor ? th.color(bgColor) : th.color('lightGray2'))};
    color: ${({ fontColor }): ThemeGetterFunc => (fontColor ? th.color(fontColor) : th.color('white'))};
  }

  svg {
    fill: ${({ bgColor }): ThemeGetterFunc => (bgColor ? th.color(bgColor) : th.color('lightGray2'))};
  }
`

export const ButtonContent = styled.span`
  margin-bottom: 1px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;

  @media (min-width: 1600px) {
    margin-bottom: 2px;
  }
`

export const ButtonLabel = styled.span`
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
  text-align: center;
`
