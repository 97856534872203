/* eslint-disable react/display-name */
import React from 'react'
import { Field } from 'formik'
import InputField from '@components/forms/components/InputField'
import { ColumnDefinition, RowValueType, ActionColumn } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { Trans } from '@lingui/react'
import { GridItem } from '@hooks/useItemsQuantity'
import ReservationStatusCell, { getReservationStatusOptions } from '@components/elements/gridCells/ReservationStatus'
import ConnectedInternalSkuCell from '@containers/ConnectedInternalSkuCell'
import { RemoveCell } from '@mailstep/design-system/ui/Blocks/CommonGrid'
import { Spinner } from '@mailstep/design-system/ui/Elements/Spinner'
import { TransferDetailItem } from '@typings/entities/Transfer'

export const createActionColumnDefinition = (): ActionColumn => {
  return {
    flexBasis: 40,
    addRowNumbers: true,
  }
}
export const createColumnDefinitions = (
  isEditable: boolean,
  getIndex: (item: GridItem<TransferDetailItem>) => number,
): ColumnDefinition[] => {
  const columns = [
    {
      name: 'reservationStatus',
      title: <Trans id="dataGrid.columnReservationStatus.title" message="Reservation status" />,
      filtering: true,
      flexBasis: 130,
      filterOptions: getReservationStatusOptions(),
      cellComponent: ReservationStatusCell,
    },
    {
      name: 'product.productSku',
      title: <Trans id="dataGrid.columnProductSku.title" message="Product SKU" />,
      sorting: true,
      filtering: true,
      flexBasis: 140,
    },
    {
      name: 'product.internalSku',
      cellComponent: ConnectedInternalSkuCell,
      title: <Trans id="dataGrid.columnInternalSku.title" message="Internal SKU" />,
      sorting: true,
      filtering: true,
      flexBasis: 140,
    },
    {
      name: 'product.name',
      title: <Trans id="dataGrid.columnProductName.title" message="Product name" />,
      sorting: true,
      filtering: true,
      flexBasis: 200,
    },
    {
      name: 'ref1',
      title: <Trans id="dataGrid.columnRef1.title" message="Ref 1" />,
      formatRowValue: (row: GridItem<TransferDetailItem>): RowValueType => {
        return isEditable ? (
          <Field name={`items[${getIndex(row)}][ref1]`} component={InputField} appearance="grid" />
        ) : (
          <span className="tooLongText">{row.ref1}</span>
        )
      },
      sorting: true,
      filtering: true,
      flexBasis: 120,
      fixedSize: isEditable,
    },
    {
      name: 'ref2',
      title: <Trans id="dataGrid.columnRef2.title" message="Ref 2" />,
      formatRowValue: (row: GridItem<TransferDetailItem>): RowValueType => {
        return isEditable ? (
          <Field name={`items[${getIndex(row)}][ref2]`} component={InputField} appearance="grid" />
        ) : (
          <span className="tooLongText">{row.ref2}</span>
        )
      },
      sorting: true,
      filtering: true,
      flexBasis: 120,
      fixedSize: isEditable,
    },
    {
      name: 'ref3',
      title: <Trans id="dataGrid.columnRef3.title" message="Ref 3" />,
      formatRowValue: (row: GridItem<TransferDetailItem>): RowValueType => {
        return isEditable ? (
          <Field name={`items[${getIndex(row)}][ref3]`} component={InputField} appearance="grid" />
        ) : (
          <span className="tooLongText">{row.ref3}</span>
        )
      },
      sorting: true,
      filtering: true,
      flexBasis: 120,
      fixedSize: isEditable,
    },
    // LOTs are not done on BE
    // {
    //   name: 'lot',
    //   title: <Trans id="dataGrid.columnLot.title" message="LOT" />,
    //   cellComponent: LotComponentTrans,
    //   sorting: true,
    //   filtering: true,
    //   flexBasis: 90,
    //   fixedSize: isEditable,
    // },
    {
      name: 'quantity',
      title: <Trans id="dataGrid.columnQty.title" message="Quantity" />,
      formatRowValue: (row: GridItem<TransferDetailItem>): RowValueType => {
        return isEditable ? (
          <Field
            name={`items[${getIndex(row)}][quantity]`}
            type="number"
            component={InputField}
            min={1}
            errorAppearance="tooltip"
            appearance="grid"
          />
        ) : (
          row.quantity
        )
      },
      sorting: true,
      filtering: true,
      filteringType: 'number',
      fixedSize: isEditable,
      flexBasis: 100,
      flexGrow: 0,
    },
    {
      name: 'availableTotal',
      title: <Trans id="dataGrid.columnAvailableTotal.title" message="Available total" />,
      formatRowValue: (row: GridItem<TransferDetailItem>): RowValueType => {
        if (row.availableStock === '?' || row.availableStock === null) {
          return row.availableStock !== null ? row.availableStock : <Spinner variant="sm" />
        }
        return row.availableStock + (row.booked || 0)
      },
      sorting: false,
      filtering: false,
      filteringType: 'number',
      flexBasis: 80,
      flexGrow: 0,
    },
    {
      name: 'availableStock',
      title: <Trans id="dataGrid.columnAvailableQty.title" message="Available QTY" />,
      formatRowValue: (row: GridItem<TransferDetailItem>): RowValueType => {
        return row.availableStock !== null ? row.availableStock : <Spinner variant="sm" />
      },
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 80,
      flexGrow: 0,
    },
    {
      name: 'book',
      title: <Trans id="dataGrid.columnBook.title" message="Book" />,
      formatRowValue: (row: GridItem<TransferDetailItem>): RowValueType => {
        return isEditable ? (
          <Field
            name={`items[${getIndex(row)}][book]`}
            type="number"
            component={InputField}
            min={0}
            max={row.quantity}
            errorAppearance="tooltip"
            appearance="grid"
          />
        ) : (
          row.book
        )
      },
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 100,
      fixedSize: isEditable,
      flexGrow: 0,
    },
    {
      name: 'booked',
      title: <Trans id="dataGrid.columnBooked.title" message="Booked" />,
      formatRowValue: (row: GridItem<TransferDetailItem>): RowValueType => {
        if (row.availableStock === null) {
          return <Spinner variant="sm" />
        }
        return row.booked || 0
      },
      sorting: true,
      filtering: true,
      filteringType: 'number',
      flexBasis: 80,
      flexGrow: 0,
    },
  ]
  if (true) {
    columns.push({
      name: 'buttons',
      title: '',
      cellComponent: RemoveCell,
      alwaysOn: true,
      flexBasis: 80,
      flexShrink: 0,
      flexGrow: 0,
    })
  }
  return columns
}
