import gql from 'graphql-tag'

const productsGetQuery = gql`
  query productsGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object, $nested: boolean) {
    productsGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, select: $select, nested: $nested })
      @rest(type: "ProductsResult", method: "POST", path: "product/list") {
      results
      paging
    }
  }
`

export default productsGetQuery
