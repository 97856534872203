import gql from 'graphql-tag'

export const complaintDetailFields = `
  actionIds
  attachmentIds
  changedAt
  createdAt
  createdBy
  currency
  description
  id
  internalId
  orderId
  organisationId
  reason
  reporter
  resolution
  resolutionDetail
  resolver
  status
  value
`

const complaintGetQuery = gql`
  query complaintGet($id: String!) {
    complaintGet(id: $id) @rest(type: "Complaint", method: "GET", path: "complaints/{args.id}", endpoint: "gateway") {
      ${complaintDetailFields}
    }
  }
`

export default complaintGetQuery
