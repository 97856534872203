import reduceReducers from 'reduce-reducers'
import createFetchModule from '@utils/store/createFetchModule'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'
import { FetchActionsWith } from '@typings/generic'

export const moduleName = 'productStocksGrid'

const fetchModule = createFetchModule('@store/productStocksGrid', 'LOAD', moduleName)
const fetchFiltersSubmodule = createFetchSubmodule('@store/productStocksGrid', 'FILTERS', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  productStocksFilters: fetchFiltersSubmodule.actionTypes,
}

export default reduceReducers(fetchModule.reducer, fetchFiltersSubmodule.reducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
  productStocksFilters: fetchFiltersSubmodule.actions.run,
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
  ...fetchFiltersSubmodule.selectors,
  // ... ownSelectors
}
