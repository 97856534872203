import React, { useMemo } from 'react'
import { Trans } from '@lingui/react'
import { x } from '@xstyled/styled-components'
import { Status as StatusType, statusToColor, StatusTrans } from '@typings/enums/StockAdvice'
import { Field, FormikValues } from 'formik'
import InfoHeader from '@components/blocks/InfoHeader'
import HighlightBox from '@components/elements/HighlightBoxReworked'
import InputField from '@components/forms/components/InputField'

interface HeaderType {
  status?: keyof typeof StatusType
  adviceId?: string
  isEditable: boolean
  withReceipts?: boolean
  values: FormikValues
}

const Header = ({ status, values, isEditable }: HeaderType): JSX.Element => {
  const infoHeaderItems = useMemo(() => {
    const statusField = {
      label: <Trans id="form.status.label" message="Status" />,
      component: status && (
        <HighlightBox appearance={statusToColor[status]} size="default">
          {StatusTrans[status]()}
        </HighlightBox>
      ),
      isSmall: true,
    }

    return [
      {
        label: <Trans id="form.adviceInternalId.label" message="Advice number" />,
        component: isEditable ? <Field name="internalId" component={InputField} /> : values?.internalId,
      },
      {
        label: <Trans id="form.adviceWmsId" message="Advice WMS ID" />,
        component: values?.wmsInternalId,
      },
      ...(status ? [statusField] : []),
    ]
  }, [isEditable, status, values?.internalId, values?.wmsInternalId])

  return (
    <x.div w="50%" minW="300px" mb={4} pr={2}>
      <InfoHeader items={infoHeaderItems} />
    </x.div>
  )
}

export default React.memo(Header)
