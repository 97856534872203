import * as yup from 'yup'
import { t } from '@lingui/macro'
import { DocumentId } from '@components/forms/utils/RegexValidation'
import { TransferDetailItem } from '@typings/entities/Transfer'

const createValidationSchema = (): object =>
  yup.object().shape({
    internalNumber: yup
      .string()
      .required(() => t({ id: 'form.transferInternalNumber.errorEmpty', message: 'Please, enter internal ID.' }))
      .max(32, () => t({ id: 'form.transferInternalNumber.errorTooLong', message: 'Maximal length is 32 characters.' }))
      .matches(DocumentId, () =>
        t({
          id: 'form.transferInternalNumber.errorInvalid',
          message: `Value can contain only digits, letters, underscore, slash and minus.`,
        }),
      ),
    wmsFrom: yup.string().required(() => t({ id: 'form.wms.errorEmpty', message: 'Please, select the physical warehouse.' })),
    warehouseFrom: yup
      .string()
      .required(() => t({ id: 'form.warehouse.errorEmpty', message: 'Please, select the virtual warehouse.' })),
    wmsTo: yup.string().required(() => t({ id: 'form.wms.errorEmpty', message: 'Please, select the physical warehouse.' })),
    warehouseTo: yup
      .string()
      .required(() => t({ id: 'form.warehouse.errorEmpty', message: 'Please, select the virtual warehouse.' }))
      .when(`warehouseFrom`, ([warehouseFrom], schema: yup.StringSchema) => {
        return schema.test(
          'warehouseFromToSame',
          () => t({ id: 'form.warehouse.errorTransferSame', message: 'Please select different virtual warehouse' }),
          (value) => {
            return !value || value !== warehouseFrom
          },
        )
      }),
    note: yup.string().max(500, () => t({ id: 'form.note.errorTooLong', message: 'Maximal length is 500 characters.' })),
    items: yup
      .array()
      .required(() => t({ id: 'form.items.errorEmpty', message: 'Please, enter at least one item.' }))
      .test(
        'is-not-lot',
        '', // we are using custom error
        function (value: TransferDetailItem[]) {
          if (!value) return false
          const problematicItem = value.find((item) => {
            return item.product.workAroundLot
          })
          if (problematicItem)
            return this.createError({
              message: t({
                id: 'form.items.errorTransferItemLot',
                message: `Can not have product with Lot (product: ${problematicItem.product.name})`,
              }),
            })

          return true
        },
      )
      .test(
        'is-active',
        '', // we are using custom error
        function (value: TransferDetailItem[]) {
          if (!value) return false
          const problematicItem = value.find((item) => !item.product.active)
          if (problematicItem)
            return this.createError({
              message: t({
                id: 'form.items.errorNotActive',
                message: `Can not have deactivated product (product: ${problematicItem.product.name})`,
              }),
            })
          return true
        },
      )
      .of(
        yup.object().shape({
          quantity: yup
            .number()
            .min(1, () => t({ id: 'form.quantity.errorMin', message: 'Quantity must be greater than or equal to 1.' })),
          book: yup
            .number()
            .min(0, () => t({ id: 'form.book.errorMin', message: 'Entered quantity cannot be negative.' }))
            .required(() => t({ id: 'form.book.errorEmpty', message: 'Please, enter the quantity to be booked.' }))
            .test(
              'maxQuantity',
              () => t({ id: 'form.book.errorMax', message: 'Entered quantity exceeds the maximum value.' }),
              function (value) {
                if (this.parent.availableStock === '?' || this.parent.availableStock === null) return true // warehouses will display bug
                const availableTotal = this.parent.availableStock + this.parent.booked
                return this.parent.quantity >= value && availableTotal >= value
              },
            ),
        }),
      ),
  })

export default createValidationSchema
