import { cancel, delay, fork, race, take } from 'redux-saga/effects'
import { Task } from 'redux-saga'

type TaskType = (action: Action) => Generator

export const sagaDebounce = (ms: number, pattern: string, task: TaskType, ...args: unknown[]) =>
  fork(function* () {
    let lastTask: Task | undefined
    while (true) {
      let action: unknown = yield take(pattern)
      if (lastTask) {
        yield cancel(lastTask) // cancel is no-op if the task has already terminated
      }

      while (true) {
        const { debounced, latestAction } = yield race({
          debounced: delay(ms),
          latestAction: take(pattern),
        })

        if (debounced) {
          lastTask = (yield fork<(action: Action) => Generator>(task, ...(args as []), action as Action)) as Task
          break
        }
        action = latestAction
      }
    }
  })
