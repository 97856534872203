import gql from 'graphql-tag'
import { complaintDetailFields } from './complaintGetQuery'

const complaintCreateQuery = gql`
  query complaintCreate(
    $reporter: String
    $organisation: String!
    $order: String!
    $type: String!
    $reason: String!
    $resolution: String
    $description: String
    $internalId: String
    $currency: String
    $value: String
  ) {
    complaintCreate(
      input: {
        reporter: $reporter
        organisation: $organisation
        order: $order
        type: $type
        reason: $reason
        resolution: $resolution
        description: $description
        internalId: $internalId
        currency: $currency
        value: $value
      }
    ) @rest(method: "POST", path: "complaints", endpoint: "gateway") {
      ${complaintDetailFields}
    }
  }
`

export default complaintCreateQuery
