import gql from 'graphql-tag'

const eshopBaselinkerSyncProducts = gql`
  query eshopBaselinkerSyncProduct($eshopId: String!, $inventoryId: String!) {
    eshopBaselinkerSyncProduct(input: { eshopId: $eshopId, inventoryId: $inventoryId })
      @rest(type: "EshopBaselinkerSyncProductResult", method: "POST", path: "baselinker/eshop/sync/products") {
      id
      eshopId
      inventoryId
    }
  }
`

export default eshopBaselinkerSyncProducts
