import { FlagAppearanceType } from '@constants/FlagAppearance'
import { t } from '@lingui/macro'

export const statusToColor: { [value: string]: FlagAppearanceType } = {
  created: 'blueSubtle',
  resolving: 'tealSubtle',
  waiting_for_reporter: 'orangeSubtle',
  waiting_for_resolver: 'orangeSubtle',
  waiting_for_resolver_log: 'orangeSubtle',
  waiting_for_cs: 'orangeSubtle',
  waiting_for_ms: 'orangeSubtle',
  waiting_for_carrier: 'orangeSubtle',
  resolved: 'greenSubtle',
  closed: 'neutralSubtle',
  canceled: 'redSubtle',
}

export const ComplaintStatusTrans = {
  created: () => t({ id: `complaintStatus.created`, message: `created` }),
  resolving: () => t({ id: 'complaintStatus.resolving', message: `resolving` }),
  waiting_for_reporter: () => t({ id: 'complaintStatus.waitingForReporter', message: `waiting for reporter` }),
  waiting_for_resolver: () => t({ id: 'complaintStatus.waitingForResolver', message: `waiting for resolver` }),
  waiting_for_resolver_log: () => t({ id: 'complaintStatus.waitingForResolverLog', message: `waiting for resolver LOG` }),
  waiting_for_cs: () => t({ id: 'complaintStatus.waitingForCs', message: `waiting for cs` }),
  waiting_for_ms: () => t({ id: 'complaintStatus.waitingForMs', message: `waiting for ms` }),
  waiting_for_carrier: () => t({ id: 'complaintStatus.waitingForCarrier', message: `waiting for carrier` }),
  resolved: () => t({ id: 'complaintStatus.resolved', message: `resolved` }),
  closed: () => t({ id: 'complaintStatus.closed/approved', message: `client approved` }),
  canceled: () => t({ id: 'complaintStatus.canceled', message: `canceled` }),
}

export const reasonToColor: { [value: string]: FlagAppearanceType } = {
  undelivered: 'neutralSubtle',
  incomplete: 'orangeSubtle',
  damaged: 'redSubtle',
  late_delivery: 'purpleSubtle',
  other: 'neutralBold',
}
export const ComplaintReasonTrans = {
  undelivered: () => t({ id: 'complaintReason.undelivered', message: `undelivered` }),
  incomplete: () => t({ id: 'complaintReason.incomplete', message: `incomplete` }),
  damaged: () => t({ id: 'complaintReason.damaged', message: `damaged` }),
  late_delivery: () => t({ id: 'complaintReason.lateDelivery', message: `late delivery` }),
  other: () => t({ id: 'complaintReason.other', message: `other` }),
}

export const resolutionToColor: { [value: string]: FlagAppearanceType } = {
  unproven: 'purpleSubtle',
  additional_goods: 'yellowSubtle',
  rejected_by_provider: 'orangeSubtle',
  rejected_by_carrier: 'orangeSubtle',
  redispatch: 'blueSubtle',
  damaged_by_carrier: 'redSubtle',
  damaged_by_customer: 'redSubtle',
  product_unavailable: 'tealSubtle',
  canceled: 'redBold',
  accepted_by_carrier: 'greenSubtle',
  accepted_by_provider: 'greenSubtle',
  wrong_product: 'pinkSubtle',
  lost_by_carrier: 'orangeBold',
  lost_by_provider: 'orangeBold',
}

export const ComplaintResolutionTrans = {
  no_resolution: () => t({ id: 'complaintResolution.no_resolution', message: `no resolution` }),
  unproven: () => t({ id: 'complaintResolution.unproven', message: `unproven` }),
  additional_goods: () => t({ id: 'complaintResolution.additional_goods', message: `additional goods` }), // Doposlané zboží (původní)
  rejected_by_provider: () => t({ id: 'complaintResolution.rejected_by_provider', message: `rejected by provider` }), //o Neuznána námi
  rejected_by_carrier: () => t({ id: 'complaintResolution.rejected_by_carrier', message: `rejected by carrier` }), //o Neuznána dopravcem
  redispatch: () => t({ id: 'complaintResolution.redispatch', message: `re-dispatch` }), //o Opakované odeslání (původní)
  damaged_by_carrier: () => t({ id: 'complaintResolution.damaged_by_carrier', message: `damaged by carrier` }), //o Poškozená zásilka cestou
  damaged_by_customer: () => t({ id: 'complaintResolution.damaged_by_customer', message: `damaged by customer` }), //o Poškozená zásilka klientem
  product_unavailable: () => t({ id: 'complaintResolution.product_unavailable', message: `product unavailable` }), //o Produkt není skladem
  canceled: () => t({ id: 'complaintResolution.canceled', message: `canceled` }), //o Storno zásilky původní
  accepted_by_carrier: () => t({ id: 'complaintResolution.accepted_by_carrier', message: `accepted by carrier` }), //o Uznána dopravcem
  accepted_by_provider: () => t({ id: 'complaintResolution.accepted_by_provider', message: `accepted by provider` }), //o Uznána námi
  wrong_product: () => t({ id: 'complaintResolution.wrong_product', message: `wrong product` }), //o Záměna zboží
  lost_by_carrier: () => t({ id: 'complaintResolution.lost_by_carrier', message: `lost by carrier` }), //o Ztracená dopravcem
  lost_by_provider: () => t({ id: 'complaintResolution.lost_by_provider', message: `lost by provider` }), //o Ztracená námi
}

export const ComplaintPossibleStatuses = [
  'waiting_for_the_carrier',
  'carrier_picked_up',
  'delivered',
  'probably_delivered',
  'returned',
  'returning',
  'ready_to_takeover',
]

export const finalStatuses = ['closed', 'canceled']

export const QuickFilterTrans = {
  all: () => t({ id: 'complaintQuickFilter.all', message: `filter all` }),
  mine: () => t({ id: 'complaintQuickFilter.mine', message: `filter mine` }),
  unassigned: () => t({ id: 'complaintQuickFilter.unassigned', message: `filter unassigned` }),
}

export const ComplaintTypeTrans = {
  expedition: () => t({ id: 'complaintType.expedition', message: `expedition` }),
}
