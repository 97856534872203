import { Formik, FormikProps, FormikValues } from 'formik'
import React from 'react'
import ComplaintCreateForm, { ExternalFormProps } from './ComplaintCreateForm'
import { FormikHandleSubmit } from '@typings/Formik'
import * as yup from 'yup'
import { t } from '@lingui/macro'
import { DecimalNumber, DocumentId } from '@components/forms/utils/RegexValidation'
import { Complaint } from '@typings/entities/Complaint'

type Props = ExternalFormProps & {
  initialValues?: FormikValues
  onSubmit: FormikHandleSubmit<Complaint>
}

const validationSchema = yup.object().shape({
  internalId: yup
    .string()
    .max(64, () => t({ id: 'form.internalId.errorTooLong', message: 'Maximal length is 64 characters.' }))
    .required(() => t({ id: 'form.internalId.errorEmpty', message: 'Please, enter internal ID.' }))
    .matches(DocumentId, () =>
      t({ id: 'form.internalId.errorInvalid', message: 'Value can contain only digits, letters, underscore, slash and minus.' }),
    ),
  reporter: yup.string().required(() => t({ id: 'form.reporter.errorEmpty', message: 'Please, select a reporter.' })),
  reason: yup.string().required(() => t({ id: 'form.reason.errorEmpty', message: 'Please, select a reason.' })),
  description: yup
    .string()
    .max(500, () => t({ id: 'form.description.errorTooLong', message: 'Maximal length is 500 characters.' })),
  value: yup.string().matches(DecimalNumber, () => t({ id: 'form.value.errorInvalid', message: 'Please, enter valid value.' })),
})

const initialValuesEmpty = {}

const ComplaintCreateFormFormik = ({
  initialValues: incomingInitialVales = initialValuesEmpty,
  onSubmit,
  title,
  onCancel,
  ...rest
}: Props): JSX.Element => {
  const initialValues = React.useMemo(() => ({ ...incomingInitialVales, currency: 'CZK' }), [incomingInitialVales]) // by request, currency is hardcoded as CZK
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {(formikBag: FormikProps<FormikValues>): JSX.Element => (
        <ComplaintCreateForm {...formikBag} onCancel={onCancel} title={title} {...rest} />
      )}
    </Formik>
  )
}

export default ComplaintCreateFormFormik
