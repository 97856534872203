import { selectors as routerSelectors } from '@store/connectedRouter'
import { useSelector } from 'react-redux'

type UseUser = {
  isUser: boolean
  isAdmin: boolean
  type: 'Admin' | 'User'
}

const useUser = (): UseUser => {
  const pathname = useSelector(routerSelectors.pathnameSelector)
  const type = pathname.match(/^\/admin(\/|$)/) ? 'Admin' : 'User'

  return {
    isUser: type === 'User',
    isAdmin: type === 'Admin',
    type,
  }
}

export default useUser
