import gql from 'graphql-tag'

const carriersGetQuery = gql`
  query carriersGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    carriersGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: $nested })
      @rest(type: "Carriers", method: "POST", path: "carrier/list") {
      results
      paging
    }
  }
`

export default carriersGetQuery
