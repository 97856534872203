import reduceReducers from 'reduce-reducers'
import produce from 'immer'
import getOr from 'lodash/fp/getOr'
import createFetchModule from '@utils/store/createFetchModule'
import { QuickFilter, QuickFilterType } from '@typings/entities/Complaint'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'
import { FetchActionsWith } from '@typings/generic'

export const moduleName = 'complaintsGrid'

const fetchModule = createFetchModule('@store/ComplaintsGrid', 'LOAD', moduleName)
const fetchFiltersSubmodule = createFetchSubmodule('@store/ComplaintsGrid', 'FILTERS', moduleName)
const exportSubmodule = createFetchSubmodule('@store/ComplaintsGrid', 'EXPORT', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  setQuickFilter: `@store/ComplaintsGrid/SET_QUICK_FILTER`,
  loadAsyncFilters: fetchFiltersSubmodule.actionTypes,
  export: exportSubmodule.actionTypes,
}

const ownReducer = (state: State, action: Action): State => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.setQuickFilter:
        draft.quickFilter = action?.payload
        break
    }
  })
}

export default reduceReducers(fetchModule.reducer, fetchFiltersSubmodule.reducer, ownReducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
  setQuickFilter: (value: QuickFilterType) => ({ type: actionTypes.setQuickFilter, payload: value }),
  loadAsyncFilters: fetchFiltersSubmodule.actions.run,
  export: exportSubmodule.actions.run,
}

type SelectorDefinitions = { getQuickFilter: QuickFilterType }

export const selectors: Selectors<SelectorDefinitions> = {
  ...fetchModule.selectors,
  // Grid specific
  ...fetchFiltersSubmodule.selectors,
  // Global - above grid
  getQuickFilter: (state) => getOr(QuickFilter.all, `quickFilter`, state?.complaintsGrid),
}
