import React, { useCallback, useState } from 'react'
import { th } from '@xstyled/system'
import styled, { x } from '@xstyled/styled-components'
import { Notification } from '@typings/entities/Notification'
import { DateTime } from 'luxon'
import FaIcon from '@mailstep/design-system/ui/Elements/Icon'
import { Trans } from '@lingui/react'
import RadioButton from '@mailstep/design-system/ui/Forms/RadioButton'
import { SetNotificationFlags } from '@hooks/apiHooks/notifications/useUpdateNotification'

const TopLine = styled(x.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: gray1;
  font-weight: 600;

  & > :not(:last-child) {
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid;
    border-color: lightGray6;
  }
`

const FlagButton = styled.div<{ $flag: boolean }>`
  color: ${({ $flag }) => ($flag ? th.color('red1') : th.color('gray1'))};
  cursor: pointer;
`

const Content = styled.span<{ $seen: boolean; $minSpacing: boolean; dangerouslySetInnerHTML: { __html?: string } }>`
  color: typoPrimary;
  font-weight: 400;
  padding-bottom: ${({ $minSpacing }) => ($minSpacing ? '0' : '12px')};
  padding-top: 12px;
  text-align: left;
  line-height: 18px;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
  flex: 1;
`

const RadioBtnWrap = styled.div`
  display: flex;
  position: relative;
  margin-right: 30px;
  &:hover {
    .hint {
      display: block;
    }
  }
`

const Hint = styled.div`
  display: none;
  position: absolute;
  font-size 10px;
  font-weight: medium;
  font-family: ${th('fonts.primary')};
  color: typoPrimary;
  border-radius: lg;
  text-align: center;
  background-color: white;
  width: 80px;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 12px);
  z-index: 1;
  padding: 2;
  box-shadow: tooltipBoxShadow;
`

const Wrapper = styled(x.div)<{
  $seen: boolean
  $minSpacing: boolean
}>`
  background-color: ${({ $seen }) => ($seen ? th.color('white') : th.color('bgLightGray'))};
  color: typoPrimary;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  flex-direction: row;
  text-align: center;
  margin-left: ${({ $minSpacing }) => ($minSpacing ? '0' : '24px')};
  margin-right: ${({ $minSpacing }) => ($minSpacing ? '0' : '24px')};
  margin-bottom: ${({ $minSpacing }) => ($minSpacing ? '12px' : '20px')};
  border: ${({ $seen }) => ($seen ? '1.5px solid' : '1px solid')};
  border-color: lightGray6;
  border-radius: 12px;

  :hover {
    color: typoPrimary;
    background-color: ${({ $seen }) => !$seen && th.color('bgLightGray1')};
    border-color: gray1;
  }

  @media (min-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
  }
`

type Props = {
  notification: Notification
  goToDetail: (notification: Notification) => void
  setNotificationFlags: SetNotificationFlags
  minSpacing?: boolean
}

const NotificationBody = ({ notification, setNotificationFlags, goToDetail, minSpacing }: Props): JSX.Element => {
  const { seen: seenOriginal, flag: flagOriginal } = notification
  const [seen, cacheSeen] = useState<boolean>(seenOriginal)
  const [flag, cacheFlag] = useState<boolean>(flagOriginal)
  const [ischecked, setIsChecked] = useState<boolean>(false)

  const markAsSeen = useCallback(() => {
    cacheSeen(!seen)
    setIsChecked(!ischecked)
    setNotificationFlags({ id: notification.id, seen: !seen, flag: notification.flag })
  }, [seen, ischecked, setNotificationFlags, notification])

  const toggleHasFlag = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setNotificationFlags({ id: notification.id, seen: notification.seen, flag: !flag })
      cacheFlag(!flag)
      e?.stopPropagation()
    },
    [flag, notification, setNotificationFlags],
  )

  const handleGoToDetail = useCallback(() => {
    cacheSeen(true)
    setNotificationFlags({ id: notification.id, seen: true, flag: notification.flag })
    setTimeout(() => {
      goToDetail(notification)
    }, 200)
  }, [notification, goToDetail, setNotificationFlags])

  const createdAtTxt = DateTime.fromISO(notification.createdAt).toFormat('dd. MM. yyyy HH:mm')

  return (
    <Wrapper $seen={seen} $minSpacing={minSpacing}>
      <RowContainer onClick={handleGoToDetail}>
        <TopLine>
          <x.span color="gray">{createdAtTxt}</x.span>
          <x.span color={seen ? 'gray1' : 'red1'}>
            {seen ? <Trans id="notifications.read" message="Read" /> : <Trans id="Notifications.notRead" message="Not read" />}
          </x.span>
          <FlagButton onClick={toggleHasFlag} $flag={flag}>
            <FaIcon icon="flag" />
          </FlagButton>
        </TopLine>
        <Content
          // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
          dangerouslySetInnerHTML={{ __html: notification?.content }}
          $seen={seen}
          $minSpacing={!!minSpacing}
        />
      </RowContainer>
      <RadioBtnWrap>
        <RadioButton onClick={markAsSeen} checked={ischecked} />
        <Hint className="hint">
          {seen ? (
            <Trans id="notifications.hint.unread" message="Mark as unread" />
          ) : (
            <Trans id="Notifications.hint.read" message="Mark as read" />
          )}
        </Hint>
      </RadioBtnWrap>
    </Wrapper>
  )
}

export default NotificationBody
