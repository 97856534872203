import { AxiosInstance } from 'axios'
import {
  ProductSyncAggregatedData,
  ProductSyncDetailData,
  ProductSynchronization,
  ProductSynchronizationDetail,
  ProductSyncRetryData,
} from '@typings/entities/ProductsSynchronization'
import { WithData } from '@typings/generic'
import { getBaselinkerRoutes } from '@services/BaselinkerApi/baselinkerRoutes'

export const SyncProductApi = (instance: AxiosInstance) => ({
  async productSyncAggregated(data: ProductSyncAggregatedData) {
    return instance.post<ProductSyncAggregatedData, WithData<ProductSynchronization[]>>(
      getBaselinkerRoutes().product.aggregated,
      data,
    )
  },
  async productSyncDetail(data: ProductSyncDetailData) {
    return instance.post<ProductSyncDetailData, WithData<ProductSynchronizationDetail[]>>(
      getBaselinkerRoutes().product.details,
      data,
    )
  },
  async productSyncRetry(data: ProductSyncRetryData) {
    return instance.post<ProductSyncRetryData>(getBaselinkerRoutes().product.retry, data)
  },
})
