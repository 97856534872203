import React, { useCallback, useState, useMemo } from 'react'
import { x } from '@xstyled/styled-components'
import Button from '@mailstep/design-system/ui/Elements/Button'
import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import { ProductApiRead } from '@typings/entities/Product'
import { Field } from 'formik'
import InputField from '@components/forms/components/InputField'
import Select from '@mailstep/design-system/ui/Elements/Select'
import { FileSubmit, OnDownloadTemplate } from '@typings/file'
import { useModal } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useModal'
import GridImportDialog from '@components/blocks/GridImportDialog'
import styled from '@xstyled/styled-components'
import ErrorMessage from '@mailstep/design-system/ui/Elements/ErrorMessage'
import { ImportResult, ImportResultMerged } from '@store/products/sagas'
import Alert from '@mailstep/design-system/ui/Elements/Alert'
import { ResponsiveRowWrap } from '@components/forms/styles'

interface Props<ItemType> {
  formatGridItem: (qty: number, product: ProductApiRead) => ItemType
  onAdd: (gridItems: ItemType | ItemType[]) => void
  loadProducts: (fulltext: string) => Promise<ProductApiRead[]>
  disabled?: boolean
  banLotProducts?: boolean
  onImportItems?: FileSubmit<{ product: ProductApiRead; quantity: number }[]>
  onDownloadImportTemplate?: OnDownloadTemplate
}

const getOptionValue = (option: ProductApiRead): string => option.id
const getOptionLabel = (option: ProductApiRead): string => `${option.name} (${option.internalSku})`

const ProductItemsWrap = styled.div`
  display: flex;
  & > :first-child {
    min-width: 200px;
    margin-right: 2;
  }
  & > :last-child {
    width: 8%;
    min-width: 80px;
  }
`

export const AddProductItem = <GridItemType,>({
  formatGridItem,
  onAdd,
  disabled,
  loadProducts,
  banLotProducts,
  onImportItems,
  onDownloadImportTemplate,
}: Props<GridItemType>): JSX.Element => {
  const [product, setProduct] = useState<ProductApiRead | null>(null)
  const [productPcs, setProductPcs] = useState(1)
  const [mergedItems, setMergedItems] = useState<ImportResultMerged>({})

  const setPcs = useCallback((event) => setProductPcs(Number(event.target.value)), [])

  const lotError = Boolean(banLotProducts && product && product.workAroundLot)

  const addItem = useCallback(() => {
    if (product) {
      const itemToAdd = formatGridItem(productPcs, product)
      onAdd(itemToAdd)
      setProduct(null)
      setProductPcs(1)
    }
  }, [formatGridItem, onAdd, product, productPcs])

  const { onOpen: onImportClick, isOpen: importFormVisible, onClose: hideImportForm } = useModal()

  const addImportedItems = useCallback(
    ({ products, merged }: ImportResult) => {
      const newItems = (products || []).map((item) => formatGridItem(item.quantity, item.product))
      onAdd(newItems)
      setMergedItems(merged)
    },
    [formatGridItem, onAdd],
  )

  const selectedOption = useMemo(() => (product ? [product] : undefined), [product])
  return (
    <x.div mt={3}>
      <ResponsiveRowWrap>
        <ProductItemsWrap>
          <Select
            name="productId"
            loadOptions={loadProducts}
            defaultOptions={selectedOption}
            onChange={setProduct}
            placeholder={t({
              id: 'form.items.productSearchPlaceholder',
              message: 'Fulltext product search: SKU / Ref. nr / Name',
            })}
            value={product?.id}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            disabled={disabled}
          />
          <Field
            name="productPcs"
            type="number"
            component={InputField}
            disabled={disabled}
            onChange={setPcs}
            min={1}
            value={productPcs}
            showArrowsController
            setNumber={setProductPcs}
          />
        </ProductItemsWrap>
        <Button onClick={addItem} type="button" disabled={disabled || !product || !productPcs || productPcs < 1 || lotError}>
          <Trans id="form.items.buttonAddProductItem" message="Add item" />
        </Button>
        {onImportItems && (
          <Button onClick={onImportClick} variant="outline" appearance="primary" type="button" disabled={disabled}>
            <Trans id="form.items.importButton" message="Import items" />
          </Button>
        )}
      </ResponsiveRowWrap>
      {Object.keys(mergedItems)?.length > 0 && (
        <x.div mt={3}>
          <Alert
            intent="warning"
            title={t({
              id: 'form.items.import.merged',
              message: 'All (total {count}x) products with same EAN have been merged.',
              values: { count: Object.keys(mergedItems).length },
            })}
          />
        </x.div>
      )}
      {lotError ? (
        <ErrorMessage>
          {/* this error message is form specific because it is only disabled on transfers temporarily and generally speaking never should be */}
          <Trans id="form.items.errorTransferItemLot.Add" message="Sorry, you can not transfer products with lot." />
        </ErrorMessage>
      ) : null}
      {importFormVisible && onImportItems && (
        <GridImportDialog<{ product: ProductApiRead; quantity: number }[]>
          title={<Trans id="form.items.importHeading" message="Items Import" />}
          onClose={hideImportForm}
          onImportSubmit={onImportItems}
          onTemplateDownloadSubmit={onDownloadImportTemplate}
          onImportSuccess={addImportedItems}
        />
      )}
    </x.div>
  )
}
