import React, { ReactNode } from 'react'
import { th } from '@xstyled/system'
import styled, { x } from '@xstyled/styled-components'

const Subtitle = styled.span`
  font-weight: 500;
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 3;
  margin-bottom: 3;
  border-bottom: 1px solid ${th('colors.white')};
`

type DialogProps = {
  subtitle: ReactNode
  children: ReactNode
  hideSeparator?: boolean
}

export const ActionDialogContentWrapper = ({ subtitle, children, hideSeparator }: DialogProps): JSX.Element => {
  return (
    <x.div>
      {subtitle && (
        <>
          <x.div mt={2}>
            <Subtitle>{subtitle}</Subtitle>
          </x.div>
          {!hideSeparator && <Separator />}
        </>
      )}
      <x.div>{children}</x.div>
    </x.div>
  )
}
