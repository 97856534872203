import React from 'react'
import get from 'lodash/fp/get'
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik'
import { ImportTemplateCommonForm } from './ImportTemplateCommonForm'
import { ExternalFormProps } from './types'

type Props = ExternalFormProps & {
  initialValues?: FormikValues
  onSubmit: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void | Promise<any>
}

const initialValuesEmpty = {}

const ImportTemplateCommonFormFormik = ({
  initialValues = initialValuesEmpty,
  fileOptions,
  onSubmit,
  ...rest
}: Props): JSX.Element => {
  initialValues.fileFormat = get('[0].value', fileOptions)
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(formikBag: FormikProps<FormikValues>): JSX.Element => (
        <ImportTemplateCommonForm fileOptions={fileOptions} {...formikBag} {...rest} />
      )}
    </Formik>
  )
}

export default ImportTemplateCommonFormFormik
