import React, { useCallback } from 'react'
import styled from '@xstyled/styled-components'
import { Field, FieldArray, Form, FormikProps } from 'formik'
import InputField from '@components/forms/components/InputField'
import SelectField from '@components/forms/components/SelectField'
import SwitchField from '@components/forms/components/SwitchField'
import CommonErrorField from '@components/forms/components/CommonErrorField'
import TextAreaField from '@components/forms/components/TextAreaField'
import RefNumbersField from '../ProductEditForm/components/RefNumbersField'
import { Trans } from '@lingui/react'
import SetItems from './components/SetItems'
import { Col } from '@designSystem/Grid'
import { ExternalFormProps } from './types'
import SetTabWrap from './components/SetTabWrap'
import { Ability, withAcl } from '@components/blocks/Acl'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'
import { Product, ProductType } from '@typings/entities/Product'
import { ResponsiveRowWrap, ResponsiveSectionWrap } from '../styles'

type Props = ExternalFormProps &
  FormikProps<Product> & {
    ability: Ability
    setType: React.Dispatch<React.SetStateAction<ProductType>>
  }

const ItemsWrap = styled.div`
  border-top: slim;
  border-bottom: slim;
  border-color: lightGray3;
  padding: 4 0 4 0;
  margin: 4 0 4 0;
`

const ProductSetForm = ({
  handleSubmit,
  isSubmitting,
  createView = false,
  productGroupOptions,
  createProductGroup,
  eshopOptions,
  loadProducts,
  setFieldValue,
  itemsGridSelectors,
  itemsGridActions,
  isSecondaryLoading,
  title,
  titleIcon,
  onCancel,
  ability,
  values,
  setType,
}: Props): JSX.Element => {
  const isAdminDisabled = !createView && !ability.can('adminEdit', 'Product')
  const isSupervisorDisabled = !createView && !ability.can('supervisorEdit', 'Product')

  return (
    <Modal
      title={title}
      titleIcon={titleIcon}
      onCancel={onCancel}
      cancelLabel={<Trans id="form.buttonCancel" message="Cancel" />}
      hasFooter={false}
      width="wide"
      isLoading={isSubmitting}
    >
      <SetTabWrap
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        setType={setType}
        type={values.type}
        setFieldValue={setFieldValue}
      >
        <Form onSubmit={handleSubmit}>
          <CommonErrorField />
          <ResponsiveSectionWrap>
            <Col mr={3}>
              <ResponsiveRowWrap>
                <Field
                  component={InputField}
                  label={<Trans id="form.productName.label" message="Name" />}
                  name="name"
                  spaceAround
                />
              </ResponsiveRowWrap>
              <ResponsiveRowWrap>
                <Field
                  component={SelectField}
                  label={<Trans id="form.eshops.label" message="Eshops" />}
                  name="eshops"
                  options={eshopOptions}
                  isMulti
                  spaceAround
                  isSearchable={false}
                  isLoading={isSecondaryLoading}
                />
              </ResponsiveRowWrap>
              <ResponsiveRowWrap>
                <Field
                  component={TextAreaField}
                  label={<Trans id="form.description.label" message="Description" />}
                  name="description"
                  spaceAround
                />
              </ResponsiveRowWrap>
            </Col>
            <Col>
              <ResponsiveRowWrap>
                <Field
                  component={InputField}
                  label={<Trans id="form.productSku.label" message="Product SKU" />}
                  name="productSku"
                  spaceAround
                  disabled={isSupervisorDisabled}
                />
                <Field
                  component={InputField}
                  label={<Trans id="form.internalSku.label" message="Internal SKU" />}
                  name="internalSku"
                  spaceAround
                  disabled={isSupervisorDisabled}
                />
              </ResponsiveRowWrap>
              <ResponsiveRowWrap>
                <FieldArray
                  name="referenceNumbers"
                  render={useCallback(
                    (props) => (
                      <RefNumbersField {...props} t disabled={isAdminDisabled} />
                    ),
                    [isAdminDisabled],
                  )}
                />
              </ResponsiveRowWrap>
              <ResponsiveRowWrap>
                <Field
                  component={SelectField}
                  onCreateOption={createProductGroup}
                  options={productGroupOptions}
                  label={<Trans id="form.productGroup.label" message="Product group" />}
                  name="productGroup"
                  isClearable
                  spaceAround
                  isLoading={isSecondaryLoading}
                />
                <Field
                  component={SwitchField}
                  label={<Trans id="form.active.label" message="Active" />}
                  name="active"
                  disabled={createView}
                  labelPosition="left"
                  variant="primary"
                />
              </ResponsiveRowWrap>
            </Col>
          </ResponsiveSectionWrap>
          {values.type == 'bundle' && (
            <ItemsWrap>
              <SetItems
                isEditable
                loadProducts={loadProducts}
                itemsGridSelectors={itemsGridSelectors}
                itemsGridActions={itemsGridActions}
                setFieldValue={setFieldValue}
              />
            </ItemsWrap>
          )}
        </Form>
      </SetTabWrap>
    </Modal>
  )
}

export default withAcl(ProductSetForm)
