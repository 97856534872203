/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useCallback, useEffect } from 'react'
import { Settings, GridSelectorsType, GridActionsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { useGridStore } from '@hooks/useCommonGrid'
import { useLongListFetchModule } from '@utils/containers/useFetchModule'
import buildCriteria from '@utils/queries/buildCriteria'
import buildSorting from '@utils/queries/buildSorting'
import useShallowRouting from '@components/blocks/CommonGrid/useShallowRouting'

type ReturnProps<P> = {
  data: P[]
  gridIsLoading: boolean
  error: any
  allRowsCount: number
  reloadData: (keepPage?: boolean) => void
  fullFilters: any
  gridSelectors: GridSelectorsType
  gridActions: GridActionsType
}

export type FiltersSkipTest = (filters: ReturnProps<any>['fullFilters']) => boolean

type Config = {
  gridName: string
  skip?: boolean | FiltersSkipTest
  defaultSorting?: Settings['sorting']
  containerFilters?: any
  storeModule: any
  extra?: Record<string, unknown>
}

const usePageGridLoading = <ENT extends object>(config: Config): ReturnProps<ENT> => {
  const { gridName, skip, defaultSorting, containerFilters, storeModule, extra } = config
  const { gridActions, gridSelectors } = useGridStore(gridName)

  const sorting = useMemo(() => buildSorting(gridSelectors.sorting), [gridSelectors.sorting])

  // calculate/build full filter criteria
  const fullFilters = useMemo(
    () => ({
      ...(containerFilters || {}),
      ...buildCriteria(gridSelectors.filter || {}),
    }),
    [JSON.stringify(containerFilters), JSON.stringify(gridSelectors.filter)],
  )

  // audits ip filter validation hack.
  const containsInvalidFilter = useMemo(() => {
    const filters = gridSelectors.filter || {}
    return !!Object.values(filters).find((value) => value?.value?.isInvalid)
  }, [gridSelectors.filter || {}])

  const skipLoading = useMemo<boolean>(
    () => (typeof skip == 'function' ? skip(fullFilters) : skip) || containsInvalidFilter || false,
    [containsInvalidFilter, fullFilters, skip],
  )

  const {
    data,
    isLoading,
    error,
    itemsTotal: allRowsCount,
    loadedPage,
    loadPage,
  } = useLongListFetchModule<ENT>({
    storeModule,
    criteria: fullFilters,
    rowsPerPage: gridSelectors.rowsPerPage || 10,
    sorting: sorting || null,
    skip: skipLoading,
    extra,
  })

  const { replaceShallowPath, rowsPerPageValue } = useShallowRouting(gridActions, gridSelectors)

  // init shit
  useEffect(() => {
    // todo revert changes here, this is hack so that complaint grid page memory works
    defaultSorting && gridActions.init?.({ defaultSorting })
  }, [])

  // "page synchronization" after page reset (ie after change of sorting)
  useEffect(() => {
    replaceShallowPath(loadedPage, rowsPerPageValue)
    if (gridSelectors?.page != loadedPage) gridActions.setPage?.(loadedPage)
  }, [loadedPage])

  // change of page in grid
  useEffect(() => {
    loadPage(gridSelectors?.page)
  }, [gridSelectors?.page])

  const reloadData = useCallback(
    (keepPage?: boolean) => {
      keepPage ? loadPage(loadedPage) : loadPage()
    },
    [loadPage, loadedPage],
  )

  return {
    data,
    gridIsLoading: isLoading,
    error,
    allRowsCount,
    reloadData,
    fullFilters,
    gridActions,
    gridSelectors,
  }
}

export default usePageGridLoading
