import { DateTime } from 'luxon'
import { DatePattern, DatePatternWithTime } from '@components/forms/utils/RegexValidation'

export const validateFutureDate = (value?: string | null, resetToMidnight = false): boolean => {
  if (!value) {
    return true
  }

  let date
  const today = new Date()

  switch (true) {
    case DatePatternWithTime.test(value):
      date = DateTime.fromFormat(value, 'dd.MM.yyyy HH:mm').toJSDate()
      break
    case DatePattern.test(value):
      date = DateTime.fromFormat(value, 'dd.MM.yyyy').toJSDate()
      break
    default:
      date = new Date(value)
  }

  if (resetToMidnight) {
    today.setHours(0, 0, 0, 0)
  }

  return date >= today
}
