import React, { useEffect, useMemo } from 'react'
import { ProductStockApi } from '@typings/entities/ProductStock'
import { ExpeditionDetailItem, ExpeditionDetailGridItem, ExpeditionDetail } from '@typings/entities/Expedition'
import { isMetaLot } from '@utils/expedition'
import reduce from 'lodash/reduce'

type ReturnProps = {
  allItems: ExpeditionDetailGridItem[]
  quantitiesAreLoading: boolean
}

export const useItemsQty = (
  items: ExpeditionDetailItem[],
  warehouseId: string | undefined,
  wmsId: string | undefined,
  bookedData: ExpeditionDetail['bookedData'] | undefined,
  setFieldValue: Function,
  loadProductStocks: (productIds: string[], warehouse: string, wms: string) => Promise<any>,
  isEditable: boolean | undefined,
): ReturnProps => {
  const [productStocks, setProductStocks] = React.useState<ProductStockApi[]>([])
  const [productStocksIsLoading, setProductLoading] = React.useState<boolean>(false)

  /**
   * This block is responsible for keeping productStock value loaded
   */

  const [loadedProductIds, setLoadedProductId] = React.useState<string[]>([])
  const productIds: string[] = (items || []).map((item) => item.product?.id).filter(Boolean)
  const asyncLoadCall = async (loadIds: string[], merge: boolean): Promise<void> => {
    setProductLoading(true)
    if (!merge) setLoadedProductId([])
    const stocks = await loadProductStocks(loadIds, warehouseId, wmsId)
    setProductStocks(merge ? [...productStocks, ...stocks] : stocks)
    setLoadedProductId(merge ? [...loadedProductIds, ...loadIds] : loadIds)
    setProductLoading(false)
  }
  useEffect(() => {
    if (!warehouseId || !wmsId || !productIds.length) return
    asyncLoadCall(productIds, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, wmsId])
  useEffect(() => {
    if (!warehouseId || !wmsId || !productIds.length) return
    const missingProductIds = productIds.filter((id) => !loadedProductIds.includes(id))
    if (missingProductIds.length) {
      asyncLoadCall(missingProductIds, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIds.length])

  /**
   * Rest is all responsible for calculation stock related items data
   */

  let needItemsUpdate = false

  const allItems = useMemo(() => {
    return (items || []).map((item, index) => {
      const productStockIsLoading = !loadedProductIds.includes(item.product.id)
      // stock is result form productStock endpoint. We are interested in availible amounts (for both specific lots and total)
      const foundStock = productStocks.find((stock) => stock.product.id === item.product.id)
      const availableAllLots = foundStock?.available || 0
      // amounts booked  by this expedition
      const foundBookedData = bookedData?.items?.[item.product.id] || {}
      const bookedAllLots = reduce(foundBookedData, (sum, n) => sum + n, 0)

      let stocksState: ExpeditionDetailGridItem['stocksState'] = 'ready'
      if (productStockIsLoading) {
        stocksState = 'loading'
      } else if (!warehouseId || !wmsId) {
        stocksState = 'notReady'
      }

      let availableStock
      let booked
      let forceLotReset = false
      if (!item.product.workAroundLot) {
        // product has not lots allowed
        availableStock = availableAllLots
        booked = bookedAllLots
      } else if (!item.lot) {
        // Product has lots allowed but lot input empty - SHOULD NOT HAPPEN, this is just precaution
        availableStock = availableAllLots
        booked = 0
      } else if (isMetaLot(item.lot)) {
        // Product has lots allowed but item has meta lot selected
        availableStock = availableAllLots
        booked = foundBookedData[item.lot] || 0
      } else {
        // Product has lots allowed and item has lot set
        const foundLotStock = foundStock?.lots.find(({ lot }) => lot == item.lot)
        availableStock = foundLotStock?.available || 0
        booked = foundBookedData[item.lot] || 0
        // when lot does not exist, reset lot to default. This MUST NOT trigger on expedition no longer editable
        if (!foundLotStock && isEditable && !productStockIsLoading) {
          forceLotReset = true
        }
      }

      const availableTotal = availableStock + booked

      // stockLots = available lot options for use to choose form
      let stockLots: ExpeditionDetailGridItem['stockLots']
      if (!item.product.workAroundLot || !isEditable) {
        stockLots = []
      } else {
        stockLots = foundStock?.lots || []
      }

      let reservationStatus: ExpeditionDetailGridItem['reservationStatus'] = 'notReserved'
      if (booked >= item.quantity) {
        reservationStatus = 'reserved'
      } else if (booked + item.bookedAdviceTotal >= item.quantity) {
        reservationStatus = 'paired'
      } else if (booked + item.bookedAdviceTotal >= 1) {
        reservationStatus = 'partially'
      }

      // book amount automatic correction
      let book = item.book
      if (book && item.quantity < book) {
        book = item.quantity
      }

      // changes that need to be propagated outside of items Field back into from (because of form submitting and/or validation)
      if (forceLotReset || stocksState !== item.stocksState || availableTotal != item.availableTotal || book != item.book) {
        needItemsUpdate = true
      }

      return {
        ...item,
        index,
        availableStock,
        book,
        booked,
        stockLots,
        reservationStatus,
        availableTotal,
        stocksState,
        lot: forceLotReset ? '__oldest' : item.lot,
      }
    })
  }, [items, productStocks, loadedProductIds])

  // update values in expedition entire form if we need to
  if (needItemsUpdate) {
    setFieldValue('items', allItems, false)
  }

  return { allItems, quantitiesAreLoading: productStocksIsLoading }
}
