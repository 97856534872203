import gql from 'graphql-tag'

const usersGetQuery = gql`
  query usersGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object, $nested: boolean) {
    usersGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: $nested })
      @rest(type: "UsersResult", method: "POST", path: "user/list") {
      results
      paging
    }
  }
`

export default usersGetQuery
