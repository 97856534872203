import { useState } from 'react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '@store/analytics'

export const useCookieBanner = ({ onClose }: { onClose: () => void }) => {
  const [isChecked, setIsChecked] = useState<boolean>(true)
  const dispatch = useDispatch()

  const handleCancel = useCallback(() => {
    onClose()
    window.localStorage.setItem('isCookieBannerHidden', 'true')
  }, [onClose])

  const handleConfirm = useCallback(() => {
    if (isChecked) {
      dispatch(actions.allowedStatisticalCookies())
    }
    handleCancel()
  }, [handleCancel, dispatch, isChecked])

  const handleToggle = useCallback(() => {
    setIsChecked(!isChecked)
  }, [isChecked])

  return { isChecked, handleToggle, handleConfirm, handleCancel }
}
