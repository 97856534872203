import { Schema } from 'yup'

export type RouteDefinition = {
  key: string
  path: string | string[]
  toUrl: (params?: object, ...args: any[]) => string
}

type SchemaObject = {
  [key: string]: Schema<any>
}

const replacePathParams = <T>(
  path: string,
  givenParams: { [key in keyof SchemaObject]: any },
  pathParams: SchemaObject | object | null | undefined = {},
): string => {
  let formattedPath = path
  for (const pathParamName in pathParams) {
    const pathParamValidation: Schema<T> = pathParams[pathParamName as keyof typeof pathParams]
    const value = givenParams[pathParamName as keyof typeof pathParams]
    if (pathParamValidation) {
      if (typeof pathParamValidation.validateSync !== 'function') {
        throw new Error('Param validation must be yup schema.')
      }
      try {
        pathParamValidation.validateSync(value)
      } catch (e) {
        throw new Error(`Error while validating param ${pathParamName} in path ${path}: ${(e as Error).message}`)
      }
    }
    const valueToReplace = typeof value !== 'undefined' ? value : ''
    formattedPath = formattedPath.replace(`:${pathParamName}`, valueToReplace)
  }
  return formattedPath
}

const createRoute = (
  path: string | string[],
  pathParams: object | null | undefined = {},
  options?: {
    choosePath?: (path: string | string[], pathParams?: object, ...args: any[]) => string
  },
): RouteDefinition => ({
  key: Array.isArray(path) ? path[0] : path,
  path,
  toUrl: (params: object = {}, ...args: []): string => {
    let formattedPath = options?.choosePath ? options?.choosePath(path, params, ...args) : path
    if (Array.isArray(formattedPath))
      throw new Error(
        `A path after choosing must be a string, ${typeof formattedPath} given. Probably options.choosePath did not return one path (${JSON.stringify(
          formattedPath,
        )}). `,
      )
    formattedPath = replacePathParams(formattedPath, params, pathParams)
    return formattedPath
  },
})

export default createRoute
