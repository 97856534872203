import React, { useCallback, useMemo } from 'react'
import LoadingOverlay from '@components/blocks/LoadingOverlay'
import ProductEditForm from '@components/forms/ProductEditForm'
import { Product, ProductApiWrite, ProductType } from '@typings/entities/Product'
import { EshopApi } from '@typings/entities/Eshop'
import { formatValuesToWrite, onOpenLightbox, onCreateProductGroup } from './utils'
import { actions as productsActions } from '@store/products'
import * as productsStore from '@store/products'
import { useDetailFetchModule, useFetchModuleSelector, useListFetchModule } from '@utils/containers/useFetchModule'
import { useDispatch, useSelector } from 'react-redux'
import { useGridStore } from '@hooks/useCommonGrid'
import { Country } from '@typings/entities/Country'
import getCountryOptions from '@utils/getCountryOptions'
import * as countriesStore from '@store/countries'
import * as eshopsStore from '@store/eshops'
import * as productStocksStore from '@store/productStocks'
import { useOnSubmit } from '@utils/containers/modalForm'
import { selectors as organisationSelectors } from '@store/organisation'
import toSelectOption from '@utils/toSelectOption'
import { ProductStock } from '@typings/entities/ProductStock'
import getEshopOptions from '@utils/getEshopOptions'
import { FormikHandleSubmit } from '@typings/Formik'
import { useApolloClient } from '@apollo/client'
import { useLoadProductGroupList } from '@hooks/apiHooks/productGroups/useLoadProductGroupList'
import { useProductItems } from '@utils/containers/productItems'
import fetchFileAuthorized from '@utils/fetchFileAuthorized'

type Props = {
  id: string | undefined
  onSuccess: () => void
  onCancel: () => void // passed to dialog
  createTitle: string | JSX.Element
  editTitle: string | JSX.Element
  createdProductType?: ProductType
}

const Container = ({ id, onSuccess, onCancel, createTitle, editTitle, createdProductType }: Props): JSX.Element => {
  const { data, isLoading, error } = useDetailFetchModule<Product>(productsStore, id, { submodule: 'detail' })
  const isCreatingNew = !id
  const isLotEvidenceEditable = data?.canToggleLotEvidence

  const selectedOrganisation = useSelector(organisationSelectors.organisation)
  const usedOrganisation = isCreatingNew ? selectedOrganisation : data && data?.organisation

  const { data: countries, isLoading: countriesIsLoading } = useFetchModuleSelector<Country[]>(countriesStore, 'loadList') // loaded automatically by org
  const countryOptions = useMemo(() => getCountryOptions(countries || []), [countries])

  const { data: productGroups, isLoading: productGroupsIsLoading, reload } = useLoadProductGroupList(usedOrganisation)
  const productGroupOptions = useMemo(() => (productGroups || []).map(toSelectOption('id', 'name')), [productGroups])

  // TODO optimize for ui when possible
  const { data: eshops, isLoading: eshopsIsLoading } = useListFetchModule<EshopApi>(
    eshopsStore,
    { organisation: usedOrganisation },
    !usedOrganisation,
  )
  const eshopOptions = useMemo(() => getEshopOptions(eshops), [eshops])

  const { data: productStocks, isLoading: productStocksIsLoading } = useListFetchModule<ProductStock>(
    productStocksStore,
    {
      product: { eq: id },
      OR: [{ quantity: { gt: 0 } }, { missing: { gt: 0 } }, { incoming: { gt: 0 } }],
    },
    isCreatingNew,
  )

  const dispatch = useDispatch()
  const onSubmit = useOnSubmit<ProductApiWrite>(dispatch, onSuccess, productsActions.create, productsActions.edit)
  const handleSubmit: FormikHandleSubmit<Product> = useCallback(
    (values, formikHelpers) => {
      return onSubmit(formatValuesToWrite({ ...values }), formikHelpers) // TODO figure out typing of type change here
    },
    [onSubmit],
  )

  const initialValues = useMemo(
    () =>
      isCreatingNew
        ? {
            active: true,
            eshops: [],
            organisation: selectedOrganisation,
            type: createdProductType || 'physical',
          }
        : data || {},
    [createdProductType, data, isCreatingNew, selectedOrganisation],
  )
  const client = useApolloClient()

  const createProductGroup = useMemo(
    () => onCreateProductGroup(client, usedOrganisation, reload),
    [client, usedOrganisation, reload],
  )
  const openLightbox = useMemo(() => onOpenLightbox(dispatch), [dispatch])

  const isSecondaryLoading = eshopsIsLoading || countriesIsLoading || productGroupsIsLoading

  const { gridActions: itemsGridActions, gridSelectors: itemsGridSelectors } = useGridStore('productSetItems')
  const { loadProducts } = useProductItems(usedOrganisation)

  return (
    <LoadingOverlay isLoading={isLoading || productStocksIsLoading || isSecondaryLoading} error={error}>
      <ProductEditForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        title={isCreatingNew ? createTitle : editTitle}
        titleIcon={isCreatingNew ? 'plus' : 'edit'}
        countryOptions={countryOptions}
        eshopOptions={eshopOptions}
        productGroupOptions={productGroupOptions}
        createProductGroup={createProductGroup}
        productStocks={productStocks}
        itemsGridActions={itemsGridActions}
        itemsGridSelectors={itemsGridSelectors}
        onDownloadAttachment={fetchFileAuthorized}
        createView={isCreatingNew} // todo unify with other forms
        loadProducts={loadProducts}
        openLightbox={openLightbox}
        isSecondaryLoading={isSecondaryLoading}
        isLotEvidenceEditable={isLotEvidenceEditable}
      />
    </LoadingOverlay>
  )
}

export default Container
