import React, { useMemo } from 'react'
import { LightBoxActionType, LightBoxType } from '@typings/UI'
import Image from '@mailstep/design-system/ui/Elements/Image'
import styled from '@xstyled/styled-components'
import FaIcon from '@mailstep/design-system/ui/Elements/Icon'
import { Trans } from '@lingui/react'
import { DateTime } from 'luxon'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'

type LightBoxProps = LightBoxActionType & LightBoxType

const DialogWrap = styled.div`
  margin: 100px auto 0 auto; /* Location of the box */
  width: fit-content;
  background-color: white;
  border-radius: lg;
  padding-bottom: 68px;
  max-width: 80%;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80px;
  align-items: center;
`

const Body = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CloseIcon = styled.div`
  cursor: pointer;
  font-size: 14px;
  padding: 4px;
  color: lightGray3;
  :hover {
    color: blue2;
  }
`

const SideIcon = styled.div`
  cursor: pointer; // todo add FaIcon
  margin: 0 24px;
  padding: 4px;
  font-size: 14px;
  color: red1;
  :hover {
    color: blue2;
  }
`

const DateLbl = styled.span``

// we dont assume many images on product, so we just preload all
const Preloader = styled.div<{
  $isVisible: boolean
}>`
  display: ${(props: any): string => (props.$isVisible ? 'inherit' : 'none')};
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 10px;
  color: typoPrimary;
  font-weight: bold;
  ${DateLbl} {
    color: gray;
    font-weight: semiBold;
  }
`

const StyledImage = styled(Image)`
  max-width: 100%;
`

const LightBox = ({ isOpen, photoIndex, images, onClose, onMovePrev, onMoveNext }: LightBoxProps): JSX.Element | null => {
  const currentImage = useMemo(() => images && images[photoIndex], [images, photoIndex])
  const createdAtReadable = useMemo(
    () => currentImage && currentImage.createdAt && DateTime.fromISO(currentImage.createdAt).toFormat('dd. MM. yyyy'),
    [currentImage],
  )

  if (!isOpen || !currentImage) return null
  return (
    <Modal
      onClose={onClose}
      isShown={isOpen}
      width="auto"
      title={
        <Header>
          <Title>
            <span>{currentImage.originalName}</span>
            <DateLbl>
              <Trans id="file.uploaded" message="Uploaded" /> {createdAtReadable}
            </DateLbl>
          </Title>
        </Header>
      }
    >
      <Body>
        <SideIcon onClick={onMovePrev}>
          <FaIcon icon="goLeft" />
        </SideIcon>
        {images.map((image, index) => (
          <Preloader key={index} $isVisible={index == photoIndex}>
            <StyledImage src={image.urlLarge} />
          </Preloader>
        ))}
        <SideIcon onClick={onMoveNext}>
          <FaIcon icon="goRight" />
        </SideIcon>
      </Body>
    </Modal>
  )
}

export default LightBox
