import reduceReducers from 'reduce-reducers'
import createFetchModule from '@utils/store/createFetchModule'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'
import { FetchActionsWith } from '@typings/generic'

export const moduleName = 'stockMovementsGrid'

const fetchModule = createFetchModule('@store/StockMovementsGrid', 'LOAD', moduleName)
const fetchFiltersSubmodule = createFetchSubmodule('@store/StockMovementsGrid', 'FILTERS', moduleName)
const exportSubmodule = createFetchSubmodule('@store/StockMovementsGrid', 'EXPORT', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  export: exportSubmodule.actionTypes,
  stockMovementFilters: fetchFiltersSubmodule.actionTypes,
}

export default reduceReducers(fetchModule.reducer, fetchFiltersSubmodule.reducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
  export: exportSubmodule.actions.run,
  stockMovementFilters: fetchFiltersSubmodule.actions.run,
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
  ...fetchFiltersSubmodule.selectors,
  // ... ownSelectors
}
