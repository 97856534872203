import React, { useEffect, useState, useCallback } from 'react'

type PrintingProviderProps = {
  children: JSX.Element
}

export const PrintingContext = React.createContext<boolean>(false)

export const Provider = ({ children }: PrintingProviderProps): JSX.Element => {
  const [isPrinting, setIsPrinting] = useState(false)

  const customBeforePrintListener = useCallback(() => {
    setIsPrinting(true)
    setTimeout(window.print, 0)
  }, [])

  const beforePrintListener = useCallback(() => {
    setIsPrinting(true)
  }, [])

  const afterPrintListener = useCallback(() => {
    setIsPrinting(false)
  }, [])

  useEffect(() => {
    window.addEventListener('custombeforeprint', customBeforePrintListener)
    window.addEventListener('beforeprint', beforePrintListener)
    window.addEventListener('afterprint', afterPrintListener)

    return (): void => {
      window.removeEventListener('custombeforeprint', customBeforePrintListener)
      window.removeEventListener('beforeprint', beforePrintListener)
      window.removeEventListener('afterprint', afterPrintListener)
    }
  }, [customBeforePrintListener, beforePrintListener, afterPrintListener])

  return <PrintingContext.Provider value={isPrinting}>{children}</PrintingContext.Provider>
}
