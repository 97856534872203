import reduceReducers from 'reduce-reducers'
import createFetchModule from '@utils/store/createFetchModule'
import { FetchActionsWith } from '@typings/generic'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'

export const moduleName = 'carriers'

const fetchModule = createFetchModule('@store/Carriers', 'LOAD', moduleName)
const loadList = createFetchSubmodule('@store/Carriers', 'LOAD_LIST', moduleName)
const setCarriers = createFetchSubmodule('@store/Carriers', 'SET_CARRIERS', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  loadList: loadList.actionTypes,
  setCarriers: setCarriers.actionTypes,
}

export default reduceReducers(fetchModule.reducer, loadList.reducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
  loadList: loadList.actions.run,
  setCarriers: setCarriers.actions.run,
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
  ...loadList.selectors,
}
