// if we have prefix capitalize first letter of suffix and join
export const camelJoin = (prefix: string | undefined, suffix: string): string => {
  if (!prefix) return suffix
  return prefix + suffix.charAt(0).toUpperCase() + suffix.slice(1)
}

export const trimValue = (value: string | number): string | number => {
  if (typeof value === 'string') return value.trimStart()
  else return value
}
