import siteMap from '@constants/sitemap'

export type MenuVariant = 'Admin' | 'User' | undefined

export const getSettingsMenuItems = (type: MenuVariant, eshopId?: string) => {
  const siteMaps = siteMap({ type, eshopId })
  return [
    {
      ...siteMaps.profile,
      items: [siteMaps.personalSettings, siteMaps.security, siteMaps.menuSettings],
    },
    siteMaps.eshops,
    siteMaps.suppliers,
    siteMaps.partners,
    ...(type === 'Admin' ? [siteMaps.users, siteMaps.organisations] : [siteMaps.carriers]),
    {
      ...siteMaps.integrations,
      items: [
        {
          ...siteMaps.baselinker,
          subItems: [
            siteMaps.configuration,
            siteMaps.synchronization,
            siteMaps.warehouses,
            siteMaps.expeditionStatuses,
            siteMaps.baselinkerCarriers,
          ],
        },
        siteMaps.webhooks,
        siteMaps.byznys,
      ],
    },
  ]
}
