import { useQuery } from '@apollo/client'
import warehousesGetQuery from '@queries/warehousesGetQuery'
import { ApiHookResultWithReload } from '@typings/ApiHooks'
import { WarehouseNested } from '@typings/entities/Warehouse'

export const useLoadWarehousesList = (
  organisation?: string | null,
  eshop?: string,
): ApiHookResultWithReload<WarehouseNested[]> => {
  const criteria = eshop ? { eshops: { eq: eshop } } : { organisation: { eq: organisation } }
  const {
    data,
    error,
    loading: isLoading,
    refetch: reload,
  } = useQuery(warehousesGetQuery, {
    variables: {
      criteria,
    },
    skip: !organisation && !eshop,
    fetchPolicy: 'no-cache',
  })

  return {
    data: data?.warehousesGet?.results,
    error,
    isLoading,
    reload,
  }
}
