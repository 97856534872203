import React, { Consumer } from 'react'
import { createContextualCan, CanProps } from '@casl/react'
import AbilityContext from './Context'
import { AnyAbility, SubjectType } from '@casl/ability'

const ContextualCan = createContextualCan(AbilityContext.Consumer as Consumer<AnyAbility>)

type Props = CanProps<any> & {
  extra: any
  // forcing to use a parameter due to incosistent types definition coming from @casl
  a: SubjectType
}

export const Can = (props: Props): JSX.Element => {
  const a = props.extra ? [props.a, props.extra] : props.a
  return <ContextualCan {...props} a={a} />
}
