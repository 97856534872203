import React, { useCallback } from 'react'
import { QuickAccessDialogs as QuickAccessDialogsType } from '@typings/UI'
import Dialog from './Dialog'

type Props = {
  dialogs: QuickAccessDialogsType
  setDialogs: (dialogs: QuickAccessDialogsType) => void
}

const QuickAccessDialogs = ({ dialogs, setDialogs }: Props): JSX.Element | null => {
  // !! Callback only edits top dialog. - We assume that only top/last dialog is visible and only its controls are accessible. TODO fix, just in case...
  const closeDialog = useCallback(() => {
    if (dialogs?.length && dialogs?.length > 1) {
      setDialogs(dialogs.slice(0, -1))
    } else {
      setDialogs(null)
    }
  }, [dialogs, setDialogs])

  if (!dialogs || !dialogs.length) return null
  return (
    <>
      {dialogs.map((quickAccessDialog, i) => (
        <Dialog key={i} openedDialog={quickAccessDialog} closeDialog={closeDialog} />
      ))}
    </>
  )
}

export default QuickAccessDialogs
