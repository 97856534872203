import React, { useCallback } from 'react'
import languageItems from '@constants/LanguageItems'
import styled from '@xstyled/styled-components'
import DropdownMenu from '@mailstep/design-system/ui/Elements/DropdownMenu'
import { LanguageItem } from './types'
import { useClickOutside } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useClickOutside'

const StyledFlag = styled.div`
  width: auto;
  cursor: pointer;
`

const StyledDropdownMenu = styled(DropdownMenu)`
  position: absolute;
  top: 30;
  right: -5px;
  width: max-content;
  @media (min-width: 1024px) {
    left: -5px;
  }
  & > ul > a > * {
    display: flex;
    align-items: center;
  }

  :before {
    content: '';
    top: -1px;
    right: 15px;
    position: absolute;
    height: 10px;
    width: 10px;
    background: red;
    -webkit-transform: rotate(45deg) translate(-7px);
    -ms-transform: rotate(45deg) translate(-7px);
    transform: rotate(45deg) translate(-7px);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    @media (min-width: 1024px) {
      left: 15px;
    }
  }
`

const LanguageSwitchWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const DownImg = styled.img`
  margin-left: 15px;
  display: inline-block;
  width: 8px;
  height: 8px;
`

const LanguageFlagWrap = styled.div`
  cursor: pointer;
  position: relative;
  display: grid;
  grid-template-columns: 20px auto;
  align-items: center;
`

type Props = {
  activeLanguage: string
  onChange?: Function
}

export const LanguageSwitch = ({ activeLanguage, onChange }: Props): JSX.Element | null => {
  const [flagDropdownVisible, setFlagDropdownVisible] = React.useState(false)

  const onClose = useCallback(() => {
    setFlagDropdownVisible(false)
  }, [])
  const flagMenuRef = useClickOutside({ onClose })
  const handleFlagClick = React.useCallback(() => {
    setFlagDropdownVisible(!flagDropdownVisible)
  }, [flagDropdownVisible])

  const activeLanguageObj: LanguageItem | undefined = languageItems.find((language) => language.languageCode === activeLanguage)
  const filteredLanguages: LanguageItem[] = languageItems.filter((language) => language.languageCode !== activeLanguage)
  const languageToggle = useCallback(
    (languageCode: string) => (): void => {
      onChange && onChange(languageCode)
    },
    [onChange],
  )
  const formattedLanguages = filteredLanguages.map((language) => ({
    title: language.title,
    icon: language.icon,
    link: language.link,
    onClick: languageToggle(language.languageCode),
  }))

  if (formattedLanguages && activeLanguageObj) {
    return (
      <LanguageSwitchWrap ref={flagMenuRef}>
        <LanguageFlagWrap onClick={handleFlagClick}>
          <StyledFlag className="ignore-element-for-closing-hook">{activeLanguageObj.icon}</StyledFlag>
          <DownImg
            width="10"
            height="10"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMCAxMCI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogI2YwZjsKICAgICAgICBvcGFjaXR5OiAwOwogICAgICB9CgogICAgICAuY2xzLTIgewogICAgICAgIGZpbGw6ICM3MDcwNzA7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIGlkPSJDaGV2cm9uIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4KSByb3RhdGUoOTApIj4KICAgIDxyZWN0IGlkPSJGcmFtZSIgY2xhc3M9ImNscy0xIiB3aWR0aD0iMTAiIGhlaWdodD0iNiIvPgogICAgPHBhdGggaWQ9IlNoYXBlIiBjbGFzcz0iY2xzLTIiIGQ9Ik0xMCwxQTEsMSwwLDAsMCw4LjI5My4yOTNMNSwzLjU4NiwxLjcwNy4yOTNBMSwxLDAsMSwwLC4yOTMsMS43MDdsNCw0YTEsMSwwLDAsMCwxLjQxNSwwbDQtNEExLDEsMCwwLDAsMTAsMVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIgOCkgcm90YXRlKC05MCkiLz4KICA8L2c+Cjwvc3ZnPgo="
          />
        </LanguageFlagWrap>
        {/* {activeLanguageObj.title} TODO delete if not used on login page*/}
        <StyledDropdownMenu showMenu={flagDropdownVisible} items={formattedLanguages} />
      </LanguageSwitchWrap>
    )
  } else return null
}
