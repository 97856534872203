import React from 'react'
import { x } from '@xstyled/styled-components'
import { ExchangeRate } from '@typings/entities/ExchangeRate'
import { FormGrid } from '@components/blocks/FormGrid'
import createColumnDefinitions from './tableDefinition'
import { useGridStore, GridNames } from '@hooks/useCommonGrid'

type Props = {
  isLoading?: boolean
  exchangeRates: ExchangeRate[]
}

const ExchangeRates = ({ isLoading, exchangeRates }: Props): JSX.Element => {
  const { gridActions, gridSelectors } = useGridStore(GridNames.ExchangeRates)

  return (
    <x.div mb={4} mt={4}>
      <FormGrid
        minHeightRows={2}
        isLoading={isLoading}
        items={exchangeRates}
        columnsDefinitions={createColumnDefinitions(exchangeRates)}
        columnLayout="normal"
        gridActions={gridActions}
        gridSelectors={gridSelectors}
        quickFilter="currency"
      />
    </x.div>
  )
}

export default ExchangeRates
