import reduceReducers from 'reduce-reducers'
import createFetchModule from '@utils/store/createFetchModule'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'
import { FetchActionsWith } from '@typings/generic'
import { getDirectFetchAction } from '@utils/store/fetchDirectModule'

export const moduleName = 'products'

const fetchModule = createFetchModule('@store/Products', 'LOAD', moduleName)
const loadDetailSubmodule = createFetchSubmodule('@store/Products', 'DETAIL', moduleName)
const submoduleCreateProduct = createFetchSubmodule('@store/Products', 'CREATE', moduleName)
const submoduleEditProduct = createFetchSubmodule('@store/Products', 'EDIT', moduleName)
const submoduleDeleteProducts = createFetchSubmodule('@store/Products', 'DELETE_BATCH', moduleName)
const submoduleActivateProduct = createFetchSubmodule('@store/Products', 'ACTIVATE', moduleName)
const submoduleDeactivateProduct = createFetchSubmodule('@store/Products', 'DEACTIVATE', moduleName)
const submoduleActivateProducts = createFetchSubmodule('@store/Products', 'ACTIVATE_BATCH', moduleName)
const submoduleDeactivateProducts = createFetchSubmodule('@store/Products', 'DEACTIVATE_BATCH', moduleName)
const submoduleDownloadImportTemplate = createFetchSubmodule('@store/Products', 'DOWNLOAD_IMPORT_TEMPLATE', moduleName)
const submoduleImportProducts = createFetchSubmodule('@store/Products', 'IMPORT_PRODUCTS', moduleName)
const submoduleExportProducts = createFetchSubmodule('@store/Products', 'EXPORT', moduleName)
const importItemsSubmodule = createFetchSubmodule('@store/Products', 'IMPORT_ITEMS', moduleName)
const downloadItemsTemplateSubmodule = createFetchSubmodule('@store/Products', 'DOWNLOAD_ITEMS_TEMPLATE', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  loadDetail: loadDetailSubmodule.actionTypes,
  loadListDirect: `@store/Products/LOAD_LIST_DIRECT`,
  create: submoduleCreateProduct.actionTypes,
  edit: submoduleEditProduct.actionTypes,
  deleteProducts: submoduleDeleteProducts.actionTypes,
  activateProduct: submoduleActivateProduct.actionTypes,
  deactivateProduct: submoduleDeactivateProduct.actionTypes,
  activateProducts: submoduleActivateProducts.actionTypes,
  deactivateProducts: submoduleDeactivateProducts.actionTypes,
  downloadImportTemplate: submoduleDownloadImportTemplate.actionTypes,
  importProducts: submoduleImportProducts.actionTypes,
  exportProducts: submoduleExportProducts.actionTypes,
  importItems: importItemsSubmodule.actionTypes,
  downloadItemsTemplate: downloadItemsTemplateSubmodule.actionTypes,
}

export default reduceReducers(fetchModule.reducer, loadDetailSubmodule.reducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
  loadDetail: loadDetailSubmodule.actions.run,
  loadListDirect: getDirectFetchAction(actionTypes.loadListDirect),
  create: submoduleCreateProduct.actions.run,
  edit: submoduleEditProduct.actions.run,
  deleteProducts: submoduleDeleteProducts.actions.run,
  activateProduct: submoduleActivateProduct.actions.run,
  deactivateProduct: submoduleDeactivateProduct.actions.run,
  activateProducts: submoduleActivateProducts.actions.run,
  deactivateProducts: submoduleDeactivateProducts.actions.run,
  downloadImportTemplate: submoduleDownloadImportTemplate.actions.run,
  importProducts: submoduleImportProducts.actions.run,
  exportProducts: submoduleExportProducts.actions.run,
  importItems: importItemsSubmodule.actions.run,
  downloadItemsTemplate: downloadItemsTemplateSubmodule.actions.run,
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
  ...loadDetailSubmodule.selectors,
}
