import { ObjectSchemaDefinition } from 'yup'
import * as yup from 'yup'
import { t } from '@lingui/macro'
import {
  DocumentId,
  DecimalNumber,
  WholeNumber,
  PhoneSimple,
  DecimalNumberWithComma,
  NumberWithoutDots,
} from '@components/forms/utils/RegexValidation'
import { ExpeditionDetailItem } from '@typings/entities/Expedition'
import getRuntimeConfig from '@utils/getRuntimeConfig'
import { isMetaLot } from '@utils/expedition'
import { getDecimalForCurrency } from '@utils/getDecimalForCurrency'
import { validateDateFormat } from '@utils/validateDateFormat'
import { validateFutureDate } from '@utils/validateFutureDate'
import { validatePastDate } from '@utils/validatePastDate'

type ValidationSchemaType = ObjectSchemaDefinition<object>
type ServiceFieldsMandatory = {
  pickupPlace: boolean
  externalCarrierPickupPlace: boolean
  email: boolean
  phone: boolean
  name: boolean
}

const specifyValidationSchemaKeys = (specifyingString: string, schemaToSpecify: object): ValidationSchemaType => {
  let newSchema: ValidationSchemaType = {}
  for (const key of Object.keys(schemaToSpecify)) {
    newSchema = {
      ...newSchema,
      [`${specifyingString}${key.charAt(0).toUpperCase() + key.slice(1)}`]: schemaToSpecify[key],
    }
  }
  return newSchema
}

const getAddressValidationSchema = (
  specifyingString: string,
  serviceFieldsMandatory: ServiceFieldsMandatory,
): ValidationSchemaType => {
  const addresseeShared = yup.lazy(() => {
    return yup
      .string()
      .trim()
      .max(255, t({ id: 'form.general.max255Chars', message: 'Maximal length is 255 characters.' }))
      .when(
        [`${specifyingString}Company`, `${specifyingString}FirstName`, `${specifyingString}LastName`],
        ([company, firstName, lastName], schema: yup.StringSchema) => {
          if (serviceFieldsMandatory.name) {
            const atLeastOne = firstName || lastName
            return !atLeastOne
              ? schema.required(
                  t({
                    id: 'form.firstNameOrLastName.errorEmpty',
                    message: 'Chosen Carrier Service requires first and last name to be provided',
                  }),
                )
              : schema.nullable()
          } else {
            const atLeastOne = company || (firstName && lastName)
            return !atLeastOne
              ? schema.required(
                  t({
                    id: 'form.expeditionAddressee.errorEmpty',
                    message: 'Please, enter a name or a company.',
                  }),
                )
              : schema.nullable()
          }
        },
      )
  })

  const addressValidation: ValidationSchemaType = {
    phone: serviceFieldsMandatory.phone
      ? yup
          .string()
          .required(
            t({
              id: 'form.phoneNumberForCarrierService.errorEmpty',
              message: 'Phone number is required by the chosen carrier service.',
            }),
          )
          .matches(
            PhoneSimple,
            t({
              id: 'form.phoneNumber.errorInvalid',
              message: 'Please, enter valid phone number (+420 123456789).',
            }),
          )
      : yup.string().matches(
          PhoneSimple,
          t({
            id: 'form.phoneNumber.errorInvalid',
            message: 'Please, enter valid phone number (+420 123456789).',
          }),
        ),
    email: serviceFieldsMandatory.email
      ? yup
          .string()
          .required(
            t({
              id: 'form.emailForCarrierService.errorEmpty',
              message: 'Email address is required by the chosen carrier service.',
            }),
          )
          .email(
            t({
              id: 'form.email.errorInvalid',
              message: 'Email address does not have correct format (john@example.net).',
            }),
          )
      : yup
          .string()
          .max(60, t({ id: 'form.general.max60Chars', message: 'Maximal length is 60 characters.' }))
          .email(
            t({
              id: 'form.email.errorInvalid',
              message: 'Email address does not have correct format (john@example.net).',
            }),
          ),
    firstName: addresseeShared,
    lastName: addresseeShared,
    degree: yup
      .string()
      .nullable()
      .max(64, t({ id: 'form.expeditionDegree.errorTooLong', message: 'Maximal length is 64 characters.' })),
    company: addresseeShared,
    street: yup
      .string()
      .trim()
      .max(255, t({ id: 'form.general.max255Chars', message: 'Maximal length is 255 characters.' }))
      .required(t({ id: 'form.street.errorEmpty', message: 'Please, enter the street.' })),
    houseNr: yup
      .string()
      .trim()
      .min(1, t({ id: 'form.houseNr.errorTooShort', message: 'Minimal length is 1 characters.' }))
      .max(10, t({ id: 'form.general.max10Chars', message: 'Maximal length is 10 characters.' })),
    zip: yup
      .string()
      .trim()
      .min(2, t({ id: 'form.general.min2Chars', message: 'Minimal length is 2 characters.' }))
      .max(10, t({ id: 'form.general.max10Chars', message: 'Maximal length is 10 characters.' }))
      .required(t({ id: 'form.zip.errorEmpty', message: 'Please, enter the ZIP.' })),
    city: yup
      .string()
      .trim()
      .min(2, t({ id: 'form.general.min2Chars', message: 'Minimal length is 2 characters.' }))
      .max(40, t({ id: 'form.general.max40Chars', message: 'Maximal length is 40 characters.' }))
      .required(t({ id: 'form.city.errorEmpty', message: 'Please, enter the city.' })),
    country: yup
      .string()
      .trim()
      .required(t({ id: 'form.country.errorEmpty', message: 'Please, select the country.' })),
  }

  return specifyValidationSchemaKeys(specifyingString, addressValidation)
}

export const createValidationSchema = (
  displayDeliveryAddress = false,
  displayCod = false,
  serviceFieldsMandatory: ServiceFieldsMandatory,
): object => {
  const deliveryCostCurrency = yup.lazy(() => {
    return (
      yup
        .string()
        // test that delivery cost currency is filled (only if the delivery cost is filled)
        .test('currency validation', '', function () {
          if (!!this.parent.deliveryCost && !this.parent.deliveryCostCurrency) {
            return this.createError({
              message: t({
                id: 'form.deliveryCostCurrency.errorEmpty',
                message: 'Please, enter a delivery cost currency.',
              }),
            })
          }
          return true
        })
        // test that product value currency is same as deliver cost currency (only if the product value currency is filled)
        .test('equal currency', '', function () {
          // find some item with incorrect currency
          const itemWithIncorrectCurrency = this.parent.items?.some((item) => {
            if (!item.productValueCurrency) {
              return false
            }
            if (item.productValueCurrency !== this.parent.deliveryCostCurrency) {
              return true
            }
            return false
          })

          if (itemWithIncorrectCurrency) {
            return this.createError({
              message: t({
                id: 'form.deliveryCostCurrency.errorEqualValue',
                message: 'Delivery cost currency and product value currency should be same.',
              }),
            })
          }

          return true
        })
    )
  })

  const deliveryCost = yup.lazy(() => {
    return (
      yup
        .number()
        // test that delivery cost is filled (only if the delivery cost currency is filled)
        .test('cost validation', '', function () {
          if (!!this.parent.deliveryCostCurrency && !this.parent.deliveryCost) {
            return this.createError({
              message: t({
                id: 'form.deliveryCost.errorEmpty',
                message: 'Please, enter a delivery cost.',
              }),
            })
          }
          return true
        })
        // test that delivery cost is filled (only if the product value is filled)
        .test('filled product value', '', function () {
          const hasProductValue = this.parent.items?.some((item) => {
            return item.productValue
          })

          if (hasProductValue && !this.parent.deliveryCost) {
            return this.createError({
              message: t({
                id: 'form.deliveryCost.errorEmpty',
                message: 'Please, enter a delivery cost.',
              }),
            })
          }
          return true
        })
    )
  })

  const productValueShared = yup.lazy(() => {
    return yup
      .string()
      .when([`productValueCurrency`, `productValue`], ([productValueCurrency, productValue], schema: yup.StringSchema) => {
        const atLeastOne = productValueCurrency || productValue

        return atLeastOne
          ? schema.required(
              t({
                id: 'form.productValue.errorEmpty',
                message: 'Please, enter a product value and product value currency.',
              }),
            )
          : schema.nullable()
      })
  })

  let validationSchema = yup.object().shape({
    ...getAddressValidationSchema('billing', serviceFieldsMandatory),
    billingRegistrationNumber: yup
      .string()
      .nullable()
      .max(14, () =>
        t({ id: 'form.registrationNumber.errorTooLong', message: 'Registration number must be at most 14 characters.' }),
      ),
    billingVatNumber: yup
      .string()
      .nullable()
      .max(16, () => t({ id: 'form.vatNumber.errorTooLong', message: 'VAT number must be at most 16 characters.' })),
    orderNumber: yup
      .string()
      .required(() => t({ id: 'form.orderNumber.errorEmpty', message: 'Please, enter the order number.' }))
      .max(30, () => t({ id: 'form.orderNumber.errorTooLong', message: 'Maximal length is 30 characters.' }))
      .matches(DocumentId, () =>
        t({
          id: 'form.orderNumber.errorInvalid',
          message: 'Value can contain only digits, letters, underscore, slash and minus.',
        }),
      ),
    eshop: yup.string().required(() => t({ id: 'form.eshop.errorEmpty', message: 'Please, select the eshop.' })),
    warehouse: yup
      .string()
      .required(() => t({ id: 'form.warehouse.errorEmpty', message: 'Please, select the virtual warehouse.' })),
    wms: yup.string().required(() => t({ id: 'form.wms.errorEmpty', message: 'Please, select the physical warehouse.' })),
    carrier: yup.string().required(() => t({ id: 'form.carrier.errorEmpty', message: 'Please, select the shipping carrier.' })),
    carrierService: yup
      .string()
      .required(() => t({ id: 'form.carrierService.errorEmpty', message: 'Please, select the shipping service.' })),
    requiredExpeditionDate: yup
      .string()
      .nullable()
      .typeError(() => t({ id: 'form.requiredExpeditionDate.errorInValid', message: 'Please enter a valid date.' }))
      .test(
        'isValidDateFormat',
        t({ id: 'form.dateOfBirth.errorInvalidFormat', message: 'Invalid date format. Please use dd.MM.yyyy.' }),
        validateDateFormat,
      )
      .test(
        'isValidDate',
        t({ id: 'form.requiredExpeditionDate.errorTooEarly', message: 'Please select a future date.' }),
        (value) => validateFutureDate(value, true),
      ),
    eshopOrderDate: yup
      .string()
      .nullable()
      .typeError(() => t({ id: 'form.eshopOrderDate.errorInValid', message: 'Please enter a valid date.' }))
      .test(
        'isValidDateFormat',
        t({ id: 'form.dateOfBirth.errorInvalidFormat', message: 'Invalid date format. Please use dd.MM.yyyy.' }),
        validateDateFormat,
      )
      .test(
        'isValidDate',
        t({ id: 'form.eshopOrderDate.errorTooLate', message: 'Please select today or past date.' }),
        validatePastDate,
      ),
    ref1: yup
      .string()
      .min(2, () => t({ id: 'form.general.min2Chars', message: 'Minimal length is 2 characters.' }))
      .max(36, () => t({ id: 'form.general.max36Chars', message: 'Maximal length is 36 characters.' })),
    ref2: yup
      .string()
      .min(2, () => t({ id: 'form.general.min2Chars', message: 'Minimal length is 2 characters.' }))
      .max(36, () => t({ id: 'form.general.max36Chars', message: 'Maximal length is 36 characters.' })),
    ref3: yup
      .string()
      .min(2, () => t({ id: 'form.general.min2Chars', message: 'Minimal length is 2 characters.' }))
      .max(36, () => t({ id: 'form.general.max36Chars', message: 'Maximal length is 36 characters.' })),
    deliveryCost: deliveryCost,
    deliveryCostCurrency: deliveryCostCurrency,
    note: yup.string().max(500, t({ id: 'form.note.errorTooLong', message: 'Maximal length is 500 characters.' })),
    value: yup
      .string()
      .matches(DecimalNumber, () => t({ id: 'form.value.errorInvalid', message: 'Please, enter valid value.' }))
      .required(() => t({ id: 'form.value.errorEmpty', message: 'Please, enter the value.' })),
    carrierNote: yup.string().max(500, () => t({ id: 'form.note.errorTooLong', message: 'Maximal length is 500 characters.' })),
    productPcs: yup
      .number()
      .min(1, () => t({ id: 'form.positiveNumber', message: 'Please enter a number greater than or equal to 1' })),
    recipientIdentificationNumber: yup
      .string()
      .trim()
      .max(32, () => t({ id: 'form.recipientIdentificationNumber.errorTooLong', message: 'Maximal length is 32 characters.' })),
    items: yup
      .array()
      .required(() => t({ id: 'form.items.errorEmpty', message: 'Please, enter at least one item.' }))
      // test that all items are allowed in eshop corresponding to expedition
      .test(
        'is-in-eshop',
        '', // we are using custom error
        function (value: ExpeditionDetailItem[]) {
          const eshopValue = this.parent.eshop
          if (!value || !eshopValue) return false
          const problematicItem = value.find((item) => {
            return !item.product.eshops.find(({ id }) => id == eshopValue)
          })
          if (problematicItem)
            return this.createError({
              message: t({
                id: 'form.items.errorExpeditionItemEshop',
                message: `Product not allowed in selected Eshop (product: ${problematicItem.product.name})`,
              }),
            })

          return true
        },
      )
      // test that there are not duplicate items (duplicate products, or product/lot combination for product with lots)
      .test(
        'is-lot-unique',
        '', // we are using custom error
        function (value: ExpeditionDetailItem[]) {
          const testMap = {}
          if (!value) return false

          const duplicateItem = value.find((item) => {
            let key = `${item.product.id}`
            if (item.product.workAroundLot && !isMetaLot(item.lot)) {
              key += `|${item.lot}`
            }
            if (testMap[key]) {
              return true
            }
            testMap[key] = true
          })

          if (duplicateItem) {
            const lotName: string = isMetaLot(duplicateItem.lot)
              ? t({ id: 'lotOptions.metaLotGeneric', message: 'none' })
              : duplicateItem.lot
            return this.createError({
              message: t({
                id: 'form.items.errorDuplicate',
                message: `Duplicate Product/Lot combination. (product: ${duplicateItem.product.name} lot: ${
                  duplicateItem.product.workAroundLot ? lotName : 'n/a'
                })`,
              }),
            })
          }
          return true
        },
      )
      // test that all items are active
      .test(
        'is-active',
        '', // we are using custom error
        function (value: ExpeditionDetailItem[]) {
          if (!value) return false
          const problematicItem = value.find((item) => !item.product.active)
          if (problematicItem)
            return this.createError({
              message: t({
                id: 'form.items.errorNotActive',
                message: `Can not have deactivated product (product: ${problematicItem.product.name})`,
              }),
            })
          else return true
        },
      )
      .of(
        yup.object().shape({
          productValue: productValueShared,
          productValueCurrency: productValueShared,
          quantity: yup
            .number()
            .min(1, () => t({ id: 'form.quantity.errorMin', message: 'Quantity must be greater than or equal to 1.' })),
          book: yup
            .number()
            .min(0, () => t({ id: 'form.book.errorMin', message: 'Entered quantity cannot be negative.' }))
            .required(() => t({ id: 'form.book.errorEmpty', message: 'Please, enter the quantity to be booked.' }))
            .test(
              'maxQuantity',
              () => t({ id: 'form.book.errorMax', message: 'Entered quantity exceeds the maximum value.' }),
              function (value) {
                if (this.parent.stocksState !== 'ready') return true // warehouses will display bug
                return this.parent.quantity >= value && this.parent.availableTotal >= value
              },
            ),
          ...(getRuntimeConfig('FRONTEND__SHOW_PAIRING') && {
            bookAdviceTotal: yup.number().test(
              'maxQuantity',
              () =>
                t({
                  id: 'form.bookAdviceExp.errorMax',
                  message: `Entered quantity exceeds the maximum value. Maximum is quantity minus book amount`,
                }),
              function (value) {
                if (this.parent.stocksState !== 'ready') return true // warehouses will display bug
                return !value || this.parent.quantity >= value + this.parent.book
              },
            ),
          }),
        }),
      ),
  })

  if (serviceFieldsMandatory.pickupPlace) {
    validationSchema = validationSchema.shape({
      carrierPickupPlace: yup
        .mixed()
        .required(() => t({ id: 'form.carrierPickupPlace.errorEmpty', message: 'Please, select the pickup place.' })),
    })
  }

  if (serviceFieldsMandatory.externalCarrierPickupPlace) {
    validationSchema = validationSchema.shape({
      externalCarrierPickupPlace: yup
        .mixed()
        .required(() =>
          t({ id: 'form.externalCarrierPickupPlace.errorEmpty', message: 'Please, select external pickup place.' }),
        ),
    })
  }

  if (displayDeliveryAddress) {
    validationSchema = validationSchema.shape(getAddressValidationSchema('delivery', serviceFieldsMandatory))
  }
  if (displayCod) {
    validationSchema = validationSchema.shape({
      codVariableSymbol: yup
        .string()
        .matches(WholeNumber, () =>
          t({ id: 'form.variableSymbol.errorInvalid', message: 'Please, enter valid variable symbol.' }),
        )
        .required(t({ id: 'form.variableSymbol.errorEmpty', message: 'Please, enter the variable symbol.' })),
      codValue: yup
        .string()
        .test('numberOfDecimalDigits', '', function (value) {
          const currencyDecimals = getDecimalForCurrency(this.parent.currency)
          const decimals = value?.toString().match(DecimalNumberWithComma)

          if (decimals) {
            const decimalPartLength = decimals[0].length - 1

            if (decimalPartLength <= currencyDecimals) {
              return true
            } else {
              return this.createError({
                message: t({
                  id: 'form.numberOfDecimalDigits.errorInvalid',
                  message: `The number of decimal digits should be ${currencyDecimals}`,
                }),
              })
            }
          }
          return true
        })
        .matches(NumberWithoutDots, () => t({ id: 'form.codValue.errorInvalid', message: 'Please, enter valid value.' }))
        .required(() => t({ id: 'form.codValue.errorEmpty', message: 'Please, enter the value.' })),
    })
  }
  return validationSchema
}
