import React, { memo, useCallback, useMemo, useRef } from 'react'
import { matchPath } from 'react-router'
import { Item } from './types'
import {
  ItemLinkWrap,
  ItemIcon,
  ItemDropdownArrow,
  SubItemsWrap,
  OverflowWithEllipsis,
  MenuItemContainer,
  ItemLabel,
  ItemsSeparator,
} from './styles'
import SubitemTooltip from './components/SubitemTooltip'
import useOutSideHover from '@hooks/useOutsideHover'
import { useLeftMenuContext } from './context/useLeftMenuContext'
import { Paragraph3, Paragraph5 } from '@mailstep/design-system/ui/Elements/Typography'
import Icon from '@mailstep/design-system/ui/Elements/Icon'
import { removeOptionalEnd } from '@components/forms/utils/RegexValidation'

type Props = Item & {
  isSubitem?: boolean
  isCompact: boolean
  lightMode?: boolean
  separator?: boolean
  isLeftMenuOpen?: boolean
  onCloseLeftMenu?: () => void
}

const MenuItem = ({
  icon,
  title,
  link = '',
  items,
  id,
  isSubitem,
  isCompact,
  lightMode = false,
  separator = false,
  isLeftMenuOpen,
  onCloseLeftMenu,
}: Props): null | JSX.Element => {
  const hasChildren = useMemo(() => items && items?.length > 0, [items])
  const { expandedItem, expandItem } = useLeftMenuContext()
  const isExpanded = expandedItem === id

  const toggleChildren = useCallback(() => {
    hasChildren && expandItem(isExpanded ? null : id)
    !hasChildren && !lightMode && onCloseLeftMenu && onCloseLeftMenu()
  }, [hasChildren, expandItem, isExpanded, id, lightMode, onCloseLeftMenu])

  const linkProps = useMemo(() => {
    return link.startsWith('https://') ? { to: { pathname: link }, target: '_blank' } : { to: link }
  }, [link])

  const Title = isCompact ? Paragraph5 : Paragraph3
  const listExpandedItems = isExpanded && !isCompact

  const parentRef = useRef(null)
  const { ref: childRef, isHovering, handleMouseOver } = useOutSideHover({ parentRef })

  let itemLinkWrapClassName = !isSubitem ? 'toplevel' : 'nestedlevel'
  if (isExpanded) {
    itemLinkWrapClassName += ' expanded'
  }
  if (separator) {
    itemLinkWrapClassName += ' separator'
  }

  const isActive = useCallback(
    (match, location): boolean => {
      const childSelected = !!items?.find((item) => item.link?.replace(removeOptionalEnd, '') === location.pathname)
      const isActive = !!matchPath(location.pathname, { path: match?.path })
      // partial match helps to match paths with parameters which are not matched by matchPath above
      const partialMatch = location.pathname.startsWith(link.slice(0, -1)) && location.pathname.includes('/settings/')
      return isActive || childSelected || partialMatch
    },
    [items, link],
  )

  return (
    <>
      {separator && <ItemsSeparator />}

      <MenuItemContainer
        ref={parentRef}
        $isCompact={isCompact}
        $lightMode={lightMode}
        hasChildren={hasChildren}
        isHovering={isHovering}
      >
        <ItemLinkWrap
          {...linkProps}
          onMouseOver={handleMouseOver}
          exact
          activeClassName={hasChildren ? 'selected' : undefined}
          onClick={toggleChildren}
          $isCompact={isCompact}
          className={itemLinkWrapClassName}
          isActive={isActive}
          $lightMode={lightMode}
        >
          <ItemLabel $isCompact={isCompact} isSubitem={isSubitem}>
            {!isSubitem && icon && (
              <ItemIcon className="mainIcon" $isCompact={isCompact}>
                {typeof icon === 'string' ? <Icon icon={icon} fill="none" /> : icon}
              </ItemIcon>
            )}
            <Title variant="semiBold" mt={0} mb={0}>
              <OverflowWithEllipsis>{title}</OverflowWithEllipsis>
            </Title>
          </ItemLabel>

          {!isCompact && hasChildren && <ItemDropdownArrow icon="goDown" $lightMode={lightMode} />}
        </ItemLinkWrap>
        {isHovering && isCompact && hasChildren && !listExpandedItems && (
          <SubitemTooltip
            items={items}
            childRef={childRef}
            parentRef={parentRef}
            isLeftMenuOpen={isLeftMenuOpen}
            onCloseLeftMenu={onCloseLeftMenu}
            lightMode={lightMode}
          />
        )}
      </MenuItemContainer>
      {hasChildren && listExpandedItems && (
        <SubItemsWrap>
          {items?.map((item, index) => (
            <MenuItem
              key={`${index}`}
              isCompact={isCompact}
              lightMode={lightMode}
              {...item}
              onCloseLeftMenu={onCloseLeftMenu}
              isSubitem
            />
          ))}
        </SubItemsWrap>
      )}
    </>
  )
}

export default memo(MenuItem)
