import React from 'react'
import { Trans } from '@lingui/react'
import { ColumnDefinition, PageGridProps } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import CommonGrid from '@components/blocks/CommonGrid'
import { DateTimeCell } from '@mailstep/design-system/ui/Blocks/CommonGrid'
import { AggregatedSynchronizationStatusCell } from '@components/elements/gridCells/EnumInCell'
import SyncCell from '../../pages/SharedModule/Baselinker/synchronization/components/SyncCell'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'
import withProps from '@HoC/withProps'
import TextCellWithPopup from '@components/elements/TextCellWithPopup'
import useIsSyncRunning from '@hooks/useIsSyncRunning'

type Props = PageGridProps & { data: any; onClose: () => void; isProductSync: boolean; isSyncRunning: boolean }

export const createColumnsDefinitions = (isSyncRunning: boolean): ColumnDefinition[] => [
  {
    name: 'baseLinkerProductId',
    title: <Trans id="dataGrid.columnBaselinkerProductId.title" message="BaseLinker product ID" />,
    flexBasis: 120,
    filtering: true,
    filterExtraProps: {
      isPresent: true,
      defaultComparator: 'in',
    },
  },
  {
    name: 'ean',
    title: <Trans id="dataGrid.columnProductSku.title" message="Product SKU" />,
    flexBasis: 120,
    filtering: true,
    filterExtraProps: {
      isPresent: true,
      defaultComparator: 'in',
    },
  },
  {
    name: 'lastSyncResult',
    title: <Trans id="dataGrid.columnStatus.title" message="Status" />,
    flexBasis: 120,
    cellComponent: withProps({ type: 'product' })(AggregatedSynchronizationStatusCell),
  },
  {
    name: 'sku',
    title: <Trans id="dataGrid.columnInternalSku.title" message="Internal SKU" />,
    flexBasis: 130,
    filtering: true,
    filterExtraProps: {
      isPresent: true,
      defaultComparator: 'in',
    },
  },
  {
    name: 'inventoryId',
    title: <Trans id="dataGrid.inventoryId.title" message="Inventory ID" />,
    flexBasis: 100,
    filtering: true,
    filterExtraProps: {
      isMulti: true,
      isPresent: true,
      defaultComparator: 'in',
    },
  },
  {
    name: 'error',
    title: <Trans id="dataGrid.columnErrorMessage.title" message="Error message" />,
    flexBasis: 180,
    cellComponent: withProps({ path: ['lastProductSyncMessage', 'lastStockSyncMessage'] })(TextCellWithPopup),
  },
  {
    name: 'updatedAt',
    title: <Trans id="dataGrid.columnSyncDate.title" message="Sync Date" />,
    flexBasis: 145,
    filteringType: 'date',
    filtering: true,
    filterExtraProps: { filterTime: true },
    cellComponent: DateTimeCell,
    passProps: {
      dateTimeFormat: 'dd. MM. yyyy HH:mm:ss',
    },
  },
  {
    name: 'synchronization',
    title: <Trans id="dataGrid.columnSyncAction.title" message="Synchronization" />,
    flexBasis: 120,
    flexGrow: 0,
    cellComponent: withProps({ isSyncRunning })(SyncCell),
  },
]

const UnsyncedProductsList = ({ data, onClose, isProductSync, ...gridProps }: Props): JSX.Element => {
  const [isProductSyncRunning, isProductStockSyncRunning] = useIsSyncRunning()
  const isSyncRunning = isProductSyncRunning || isProductStockSyncRunning

  return (
    <Modal
      width="mediumLarge"
      title={
        isProductSync ? (
          <Trans id="baselinker.unsyncedProducts.title" message="Unsynced products" />
        ) : (
          <Trans id="baselinker.unsyncedStocks.title" message="Unsynced stocks" />
        )
      }
      onClose={onClose}
      hasFooter={false}
    >
      <CommonGrid
        {...gridProps}
        rowsData={data}
        className="fullWidthGrid"
        columnsDefinitions={createColumnsDefinitions(isSyncRunning)}
        optimizeFilters
        hideAllControls
      />
    </Modal>
  )
}

export default UnsyncedProductsList
