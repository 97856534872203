import getAuthorizationToken from '@utils/getAuthorizationToken'
import getRuntimeConfig from '@utils/getRuntimeConfig'

const UPLOAD_ENDPOINTS = {
  default: getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL'),
  gateway: getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL_V3'),
}

type FileFormUpload = (endpoint: string, file: File, formData?: FormData, base?: keyof typeof UPLOAD_ENDPOINTS) => Promise<any>

export const fileFormUpload: FileFormUpload = (endpoint, file, body = new FormData(), base = 'default') => {
  const token = getAuthorizationToken()
  const headers = {
    authorization: `Bearer ${token}`,
  }

  body.append('file', file)
  return fetch(`${UPLOAD_ENDPOINTS[base]}${endpoint}`, { headers, method: 'POST', body })
}
