import gql from 'graphql-tag'

const productGroupCreateQuery = gql`
  query productGroupCreate($name: String!, $organisation: String!) {
    productGroupCreate(input: { name: $name, organisation: $organisation })
      @rest(type: "productGroupCreateResult", method: "POST", path: "product-group") {
      id
      name
      organisation
      createdAt
    }
  }
`

export default productGroupCreateQuery
