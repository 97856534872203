import React, { useCallback, useRef, useState } from 'react'
import { connect } from 'formik'
import InputField from '@components/forms/components/InputField'
import styled from 'styled-components'
import { th } from '@xstyled/system'
import { debounce } from 'lodash'
import { useFoxentry } from '@hooks/apiHooks/foxentryAutocomplete/useFoxentry'

const Wrap = styled.div`
  height: 160px;
  width: 25%;
  box-shadow: ${th.shadow('cardShadow')};
  border-radius: 5px;
  overflow: overlay;
  position: absolute;
  z-index: 1;
  ::-webkit-scrollbar-thumb {
    border: none;
    border-radius: 4px;
  }
  ::-webkit-scrollbar {
    width: 17px;
  }
  ::-webkit-scrollbar-track {
    background-color: bgLightGray1;
    borderradius: 4px;
    margin: 6px;
  }
`

const StyledItem = styled.div`
  cursor: pointer;
  display: block;
  padding-top: 10px;
  padding-left: 12px;
  background-color: ${th.color('white')};
  font-size: 12px;
  font: ${th.font('primary')};
  font-weight: ${th.fontWeight('medium')};
  color: ${th.color('typoPrimary')};
  position: relative;
  &:hover {
    font-weight: ${th.fontWeight('bold')};
  }
`
type Address = {
  street: { name: string }
  streetNumber: { full: string }
  city: { name: string }
  zip: string
}

type Props = {
  form: { values: Record<string, string> }
  field: { name: string }
  formik: {
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
    setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void
  }
}

const availableCountries = ['CZ', 'SK', 'PL']

const AutocompleteAddressInputField = (props: Props): JSX.Element => {
  const {
    formik: { setFieldTouched, setFieldValue },
    form: { values },
    field: { name: fieldName },
  } = props
  const [autocompleteData, setAutocompleteData] = useState([])
  const selectedCountry = values[fieldName.replace('Street', 'Country')]
  const partnerValue = props?.form?.values?.partner
  const { data, getAddress } = useFoxentry()

  const onBlurAction = useCallback(() => {
    const timeout = setTimeout(() => {
      setFieldTouched(fieldName, true)
      setAutocompleteData([])
    }, 300)
    return (): void => clearTimeout(timeout)
  }, [setFieldTouched, fieldName])

  const debouncedSearch = useRef(
    debounce(async (value: string, selectedCountry: string) => {
      await getAddress(value, selectedCountry)
    }, 150),
  ).current

  const onChangeValue = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setFieldValue(fieldName, value)
      if (partnerValue) {
        setFieldValue('partner', null, false)
      }
      if (!value) {
        return setAutocompleteData([])
      }
      if (availableCountries.includes(selectedCountry)) {
        try {
          debouncedSearch(value, selectedCountry)
          data && setAutocompleteData(data)
        } catch (err) {
          setAutocompleteData([])
          console.warn(err)
        }
      }
    },
    [setFieldValue, fieldName, partnerValue, selectedCountry, debouncedSearch, data],
  )

  const onAddressSelection = useCallback(
    (address: Address) => (): void => {
      setFieldValue(fieldName, address.street.name)
      setFieldValue(fieldName.replace('Street', 'HouseNr'), address.streetNumber.full)
      setFieldValue(fieldName.replace('Street', 'City'), address.city.name)
      setFieldValue(fieldName.replace('Street', 'Zip'), address.zip)
      setAutocompleteData([])
    },
    [setFieldValue, fieldName],
  )

  return (
    <div>
      <InputField value={values[fieldName]} onChange={onChangeValue} onBlur={onBlurAction} {...props} />
      {autocompleteData?.length > 0 && (
        <Wrap>
          {autocompleteData.map((address: Address, index: number) => {
            const fullAddress = `${address.street.name} ${address.streetNumber.full}, ${address.zip} ${address.city.name}`
            return (
              <StyledItem key={`${fullAddress}_${index}`} onClick={onAddressSelection(address)}>
                {fullAddress}
              </StyledItem>
            )
          })}
        </Wrap>
      )}
    </div>
  )
}

export default connect(AutocompleteAddressInputField)
