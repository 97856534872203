import { css, x } from '@xstyled/styled-components'
import React from 'react'
import styled from 'styled-components'
import { HamburgerMenu as HamburgerMenuIcon } from '@mailstep/design-system/ui/Elements/Icon/icons/HamburgerMenu'
import { MobileCancel } from '@mailstep/design-system/ui/Elements/Icon/icons/MobileCancel'
import { th } from '@xstyled/system'
import { useTheme } from '@xstyled/styled-components'

const HamburgerMenuWrapper = styled(x.div)<{ isLeftMenuOpen: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 70px;
  background-color: neutral20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: ${th.shadow('dropShadow')};
  &:hover {
    cursor: pointer;
  }

  ${({ isLeftMenuOpen }) =>
    isLeftMenuOpen
      ? css`
          position: absolute;
          left: 92%;
          top: 7px;
          z-index: 6;
        `
      : css`
          & > svg > path {
            stroke: ${th.color('blue2')};
          }
        `}
  @media (min-width: 1024px) {
    display: none;
  }
`

type Props = {
  isLeftMenuOpen?: boolean
  onClick?: () => void
}

export const HamburgerMenu = ({ isLeftMenuOpen, onClick }: Props) => {
  const theme = useTheme()
  const neutralColor = th.color('neutral20')({ theme }) as string

  const dispalyedIcon = isLeftMenuOpen ? (
    <MobileCancel fill={neutralColor} />
  ) : (
    <HamburgerMenuIcon fill={neutralColor} stroke={'none'} />
  )
  return (
    <HamburgerMenuWrapper mr={3} onClick={onClick} isLeftMenuOpen={isLeftMenuOpen}>
      {dispalyedIcon}
    </HamburgerMenuWrapper>
  )
}
