import React, { useCallback, useMemo } from 'react'
import { Field, FormikValues } from 'formik'
import { Col } from '@designSystem/Grid'
import SelectField from '@components/forms/components/SelectField'
import TextAreaField from '@components/forms/components/TextAreaField'
import { Option as SelectOption } from '@mailstep/design-system/ui/Elements/Select/types'
import { Trans } from '@lingui/react'
import { t } from '@lingui/macro'
import { Wms } from '@typings/entities/Wms'
import { formikCallback } from '@typings/Formik'
import { StyledSubTitle } from '@components/elements/Typography/lib'
import { TransferType } from '@typings/entities/Transfer'
import { ResponsiveRowWrap } from '@components/forms/styles'

const createWarehouseOptions = (wms?: string, wmses?: Wms[]): SelectOption[] => {
  const selectedWms: Wms | undefined = wmses?.find(({ id }) => id === wms)
  return selectedWms?.warehouses?.map(({ id, name }) => ({ value: id, label: name })) ?? []
}

const getWmsOptions = (wmses?: Wms[]): SelectOption[] => (wmses || []).map((wms) => ({ value: wms.id, label: wms.name }))

const hasOption = (options: SelectOption[], needle: string | number): boolean =>
  !!options.find((option) => option.value == needle)

type Props = {
  isFormEditable: boolean
  canAdminEdit: boolean
  wmses?: Wms[]
  setFieldValue: formikCallback
  values: FormikValues
  isCreatingNew: boolean
}

const Main = ({ isFormEditable, wmses, setFieldValue, values, canAdminEdit, isCreatingNew }: Props): JSX.Element => {
  const { type, wmsFrom, wmsTo, warehouseFrom, warehouseTo } = values

  const handleWmsFromChange = useCallback(
    (from: TransferType) => {
      if (isCreatingNew && type === TransferType.virtual) {
        setFieldValue('wmsTo', from)
      }
    },
    [type, isCreatingNew, setFieldValue],
  )

  const wmsOptions = useMemo(() => getWmsOptions(wmses), [wmses])

  const warehouseFromOptions = useMemo(() => {
    const options = createWarehouseOptions(wmsFrom, wmses)
    if (!hasOption(options, warehouseFrom)) setFieldValue('warehouseFrom', '')
    return options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wmsFrom, wmses])

  const warehouseToOptions = useMemo(() => {
    const options = createWarehouseOptions(wmsTo, wmses)
    if (!hasOption(options, warehouseTo)) setFieldValue('warehouseTo', '')
    return options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wmsTo, wmses])

  return (
    <Col size={12}>
      <ResponsiveRowWrap>
        <Col>
          <StyledSubTitle>
            <Trans id="form.transferLocationFrom.label" message="From location" />
          </StyledSubTitle>
          <Field
            component={SelectField}
            name="wmsFrom"
            label={<Trans id="form.wms.label" message="Physical warehouse" />}
            options={wmsOptions}
            placeholder={t({ id: 'form.wms.placeholder', message: 'Select physical warehouse...' })}
            disabled={!isFormEditable || !isCreatingNew}
            onValueChange={handleWmsFromChange}
            spaceAround
          />
          <Field
            component={SelectField}
            name="warehouseFrom"
            label={<Trans id="form.warehouse.label" message="Virtual warehouse" />}
            options={warehouseFromOptions}
            placeholder={t({ id: 'form.warehouse.placeholder', message: 'Select virtual warehouse...' })}
            disabled={!isFormEditable || !wmsFrom || !isCreatingNew}
            spaceAround
          />
        </Col>
        <Col>
          <StyledSubTitle>
            <Trans id="form.transferLocationTo.label" message="To location" />
          </StyledSubTitle>
          <Field
            component={SelectField}
            name="wmsTo"
            label={<Trans id="form.wms.label" message="Physical warehouse" />}
            options={wmsOptions}
            placeholder={t({ id: 'form.wms.placeholder', message: 'Select physical warehouse...' })}
            disabled={!isFormEditable || (type === 'virtual' && !canAdminEdit) || !isCreatingNew}
            spaceAround
          />
          <Field
            component={SelectField}
            name="warehouseTo"
            label={<Trans id="form.warehouse.label" message="Virtual warehouse" />}
            options={warehouseToOptions}
            placeholder={t({ id: 'form.warehouse.placeholder', message: 'Select virtual warehouse...' })}
            disabled={!isFormEditable || !wmsTo || !isCreatingNew}
            spaceAround
          />
        </Col>
      </ResponsiveRowWrap>

      <ResponsiveRowWrap>
        <Col>
          <Field
            name="note"
            label={<Trans id="form.note.label" message="Note / Description" />}
            component={TextAreaField}
            disabled={!isFormEditable}
            spaceAround
          />
        </Col>
        <Col />
      </ResponsiveRowWrap>
    </Col>
  )
}

export default Main
