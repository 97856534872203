import React from 'react'
import { Ability } from '@casl/ability'
import AbilityContext from '@components/blocks/Acl/Context'

export const withAcl =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: Omit<P, 'ability'>): JSX.Element => {
    return (
      <AbilityContext.Consumer>
        {(ability?: Ability): JSX.Element => <Component {...(props as P)} ability={ability} />}
      </AbilityContext.Consumer>
    )
  }
