import reduceReducers from 'reduce-reducers'
import createFetchModule from '@utils/store/createFetchModule'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'
import { FetchActionsWith } from '@typings/generic'

export const moduleName = 'stockAdvicesGrid'

const fetchModule = createFetchModule('@store/StockAdvicesGrid', 'LOAD', moduleName)
const exportSubmodule = createFetchSubmodule('@store/StockAdvicesGrid', 'EXPORT', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  export: exportSubmodule.actionTypes,
}

export default reduceReducers(fetchModule.reducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
  export: exportSubmodule.actions.run,
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
  // ... ownSelectors
}
