import { useModal } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useModal'
import { useCallback } from 'react'

export const useSyncProductDialogs = () => {
  const {
    onOpen: openSyncProductsDialog,
    onClose: closeSyncProductsDialog,
    isOpen: syncProductsDialogVisible,
  } = useModal<string | null>()

  const {
    onOpen: openSyncProductStocksDialog,
    onClose: closeSyncProductStocksDialog,
    isOpen: syncProductStocksDialogVisible,
  } = useModal<string | null>()

  const handleSynchronizeProductsClick = useCallback(() => {
    openSyncProductsDialog()
  }, [openSyncProductsDialog])

  const handleSynchronizeProductStocksClick = useCallback(() => {
    openSyncProductStocksDialog()
  }, [openSyncProductStocksDialog])

  return {
    handleSynchronizeProductsClick,
    handleSynchronizeProductStocksClick,
    closeSyncProductStocksDialog,
    closeSyncProductsDialog,
    syncProductStocksDialogVisible,
    syncProductsDialogVisible,
  }
}
