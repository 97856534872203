import React, { useCallback, useMemo } from 'react'
import { selectors as organisationSelectors } from '@store/organisation'
import * as stockAdviceStore from '@store/stockAdvices'
import { actions as stockAdvicesActions } from '@store/stockAdvices'
import StockAdviceEditForm from '@components/forms/StockAdviceEditForm'
import { useDetailFetchModule, useLazyListFetchModule } from '@utils/containers/useFetchModule'
import { useDispatch, useSelector } from 'react-redux'
import LoadingOverlay from '@components/blocks/LoadingOverlay'
import { useProductItems } from '@utils/containers/productItems'
import { useGridStore } from '@hooks/useCommonGrid'
import * as expeditionItemsStore from '@store/expeditionItemsGrid'
import { ExpeditionItemNested } from '@typings/entities/Expedition'
import { onSearchSuppliers, formatToRead, formatToWrite } from './utils'
import { StockAdviceApi, StockAdviceDetail } from '@typings/entities/StockAdvice'
import { actions as uiActions } from '@store/ui'
import { sentMessage, useActionSubmit, useAdvancedSubmit, useSaveAndSend } from '@utils/containers/advancedFormHelpers'
import { t } from '@lingui/macro'
import { useApolloClient } from '@apollo/client'
import { useLoadWarehousesList } from '@hooks/apiHooks/werehouses/useLoadWarehousesList'
import { useLoadInboundReceiptsList } from '@hooks/apiHooks/inboundReceipt/useLoadInboundReceiptsList'

type Props = {
  id: string | undefined
  onSuccess: () => void
  onCloseForm: () => void // passed to dialog
  createTitle: string | JSX.Element
  editTitle: string | JSX.Element
  titleIcon: string
}

const Container = ({ id, onSuccess, onCloseForm, createTitle, editTitle, titleIcon }: Props): JSX.Element => {
  const { data, isLoading, error } = useDetailFetchModule<StockAdviceApi>(stockAdviceStore, id)
  const isCreatingNew = !id

  const selectedOrganisation = useSelector(organisationSelectors.organisation)
  const usedOrganisation = isCreatingNew ? selectedOrganisation : data?.organisation

  const dispatch = useDispatch()
  const client = useApolloClient()

  const { data: inboundReceipts } = useLoadInboundReceiptsList(id)

  const { data: warehouses, isLoading: warehousesIsLoading } = useLoadWarehousesList(usedOrganisation)

  const searchSuppliers = useMemo(() => onSearchSuppliers(client, usedOrganisation), [client, usedOrganisation])

  const initialValues: Partial<StockAdviceDetail> = useMemo(() => {
    if (!isCreatingNew) return data ? formatToRead(data) : {}
    const defaultWarehouseId = warehouses?.length == 1 ? warehouses[0].id : null
    const defaultWmsId = warehouses?.find((warehouse) => warehouse?.id === defaultWarehouseId)?.defaultWms?.id
    return {
      warehouse: defaultWarehouseId || '',
      wms: defaultWmsId || '',
      organisation: selectedOrganisation,
      status: 'new',
    }
  }, [data, isCreatingNew, selectedOrganisation, warehouses])

  const { gridActions: itemsGridActions, gridSelectors: itemsGridSelectors } = useGridStore('stockAdviceItems')

  const { onImportItems, onDownloadImportTemplate, loadProducts } = useProductItems(usedOrganisation, { allowBundles: true })

  // we need to wrap into custom callback as we need to transform returned values
  // to pass down non-physical products as disabled options into selectbox
  const loadProductsProcessed = useCallback(
    (fulltext: string, eshop?: string) => {
      return loadProducts(fulltext, eshop).then((data) => {
        return data.map((product) => ({ isDisabled: product.type !== 'physical', ...product }))
      })
    },
    [loadProducts],
  )

  const onSave = useAdvancedSubmit(
    dispatch,
    stockAdvicesActions.createInbound,
    stockAdvicesActions.editInbound,
    onSuccess,
    formatToWrite,
  )
  const onSend = useActionSubmit(dispatch, stockAdvicesActions.sendStockAdvice, onSuccess, sentMessage())
  const onSaveAndSend = useSaveAndSend(onSave, onSend)

  const onDelete = useActionSubmit(
    dispatch,
    stockAdvicesActions.deleteStockAdvice,
    onSuccess,
    t({ id: 'cornerDialog.deleteStockAdviceSuccess.text', message: 'The stock advice is successfully deleted.' }),
  )
  const onOpen = useActionSubmit(
    dispatch,
    stockAdvicesActions.openStockAdvice,
    onSuccess,
    t({ id: 'cornerDialog.openStockAdviceSuccess.text', message: 'Stock advice has been successfully opened.' }),
  )
  const onClose = useActionSubmit(
    dispatch,
    stockAdvicesActions.closeStockAdvice,
    onSuccess,
    t({ id: 'cornerDialog.closeStockAdviceSuccess.text', message: 'Stock advice has been successfully closed.' }),
  )

  /**
   * Pairing table related props
   */

  const {
    data: expeditionItems,
    isLoading: expeditionItemsIsLoading,
    load: loadExpeditionItems,
  } = useLazyListFetchModule<ExpeditionItemNested>(expeditionItemsStore)
  const handleLoadExpeditionItems = useCallback(
    (productId: string, warehouse: string, wms: string): void => {
      loadExpeditionItems(
        {
          product: { eq: productId },
          'expedition.warehouse': { eq: warehouse },
          'expedition.wms': { eq: wms },
          // TODO move expedition status filter here when possible
        },
        { nested: true },
      )
    },
    [loadExpeditionItems],
  )

  const onOpenExpedition = useCallback((id: string) => dispatch(uiActions.openQuickAccessDialog('expedition', id)), [dispatch])

  const { gridActions: pairingGridActions, gridSelectors: pairingGridSelectors } = useGridStore('stockAdviceItemsPairing')

  return (
    <LoadingOverlay isLoading={isLoading || warehousesIsLoading} error={error}>
      <StockAdviceEditForm
        initialValues={initialValues}
        title={isCreatingNew ? createTitle : editTitle}
        titleIcon={titleIcon}
        adviceId={id}
        loadProducts={loadProductsProcessed}
        onImportItems={onImportItems}
        onDownloadImportTemplate={onDownloadImportTemplate}
        itemsGridActions={itemsGridActions}
        itemsGridSelectors={itemsGridSelectors}
        warehouses={warehouses}
        pairingGridActions={pairingGridActions}
        pairingGridSelectors={pairingGridSelectors}
        inboundReceipts={inboundReceipts}
        expeditionItems={expeditionItems}
        expeditionItemsIsLoading={expeditionItemsIsLoading}
        loadExpeditionItems={handleLoadExpeditionItems}
        onOpenExpedition={onOpenExpedition}
        searchSuppliers={searchSuppliers}
        onBack={onCloseForm}
        onSend={onSend}
        onSave={onSave}
        onSaveAndSend={onSaveAndSend}
        onDelete={onDelete}
        onOpen={onOpen}
        onClose={onClose}
      />
    </LoadingOverlay>
  )
}

export default Container
