import { useCallback } from 'react'
import { AnyAction, Dispatch } from 'redux'
import { formatApiErrorToFormikErrors } from '@utils/formik/formatApiToFormikErrors'
import { ApiError } from '@typings/entities/Error'
import { FormikErrors, FormikHelpers } from 'formik'
import { FormikHandleSubmit } from '@typings/Formik'

export const useOnSubmit = <ENT extends { id: string }>(
  dispatch: Dispatch,
  onSuccess: null | ((formikHelpers: FormikHelpers<ENT>) => void),
  createAction: null | ((values: ENT) => AnyAction),
  editAction: null | ((values: ENT) => AnyAction),
): FormikHandleSubmit<ENT> =>
  useCallback(
    (values, formikHelpers) => {
      let promise: Promise<unknown>
      if (!values?.id) {
        promise = createAction ? dispatch(createAction(values)).promise : Promise.reject('useOnSubmit action not set')
      } else {
        promise = editAction ? dispatch(editAction(values)).promise : Promise.reject('useOnSubmit action not set')
      }
      return promise
        .then(() => {
          formikHelpers.setSubmitting(false)
          onSuccess && onSuccess(formikHelpers)
        })
        .catch((error: ApiError) => {
          formikHelpers.setErrors(formatApiErrorToFormikErrors(error) as FormikErrors<ENT>)
        })
    },
    [dispatch, onSuccess, createAction, editAction],
  )
