import React, { useMemo } from 'react'
import { selectors as organisationSelectors } from '@store/organisation'
import { useDispatch, useSelector } from 'react-redux'
import LoadingOverlay from '@components/blocks/LoadingOverlay'
import { useLoadProductStocks, useProductItems } from '@utils/containers/productItems'
import { useGridStore } from '@hooks/useCommonGrid'
import { sentMessage, useSaveAndSend } from '@utils/containers/advancedFormHelpers'
import { t } from '@lingui/macro'
import TransferForm from '@components/forms/TransferForm'
import { formatTransferToRead, formatTransferToWrite } from './formUtils'
import { TransferDetail } from '@typings/entities/Transfer'
import { useLoadWMSList } from '@hooks/apiHooks/wms/useFetchWMSList'
import { useLoadTransferDetails } from '@hooks/apiHooks/transfers/useLoadTransterDetails'
import { useApolloClient } from '@apollo/client'
import { useAdvancedSubmit } from '@hooks/useAdvancedSubmit'
import transferCreateQuery from '@queries/transferCreateQuery'
import transferUpdateQuery from '@queries/transferUpdateQuery'
import { useActionSubmit } from '@hooks/useActionSubmit'
import transferSendQuery from '@queries/transferSendQuery'
import transferCancelQuery from '@queries/transferCancelQuery'

type Props = {
  id: string | undefined
  onSuccess: () => void
  onCloseForm: () => void // passed to dialog
  createTitle: string | JSX.Element
  editTitle: string | JSX.Element
  titleIcon: string
}

const Container = ({ id, onCloseForm, onSuccess, createTitle, editTitle, titleIcon }: Props): JSX.Element => {
  const { data, isLoading, error } = useLoadTransferDetails(id)
  const isCreatingNew = !id
  const selectedOrganisation = useSelector(organisationSelectors.organisation)
  const usedOrganisation = isCreatingNew ? selectedOrganisation : data?.organisation

  const dispatch = useDispatch()
  const client = useApolloClient()

  const { data: wmses, isLoading: wmsesIsLoading } = useLoadWMSList(usedOrganisation)

  const initialValues: Partial<TransferDetail> = useMemo(() => {
    if (!isCreatingNew) return data ? formatTransferToRead(data) : {}
    return { organisation: selectedOrganisation }
  }, [data, isCreatingNew, selectedOrganisation])

  const { gridActions: itemsGridActions, gridSelectors: itemsGridSelectors } = useGridStore('TransferItems')

  const { onImportItems, onDownloadImportTemplate, loadProducts } = useProductItems(usedOrganisation)
  const loadProductStocks = useLoadProductStocks(dispatch)

  const onSave = useAdvancedSubmit(
    client,
    [transferCreateQuery, 'transfer'],
    [transferUpdateQuery, 'transferUpdate'],
    onSuccess,
    formatTransferToWrite,
  )
  const onSend = useActionSubmit(client, transferSendQuery, onSuccess, sentMessage())
  const onSaveAndSend = useSaveAndSend(onSave, onSend)

  const onCancel = useActionSubmit(
    client,
    transferCancelQuery,
    onSuccess,
    t({ id: 'cornerDialog.cancelSuccess.text', message: 'Cancellation has been successfully performed.' }),
  )

  return (
    <LoadingOverlay isLoading={isLoading || wmsesIsLoading} error={error}>
      <TransferForm
        initialValues={initialValues}
        title={isCreatingNew ? createTitle : editTitle}
        isCreatingNew={isCreatingNew}
        titleIcon={titleIcon}
        loadProducts={loadProducts}
        onImportItems={onImportItems}
        onDownloadImportTemplate={onDownloadImportTemplate}
        loadProductStocks={loadProductStocks}
        itemsGridActions={itemsGridActions}
        itemsGridSelectors={itemsGridSelectors}
        wmses={wmses}
        onBack={onCloseForm}
        onSave={onSave}
        onSaveAndSend={onSaveAndSend}
        onCancel={onCancel}
      />
    </LoadingOverlay>
  )
}

export default Container
