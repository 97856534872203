import gql from 'graphql-tag'

const userMeUpdateQuery = gql`
  query userMeUpdate(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $dateOfBirth: String!
    $sex: String
    $addressLine1: String!
    $addressLine2: String
    $addressLine3: String
    $photo: String
    $active: Boolean
    $oldPassword: String
    $newPassword: String
    $preferredLanguage: String
  ) {
    userMeUpdate(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        dateOfBirth: $dateOfBirth
        sex: $sex
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        addressLine3: $addressLine3
        photo: $photo
        active: $active
        oldPassword: $oldPassword
        newPassword: $newPassword
        preferredLanguage: $preferredLanguage
      }
    ) @rest(method: "PUT", path: "me") {
      id
      firstName
      lastName
      email
      phone
      dateOfBirth
      sex
      addressLine1
      addressLine2
      addressLine3
      photo
      active
      preferredLanguage
    }
  }
`

export default userMeUpdateQuery
