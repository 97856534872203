import React, { useCallback, useState } from 'react'
import styled from '@xstyled/styled-components'
import { useDropzone } from 'react-dropzone'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'
import { t } from '@lingui/macro'
import { Input } from '@mailstep/design-system/ui/Forms/Input'
import FileDropZone from '@components/elements/FileDropZone'

type Props = {
  name: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  isOpen: boolean
  onClose: () => void
}

const Wrapper = styled.div`
  & > :not(:last-child) {
    margin-bottom: 3;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InvoiceAttachment = ({ name, setFieldValue, isOpen, onClose }: Props): JSX.Element => {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
  const [error, setError] = useState(undefined)

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles) => {
      const droppedFile = acceptedFiles?.[0]
      setSelectedFile(droppedFile)
      if (droppedFile.type !== 'application/pdf') {
        setError(t({ id: 'form.pdfAttachment.errorType', message: 'Please choose a PDF file.' }))
      } else if (droppedFile.size > 2 * 1024 * 1024) {
        setError(t({ id: 'form.pdfAttachment.errorTooLarge', message: 'File size is too large' }))
      } else {
        setError(undefined)
      }
    },
  })

  const handleSubmit = useCallback(() => {
    setFieldValue(name, selectedFile, false)
    onClose()
  }, [setFieldValue, name, onClose, selectedFile])

  const handleCancel = useCallback(() => {
    setSelectedFile(null)
    onClose()
  }, [onClose])

  return (
    <Modal
      title={t({ id: 'form.invoiceAttachment.heading', message: 'Invoice attachment' })}
      isShown={isOpen}
      onClose={handleCancel}
      onCancel={handleCancel}
      onConfirm={handleSubmit}
      isConfirmDisabled={!!error || !selectedFile}
      confirmLabel={t({ id: 'form.buttonConfirm', message: 'Confirm' })}
      cancelLabel={t({ id: 'form.buttonCancel', message: 'Cancel' })}
    >
      <Wrapper>
        {selectedFile && <Input type="text" value={selectedFile.name} error={error} spaceAround disabled />}
        <FileDropZone getRootProps={getRootProps} getInputProps={getInputProps} isDragActive={isDragActive} onOpen={open} />
      </Wrapper>
    </Modal>
  )
}

export default InvoiceAttachment
