import { useCallback } from 'react'
import { CommonErrorFormikObject, formatApiErrorToFormikErrors } from '@utils/formik/formatApiToFormikErrors'
import { FormikErrors, FormikHelpers } from 'formik'
import { t } from '@lingui/macro'
import { AnyAction, Dispatch } from 'redux'
import { ApiError } from '@typings/entities/Error'
import { FormikHandleSubmit } from '@typings/Formik'
import { displayToast } from '@utils/toast'

// TODO this all could use little more work on 'id' typing

export const getFailHandler =
  <T extends Record<string, string> | CommonErrorFormikObject>(formikHelpers: FormikHelpers<T>, setErrorsToFormik?: boolean) =>
  (error: ApiError): void => {
    formikHelpers.setSubmitting(false)
    setErrorsToFormik && formikHelpers.setErrors(formatApiErrorToFormikErrors(error) as FormikErrors<T>)
  }

export const warningMessage = () => t({ id: 'cornerDialog.dmgStore.text', message: 'You have selected a DMG store' })
export const savedMessage = () => t({ id: 'cornerDialog.saveSuccess.text', message: 'Data has been successfully saved.' })
export const savedIcorrectStateMessage = () =>
  t({ id: 'cornerDialog.saveIncorrectState.title', message: 'Saved in incorrect state' })
export const sentMessage = () => t({ id: 'cornerDialog.sendSuccess.text', message: 'Data has been successfully send.' })

/**
 * submit of id for any special action - close/cancel/sendToWms...
 */

export type ChainedActionSubmit<ENT extends { id?: string }> = (
  values: ENT,
  formikHelpers: FormikHelpers<ENT>,
  isChained?: boolean,
) => Promise<void>

type ActionExtraOptions = {
  setErrorsToFormik?: boolean
}

export const useActionSubmit = <ENT extends { id?: string }>(
  dispatch: Dispatch,
  action: (params: { id?: string }) => AnyAction,
  onSuccess: () => void,
  successMessage: string,
  options?: ActionExtraOptions,
): ChainedActionSubmit<ENT> => {
  const setErrorsToFormik = !!options?.setErrorsToFormik
  return useCallback(
    ({ id }, formikHelpers, isChained) => {
      formikHelpers.setSubmitting(true) // some forms do not do this, TODO unify one way or the other
      const handleFail = getFailHandler(formikHelpers, setErrorsToFormik)

      return dispatch(action({ id }))
        .promise.then(() => {
          formikHelpers.setSubmitting(false)
          displayToast({ type: 'success', text: isChained ? savedMessage() : successMessage })
          onSuccess && onSuccess()
        })
        .catch(handleFail)
    },
    [action, dispatch, onSuccess, setErrorsToFormik, successMessage],
  )
}

/**
 * submit for form data - create/update
 */

export type ChainedDataSubmit<ENT extends { id?: string }> = (
  values: ENT,
  formikHelpers: FormikHelpers<ENT>,
  isChained?: boolean,
) => Promise<string | void>

export const useAdvancedSubmit = <ENT extends { id?: string }>(
  dispatch: Dispatch,
  createAction: (values: ENT) => AnyAction,
  editAction: (values: ENT) => AnyAction,
  onSuccess: (id: string) => void,
  formatValues?: (values: ENT) => ENT,
): ChainedDataSubmit<ENT> => {
  return useCallback(
    (values, formikHelpers, isChained) => {
      const handleFail = getFailHandler(formikHelpers, true)
      let promise: Promise<ENT & { id: string }>
      const formattedValues = formatValues ? formatValues(values) : values

      if (!values?.id) {
        promise = dispatch(createAction(formattedValues)).promise
      } else {
        promise = dispatch(editAction(formattedValues)).promise
      }

      return promise
        .then((response) => {
          if (!isChained) {
            formikHelpers.setSubmitting(false)
            displayToast({ type: 'success', text: savedMessage() })
            onSuccess && onSuccess(response?.id)
          }
          return response?.id
        })
        .catch(handleFail)
    },
    [createAction, dispatch, editAction, formatValues, onSuccess],
  )
}

export const useSaveAndSend = <ENT extends { id?: string }>(
  onSave: ChainedDataSubmit<ENT>,
  onSend: ChainedActionSubmit<ENT>,
): FormikHandleSubmit<ENT> =>
  useCallback(
    (values, formikHelpers) => {
      onSave(values, formikHelpers, true).then((id) => {
        id && onSend({ id } as ENT, formikHelpers, true)
      })
    },
    [onSave, onSend],
  )
