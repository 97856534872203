import gql from 'graphql-tag'

const eshopBaselinkerEdit = gql`
  query eshopBaselinkerEdit(
    $id: String!
    $token: String!
    $syncDetailToken: String!
    $warehouseMapping: Array!
    $expeditionStatusMapping: Array!
    $unsynchronizedOrderStatusMapping: Int!
    $organisation: String!
    $carrierMapping: Array!
  ) {
    eshopBaselinkerEdit(
      id: $id
      input: {
        token: $token
        syncDetailToken: $syncDetailToken
        warehouseMapping: $warehouseMapping
        expeditionStatusMapping: $expeditionStatusMapping
        unsynchronizedOrderStatusMapping: $unsynchronizedOrderStatusMapping
        organisation: $organisation
        carrierMapping: $carrierMapping
      }
    ) @rest(type: "BaselinkerEditResult", method: "POST", path: "baselinker/eshop-setting/{args.id}") {
      organisation
      token
      syncDetailToken
      unsynchronizedOrderStatusMapping
      warehouseMapping
      expeditionStatusMapping
      carrierMapping
    }
  }
`

export default eshopBaselinkerEdit
