import styled, { css } from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import FaIcon from '@mailstep/design-system/ui/Elements/Icon'
import { NavLink } from 'react-router-dom'

const leftMenuAfter = css<{ $lightMode?: boolean }>`
  ::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 40px;
    z-index: -1;
    border-radius: 9px 0 0 9px;
    left: 8px;
    top: -1px;
  }
`

const compactMenuItem = css`
  height: 40px;
  width: 40px;
  padding: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
`
export const SubItemsWrap = styled.div`
  margin-left: 23px;
  border-left: 1.5px solid;
  border-color: ${th('colors.red1')};
`

export const ItemsSeparator = styled.div`
  height: 1px;
  width: 100%;
  margin: 12px 0;
  background-color: ${th('colors.lightGray6')};
`

export const ItemLabel = styled.div<{ $isCompact?: boolean; isSubitem?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ $isCompact }) => ($isCompact ? 'column' : 'row')};
  margin-bottom: ${({ $isCompact }) => ($isCompact ? '10px' : '0')};
  margin-left: ${({ isSubitem }) => (isSubitem ? '15px' : '0')};
`

export const ItemDropdownArrow = styled(FaIcon)<{
  $lightMode?: boolean
}>`
  color: ${({ $lightMode }) => ($lightMode ? th.color('typoPrimary') : 'white')};
  svg {
    max-width: 11px;
  }
`

export const TooltipItemLabel = styled.div`
  line-height: 1.5;
  letter-spacing: 0.4px;
`

export const ItemLinkWrap = styled(NavLink)<{
  $isCompact?: boolean
  $lightMode?: boolean
  appearance?: 'gray' | 'default'
}>`
  display: flex;
  flex-direction: ${({ $isCompact }) => ($isCompact ? 'column' : 'row')};
  align-items: center;
  cursor: pointer;
  height: ${({ $isCompact }) => ($isCompact ? '60px' : '40px')};
  border-radius: ${({ $lightMode }) => ($lightMode ? '8px' : '10px')};
  font-weight: 600;
  letter-spacing: 0.4px;
  position: relative;
  color: ${({ $lightMode }) => ($lightMode ? th.color('typoPrimary') : th.color('lightGray5'))};
  transition: moving 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: transform;

  &.toplevel {
    margin: ${({ $isCompact }) => ($isCompact ? '0 0 12px 0' : '16px 0')};
    padding: ${({ $isCompact }) => ($isCompact ? '10px 14px 6px 13px' : '9px 10px')};
    gap: ${({ $isCompact }) => ($isCompact ? 'none' : '30px')};
    justify-content: ${({ $isCompact }): string => ($isCompact ? 'center' : 'space-between')};
    &.active {
      color: ${({ $lightMode }) => ($lightMode ? th('colors.red1') : 'white')};
    }
  }

  &.nestedlevel {
    padding: 0;
    :hover {
      color: ${({ $lightMode }) => ($lightMode ? th('colors.red1') : 'white')};
    }
  }

  &.active > ${TooltipItemLabel} {
    background-color: ${({ $lightMode }) => ($lightMode ? th.color('red20') : th.color('blue2'))};
    color: ${({ $lightMode }) => ($lightMode ? th.color('red1') : th.color('white'))};
    width: 100%;
    border-left: 4px solid ${th('colors.red1')};
    :last-child {
      border-bottom-right-radius: 10px;
    }
    :first-child {
      border-top-right-radius: 10px;
    }
  }

  ${TooltipItemLabel} {
    color: ${({ $lightMode }) => ($lightMode ? th.color('typoPrimary') : th.color('white'))};
    padding-left: 10px;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
  }

  &.active,
  &.selected {
    ${ItemDropdownArrow} {
      color: ${({ $lightMode }) => ($lightMode ? th.color('red1') : th.color('white'))};
    }
  }
`

export const MenuItemContainer = styled.div<{
  $isCompact?: boolean
  $lightMode?: boolean
  hasChildren?: boolean
  isHovering?: boolean
}>`
   z-index: 1;
   stroke: ${({ $lightMode }) => ($lightMode ? th.color('blue2') : undefined)};
   .selected > ${ItemLabel}, .active > ${ItemLabel} {
     color: ${({ $lightMode }) => ($lightMode ? th.color('red1') : th.color('white'))};
     stroke: ${({ $lightMode }) => ($lightMode ? th.color('red1') : th.color('blue2'))};
   }

    .toplevel {
      ${({ $isCompact, hasChildren, isHovering }) =>
        $isCompact &&
        hasChildren &&
        isHovering &&
        css`
          & ${ItemIcon} {
            ${leftMenuAfter}
            ::after {
              background-color: ${({ $lightMode }) => ($lightMode ? th.color('neutral20') : th.color('neutral500'))};
            }
          }
        `}

        ${({ $isCompact, $lightMode, hasChildren }) =>
          $isCompact &&
          css`
            & ${ItemIcon} {
              ${compactMenuItem}
              :hover {
                background-color: ${hasChildren ? 'transparent' : $lightMode ? th.color('neutral20') : th.color('neutral500')};
              }
            }
          `}

          &.active, &.selected {
            ${({ $isCompact, hasChildren, isHovering, $lightMode }) =>
              $isCompact && hasChildren && isHovering
                ? css`
                    & ${ItemIcon} {
                      ${leftMenuAfter}
                      ::after {
                        background-color: ${$lightMode ? th.color('red20') : th.color('neutral500')};
                      }
                    }
                  `
                : css`
                    & ${ItemIcon} {
                      background-color: ${!$isCompact ? 'transparent' : $lightMode ? th.color('red20') : th.color('red1')};
                      :hover {
                        background-color: ${$lightMode ? th.color('red20') : 'none'};
                      }
                    }
                  `}
       
            background-color: ${({ $isCompact, $lightMode }) => {
              if ($isCompact) return 'none'
              if ($lightMode) return th.color('red20')
              return th.color('red1')
            }};
            svg {
              stroke: ${({ $lightMode }) => ($lightMode ? th.color('red1') : th.color('white'))};
            }
          }

          &.expanded > ${ItemDropdownArrow} {
            visibility: visible;
            svg {
              transform: rotate(180deg);
            }
          }
    }

    &:hover {
      ${ItemLinkWrap}.toplevel {
        background-color: ${({ $isCompact, $lightMode }) =>
          $isCompact ? 'none' : $lightMode ? th.color('neutral20') : th.color('blue4')};
      }
      ${ItemLinkWrap}.toplevel.active, ${ItemLinkWrap}.toplevel.selected {
        background-color: ${({ $lightMode, $isCompact }) => {
          if ($isCompact) return 'none'
          if ($lightMode) return th.color('red20')
          return th.color('red70')
        }};
      }
      ${ItemLinkWrap}.nestedlevel {
        background-color: inherit;
      }
    }
  }
`

export const ItemIcon = styled.span<{ $isCompact?: boolean }>`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  margin-right: ${({ $isCompact }) => ($isCompact ? '0' : '11px')};
  margin-bottom: ${({ $isCompact }) => ($isCompact ? '4px' : '0')};
`

export const TooltipWrap = styled.div<{ $lightMode?: boolean }>`
  position: absolute;
  background-color: ${({ $lightMode }) => ($lightMode ? th.color('white') : th.color('neutral500'))};
  white-space: nowrap;
  transform: translateX(8px);
  width: 248px;
  border-radius: 0 10px 10px 0;
  z-index: 5;
  &::before {
    content: '';
    position: absolute;
    border-radius: 10px;
    width: calc(100% + 80px);
    height: calc(100% + 100px);
    left: -16px;
    top: -50px;
  }

  & :not(:last-child) {
    border-radius: 0;
    border-bottom: 0.5px solid ${({ $lightMode }) => ($lightMode ? th.color('neutral20') : th('colors.textTertiary'))};
  }

  &:first-child > {
    :hover {
      border-top-right-radius: 10px;
      background-color: ${({ $lightMode }) => ($lightMode ? th.color('red20') : th.color('blue2'))};
    }
  }

  & :not(:first-child) {
    :hover {
      border-top-right-radius: 0;
    }
  }

  & :last-child > {
    :hover {
      border-bottom-right-radius: 10px;
      background-color: ${({ $lightMode }) => ($lightMode ? th.color('red20') : th.color('blue2'))};
    }
  }
`

export const OverflowWithEllipsis = styled.span`
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
