import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useCurrentPage } from '@hooks/useCurrentPage'

type Props = {
  title?: string
}

const Header = ({ title }: Props): JSX.Element => {
  const currentPage = useCurrentPage()
  const titleText = title || currentPage?.title

  return (
    <HelmetProvider>
      <Helmet>
        <title>Mailship {titleText ? ` - ${titleText}` : ''}</title>
      </Helmet>
    </HelmetProvider>
  )
}

export default Header
