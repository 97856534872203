import React from 'react'
import styled, { x } from '@xstyled/styled-components'
import { th } from '@xstyled/system'

type Props = {
  children: React.ReactNode
}
const WhiteBox = styled(x.div)`
  display: flex;
  flex-wrap: wrap;
  border: none;
  border-radius: md;
  background-color: white;
  margin-bottom: 2;
`

const StyledAlert = styled(x.div)`
  width: 100%;
  border: none;
  border-radius: md;
  background-color: rgba(239, 239, 200);
  font-family: ${th('fonts.primary')};
  font-size: 2;
  padding: 2;
  svg {
    fill: '#0D0D0D';
    margin-right: 2;
  }
`

const LoginErrorAlert = (props: Props): JSX.Element => {
  return (
    <WhiteBox>
      <StyledAlert>{props.children}</StyledAlert>
    </WhiteBox>
  )
}

export default LoginErrorAlert
