import { useContext } from 'react'
import { LeftMenuContext } from './LeftMenuContext'

export const useLeftMenuContext = () => {
  const context = useContext(LeftMenuContext)

  if (!context) throw new Error('useLeftMenuContext context must be use inside LeftMenuProvider')

  return context
}
