import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'

interface Props {
  parentRef: MutableRefObject<HTMLDivElement | null | undefined>
}
interface ReturnType {
  isHovering: boolean
  handleMouseOver: () => void
  ref: MutableRefObject<null>
}

const useOutSideHover = ({ parentRef }: Props): ReturnType => {
  const ref = useRef(null)

  const [isHovering, setIsHovering] = useState(false)

  const handleMouseOver = useCallback(() => {
    setIsHovering(true)
  }, [])

  const handleMouseOut = useCallback(
    (e) => {
      if (!parentRef.current?.contains(e.target) && !ref.current?.contains(e.target)) {
        setIsHovering(false)
      }
    },
    [parentRef],
  )

  useEffect(() => {
    document.addEventListener('mouseover', handleMouseOut)

    return (): void => {
      document.removeEventListener('mouseover', handleMouseOut)
    }
  }, [handleMouseOut])

  return { isHovering, handleMouseOver, ref }
}

export default useOutSideHover
