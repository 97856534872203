import React, { FC } from 'react'
import isFunction from 'lodash/fp/isFunction'

const withProps =
  (extraProps: any) =>
  <P,>(Component: FC<P>): FC<P> =>
  (props: any) =>
    <Component {...props} {...(isFunction(extraProps) ? extraProps(props) : extraProps)} />

export default withProps
