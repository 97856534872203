import gql from 'graphql-tag'
import { organisationDetailFields } from './organisationGetQuery'

const organisationUpdateQuery = gql`
  query organisationUpdate(
    $id: String!
    $name: String
    $registrationNumber: String
    $vatNumber: String
    $primaryCountry: String
    $addressLine1: String
    $addressLine2: String
    $addressLine3: String
    $owner: String
    $countriesOfOperation: Array
    $users: Array
    $active: Boolean
    $code: String
    $stockAdviceCloseInterval: Int
    $ignoreAddressValidation: Boolean
    $strictFifo: Boolean
    $automaticExchangeEnabled: Boolean
  ) {
    organisationUpdate(
      id: $id
      input: {
        name: $name
        registrationNumber: $registrationNumber
        vatNumber: $vatNumber
        primaryCountry: $primaryCountry
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        addressLine3: $addressLine3
        owner: $owner
        countriesOfOperation: $countriesOfOperation
        users: $users
        active: $active
        code: $code
        stockAdviceCloseInterval: $stockAdviceCloseInterval
        ignoreAddressValidation: $ignoreAddressValidation
        automaticExchangeEnabled: $automaticExchangeEnabled
        strictFifo: $strictFifo
      }
    ) @rest(type: "Organisation", method: "PUT", path: "organisation/{args.id}") {
      ${organisationDetailFields}
    }
  }
`

export default organisationUpdateQuery
