import gql from 'graphql-tag'

const expeditionsGetQuery = gql`
  query expeditionsGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    expeditionsGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: true })
      @rest(type: "ExpeditionsListResult", method: "POST", path: "expedition/list") {
      results
      paging
    }
  }
`

export const expeditionsGetFiltersQuery = gql`
  query expeditionsGetFilters($path: String) {
    expeditionsGetFilters(path: $path)
      @rest(type: "ExpeditionsResultFilter", method: "GET", path: "codetable/expedition-filtering{args.path}") {
      carriers
      carrierServices
      countries
      warehouses
      wmses
    }
  }
`

export default expeditionsGetQuery
