import { useMemo } from 'react'
import siteMap, { SiteMapItem } from '@constants/sitemap'
import useUser from '@hooks/useUser'

export const useCreateBreadcrumbPath = (eshopId: string | undefined, items: string): SiteMapItem[] => {
  const { type } = useUser()

  const breadcrumbPath = useMemo(() => {
    const breadcrumbItems = siteMap({ type, eshopId })
    const path = [breadcrumbItems.mailship]

    items.split(',').forEach((item) => path.push(breadcrumbItems[item]))

    return path
  }, [eshopId, type, items])

  return breadcrumbPath
}
