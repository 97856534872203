import React, { useCallback, useMemo, useState } from 'react'
import { GridSelectorsType, GridActionsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { ExpeditionDetailGridItem, BookStockAdvice } from '@typings/entities/Expedition'
import { Trans } from '@lingui/react'
import { FormGrid } from '@components/blocks/FormGrid'
import styled, { x } from '@xstyled/styled-components'
import { t } from '@lingui/macro'
import { Input } from '@mailstep/design-system/ui/Forms/Input'
import { StockAdviceItemApi } from '@typings/entities/StockAdvice'
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'
import createColumnDefinitions, { GridItem } from './PairingTableDefinition'
import { constructMap } from '@utils/transformations'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'
import { StyledSubTitle } from '@components/elements/Typography/lib'

type Props = {
  onCancel: () => void
  item: ExpeditionDetailGridItem
  setAdvicePairingForRow: (bookStockAdvices: BookStockAdvice[]) => void
  adviceItems: StockAdviceItemApi[]
  adviceItemsIsLoading: boolean
  gridSelectors: GridSelectorsType
  gridActions: GridActionsType
  onOpenAdvice: (id: string) => void
}

type PairingMap = { [id: string]: number }

const DetailLine = styled(x.div)`
  display: flex;
  flex-direction: row;
  justify-items: space-around;

  & > :not(:last-child) {
    margin-right: 2;
  }
`

const AdviceOverview = ({
  onCancel,
  item,
  setAdvicePairingForRow,
  adviceItemsIsLoading,
  adviceItems,
  gridSelectors,
  gridActions,
  onOpenAdvice,
}: Props): JSX.Element => {
  const product = item.product

  const initialPairingMap: PairingMap = useMemo(() => {
    return constructMap(item.bookStockAdvices, 'stockAdviceItem', 'quantity')
  }, [item.bookStockAdvices])

  const [itemParing, setItemPairing] = useState<PairingMap>(initialPairingMap)

  const onRowAction = useCallback(
    (value: string, field: string, row: GridItem): void => {
      if (field === 'change') {
        const newPairing = { ...itemParing }
        newPairing[row.id] = Number(value)
        setItemPairing(newPairing)
      }
    },
    [itemParing],
  )

  const handleSubmit = useCallback(() => {
    const advicePairing = map(itemParing, (value, key) => ({
      stockAdviceItem: key,
      quantity: value,
    })).filter(({ stockAdviceItem }) => stockAdviceItem && adviceItems.find(({ id }) => id == stockAdviceItem))
    setAdvicePairingForRow(advicePairing.filter((pairing) => pairing.quantity > 0))
  }, [itemParing, setAdvicePairingForRow, adviceItems])

  const extendedItems: GridItem[] = useMemo(() => {
    return adviceItems.map((adviceItem) => {
      const awaitingQuantity = adviceItem.quantity - (adviceItem.suppliedQuantity || 0)
      const bookedTotal = sumBy(adviceItem.bookStockAdvices || [], 'quantity')
      // since there is one pairable expedition item per product, filtering out this item equals to filtering out this expedition
      const bookedByOtherExpeditions = sumBy(
        (adviceItem.bookStockAdvices || []).filter(({ expeditionItem }) => expeditionItem !== item.id),
        'quantity',
      )
      return {
        ...adviceItem,
        toBook: itemParing[adviceItem.id] || 0,
        awaitingQuantity,
        bookedByOtherExpeditions,
        availableTotal: awaitingQuantity - bookedByOtherExpeditions,
        availableStock: awaitingQuantity - bookedTotal,
      }
    })
  }, [adviceItems, item.id, itemParing])

  return (
    <Modal
      title={<Trans id="form.expeditionItems.advicePairingTitle" message="Item advice pairing" />}
      onCancel={onCancel}
      onConfirm={handleSubmit}
      width="wide"
      confirmLabel={t({ id: 'form.buttonSave', message: 'Save' })}
      cancelLabel={<Trans id="form.buttonCancel" message="Cancel" />}
    >
      <DetailLine mb={2}>
        <Input value={product.name} type={'text'} label={<Trans id="form.productName.label" message="Name" />} disabled />
        <Input
          value={product.productSku}
          type={'text'}
          label={<Trans id="form.productSku.label" message="Product SKU" />}
          disabled
        />
        <Input
          value={product.internalSku}
          type={'text'}
          label={<Trans id="form.internalSku.label" message="Internal SKU" />}
          disabled
        />
      </DetailLine>
      <x.div mt={5} pb={3}>
        <StyledSubTitle>
          <Trans id="form.expeditionItems.lotItems" message="LOT items" />
        </StyledSubTitle>
      </x.div>
      <FormGrid
        gridActions={gridActions}
        gridSelectors={gridSelectors}
        items={extendedItems}
        isLoading={adviceItemsIsLoading}
        columnsDefinitions={createColumnDefinitions(onOpenAdvice)}
        onRowAction={onRowAction}
        minHeightRows={3}
      />
    </Modal>
  )
}

export default AdviceOverview
