import reduceReducers from 'reduce-reducers'
import createFetchModule from '@utils/store/createFetchModule'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'
import { FetchActionsWith } from '@typings/generic'

export const moduleName = 'organisations'

const fetchModule = createFetchModule('@store/Organisations', 'LOAD', moduleName)
const loadDetailSubmodule = createFetchSubmodule('@store/Organisations', 'LOAD_DETAIL', moduleName)
const aresFetchSubmodule = createFetchSubmodule('@store/Organisations', 'ARES_LOAD', moduleName)
const submoduleJoinUsersToOrganisation = createFetchSubmodule('@store/Organisations', 'JOIN_USER', moduleName)
const submoduleRemoveUsersFromOrganisation = createFetchSubmodule('@store/Organisations', 'REMOVE_USER', moduleName)
const submoduleActivateValidation = createFetchSubmodule('@store/Organisations', 'ACTIVATE_VALIDATION', moduleName)
const submoduleDeactivateValidation = createFetchSubmodule('@store/Organisations', 'DEACTIVATE_VALIDATION', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  loadDetail: loadDetailSubmodule.actionTypes,
  aresLoad: aresFetchSubmodule.actionTypes,
  joinUsersToOrganisation: submoduleJoinUsersToOrganisation.actionTypes,
  removeUsersFromOrganisation: submoduleRemoveUsersFromOrganisation.actionTypes,
  activateValidation: submoduleActivateValidation.actionTypes,
  deactivateValidation: submoduleDeactivateValidation.actionTypes,
}

export default reduceReducers(fetchModule.reducer, loadDetailSubmodule.reducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
  loadDetail: loadDetailSubmodule.actions.run,
  aresLoad: aresFetchSubmodule.actions.run,
  joinUsersToOrganisation: submoduleJoinUsersToOrganisation.actions.run,
  removeUsersFromOrganisation: submoduleRemoveUsersFromOrganisation.actions.run,
  activateValidation: submoduleActivateValidation.actions.run,
  deactivateValidation: submoduleDeactivateValidation.actions.run,
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
  ...loadDetailSubmodule.selectors,
  // ... ownSelectors
}
