import { saveAs } from 'file-saver'
import getAuthorizationToken from '@utils/getAuthorizationToken'
import { displayToast } from './toast'
import { t } from '@lingui/macro'
import toBase64 from '@utils/toBase64'

const fetchFileAuthorized = async (fileUrl: string, fileName = 'file', method = 'GET', body?: string): Promise<any> => {
  const token = getAuthorizationToken()
  return await fetch(fileUrl, {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body,
  })
    .then((response) => {
      if (response.status === 401) {
        throw new Error('Error: unauthorized.')
      }
      return response
    })
    .then((response) => response.blob())
    .then((blob) => saveAs(blob, fileName))
    .catch((e) => {
      console.error(e)
      displayToast({ type: 'error', text: t({ id: 'fileDownload.error', message: 'File download failed.' }) })
    })
}

// @TODO merge with fetchFileAuthorized
export const fetchFileAuthorizedAsBase64 = async (
  fileUrl: string,
  fileName = 'file',
  method = 'GET',
  body?: string,
): Promise<any> => {
  const token = getAuthorizationToken()
  return await fetch(fileUrl, {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body,
  })
    .then((response) => {
      if (response.status === 401) {
        throw new Error('Error: unauthorized.')
      }
      return response
    })
    .then((response) => response.blob())
    .then((blob) => toBase64(blob))
    .catch((e) => {
      console.error(e)
      displayToast({ type: 'error', text: t({ id: 'fileDownload.error', message: 'File download failed.' }) })
    })
}

export default fetchFileAuthorized
