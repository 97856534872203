import gql from 'graphql-tag'

const suppliersGetQuery = gql`
  query suppliersGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    suppliersGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting })
      @rest(type: "SuppliersResult", method: "POST", path: "supplier/list") {
      results
      paging
    }
  }
`

export default suppliersGetQuery
