import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik'
import React, { useCallback } from 'react'
import { FormikHandleSubmit } from '@typings/Formik'
import StockAdviceEditForm, { Props as StockAdviceEditFormProps } from './StockAdviceEditForm'
import createValidationSchema from './validation'
import { convertToISO } from '@utils/date'
import { useAcl } from '@components/blocks/Acl'
import { EditableStatuses, PairingStatuses } from '@typings/enums/StockAdvice'

export interface Props extends StockAdviceEditFormProps {
  /**
   * initial values loaded from API
   */
  initialValues: FormikValues
  /**
   * on Save button click
   */
  onSave: FormikHandleSubmit
  /**
   * on Save and Send button click
   */
  onSaveAndSend: FormikHandleSubmit
  /**
   * on Delete button click
   */
  onDelete: FormikHandleSubmit
  /**
   * on Send button click
   */
  onSend: FormikHandleSubmit
  /**
   * Open already closed advice click
   */
  onOpen: FormikHandleSubmit
  /**
   * Close partially-finished advice click
   */
  onClose: FormikHandleSubmit
}

const initialValuesEmpty = {}

const StockAdviceEditFormFormik = ({
  initialValues = initialValuesEmpty,
  onSave,
  onSend,
  onSaveAndSend,
  ...rest
}: Props): JSX.Element => {
  const ability = useAcl()
  const isEditAllowed = ability.can('edit', 'StockAdvice')
  const isEditable = isEditAllowed && EditableStatuses.includes(initialValues?.status)
  const isPairingEditable = isEditAllowed && PairingStatuses.includes(initialValues?.status)

  const handleSubmit = useCallback(
    ({ button, ...values }, formikHelpers: FormikHelpers<FormikValues>) => {
      const newValues = { ...values, expectedAt: convertToISO(values.expectedAt) }

      switch (button) {
        case 'save':
          onSave(newValues, formikHelpers)
          break
        case 'send':
          onSend(newValues, formikHelpers)
          break
        case 'saveAndSend':
          onSaveAndSend(newValues, formikHelpers)
          break
      }
    },
    [onSave, onSend, onSaveAndSend],
  )

  const initialTouched = React.useMemo(() => {
    const initialTouched = {}
    initialTouched.items = (initialValues?.items || []).map((item, index) => {
      return { bookAdviceTotal: true, ...(initialTouched.items?.[index] || {}) }
    }, [])
    return initialTouched
  }, [initialValues?.items])

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      initialTouched={initialTouched}
      validationSchema={createValidationSchema(isEditable)}
    >
      {(formikBag: FormikProps<FormikValues>): JSX.Element => (
        <StockAdviceEditForm isEditable={isEditable} isPairingEditable={isPairingEditable} {...formikBag} {...rest} />
      )}
    </Formik>
  )
}

export default StockAdviceEditFormFormik
