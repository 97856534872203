import React, { memo, useCallback, useMemo, useState } from 'react'
import styled, { createGlobalStyle } from '@xstyled/styled-components'
import ToastMessagesContainer from './components/ToastMessagesContainer'
import { Normalize, NormalizeBaseFonts } from '@designSystem/Normalize'
import LeftMenu from '@components/blocks/LeftMenu'
import StatusRows from '@components/blocks/StatusRows'
import { Item } from '@components/blocks/LeftMenu/types'
import { CornerDialogType, ProcessDialog as ProcessDialogType, QuickAccessDialogs as QuickAccessDialogsType } from '@typings/UI'
import LightBox from '@components/blocks/LightBox'
import ProcessDialog from '@components/blocks/Dialogs/ProcessDialog'
import QuickAccessDialogs from './QuickAccessDialogs'
import { CornerDialog } from '@components/blocks/CornerDialog'
import { DialogBody } from './components/DialogBody'
import { History } from 'history'
import Message from '@typings/entities/Message'
import { x } from '@xstyled/styled-components'
import { useCheckDeviceWidth } from './utils'
import CookieBanner from '@components/blocks/CookieBanner'
import CreateButton from '@components/blocks/LeftMenu/components/CreateButton'
import { createQuickMenuItems } from '@components/blocks/LeftMenu/createMenuItems'
import { useDispatch, useSelector } from 'react-redux'
import { selectors as organisationSelectors } from '@store/organisation'
import { Ability, withAcl } from '@components/blocks/Acl'

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  ::-webkit-scrollbar {
    width: 17px;
  }

  ::-webkit-scrollbar-thumb {
    border: none;
    border-radius: 4px;
    box-shadow: inset 0 0 10px 10px ${({ theme }) => theme.colors.lightGray4};

    &:hover {
      box-shadow: inset 0 0 10px 10px ${({ theme }) => theme.colors.gray};
    }
  }

  ::-webkit-scrollbar-track {
    backgroundcolor: bgLightGray1;
    borderradius: 4px;
    margin: 6px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGray2};
    }
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`

export const LeftCol = styled(x.div)<{ isLeftMenuOpen: boolean }>`
  display: ${({ isLeftMenuOpen }) => (isLeftMenuOpen ? 'block' : 'none')};

  @media (min-width: 1024px) {
    display: block;
    align-self: stretch;
    height: fit-content;

    @media print {
      display: none;
    }
  }
`

export const RightCol = styled(x.div)`
  align-self: stretch;
  flex-grow: 1;
`

export const Wrapper = styled.div<{
  leftMenuCompact: boolean
}>`
  display: flex;
  max-width: 100%;
  flex-grow: 1;
  background: ${({ theme }) => theme.colors.bgLightGray};
  height: 92vh;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  @media (min-width: 1024px) {
    ::-webkit-scrollbar {
      width: 15px;
    }
  }

  ${LeftCol} + ${RightCol} {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
      max-width: ${(props): string => (props.leftMenuCompact ? 'calc(100% - 75px)' : 'calc(100% - 255px)')};
      margin-left: ${(props): string => (props.leftMenuCompact ? '75' : '255')}px;
    }
  }

  @media print {
    ${LeftCol} + ${RightCol} {
      margin-left: 0;
    }
  }
`

export const ContentWrapper = styled(x.div)`
  height: 100%;
  margin: 0;
  @media (min-width: 1024px) {
    margin: 4;
  }
`

export type Props = {
  children: React.ReactNode
  hasLayout?: boolean
  leftMenuItems: Item[]
  cornerDialog: CornerDialogType | null
  processDialog: ProcessDialogType | null
  isCookieBannerHidden: boolean
  quickAccessDialogs: QuickAccessDialogsType
  setQuickAccessDialogs: (dialogs: QuickAccessDialogsType) => void
  onCloseCornerDialog: () => void
  onCloseProcessDialog: () => void
  onCloseCookieBanner: () => void
  statusRows?: Message[]
  leftMenuCompact: boolean
  setLeftMenuCompact?: (compact: boolean) => void
  HeadingComponent: React.ComponentType
  history: History
  ability: Ability
}

const CommonLayout = ({
  children,
  hasLayout = true,
  leftMenuItems,
  cornerDialog,
  processDialog,
  statusRows,
  isCookieBannerHidden,
  onCloseCornerDialog,
  onCloseProcessDialog,
  onCloseCookieBanner,
  quickAccessDialogs,
  setQuickAccessDialogs,
  leftMenuCompact,
  setLeftMenuCompact,
  HeadingComponent,
  history,
  ability,
}: Props): JSX.Element | null => {
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState<boolean>(false)
  const dispatch = useDispatch()

  const onCloseLeftMenu = useCallback(() => {
    setIsLeftMenuOpen?.(false)
  }, [setIsLeftMenuOpen])
  const { isMobile } = useCheckDeviceWidth()
  const isLeftMenuCompact = isMobile ? false : leftMenuCompact

  const activeOrganisation = useSelector(organisationSelectors.organisation)

  const quickMenuItems = useMemo(
    () => [...createQuickMenuItems(dispatch, ability, !!activeOrganisation)],
    [ability, activeOrganisation, dispatch],
  )

  if (typeof window === 'undefined') {
    return null
  }

  return (
    <>
      <GlobalStyle />
      <Normalize />
      <NormalizeBaseFonts />

      {/* PAGE CONTENT */}
      {hasLayout && <HeadingComponent isLeftMenuOpen={isLeftMenuOpen} setIsLeftMenuOpen={setIsLeftMenuOpen} />}
      <Wrapper leftMenuCompact={isLeftMenuCompact}>
        {hasLayout && (
          <LeftCol isLeftMenuOpen={isLeftMenuOpen}>
            <LeftMenu
              menuItems={leftMenuItems}
              isCompact={isLeftMenuCompact}
              setIsCompact={setLeftMenuCompact}
              isLeftMenuOpen={isLeftMenuOpen}
              onCloseLeftMenu={onCloseLeftMenu}
            >
              <CreateButton items={quickMenuItems} isCompact={isLeftMenuCompact} />
            </LeftMenu>
          </LeftCol>
        )}
        <RightCol>
          <ContentWrapper>
            {statusRows && statusRows?.length > 0 && (
              <x.div px={2} pt={2}>
                <StatusRows statusRows={statusRows} />
              </x.div>
            )}
            {children}
          </ContentWrapper>
        </RightCol>
      </Wrapper>

      {/* DIALOGS */}
      <QuickAccessDialogs dialogs={quickAccessDialogs} setDialogs={setQuickAccessDialogs} />
      <LightBox />

      {cornerDialog && (
        <CornerDialog
          title={cornerDialog.title}
          onClose={onCloseCornerDialog}
          intent={cornerDialog.intent || 'success'}
          icon={cornerDialog.intent === 'error' ? 'false' : 'check'}
        >
          <DialogBody
            text={cornerDialog.text}
            onClose={onCloseCornerDialog}
            redirectTo={cornerDialog.redirectTo}
            history={history}
            confirmLabel={cornerDialog.confirmLabel}
            actionLabel={cornerDialog.actionLabel}
          />
        </CornerDialog>
      )}
      {!isCookieBannerHidden && <CookieBanner onClose={onCloseCookieBanner} />}
      {processDialog && <ProcessDialog {...processDialog} onClose={onCloseProcessDialog} />}
      <ToastMessagesContainer
        position="top-right"
        autoClose={10000}
        closeButton={false}
        closeOnClick={false}
        draggable={false}
        hideProgressBar
        newestOnTop
      />
    </>
  )
}

export default withAcl(memo(CommonLayout))
