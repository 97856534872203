import { t } from '@lingui/macro'
import { type TabDefinition } from '@mailstep/design-system/ui/Blocks/Tabs'

export const tabsDefinitions = (): TabDefinition[] => [
  {
    icon: 'cube',
    label: t({ id: 'product.tabs.edit', message: 'Edit product' }),
    default: true,
  },
  {
    icon: 'flatBox',
    label: t({ id: 'product.tabs.stockOverview', message: 'Stock overview' }),
  },
]

export const stockTabsDefinitions = (): TabDefinition[] => [
  {
    icon: 'cube',
    label: t({ id: 'product.tabs.editSet', message: 'Edit set' }),
    default: true,
  },
]
