import React from 'react'
import styled, { x } from '@xstyled/styled-components'
import { H2, Paragraph3, Text } from '@mailstep/design-system/ui/Elements/Typography'
import ErrorMessage from '@mailstep/design-system/ui/Elements/ErrorMessage'
import { Row, HeadingWrapper } from './styles'
import { ExtraControlButton } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import ExtraControlButtons from '@mailstep/design-system/ui/Blocks/CommonGrid/components/ExtraControlButtons'
import Breadcrumbs from '../Breadcrumbs'

type Props = {
  heading: JSX.Element
  itemsCount?: number
  description?: JSX.Element
  breadcrumbsItems?: string
  eshopId?: string
  error?: Error
  children?: React.ReactNode
  $flexCol?: boolean
  extraControlButtons?: ExtraControlButton[]
}
export const RoundedWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  background-color: lightGray7;
  border-radius: 29px;
  padding: 3px 10px;
  margin-left: 8px;
`

const StickyToLeft = styled(x.div)`
  position: sticky;
  left: 24px;
`

const GridPageHeader = ({
  heading,
  itemsCount,
  description,
  breadcrumbsItems,
  eshopId,
  error,
  children,
  $flexCol,
  extraControlButtons,
}: Props): JSX.Element => {
  return (
    <Row $flexCol={$flexCol}>
      <HeadingWrapper variant="grid">
        {breadcrumbsItems && (
          <StickyToLeft>
            <Breadcrumbs eshopId={eshopId} items={breadcrumbsItems} />
          </StickyToLeft>
        )}

        <x.div
          display="flex"
          justifyContent="space-between"
          w="100%"
          flexWrap="wrap"
          alignItems="center"
          position="relative"
          zIndex="2"
        >
          <StickyToLeft display="flex" flexDirection="row" alignItems="center">
            <H2 variant="medium">{heading}</H2>
            {!!itemsCount && (
              <RoundedWrap>
                <Text variant="medium">{itemsCount}</Text>
              </RoundedWrap>
            )}
          </StickyToLeft>
          <x.div position="sticky" right="24px" left="auto">
            <ExtraControlButtons extraControlButtons={extraControlButtons} />
          </x.div>
        </x.div>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        {description && (
          <StickyToLeft>
            <Paragraph3 variant="normal" mt={0} mb={0}>
              {description}
            </Paragraph3>
          </StickyToLeft>
        )}
      </HeadingWrapper>
      {children}
    </Row>
  )
}

export default GridPageHeader
