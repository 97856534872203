import { ResponsiveSectionWrap } from '@components/forms/styles'
import styled from '@xstyled/styled-components'

export const TimelineWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 4 3;
  & > :not(:last-child) {
    margin-bottom: 4;
  }
  @media (min-width: 1024px) {
    margin: 4 3;
    & > :not(:last-child) {
      margin-bottom: 4;
    }
  }
`

export const TimelineItem = styled(ResponsiveSectionWrap)`
  & > :not(:last-child) {
    margin-bottom: 2;
    @media (min-width: 1024px) {
      margin-bottom: 0;
    }
  }
`

export const TimelineHighlightLabel = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: left;
  & > :first-child {
    margin-right: 2;
  }
`

export const TimelineTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: gray;
  margin-left: 4;
`
