import reduceReducers from 'reduce-reducers'
import createFetchModule from '@utils/store/createFetchModule'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'

export const moduleName = 'stockAdvices'

const loadDetail = createFetchModule('@store/StockAdvices', 'LOAD_DETAIL', moduleName)
const submoduleAdviceImportTemplate = createFetchSubmodule('@store/StockAdvices', 'IMPORT_TEMPLATE_SINGLE', moduleName)
const getImportTemplateMultipleSubmodule = createFetchSubmodule('@store/StockAdvices', 'IMPORT_TEMPLATE_MULTIPLE', moduleName)
const importStockAdviceSubmodule = createFetchSubmodule('@store/StockAdvices', 'IMPORT_STOCK_ADVICE', moduleName)
const importMultipleSubmodule = createFetchSubmodule('@store/StockAdvices', 'IMPORT_STOCK_ADVICES', moduleName)
const createInboundSubmodule = createFetchSubmodule('@store/StockAdvices', 'CREATE', moduleName)
const editInboundSubmodule = createFetchSubmodule('@store/StockAdvices', 'EDIT', moduleName)
const sendInboundSubmodule = createFetchSubmodule('@store/StockAdvices', 'SEND', moduleName)
const deleteStockAdviceSubmodule = createFetchSubmodule('@store/StockAdvices', 'DELETE', moduleName)
const openStockAdviceSubmodule = createFetchSubmodule('@store/StockAdvices', 'OPEN', moduleName)
const closeStockAdviceSubmodule = createFetchSubmodule('@store/StockAdvices', 'CLOSE', moduleName)

export const actionTypes = {
  loadDetail: loadDetail.actionTypes,
  adviceImportTemplate: submoduleAdviceImportTemplate.actionTypes,
  getImportTemplateMultiple: getImportTemplateMultipleSubmodule.actionTypes,
  importStockAdvice: importStockAdviceSubmodule.actionTypes,
  importMultiple: importMultipleSubmodule.actionTypes,
  createInbound: createInboundSubmodule.actionTypes,
  editInbound: editInboundSubmodule.actionTypes,
  sendStockAdvice: sendInboundSubmodule.actionTypes,
  deleteStockAdvice: deleteStockAdviceSubmodule.actionTypes,
  openStockAdvice: openStockAdviceSubmodule.actionTypes,
  closeStockAdvice: closeStockAdviceSubmodule.actionTypes,
}

export default reduceReducers(loadDetail.reducer)

export const actions: Record<string, (...args: any) => Action> = {
  loadDetail: loadDetail.actions.run,
  adviceImportTemplate: submoduleAdviceImportTemplate.actions.run,
  getImportTemplateMultiple: getImportTemplateMultipleSubmodule.actions.run,
  importStockAdvice: importStockAdviceSubmodule.actions.run,
  importMultiple: importMultipleSubmodule.actions.run,
  createInbound: createInboundSubmodule.actions.run,
  editInbound: editInboundSubmodule.actions.run,
  sendStockAdvice: sendInboundSubmodule.actions.run,
  deleteStockAdvice: deleteStockAdviceSubmodule.actions.run,
  openStockAdvice: openStockAdviceSubmodule.actions.run,
  closeStockAdvice: closeStockAdviceSubmodule.actions.run,
}

export const selectors: Selectors = {
  ...loadDetail.selectors,
  // ... ownSelectors
}
