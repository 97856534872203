import React, { useCallback, useEffect } from 'react'
import styled, { x } from '@xstyled/styled-components'
import { Field, FormikProps, FormikValues } from 'formik'
import getOr from 'lodash/fp/getOr'
import Button from '@mailstep/design-system/ui/Elements/Button'
import { Trans } from '@lingui/react'
import InputField from '@components/forms/components/InputField'
import { FieldLabel } from '@mailstep/design-system/ui/Elements/Label'

type Props = {
  remove: Function
  push: Function
  name: string
  form: FormikProps<FormikValues>
  disabled?: boolean
  icon?: string
}

const InputWrap = styled.div`
  margin: 0 2 0 0;
  width: 38%;
`

const ButtonAdd = styled(Button)`
  margin: 1 0 0 0;
  min-width: calc(24% - 16px);
`

const StyledWrap = styled.div`
  display: flex;
  flex-flow: wrap;
`

const RefNumbersField = ({ remove, push, name, form, disabled, icon }: Props): JSX.Element => {
  const referenceNumbers: string[] = getOr([], name, form?.values)

  const handleRemoveClick = useCallback(
    (index: number): void => {
      if (referenceNumbers.length > 1) {
        if (remove) remove(index)
        form.setTouched({ [name]: true })
      }
    },
    [form, name, referenceNumbers.length, remove],
  )

  const addAnother = useCallback(() => push(''), [push])

  useEffect(() => {
    if (referenceNumbers.length <= 0 && push) push('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceNumbers])

  return (
    <x.div>
      <FieldLabel htmlFor={name}>
        <Trans id="form.productReferenceNumbers.label" message="Reference numbers" />
      </FieldLabel>
      <StyledWrap>
        {referenceNumbers.map((refNumber: string, index: number) => (
          <InputWrap key={`${index}`} size={5}>
            <Field
              component={InputField}
              value={refNumber}
              name={`${name}[${index}]`}
              disabled={disabled}
              icon={icon}
              spaceAround
              id={index as unknown as string}
              onClear={() => handleRemoveClick(index)}
              alwaysShowClear
            />
          </InputWrap>
        ))}
        {!disabled && (
          <ButtonAdd type="button" onClick={addAnother} iconLeft="plus">
            <Trans id="form.productReferenceNumbers.add" message="Add" />
          </ButtonAdd>
        )}
      </StyledWrap>
    </x.div>
  )
}

export default RefNumbersField
