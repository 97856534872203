import React, { useCallback, useMemo, useState } from 'react'
import { GridSelectorsType, GridActionsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { StockAdviceDetailItem, BookStockAdvice } from '@typings/entities/StockAdvice'
import { Trans } from '@lingui/react'
import { FormGrid } from '@components/blocks/FormGrid'
import styled, { x } from '@xstyled/styled-components'
import { t } from '@lingui/macro'
import { Input } from '@mailstep/design-system/ui/Forms/Input'
import { ExpeditionItemNested } from '@typings/entities/Expedition'
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'
import createColumnDefinitions, { GridItem } from './PairingTableDefinition'
import { constructMap } from '@utils/transformations'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'

type Props = {
  onCancel: () => void
  editedAdviceItem?: StockAdviceDetailItem | null
  setExpeditionPairingForRow: (bookStockAdvices: BookStockAdvice[]) => void
  expeditionItems: ExpeditionItemNested[]
  expeditionItemsIsLoading: boolean
  gridSelectors: GridSelectorsType
  gridActions: GridActionsType
  onOpenExpedition: (id: string) => void
}

type PairingMap = { [id: string]: number }

const DetailLine = styled(x.div)`
  display: flex;
  flex-direction: row;
  justify-items: space-around;
  & > :not(:last-child) {
    margin-right: 2;
  }
`

const AdviceOverview = ({
  onCancel,
  editedAdviceItem,
  setExpeditionPairingForRow,
  expeditionItemsIsLoading,
  expeditionItems,
  gridSelectors,
  gridActions,
  onOpenExpedition,
}: Props): JSX.Element => {
  const product = editedAdviceItem?.product

  const initialPairing: PairingMap = useMemo(() => {
    return constructMap(editedAdviceItem?.bookStockAdvices, 'expeditionItem', 'quantity')
  }, [editedAdviceItem?.bookStockAdvices])

  const [itemParing, setItemPairing] = useState<PairingMap>(initialPairing)

  const onRowAction = useCallback(
    (value: string, field: string, row: GridItem): void => {
      if (field === 'change') {
        setItemPairing({
          ...itemParing,
          [row.id]: Number(value),
        })
      }
    },
    [itemParing],
  )

  const filteredExpeditionItem = useMemo(
    () =>
      (expeditionItems || []).filter((item) => {
        // TODO MF-2809 pairing on advice temporarily hidden
        // => meanwhile we are displaying form in read only view => only display paired items
        return !!editedAdviceItem?.bookedStockAdvices.find(
          ({ expeditionItem, quantity }) => expeditionItem == item.id && quantity > 0,
        )

        // // check expedition status
        // if (!EditableStatuses.includes(item?.expedition?.status)) return false
        // // check item lot
        // if (item.lot) return false // TODO should assigned lot be considered too here??
        // return true
      }),
    [editedAdviceItem?.bookedStockAdvices, expeditionItems],
  )

  const handleSubmit = useCallback(() => {
    const advicePairing = map(itemParing, (value, key) => ({
      expeditionItem: key,
      quantity: value,
    })).filter(({ expeditionItem }) => expeditionItem && filteredExpeditionItem.find(({ id }) => id == expeditionItem))
    setExpeditionPairingForRow(advicePairing.filter(({ quantity }) => quantity))
  }, [itemParing, setExpeditionPairingForRow, filteredExpeditionItem])

  const extendedItems: GridItem[] = useMemo(() => {
    return filteredExpeditionItem.map((row) => {
      // since there is one advice item per product, filtering out this item equals to filtering out this advice
      const bookedByOtherAdvices = sumBy(
        (row.bookStockAdvices || []).filter(({ stockAdviceItem }) => stockAdviceItem !== editedAdviceItem?.id),
        'quantity',
      )
      return {
        ...row,
        bookedByOtherAdvices,
        toBook: itemParing[row.id] || 0,
        // itemMaximum = quantity of expedition item - amount of booked stock (normal booking) - amount already paired by other advices
        itemMaximum: row.quantity - (row.booked || 0) - bookedByOtherAdvices,
      }
    })
  }, [filteredExpeditionItem, editedAdviceItem?.id, itemParing])

  return (
    <Modal
      title={<Trans id="form.adviceItems.expeditionPairingTitle" message="Item expedition pairing" />}
      onCancel={onCancel}
      onConfirm={handleSubmit}
      width="wide"
      confirmLabel={t({ id: 'form.buttonSave', message: 'Save' })}
      cancelLabel={t({ id: 'form.buttonCancel', message: 'Cancel' })}
    >
      <DetailLine mb={2}>
        <Input value={product?.name} type={'text'} label={<Trans id="form.productName.label" message="Name" />} disabled />
        <Input
          value={product?.productSku}
          type={'text'}
          label={<Trans id="form.productSku.label" message="Product SKU" />}
          disabled
        />
        <Input
          value={product?.internalSku}
          type={'text'}
          label={<Trans id="form.internalSku.label" message="Internal SKU" />}
          disabled
        />
      </DetailLine>
      <FormGrid
        gridActions={gridActions}
        gridSelectors={gridSelectors}
        items={extendedItems}
        isLoading={expeditionItemsIsLoading}
        columnsDefinitions={createColumnDefinitions(onOpenExpedition)}
        onRowAction={onRowAction}
        minHeightRows={3}
      />
    </Modal>
  )
}

export default AdviceOverview
