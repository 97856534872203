import produce from 'immer'
import camelCase from 'lodash/camelCase'
import get from 'lodash/fp/get'
import upperFirst from 'lodash/upperFirst'

// TODO REWORK ERROR HANDLING IN FETCH MODULES AND SUBMODULES

type Actions = {
  run: (...args: any) => Action
  set: (...args: any) => Action
}

type ActionTypes = {
  run: string
  fetchStarted: string
  fetchFailed: string
  fetchSucceeded: string
  set: string
}

type FetchSubModule = {
  reducer: Reducer
  selectors: {
    [key: string]: any
  }
  actions: Actions
  actionTypes: ActionTypes
}

const promiseControlSymbol = Symbol.for('promiseControl')

const createReducer = (actionFullName: string, actionName: string): Reducer => {
  const lowerActionName = camelCase(actionName)
  return (state: State, action: Action): State =>
    produce(state, (draft) => {
      switch (action.type) {
        case `${actionFullName}_FETCH_STARTED`:
          draft[lowerActionName] = { isLoading: true, loadedAt: undefined }
          return
        case `${actionFullName}_FETCH_FAILED`:
          draft[lowerActionName] = {
            isLoading: false,
            error: action.error,
            errorCode: action.errorCode || null,
            data: null,
          }
          return
        case `${actionFullName}_FETCH_SUCCEEDED`:
          draft[lowerActionName] = {
            isLoading: false,
            loadedAt: new Date().getTime(),
            error: action.error,
            errorCode: action.errorCode || null,
            data: action.data,
          }
          return
        case `${actionFullName}_SET`:
          draft[lowerActionName] = {
            data: {
              ...draft[lowerActionName]?.data,
              ...action.payload,
            },
          }
          return
      }
    })
}

const createSelector = (reducerKey: string, actionName: string): Function => {
  const prefixStartCase = `${camelCase(reducerKey)}${upperFirst(camelCase(actionName))}`
  const lowerActionName = camelCase(actionName)
  return (state: object): object => ({
    isLoading: get(`[${reducerKey}][${lowerActionName}].isLoading`, state),
    error: get(`[${reducerKey}][${lowerActionName}].error`, state),
    errorCode: get(`[${reducerKey}][${lowerActionName}].errorCode`, state),
    data: get(`[${reducerKey}][${lowerActionName}].data`, state),
    loadedAt: get(`[${reducerKey}][${lowerActionName}].loadedAt`, state),
  })
}

const createActionTypes = (actionFullName: string): ActionTypes => {
  return {
    run: actionFullName,
    fetchStarted: `${actionFullName}_FETCH_STARTED`,
    fetchFailed: `${actionFullName}_FETCH_FAILED`,
    fetchSucceeded: `${actionFullName}_FETCH_SUCCEEDED`,
    set: `${actionFullName}_SET`,
  }
}

const createActions = (actionName: string): Actions => {
  return {
    run: (payload: any): Action => {
      let promiseControl = {}
      const promise = new Promise((resolve, reject) => {
        promiseControl = { resolve, reject }
      })
      return {
        type: actionName,
        payload,
        promise,
        [promiseControlSymbol]: promiseControl,
      }
    },
    set: (payload: any): Action => ({
      type: `${actionName}_SET`,
      payload,
    }),
  }
}

export default (moduleName: string, actionName: string, reducerKey: string): FetchSubModule => {
  const actionFullName = `${moduleName}/${actionName}`
  const actionTypes = createActionTypes(actionFullName)
  const prefixStartCase = `${camelCase(reducerKey)}${upperFirst(camelCase(actionName))}`
  return {
    reducer: createReducer(actionFullName, actionName),
    selectors: {
      [prefixStartCase]: createSelector(reducerKey, actionName),
    },
    actions: createActions(actionFullName),
    actionTypes,
  }
}
