import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { x } from '@xstyled/styled-components'
import { BatchActionModal, useBatchActionModal } from '@components/blocks/BatchActionModal'
import ActionSelectModalContent from './ActionSelectModalContent'
import { GridActionsType, GridSelectorsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { Types as CGT, withReduxActions } from '@mailstep/design-system/ui/Blocks/CommonGrid'
import getOr from 'lodash/fp/getOr'
import { Trans } from '@lingui/react'
import { useItemsQty } from './useItemsQty'

import {
  BookStockAdvice,
  DeletedItemApiReadExtended,
  ExpeditionDetail,
  ExpeditionDetailGridItem,
  ExpeditionDetailItem,
} from '@typings/entities/Expedition'
import { formikCallback } from '@typings/Formik'
import { createActionColumnDefinition, createColumnDefinitions } from './TableDefinition'
import { AddProductItem, FormGrid, useFormGridChange } from '@components/blocks/FormGrid'
import LotsList from './LotsList'
import PairingOverview from './PairingOverview'
import { ProductApiRead } from '@typings/entities/Product'
import { ProductStockApi, ProductStockApiLot } from '@typings/entities/ProductStock'
import Fieldset from '@designSystem/Fieldset'
import { useModal } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useModal'
import { StockAdviceItemApi } from '@typings/entities/StockAdvice'
import { nanoid } from 'nanoid'
import sumBy from 'lodash/sumBy'
import { FileSubmit, OnDownloadTemplate } from '@typings/file'
import FormGridTitle from '@components/elements/FormGridTitle'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'
import { isMetaLot } from '@utils/expedition'
import { StyledSubTitle } from '@components/elements/Typography/lib'
import { FormikProps, FormikValues } from 'formik'
import { duplicateItemMerge } from '@components/forms/utils/FormItems'
import Button from '@mailstep/design-system/ui/Elements/Button'
// TODO bookAdviceTotal should be automaticaly recomputend somewhere in qty calculator

type Props = {
  form: FormikProps<FormikValues>
  replace: (index: number, value: any) => void
  loadProducts: (fulltext: string) => Promise<ProductApiRead[]>
  isEditable?: boolean
  isPairingEditable?: boolean
  setFieldValue: formikCallback
  warehouse: string
  wms: string
  loadProductStocks: (productIds: string[], warehouse: string, wms: string) => Promise<ProductStockApi[]>
  bookedData?: ExpeditionDetail['bookedData']
  gridSelectors: GridSelectorsType
  gridActions: GridActionsType
  lotsGridSelectors: GridSelectorsType
  lotsGridActions: GridActionsType
  pairingGridSelectors: GridSelectorsType
  pairingGridActions: GridActionsType
  isExpedited: boolean
  loadAdviceItems: (productId: string, warehouse: string, wms: string) => void
  adviceItems: StockAdviceItemApi[]
  adviceItemsIsLoading: boolean
  onOpenAdvice: (id: string) => void
  onImportItems: FileSubmit<{ product: ProductApiRead; quantity: number }[]>
  onDownloadImportTemplate: OnDownloadTemplate
  virtualItems: ExpeditionDetail['virtualItems']
  handleItemsReposition: () => void
  hasDuplicatePositions?: boolean
  canReposition: boolean
}

const createEmptyItem = (qty: number, product: ProductApiRead): ExpeditionDetailItem => ({
  id: `${nanoid()}`,
  product: product,
  quantity: parseInt(qty),
  lot: '__oldest',
  lifo: false,
  book: 0,
  availableTotal: 0,
  stocksState: 'loading', // will be possibly changed to notReady with rerender
  bookStockAdvices: [],
  bookAdviceTotal: 0,
  bookedAdviceTotal: 0,
})

const itemIdentifier = ['product.id', 'lot']

const customMergeId = (item: ExpeditionDetailItem): string =>
  `${item.product.id}|${item.lot && !isMetaLot(item.lot) ? item.lot : ''}`

const ConnectedFormGrid = withReduxActions('ExpeditionItems')(FormGrid)

const ExpeditionItemsField = ({
  form,
  replace,
  loadProducts,
  isEditable,
  isPairingEditable,
  setFieldValue,
  warehouse,
  wms,
  bookedData,
  loadProductStocks,
  gridSelectors,
  gridActions,
  lotsGridSelectors,
  lotsGridActions,
  pairingGridSelectors,
  pairingGridActions,
  isExpedited,
  loadAdviceItems,
  adviceItems,
  adviceItemsIsLoading,
  onOpenAdvice,
  onImportItems,
  onDownloadImportTemplate,
  virtualItems,
  handleItemsReposition,
  hasDuplicatePositions,
  canReposition,
}: Props): JSX.Element => {
  const itemsRaw: ExpeditionDetailItem[] = getOr([], 'values.items', form)
  const error = getOr(null, 'errors.items', form)
  // we need field bookAdviceTotal touched as multiple sources can make it invalid
  useEffect(() => {
    itemsRaw.forEach((itemsRaw, index) => {
      if (!form.touched?.items?.[index]?.bookAdviceTotal) {
        form.setFieldTouched(`items[${index}]['bookAdviceTotal']`, true, false)
      }
    })
  }, [form, itemsRaw])

  const { allItems, quantitiesAreLoading } = useItemsQty(
    itemsRaw,
    warehouse,
    wms,
    bookedData,
    setFieldValue,
    loadProductStocks,
    isEditable,
  )

  const setItems = useCallback((items: ExpeditionDetailItem[]) => setFieldValue('items', items, false), [setFieldValue])
  const { onAdd, onRemove, getIndex } = useFormGridChange<ExpeditionDetailItem>({
    gridSelectors,
    gridActions,
    allItems,
    itemIdentifier,
    setItems,
    duplicateItemMerge,
    customMergeId,
  })

  const {
    onOpen: setLotsVisible,
    isOpen: lotsVisible,
    onClose: closeLots,
    data: lotsExpeditionItem,
  } = useModal<ExpeditionDetailItem | null>()

  const setLotForRow = useCallback(
    (lot: ProductStockApiLot['lot']) => {
      if (!lotsExpeditionItem) return
      const extraValues: Partial<ExpeditionDetailItem> = {}
      // if selecting lot also wipe out pairing - items with specific lot can not be paired
      if (!isMetaLot(lot)) {
        extraValues.bookStockAdvices = []
        extraValues.bookAdviceTotal = 0
      }
      replace(getIndex(lotsExpeditionItem), { ...lotsExpeditionItem, lot, ...extraValues })
      closeLots()
    },
    [lotsExpeditionItem, closeLots, getIndex, replace],
  )

  const {
    onOpen: setAdvicesVisible,
    isOpen: advicesVisible,
    onClose: closeAdvices,
    data: advicesDialogItem,
  } = useModal<ExpeditionDetailGridItem | null>()
  const setAdvicePairingForRow = useCallback(
    (bookStockAdvices: BookStockAdvice[]) => {
      if (!advicesDialogItem) return
      const bookAdviceTotal = sumBy(bookStockAdvices, 'quantity')
      replace(getIndex(advicesDialogItem), { ...advicesDialogItem, bookStockAdvices, bookAdviceTotal })
      closeAdvices()
    },
    [advicesDialogItem, closeAdvices, getIndex, replace],
  )

  const submitBatchAction = useCallback(
    (action: string, affectedRows: ExpeditionDetailGridItem[]) => {
      if (action === 'reserve') {
        affectedRows.forEach((item) => {
          replace(getIndex(item), {
            ...item,
            book: item.quantity > item.availableTotal ? item.availableTotal : item.quantity,
          })
        })
      } else if (action === 'cancelReservation') {
        affectedRows.forEach((item) => {
          replace(getIndex(item), { ...item, book: 0 })
        })
      } else if (action === 'delete') {
        onRemove(affectedRows)
      }
      // this will uncheck all rows in grid. TODO make it automatic response to successful batchAction
      gridActions.handleUxChange && gridActions.handleUxChange('checkedRows', {})
    },
    [gridActions, replace, onRemove, getIndex],
  )

  const { onBatchAction, modalProps } = useBatchActionModal(submitBatchAction)

  const onRowAction = useCallback(
    (value: string, field: string, row: ExpeditionDetailGridItem): void => {
      if (field === 'remove') onRemove([row])
      else if (field === 'choseLot') {
        setLotsVisible(row)
      } else if (field === 'pairAdvice') {
        loadAdviceItems(row.product.id, warehouse, wms)
        setAdvicesVisible(row)
      }
    },
    [onRemove, setLotsVisible, loadAdviceItems, warehouse, wms, setAdvicesVisible],
  )

  const columnsDefinitions = createColumnDefinitions(!!isEditable, !!isPairingEditable, isExpedited, getIndex)
  const actionColumnDefinition = isEditable
    ? createActionColumnDefinition(!quantitiesAreLoading)
    : { flexBasis: 40, addRowNumbers: true }

  const errorString = error && typeof error === 'string' && form.submitCount > 0 ? error : null

  const decoratedItems: ExpeditionDetailGridItem[] = useMemo(
    () =>
      [
        ...allItems,
        // virtual items can be merged in this simply, because they have no stock that would affect anything
        ...(virtualItems || []).map(
          (item: DeletedItemApiReadExtended, index): ExpeditionDetailGridItem => ({
            index: allItems.length + index,
            ...createEmptyItem(item.quantity, item.product),
          }),
        ),
      ].map((item) => ({
        ...item,
        index: item.index + 1, // enumerate items form 1 instead of form 0
        [CGT.rowClassSymbol]: item.product.type == 'virtual' ? 'grayedOut' : null,
      })),
    [allItems, virtualItems],
  )

  return (
    <>
      <Fieldset fullWidth>
        <FormGridTitle title={<Trans id="form.expeditionItems.heading" message="Outbound items" />} error={errorString} />

        {isEditable && (
          <AddProductItem<ExpeditionDetailItem>
            onAdd={onAdd}
            loadProducts={loadProducts}
            formatGridItem={createEmptyItem}
            onImportItems={onImportItems}
            onDownloadImportTemplate={onDownloadImportTemplate}
          />
        )}

        {!!(hasDuplicatePositions && canReposition) && (
          <x.div display="flex" justifyContent="flex-end">
            <Button type="button" variant="default" appearance="tertiary" onClick={handleItemsReposition}>
              <Trans id="form.buttonReposition" message="Reposition" />
            </Button>
          </x.div>
        )}

        <x.div mt={3} mb={3}>
          <ConnectedFormGrid
            items={decoratedItems}
            columnsDefinitions={columnsDefinitions}
            actionColumnDefinition={actionColumnDefinition}
            gridSelectors={gridSelectors}
            gridActions={gridActions}
            onBatchAction={onBatchAction}
            onRowAction={onRowAction}
            minColumnWidth={30}
          />
        </x.div>
      </Fieldset>

      {lotsVisible && (
        <Modal
          onCancel={closeLots}
          hasFooter={false}
          width="medium"
          title={<Trans id="form.expeditionItems.chooseLotTitle" message="Choose lot" />}
          cancelLabel={<Trans id="form.buttonCancel" message="Cancel" />}
        >
          <>
            <x.div mt={4} pb={3}>
              <StyledSubTitle>
                <Trans id="form.expeditionItems.lotItems" message="LOT items" />
              </StyledSubTitle>
            </x.div>
            <LotsList
              stockLots={lotsExpeditionItem?.stockLots}
              gridActions={lotsGridActions}
              gridSelectors={lotsGridSelectors}
              onLotSelect={setLotForRow}
            />
          </>
        </Modal>
      )}

      {advicesVisible && advicesDialogItem && (
        <PairingOverview
          onCancel={closeAdvices}
          item={advicesDialogItem}
          setAdvicePairingForRow={setAdvicePairingForRow}
          adviceItems={adviceItems}
          adviceItemsIsLoading={adviceItemsIsLoading}
          gridActions={pairingGridActions}
          gridSelectors={pairingGridSelectors}
          onOpenAdvice={onOpenAdvice}
        />
      )}
      <BatchActionModal {...modalProps} modalContentComponent={ActionSelectModalContent} />
    </>
  )
}

export default memo(ExpeditionItemsField)
