import { useQuery } from '@apollo/client'
import usersGetQuery from '@queries/usersGetQuery'
import { ApiHookResult } from '@typings/ApiHooks'
import { UserApi } from '@typings/entities/User'

export const useLoadUserList = (skip: boolean, criteria: object): ApiHookResult<UserApi[]> => {
  const {
    data,
    error,
    loading: isLoading,
  } = useQuery(usersGetQuery, {
    variables: { criteria },
    skip: skip || !criteria,
  })

  return {
    data: data?.usersGet?.results,
    isLoading,
    error,
  }
}
