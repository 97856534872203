import reduceReducers from 'reduce-reducers'
import createFetchModule from '@utils/store/createFetchModule'

export const moduleName = 'filters'

const fetchModule = createFetchModule('@store/Filters', 'LOAD', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
}

export default reduceReducers(fetchModule.reducer)

export const actions = {
  ...fetchModule.actions,
}

export const selectors = {
  ...fetchModule.selectors,
}
