import { t } from '@lingui/macro'
import { FlagAppearanceType } from '@constants/FlagAppearance'

export const statusToColor: { [status: string]: FlagAppearanceType } = {
  error: 'redSubtle',
  finish: 'greenSubtle',
  start: 'neutralSubtle',
  ongoing: 'neutralSubtle',
  warning: 'yellowSubtle',
}

export const orderStatusTrans = {
  finish: () => t({ id: 'synchronization.success', message: `Synced` }),
  error: () => t({ id: 'synchronization.failed', message: `Failed` }),
  start: () => t({ id: 'synchronization.inProgress', message: `In progress` }),
}

export const statusFiltersTrans = {
  finish: () => t({ id: 'synchronization.success', message: `Synced` }),
  error: () => t({ id: 'synchronization.failed', message: `Failed` }),
  warning: () => t({ id: 'synchronization.warning', message: `Warning` }),
}

export const typeToColor: { [value: string]: FlagAppearanceType } = {
  order_status: 'blueSubtle',
  order_sync: 'neutralSubtle',
  package_info: 'blueSubtle',
  product_sync: 'blueSubtle',
  product_stock: 'neutralSubtle',
  product_deleted: 'yellowSubtle',
}

export const movementTypeTrans = {
  order_status: () => t({ id: 'movementType.OrderStatus', message: `Order status` }),
  order_sync: () => t({ id: 'movementType.OrderSync', message: `Order sync` }),
  package_info: () => t({ id: 'movementType.PackageInfo', message: `Package info` }),
  product_sync: () => t({ id: 'movementType.ProductSync', message: `Product sync` }),
  product_stock: () => t({ id: 'movementType.ProductStock', message: `Product stock` }),
  product_deleted: () => t({ id: 'movementType.ProductDeleted', message: `Product deleted` }),
}

export const aggregatedOrderTypeToColor: { [value: string]: FlagAppearanceType } = {
  order_sync: 'neutralSubtle',
  order_status: 'blueSubtle',
  package_info: 'blueSubtle',
}

export const aggregatedOrderTypeTrans = {
  order_status: () => t({ id: 'aggregatedOrderType.UpdateSync', message: `Status update` }),
  order_sync: () => t({ id: 'aggregatedOrderType.OrderSync', message: `Order creation` }),
  package_info: () => t({ id: 'aggregatedOrderType.PackageInfo', message: `Package info` }),
}

export const aggregatedProductTypeToColor: { [value: string]: FlagAppearanceType } = {
  product_stock: 'blueSubtle',
  product_sync: 'blueSubtle',
  product_deleted: 'yellowSubtle',
}
export const aggregatedProductTypeTrans = {
  product_stock: () => t({ id: 'aggregatedProductType.StockSync', message: `Stock update` }),
  product_sync: () => t({ id: 'aggregatedProductType.ProductSync', message: `Product update` }),
  product_deleted: () => t({ id: 'aggregatedProductType.ProductDeleted', message: `Product deleted` }),
}

export const destinationTypeTrans = {
  mailship: () => t({ id: 'destinationType.mailship', message: 'Mailship' }),
  baselinker: () => t({ id: 'destinationType.baselinker', message: 'BaseLinker' }),
}
