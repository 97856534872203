import withFormikToProps from '@utils/formik/withFormikToProps'
import Toggle from '@mailstep/design-system/ui/Elements/Toggle'
import React, { useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'

type Props = {
  name: string
  value?: boolean | null
  onChange: (event: { target: { [key: string]: string | number | undefined } }) => void
  disabled?: boolean
}

const SwitchField = ({ value, name, onChange, disabled, ...rest }: Props): JSX.Element => {
  const { values, setFieldValue } = useFormikContext()
  useEffect(() => {
    setFieldValue('headersOnly', true)
  }, [])

  const handleChange = useCallback(
    (event): void => {
      onChange({
        target: {
          name,
          type: 'change',
          value: event.target.checked,
        },
      })
    },
    [name, onChange],
  )

  return (
    <Toggle
      checked={!!value}
      onChange={disabled ? undefined : handleChange}
      name={name}
      variant="grid"
      disabled={disabled}
      {...rest}
    />
  )
}

export default withFormikToProps(SwitchField)
