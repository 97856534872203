import gql from 'graphql-tag'

const exportJobQuery = gql`
  query exportJob($jobID: String) {
    exportJobResult(jobID: $jobID, input: {}) @rest(type: "ExportJob", method: "GET", path: "export-job/{args.jobID}") {
      status
      fileUrl
      processedRecords
      expectedRecords
    }
  }
`

export default exportJobQuery
