import React, { useState, useMemo, useCallback } from 'react'
import { selectors as organisationSelectors } from '@store/organisation'
import {
  useDetailFetchModule,
  useFetchModuleSelector,
  useLazyListFetchModule,
  useListFetchModule,
} from '@utils/containers/useFetchModule'
import { useDispatch, useSelector } from 'react-redux'
import LoadingOverlay from '@components/blocks/LoadingOverlay'
import { useLoadProductStocks, useProductItems } from '@utils/containers/productItems'
import { useGridStore } from '@hooks/useCommonGrid'
import { sentMessage, useActionSubmit, useSaveAndSend } from '@utils/containers/advancedFormHelpers'
import { t } from '@lingui/macro'
import { actions as uiActions } from '@store/ui'
import * as expeditionsStore from '@store/expeditions'
import { actions as expeditionsActions } from '@store/expeditions'
import { StockAdviceItemApi } from '@typings/entities/StockAdvice'
import * as stockAdviceItemsStore from '@store/stockAdviceItemsGrid'
import { PairingStatuses as StockAdvicePairingStatuses } from '@typings/enums/StockAdvice'
import * as countriesStore from '@store/countries'
import { Country } from '@typings/entities/Country'
import { AccountData } from '@typings/entities/User'
import * as adminsStore from '@store/admins'
import { Carrier, CarrierPickupPlace } from '@typings/entities/Carrier'
import * as carriersStore from '@store/carriers'
import { ExpeditionApiExtended, ExpeditionDetail } from '@typings/entities/Expedition'
import {
  formatExpeditionToClone,
  formatExpeditionToRead,
  formatInitialError,
  formatInitialTouched,
  getOnSave,
  getOnSend,
  onSearchExternalPickupPlace,
  onSearchPickupPlace,
} from './utils'
import { selectors as organisationsSelectors } from '@store/organisations'
import { Organisation } from '@typings/entities/Organisation'
import * as eshopsStore from '@store/eshops'
import { EshopApi } from '@typings/entities/Eshop'
import getAccountOption from '@utils/getAccountOption'
import ExpeditionEditForm from '@components/forms/ExpeditionEditForm'
import { useApolloClient } from '@apollo/client'
import { useLoadCarrierServicesList } from '@hooks/apiHooks/carrierSerices/useLoadCarrierServicesList'
import { useLoadUserList } from '@hooks/apiHooks/users/useLoadUserList'
import { useLoadWarehousesList } from '@hooks/apiHooks/werehouses/useLoadWarehousesList'
import { useLoadPartnersList } from '@hooks/apiHooks/partners/useLoadPartnersList'
import { useLoadExpeditionServicesList } from '@hooks/apiHooks/expeditionService/useLoadExpeditionServicesList'

type Props = {
  id: string | undefined
  onSuccess: () => void
  onCloseForm: () => void // passed to dialog
  createTitle: string | JSX.Element
  editTitle: string | JSX.Element
  titleIcon: string
}

type Mode = 'create' | 'edit' | 'clone'

const Container = ({ id, onSuccess, onCloseForm, createTitle, editTitle, titleIcon }: Props): JSX.Element => {
  const {
    data,
    isLoading,
    error,
    reload: reloadExpedition,
  } = useDetailFetchModule<ExpeditionApiExtended>(expeditionsStore, id, {
    action: 'getExpedition',
  })

  const [pickupPlacesData, setPickupPlacesData] = useState<CarrierPickupPlace[]>([])
  const [isCloning, setIsCloning] = useState(false)
  const onCloneInvoice = useCallback(() => setIsCloning(true), [])
  const mode: Mode = isCloning ? 'clone' : id ? 'edit' : 'create'
  const isCreatingNew = mode == 'create' || mode == 'clone'

  const selectedOrganisation = useSelector(organisationSelectors.organisation)
  // Expedition does not have organisation, it has eshop and we need to load it and find organisation there
  const usedOrganisation = mode == 'create' ? selectedOrganisation : data?.eshopObj?.organisation

  const dispatch = useDispatch()

  const initialValues: Partial<ExpeditionDetail> = useMemo(() => {
    if (mode == 'clone') return data ? formatExpeditionToClone(data) : {}
    else if (mode == 'edit') return data ? formatExpeditionToRead(data) : {}
    return {}
  }, [data, mode])

  // TODO organisations are special case of loading data, needs to be reworked
  const organisations: Organisation[] = useSelector((state) => organisationsSelectors.organisations(state)?.organisationsData)
  const organisationWithAddressValidation = useMemo(
    () => !!organisations?.find(({ id }) => id === usedOrganisation)?.ignoreAddressValidation,
    [organisations, usedOrganisation],
  )

  const { data: countries, isLoading: countriesIsLoading } = useFetchModuleSelector<Country[]>(countriesStore, 'loadList') // loaded automatically by org

  // TODO this could be probably prettied too
  const { data: admins } = useFetchModuleSelector<AccountData[]>(adminsStore, null)
  const { data: users } = useLoadUserList(!usedOrganisation, { organisations: { eq: usedOrganisation } })
  const userOptions = useMemo(() => (users || []).map(getAccountOption), [users])
  const accountOptions = useMemo(() => [...userOptions, ...(admins || []).map(getAccountOption)], [userOptions, admins])

  const { data: carriers, isLoading: carriersIsLoading } = useListFetchModule<Carrier>(
    carriersStore,
    { organisations: { eq: usedOrganisation } },
    !usedOrganisation,
  )

  const carrierIds = carriers.map((carrier: Carrier) => carrier.id)
  const { data: carrierServices, isLoading: carrierServiceIsLoading } = useLoadCarrierServicesList(carrierIds)

  const { data: warehouses, isLoading: warehousesIsLoading } = useLoadWarehousesList(usedOrganisation)
  const { data: expeditionServices, isLoading: expeditionServicesIsLoading } = useLoadExpeditionServicesList(usedOrganisation)
  const { data: eshops, isLoading: eshopsIsLoading } = useListFetchModule<EshopApi>(
    eshopsStore,
    { organisation: usedOrganisation },
    !usedOrganisation,
  )
  // TODO it would be really nice if BE allowed loading partners by organisation.
  // in expedition form we need to first load organisation by eshop and then eshops by organisation so we can load partners by eshops....
  const eshopIds = eshops.map(({ id }) => id)
  const { data: partners, isLoading: partnersIsLoading } = useLoadPartnersList(eshopIds, eshopsIsLoading || !eshopIds?.length)

  const { gridActions: itemsGridActions, gridSelectors: itemsGridSelectors } = useGridStore('ExpeditionItems')

  const { onImportItems, onDownloadImportTemplate, loadProducts } = useProductItems(usedOrganisation, { allowBundles: true })
  const loadProductStocks = useLoadProductStocks(dispatch)

  const onSave = useMemo(() => getOnSave(dispatch, onSuccess, warehouses), [dispatch, onSuccess, warehouses])
  const onSend = useMemo(() => getOnSend(dispatch, onSuccess), [dispatch, onSuccess])
  const onSaveAndSend = useSaveAndSend<ExpeditionDetail>(onSave, onSend)

  const onConfirm = useActionSubmit(dispatch, expeditionsActions.processToWms, onSuccess, sentMessage())

  const onCancel = useActionSubmit(
    dispatch,
    expeditionsActions.cancelExpedition,
    onSuccess,
    t({ id: 'cornerDialog.cancelSuccess.text', message: 'Cancellation has been successfully performed.' }),
    { setErrorsToFormik: true },
  )

  const onRollback = useActionSubmit(
    dispatch,
    expeditionsActions.rollbackExpedition,
    onSuccess,
    t({ id: 'cornerDialog.rollbackSuccess.text', message: 'Rollback has been successfully performed.' }),
    { setErrorsToFormik: true },
  )

  const onInvoiceDownload = useCallback((): Promise<void> => {
    return dispatch(expeditionsActions.downloadInvoice(data?.id)).promise
  }, [data?.id, dispatch])

  const { gridActions: lotsGridActions, gridSelectors: lotsGridSelectors } = useGridStore('LotsItems')
  const client = useApolloClient()

  const searchPickupPlaces = useMemo(() => onSearchPickupPlace(client, setPickupPlacesData), [client])
  const searchExternalPickupPlace = useMemo(() => onSearchExternalPickupPlace(client), [client])

  /**
   * Edit only props, related to incorrect state
   */
  const initialErrors = useMemo(() => {
    if (mode != 'edit') return undefined
    return data?.errors && formatInitialError(data.errors)
  }, [data?.errors, mode])
  const initialTouched = useMemo(() => {
    if (mode != 'edit') return undefined
    return data?.errors && formatInitialTouched(data.errors)
  }, [data?.errors, mode])

  const onComplaintOpen = useCallback(
    () =>
      dispatch(
        uiActions.openQuickAccessDialog('createComplaint', undefined, {
          expeditionId: id,
          organisationId: usedOrganisation,
        }),
      ),
    [dispatch, id, usedOrganisation],
  )

  /**
   * Pairing table related props
   */

  const {
    data: stockAdviceItems,
    isLoading: stockAdviceItemsIsLoading,
    load: loadStockAdviceItems,
  } = useLazyListFetchModule<StockAdviceItemApi>(stockAdviceItemsStore)
  const handleLoadStockAdviceItems = useCallback(
    (productId: string, warehouse: string, wms: string): void => {
      const criteria = {
        product: { eq: productId },
        'stockAdvice.status': { in: StockAdvicePairingStatuses },
        'stockAdvice.warehouse': { eq: warehouse },
        'stockAdvice.wms': { eq: wms },
      }
      loadStockAdviceItems(criteria, { nested: true })
    },
    [loadStockAdviceItems],
  )

  const onOpenAdvice = useCallback((id: string) => dispatch(uiActions.openQuickAccessDialog('advice', id)), [dispatch])

  const { gridActions: pairingGridActions, gridSelectors: pairingGridSelectors } = useGridStore('ExpeditionItemsPairing')
  const extraIsLoading =
    eshopsIsLoading ||
    warehousesIsLoading ||
    countriesIsLoading ||
    partnersIsLoading ||
    carriersIsLoading ||
    expeditionServicesIsLoading

  return (
    <LoadingOverlay isLoading={isLoading || extraIsLoading} error={error}>
      <ExpeditionEditForm
        expeditionId={data?.id}
        onCloneInvoice={onCloneInvoice}
        initialValues={initialValues}
        title={isCreatingNew ? createTitle : editTitle}
        titleIcon={titleIcon}
        loadProducts={loadProducts}
        onImportItems={onImportItems}
        onDownloadImportTemplate={onDownloadImportTemplate}
        loadProductStocks={loadProductStocks}
        itemsGridActions={itemsGridActions}
        itemsGridSelectors={itemsGridSelectors}
        countries={countries as Country[]} // should be guaranteed by countriesIsLoading guard
        warehouses={warehouses}
        onSend={onSend}
        onSave={onSave}
        onSaveAndSend={onSaveAndSend}
        onBack={onCloseForm}
        onConfirm={onConfirm}
        onCancel={onCancel}
        onRollback={onRollback}
        pairingGridActions={pairingGridActions}
        pairingGridSelectors={pairingGridSelectors}
        adviceItems={stockAdviceItems}
        adviceItemsIsLoading={stockAdviceItemsIsLoading}
        loadAdviceItems={handleLoadStockAdviceItems}
        onOpenAdvice={onOpenAdvice}
        pickupPlacesData={pickupPlacesData}
        searchPickupPlaces={searchPickupPlaces}
        searchExternalPickupPlace={searchExternalPickupPlace}
        lotsGridActions={lotsGridActions}
        lotsGridSelectors={lotsGridSelectors}
        organisationWithAddressValidation={organisationWithAddressValidation}
        onInvoiceDownload={onInvoiceDownload}
        initialErrors={initialErrors}
        initialTouched={initialTouched}
        onComplaintOpen={onComplaintOpen}
        partners={partners}
        carriers={carriers}
        carrierServices={carrierServices}
        carrierServiceIsLoading={carrierServiceIsLoading}
        eshops={eshops}
        accounts={accountOptions}
        hasDuplicatePositions={data?.hasDuplicatePositions}
        reloadExpedition={reloadExpedition}
        expeditionServices={expeditionServices}
      />
    </LoadingOverlay>
  )
}

export default Container
