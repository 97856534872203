import { useQuery } from '@apollo/client'
import productGroupsGetQuery from '@queries/productGroupsGetQuery'
import { ApiHookResultWithReload } from '@typings/ApiHooks'
import { ProductGroup } from '@typings/entities/ProductGroup'

export const useLoadProductGroupList = (selectedOrganisation?: string): ApiHookResultWithReload<ProductGroup[]> => {
  const {
    data,
    loading: isLoading,
    error,
    refetch: reload,
  } = useQuery(productGroupsGetQuery, {
    variables: {
      criteria: { organisation: { eq: selectedOrganisation } },
    },
    fetchPolicy: 'no-cache',
    skip: !selectedOrganisation,
  })
  return {
    data: data?.productGroupsGet?.results,
    isLoading,
    error,
    reload,
  }
}
