import useDidMountEffect from '@hooks/useDidMountEffect'
import { GridActionsType, GridSelectorsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'

// pages with detail on a new page
const shallowRoutes = [
  '/admin/claims',
  '/user/claims',
  '/admin/inventory-movements',
  '/user/inventory-movements',
  '/admin/internal-inventory-movements',
  '/user/internal-inventory-movements',
  '/admin/outbound-receipts',
  '/user/outbound-receipts',
  '/admin/inbound-receipts',
  '/user/inbound-receipts',
  '/admin/return-receipts',
  '/user/return-receipts',
]

export type ShallowRoutingProps = (
  gridActions: GridActionsType,
  gridSelectors: GridSelectorsType,
) => {
  changePathOrSetPage: (page: number, rows: number) => void
  replaceShallowPath: (value: number, item: number) => void
  rowsPerPageValue: number
}

const useShallowRouting: ShallowRoutingProps = (gridActions, gridSelectors) => {
  const history = useHistory()
  const location = useLocation()
  const useQuery = () => new URLSearchParams(location.search)
  const query = useQuery()

  const { setPage, setRowsPerPage } = gridActions ?? {}
  const { rowsPerPage } = gridSelectors ?? {}
  const pageValue = Number(query.get('page'))
  const rowsPerPageValue = Number(query.get('rows'))
  const rows = rowsPerPageValue || rowsPerPage || 10
  const isShallowRoute = shallowRoutes.includes(location.pathname)

  const replaceShallowPath = useCallback(
    (page: number, rows: number) => {
      isShallowRoute &&
        page &&
        history.replace({
          pathname: location.pathname,
          search: `page=${page ? page : 1}${rows ? `&rows=${rows}` : ''}`,
        })
    },
    [history, isShallowRoute, location.pathname],
  )

  useEffect(() => {
    setRowsPerPage?.(rows)
    replaceShallowPath(pageValue, rows)
  }, [])

  useDidMountEffect(() => {
    isShallowRoute && pageValue && setPage?.(pageValue)
  }, [pageValue, isShallowRoute, setPage])

  const changePathOrSetPage = useCallback(
    (page: number, rows: number) => {
      if (isShallowRoute) {
        history.push({
          pathname: location.pathname,
          search: `page=${page}&rows=${rows}`,
        })
      } else {
        setRowsPerPage?.(rows)
        setPage?.(page)
        // Disable automatic adjustment of the rows per page when changing the screen size
        history.push({
          pathname: location.pathname,
          search: `rows=${rows}`,
        })
      }
    },
    [history, isShallowRoute, location.pathname, setPage, setRowsPerPage],
  )

  return { changePathOrSetPage, replaceShallowPath, rowsPerPageValue }
}

export default useShallowRouting
