import React from 'react'
import withFormikToProps from '@utils/formik/withFormikToProps'
import getFieldError from '@utils/formik/getFieldError'
import PhoneInputField from './PhoneInputField'

type PhonePropsFormik = {
  name: string
  label: string
  value: string
  onChange: Function
  onBlur: Function
  error?: string | boolean
}

const PhoneInputFieldFormik = (props: PhonePropsFormik): JSX.Element => {
  const { name, onChange, onBlur } = props
  const handleChange = React.useCallback(
    (phone: string) => {
      onChange({
        target: {
          name,
          type: 'change',
          value: phone,
        },
      })
    },
    [name, onChange],
  )
  const handleBlur = React.useCallback(
    (phone: string) => {
      onBlur({
        target: {
          name,
          type: 'blur',
          value: phone,
        },
      })
    },
    [name, onBlur],
  )

  const error = getFieldError(props)
  return <PhoneInputField {...props} name={name} onChange={handleChange} onBlur={handleBlur} error={error} />
}

export default withFormikToProps(PhoneInputFieldFormik)
