import gql from 'graphql-tag'

const wmsGetQuery = gql`
  query wmsGet($id: String) {
    wmsGet(id: $id) @rest(type: "Wms", method: "GET", path: "wms/{args.id}") {
      id
      name
    }
  }
`

export default wmsGetQuery
