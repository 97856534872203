import React from 'react'
import DSCornerDialog, { CornerDialogProps, Intent } from '@mailstep/design-system/ui/Blocks/CornerDialog'
import { t } from '@lingui/macro'

const statusToTitle: Record<Intent, () => string> = {
  info: () => t({ id: 'cornerDialog.info.title', message: 'Info' }),
  success: () => t({ id: 'cornerDialog.success.title', message: 'Success' }),
  error: () => t({ id: 'cornerDialog.failed.title', message: 'Error' }),
  warning: () => t({ id: 'cornerDialog.warning.title', message: 'Warning' }),
}

type Props = Omit<CornerDialogProps, 'statusToTitle'>

export const CornerDialog = ({ children, ...rest }: Props): JSX.Element => {
  return (
    <DSCornerDialog statusToTitle={statusToTitle} {...rest}>
      {children}
    </DSCornerDialog>
  )
}
