import getRuntimeConfig from '@utils/getRuntimeConfig'
import axios from 'axios'
import { useState } from 'react'

export const useFoxentry = () => {
  const [data, setData] = useState(null)
  const [error, setError] = useState('')
  const [loaded, setLoaded] = useState(false)

  const getAddress = async (values: string, country: string): Promise<any> => {
    const url = getRuntimeConfig('FRONTEND__FOXENTRY_URL')
    const apiKey = getRuntimeConfig('FRONTEND__FOXENTRY_API_KEY')

    if (!url || !apiKey) {
      return console.error('Foxentry URL or API key is missing')
    } else {
      try {
        const response = await axios.post(`${url}/locations/search`, {
          apiKey,
          options: {
            country,
            limits: {
              results: 20,
              offset: 0,
            },
          },
          query: {
            searchType: ['streetWithNumber'],
            streetWithNumber: values,
          },
        })
        setData(response.data.data)
      } catch (err) {
        setError(err)
      } finally {
        setLoaded(true)
      }
    }
  }
  return { data, error, loaded, getAddress }
}
