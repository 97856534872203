import { Ability, AbilityBuilder, Subject, createAliasResolver } from '@casl/ability'
export default class ACl {
  private abilityBuilder = new AbilityBuilder(Ability)
  private resolveAction = createAliasResolver({
    adminRead: ['read'],
    adminEdit: ['edit'],
  })
  private ability: Ability = new Ability(this.abilityBuilder.rules, {
    detectSubjectType: (subject: Subject | Subject[]): string => (Array.isArray(subject) ? subject[0] : subject),
    resolveAction: this.resolveAction,
  })

  public getAbility(): Ability {
    return this.ability
  }

  public setUserAbility(): void {
    const { can, rules } = this.abilityBuilder
    can('read', 'UserHome')
    can('read', 'Audits')
    can('read', 'Baselinker')
    can('read', 'Carriers')
    can('read', 'Complaints')
    can('read', 'Eshops')
    can('read', 'Expeditions')
    can('read', 'Stocks')
    can('read', 'StockAdvices')
    can('read', 'StockMovements')
    can('read', 'StockChanges')
    can('read', 'InternalStockChanges')
    can('read', 'InboundReceipts')
    can('read', 'MissingStock')
    can('read', 'Notifications')
    can('read', 'OutboundReceipts')
    can('read', 'Partners')
    can('read', 'Products')
    can('read', 'ReturnReceipts')
    can('read', 'Reservations')
    can('read', 'Suppliers')
    can('read', 'StockAdvices')
    can('read', 'StockChanges')
    can('read', 'StockMovements')
    can('read', 'Stocks')
    can('read', 'Transfers')
    can('read', 'Webhook')

    can('edit', 'Complaint')
    can('edit', 'Partner')
    can('edit', 'Product')
    can('edit', 'Organisation')
    can('edit', 'Eshop')
    can('edit', 'StockAdvice')
    can('edit', 'Expedition')
    can('edit', 'Notification')
    can('edit', 'Supplier')
    can('edit', 'Transfer')
    can('edit', 'StockChange')
    can('edit', 'Webhook')

    can('editProfile', 'User')
    can('close', 'Complaint')

    this.ability.update(rules)
  }

  public setAdminAbility(adminRoles: string[]): void {
    const { can, cannot, rules } = this.abilityBuilder

    can('adminRead', 'all')
    can('editProfile', 'Admin')
    can('reposition', 'Expedition')
    can('editItems', 'InternalStockChange')
    can('create', 'InternalStockChange')
    can('cancel', 'InternalStockChange')
    cannot('adminRead', 'Notifications')

    if (adminRoles.includes('ROLE_SUPERVISOR')) {
      can('adminEdit', 'all')
      can('supervisorEdit', 'Product') // special privilege CS does not have
      cannot('adminEdit', 'Notification')
    } else if (adminRoles.includes('ROLE_CUSTOMER_SERVICE')) {
      cannot('create', 'InternalStockChange')
      cannot('cancel', 'InternalStockChange')
      can('adminEdit', 'Product')
      can('adminEdit', 'Complaint')
      can('adminEdit', 'Transfer')
      can('edit', 'StockAdvice')
      can('edit', 'Expedition')
      can('edit', 'Supplier')
      can('edit', 'Partner')
    }

    this.ability.update(rules)
  }

  public setReady(): void {
    /**
     * Send Request ability access granted, this is not not really acl,
     * but mark that FE is ready to send authorized requests.
     * TODO structure in better way when we remake acl
     */
    const { can, rules } = this.abilityBuilder
    can('aclReady', 'BE')
    this.ability.update(rules)
  }
}
