import createAxiosRequest from '@utils/createAxiosRequest'

type SaveAs = (data: File, fileName: string) => void
type FileSimpleDownload = (url: string, defaultFilename: string, saveAsCallback?: SaveAs) => Promise<any>

const fileSimpleDownload: FileSimpleDownload = (url, defaultFilename, saveAsCallback = saveAs) => {
  let fileName = defaultFilename

  return createAxiosRequest({ url, method: 'GET', responseType: 'blob' })
    .then((response) => {
      if (response?.status === 401) {
        throw new Error('Error: unauthorized.')
      }

      const match = response?.headers['content-disposition']?.match(/filename=(\")?([^;]*)\1/)

      if (match) {
        fileName = match[2]
      }

      return response
    })
    .then(({ data }) => saveAsCallback(data, fileName)) // this will perform download
}

export default fileSimpleDownload
