import React, { useCallback, useMemo } from 'react'
import * as yup from 'yup'
import { x } from '@xstyled/styled-components'
import { Trans } from '@lingui/react'
import { Formik, Form, Field } from 'formik'
import { t } from '@lingui/macro'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'
import { FieldLabel } from '@mailstep/design-system/ui/Elements/Label'
import { Text } from '@mailstep/design-system/ui/Elements/Typography'
import Button from '@mailstep/design-system/ui/Elements/Button'
import InputField from '@components/forms/components/InputField'
import SelectField from '@components/forms/components/SelectField'
import { useBaselinkerProductsSyncQuery } from '@hooks/apiHooks/baselinker/useBaselinkerSync'
import toSelectOption from '@utils/toSelectOption'

export type SyncProductsDialogValues = {
  inventoryId: number | string
}

type Props = {
  onClose: () => void
  eshopId: string
  eshopName?: string
  title: string | JSX.Element
  inventoriesList?: SyncProductsDialogValues[]
}

const validationSchema = yup.object().shape({
  inventoryId: yup
    .string()
    .trim()
    .required(() => t({ id: 'form.inventoryId.errorEmpty', message: 'Please, enter Inventory ID.' })),
})

const initialValues = { inventoryId: '' }

const SyncProductsDialog = ({ onClose, eshopId, eshopName, title, inventoriesList = [] }: Props): JSX.Element => {
  const { syncProducts } = useBaselinkerProductsSyncQuery()

  const inventoriesOptions = useMemo(() => inventoriesList.map(toSelectOption('inventoryId', 'inventoryId')), [inventoriesList])

  const handleSubmit = useCallback(
    async (values: SyncProductsDialogValues) => {
      const { inventoryId } = values
      syncProducts(eshopId, inventoryId)
      onClose()
    },
    [syncProducts, eshopId, onClose],
  )

  return (
    <Modal
      width="narrow"
      title={title}
      onCancel={onClose}
      cancelLabel={<Trans id="form.buttonCancel" message="Cancel" />}
      isConfirmDisabled
      hasFooter={false}
      hasHeader={true}
    >
      <>
        <x.div mb={3}>
          <FieldLabel>
            <Trans id="form.eshop.label" message="Eshop" />
          </FieldLabel>
          <Text>{eshopName}</Text>
        </x.div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {(formik): JSX.Element => (
            <Form>
              {inventoriesOptions.length ? (
                <Field
                  component={SelectField}
                  options={inventoriesOptions}
                  placeholder={t({ id: 'form.inventoryId.placeholder', message: 'Select failed InventoryID...' })}
                  label={<Trans id="form.inventoryId.label" message="Inventory ID" />}
                  name="inventoryId"
                  spaceAround
                />
              ) : (
                <Field
                  component={InputField}
                  label={<Trans id="form.inventoryId.label" message="Inventory ID" />}
                  name="inventoryId"
                  spaceAround
                />
              )}
              <x.div mt={4} display="flex" justifyContent="flex-end">
                <Button type="submit" isLoading={formik.isSubmitting}>
                  <Trans id="form.buttonSynchronize" message="Synchronize" />
                </Button>
              </x.div>
            </Form>
          )}
        </Formik>
      </>
    </Modal>
  )
}

export default SyncProductsDialog
