import React, { useCallback } from 'react'
import Button from '@mailstep/design-system/ui/Elements/Button'
import { Trans } from '@lingui/react'
import { ProcessDialog as ProcessDialogProps } from '@typings/UI'
import { x } from '@xstyled/styled-components'
import fetchFileAuthorized from '@utils/fetchFileAuthorized'
import { CornerDialog } from '../CornerDialog'
import { Spinner } from '@mailstep/design-system/ui/Elements/Spinner'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'
import { useModal } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useModal'

type Props = ProcessDialogProps & {
  onClose: () => void
}

const ProcessDialog = ({
  onClose,
  isLoading,
  fileUrl,
  fileName,
  error,
  progress,
  errorCount = 0,
  successCount = 0,
  intent,
  result,
}: Props): JSX.Element => {
  const fetchFile = useCallback(() => {
    if (fileUrl) {
      fetchFileAuthorized(fileUrl, fileName).finally(onClose)
    }
  }, [onClose, fileUrl, fileName])

  const { onOpen, isOpen, onClose: onCloseResult } = useModal()

  return (
    <CornerDialog intent={intent} title={<Trans id="cornerDialog.process.title" message="Processing data" />} onClose={onClose}>
      <x.div display="flex" alignItems="center" justifyContent="space-between">
        <div>
          {error && <Trans id="cornerDialog.processFailed.text" message="Process failed, please try again." />}
          {progress || progress === 0 ? <span> {progress} %</span> : ''}

          {errorCount > 0 && (
            <div>
              <Trans id="cornerDialog.processFailed.count" message="Failed" />: {errorCount}
            </div>
          )}

          {successCount > 0 && (
            <div>
              <Trans id="cornerDialog.processSuccessful.count" message="Successful" />: {successCount}
            </div>
          )}
        </div>

        <div>
          {isLoading && <Spinner variant="sm" />}

          {fileUrl && (
            <>
              <x.div mb={4}>
                <Trans id="cornerDialog.processIsReady.text" message="Process is ready, now you can download the file." />
              </x.div>
              <Button onClick={fetchFile} type="button">
                <Trans id="cornerDialog.buttonDownload" message="Download" />
              </Button>
            </>
          )}

          {!isLoading && result && result?.length > 0 && (
            <>
              <Button onClick={onOpen} variant="default" appearance="secondary" type="button">
                <Trans id="cornerDialog.moreInfo" message="more info" />
              </Button>
              {isOpen && (
                <Modal width="medium" onCancel={onCloseResult} cancelLabel={<Trans id="form.buttonCancel" message="Cancel" />}>
                  <ol>
                    {result.map((item) => (
                      <li key={item} style={{ listStyle: 'auto' }}>
                        {item}
                      </li>
                    ))}
                  </ol>
                </Modal>
              )}
            </>
          )}
        </div>
      </x.div>
    </CornerDialog>
  )
}

export default ProcessDialog
