import React, { memo } from 'react'
import Command from './Command'
import NotificationsPopup from './Notifications/NotificationsPopup'
import { WarningItem } from './Warnings'
import { Notification2 } from '@mailstep/design-system/ui/Elements/Icon/icons/Notification2'
import { RoundedBackground } from '@components/elements/RoundedBackground/RoundedBackground'

type Props = {
  notificationsCount?: number
  warningsCount?: number
  warningItems?: WarningItem[]
  disabled: boolean
}

const Notifications = ({ notificationsCount = 0, warningsCount = 0, warningItems, disabled }: Props) => (
  <NotificationsPopup warningItems={warningItems} notificationCount={notificationsCount} warningsCount={warningsCount}>
    <Command
      icon={
        <RoundedBackground>
          <Notification2 />
        </RoundedBackground>
      }
      badgeCount={notificationsCount + warningsCount}
      disabled={disabled}
    />
  </NotificationsPopup>
)

export default memo(Notifications)
