import gql from 'graphql-tag'

const userPasswordResetConfirmQuery = gql`
  query userPasswordResetConfirm($token: String!, $newPassword: String!) {
    userPasswordResetConfirm(input: { token: $token, newPassword: $newPassword })
      @rest(type: "UserPasswordResetConfirm", method: "POST", path: "reset-password/user") {
      token
    }
  }
`

export default userPasswordResetConfirmQuery
