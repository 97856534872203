import React from 'react'
import { FlagCZ } from '@mailstep/design-system/ui/Elements/Icon/icons/FlagCZ'
import { FlagUSA } from '@mailstep/design-system/ui/Elements/Icon/icons/FlagUSA'
import { FlagRUS } from '@mailstep/design-system/ui/Elements/Icon/icons/FlagRUS'
import { FlagSVK } from '@mailstep/design-system/ui/Elements/Icon/icons/FlagSVK'
import { FlagESP } from '@mailstep/design-system/ui/Elements/Icon/icons/FlagESP'
import { FlagITA } from '@mailstep/design-system/ui/Elements/Icon/icons/FlagITA'
import { FlagEL } from '@mailstep/design-system/ui/Elements/Icon/icons/FlagEL'

const LanguageItems = [
  {
    languageCode: 'cs',
    icon: <FlagCZ />,
    title: 'Česky',
    link: '#',
  },
  {
    languageCode: 'en',
    icon: <FlagUSA />,
    title: 'English',
    link: '#',
  },
  {
    languageCode: 'ru',
    icon: <FlagRUS />,
    title: 'Pусский',
    link: '#',
  },
  {
    languageCode: 'sk',
    icon: <FlagSVK />,
    title: 'Slovensky',
    link: '#',
  },
  {
    languageCode: 'es',
    icon: <FlagESP />,
    title: 'Español',
    link: '#',
  },
  {
    languageCode: 'it',
    icon: <FlagITA />,
    title: 'Italiano',
    link: '#',
  },
  {
    languageCode: 'el',
    icon: <FlagEL />,
    title: 'Ελληνική',
    link: '#',
  },
]

export default LanguageItems
