import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik'
import React, { useCallback } from 'react'
import { FormikHandleSubmit } from '@typings/Formik'
import TransferForm, { ExternalFormProps } from './TransferForm'
import createValidationSchema from './validation'
import getOr from 'lodash/fp/getOr'
import { TransferType } from '@typings/entities/Transfer'

export type Props = ExternalFormProps & {
  initialValues: FormikValues
  onSave: FormikHandleSubmit
  onSaveAndSend: FormikHandleSubmit
  onCancel: FormikHandleSubmit
  onBack: () => void
  isCreatingNew: boolean
}

const initialValuesEmpty = {}

const TransferFormFormik = ({
  initialValues = initialValuesEmpty,
  onSave,
  isCreatingNew,
  onSaveAndSend,
  onCancel,
  ...rest
}: Props): JSX.Element => {
  const finInitialValues = React.useMemo(
    () => ({
      type: TransferType.virtual,
      ...initialValues,
    }),
    [initialValues],
  )

  const handleSubmit = useCallback(
    ({ button, ...values }, formikHelpers) => {
      switch (button) {
        case 'save':
          onSave(values, formikHelpers)
          break
        case 'saveAndSend':
          onSaveAndSend(values, formikHelpers)
          break
      }
    },
    [onSave, onSaveAndSend],
  )

  const handleCancel = useCallback(
    (formikHelpers: FormikHelpers<FormikValues>) => (): void => {
      formikHelpers.setSubmitting(true)
      onCancel(getOr(null, 'values', formikHelpers), formikHelpers)
    },
    [onCancel],
  )

  return (
    <Formik onSubmit={handleSubmit} initialValues={finInitialValues} validationSchema={createValidationSchema()}>
      {(formikBag: FormikProps<FormikValues>): JSX.Element => (
        <TransferForm {...formikBag} {...rest} onCancel={handleCancel(formikBag)} isCreatingNew={isCreatingNew} />
      )}
    </Formik>
  )
}

export default TransferFormFormik
