import { BaselinkerCredentials } from '@typings/entities/Eshop'
import { useMemo } from 'react'
import { BaselinkerExpeditionStatuses } from '@typings/entities/Expedition'
import { WarehouseAsNested } from '@typings/entities/Warehouse'

export const useGetInitialValues = (
  credentials?: BaselinkerCredentials,
  warehouses?: WarehouseAsNested[],
): BaselinkerCredentials =>
  useMemo(
    () => ({
      token: credentials?.token || '',
      syncDetailToken: credentials?.syncDetailToken || '',
      warehouseMapping: (warehouses || []).map(
        ({ id }) =>
          credentials?.warehouseMapping?.find(({ mailShipWarehouse }) => mailShipWarehouse == id) || {
            mailShipWarehouse: id,
            baseLinkerWarehouse: '',
          },
      ),
      expeditionStatusMapping: BaselinkerExpeditionStatuses.map((status) => {
        const found = credentials?.expeditionStatusMapping?.find(
          ({ mailShipExpeditionStatus }) => mailShipExpeditionStatus == status,
        )
        return {
          mailShipExpeditionStatus: status,
          baseLinkerOrderStatusId: found?.baseLinkerOrderStatusId || '',
        }
      }),
      carrierMapping: credentials?.carrierMapping || [],
      unsynchronizedOrderStatusMapping: credentials?.unsynchronizedOrderStatusMapping ?? null,
    }),
    [credentials, warehouses],
  )
