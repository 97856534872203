import produce from 'immer'
import getOr from 'lodash/fp/getOr'
import reduceReducers from 'reduce-reducers'

export const actionTypes = {
  setBaseLinkerProductSyncStatus: `@store/UI/SET_BASELINKER_PRODUCT_SYNC_STATUS`,
  setBaseLinkerProductStockSyncStatus: `@store/UI/SET_BASELINKER_PRODUCT_STOCK_SYNC_STATUS`,
  syncBatchProduct: `@store/BASELINKER/SET_BASELINKER_BATCH_PRODUCT_START`,
  syncBatchProductStock: `@store/BASELINKER/SET_BASELINKER_BATCH_PRODUCT_STOCK_START`,
  addBatchToSeenList: `@store/BASELINKER/ADD_BATCH_TO_SEEN_LiST`,
  prepareSyncBatchProduct: `@store/BASELINKER/PREPARE_BATCH_OF_PRODUCT`,
  prepareSyncBatchProductStock: `@store/BASELINKER/PREPARE_BATCH_OF_PRODUCT_STOCK`,
  removeBatchNotificationByType: `@store/BASELINKER/REMOVE_BATCH_NOTIFICATION_BY_TYPE`,
}

const initialState = {
  baseLinkerProductSyncStatus: true,
  baseLinkerProductStockSyncStatus: true,
  baseLinkerPreparingProductSyncStatus: false,
  baseLinkerPreparingProductStockSyncStatus: false,
  batchSeenList: [],
}

const ownReducer = (state: State = initialState, action: Action): State => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.setBaseLinkerProductSyncStatus:
        draft.baseLinkerPreparingProductSyncStatus = false
        draft.baseLinkerProductSyncStatus = action?.payload
        break
      case actionTypes.setBaseLinkerProductStockSyncStatus:
        draft.baseLinkerPreparingProductStockSyncStatus = false
        draft.baseLinkerProductStockSyncStatus = action?.payload
        break
      case actionTypes.prepareSyncBatchProduct:
        draft.baseLinkerPreparingProductSyncStatus = action.payload
        break
      case actionTypes.prepareSyncBatchProductStock:
        draft.baseLinkerPreparingProductStockSyncStatus = action.payload
        break
      case actionTypes.syncBatchProduct:
        draft.baseLinkerPreparingProductSyncStatus = false
        break
      case actionTypes.syncBatchProductStock:
        draft.baseLinkerPreparingProductStockSyncStatus = false
        break
      case actionTypes.addBatchToSeenList:
        if (!draft.batchSeenList) {
          draft.batchSeenList = []
        }
        draft.batchSeenList.push(action?.payload)
    }
  })
}

export default reduceReducers(ownReducer)

export const actions = {
  syncBatchProduct: (id: string) => ({ type: actionTypes.syncBatchProduct, payload: id }),
  syncBatchProductStock: (id: string) => ({ type: actionTypes.syncBatchProductStock, payload: id }),
  setBaseLinkerProductSyncStatus: (status: boolean) => ({ type: actionTypes.setBaseLinkerProductSyncStatus, payload: status }),
  setBaseLinkerProductStockSyncStatus: (status: boolean) => ({
    type: actionTypes.setBaseLinkerProductStockSyncStatus,
    payload: status,
  }),
  addBatchToSeenList: (id: string) => ({ type: actionTypes.addBatchToSeenList, payload: id }),
  prepareSyncBatchProduct: (isPreparing: boolean) => ({ type: actionTypes.prepareSyncBatchProduct, payload: isPreparing }),
  prepareSyncBatchProductStock: (isPreparing: boolean) => ({
    type: actionTypes.prepareSyncBatchProductStock,
    payload: isPreparing,
  }),
}

export const selectors: Selectors = {
  baseLinkerPreparingProductSyncStatus: (state: State): boolean =>
    getOr(null, `baseLinkerPreparingProductSyncStatus`, state?.baselinkerBatch),
  baseLinkerPreparingProductStockSyncStatus: (state: State): boolean =>
    getOr(null, `baseLinkerPreparingProductStockSyncStatus`, state?.baselinkerBatch),
  baseLinkerProductSyncStatus: (state: State): boolean => getOr(null, `baseLinkerProductSyncStatus`, state?.baselinkerBatch),
  baseLinkerProductStockSyncStatus: (state: State): boolean =>
    getOr(null, `baseLinkerProductStockSyncStatus`, state?.baselinkerBatch),
  batchSeenList: (state: State): string[] => state.baselinkerBatch.batchSeenList,
}
