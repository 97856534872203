import { ApolloClient } from '@apollo/client'
import { push } from 'connected-react-router'
import { selectors as routerSelectors } from '@store/connectedRouter'
import { t } from '@lingui/macro'
import { takeLatest, put, call, fork, select, delay, getContext, spawn } from 'redux-saga/effects'
import { actionTypes, actions } from './index'
import { actions as organisationsActions } from '@store/organisations'
import { actions as countriesActions } from '@store/countries'
import createFetchSaga from '@utils/store/createFetchSaga'
import loginQuery from '@queries/userLoginQuery'
import userMeGetQuery from '@queries/userMeGetQuery'
import userMeUpdateQuery from '@queries/userMeUpdateQuery'
import userUpdateMenuPreferencesQuery from '@queries/userUpdateMenuPreferencesQuery'
import userPasswordResetQuery from '@queries/userPasswordResetQuery'
import userPasswordResetConfirmQuery from '@queries/userPasswordResetConfirmQuery'
import pages from '@pages/index'
import jwtDecode from 'jwt-decode'
import { displayToast } from '@utils/toast'
import { DecodedToken } from '@typings/UI'
import { setTokenToLocalStorage } from '@utils/getAuthorizationToken'

const fetchMe = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({
      query: userMeGetQuery,
      variables: action.payload,
    })
    .then(({ data }) => ({ data: data?.userMeGet }))
}

function* loadUser(): Generator {
  yield put(actions.load())
  yield put(countriesActions.loadList())
  yield put(organisationsActions.run())
}

function* onLoginSuccess(action: Action): Generator {
  yield call([window.localStorage, window.localStorage.setItem], 'userRefreshToken', action?.data?.refreshToken)
  yield put(actions.setAuth({ token: action?.data?.token, exp: action?.data?.exp }))
  yield call(loadUser)
  yield put(push(pages.userHome.route.toUrl()))
}

export function* onInitUserSuccess(): Generator {
  yield call(loadUser)
  const path = (yield select(routerSelectors.pathnameSelector)) as string
  if (path == pages.userLogin.route.toUrl()) {
    yield put(push(pages.userHome.route.toUrl()))
  }
}

function* onSetAuth(action: Action): Generator {
  setTokenToLocalStorage('user', action?.payload?.token)
}

function* onLogout(): Generator {
  yield call([window.localStorage, window.localStorage.removeItem], 'userToken')
  yield call([window.localStorage, window.localStorage.removeItem], 'userRefreshToken')
  const path = (yield select(routerSelectors.pathnameSelector)) as string
  if (!/^\/user\/login/.test(path)) yield put(push(pages.userLogin.route.toUrl()))
}

const fetchLogin = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({
      query: loginQuery,
      variables: action.payload,
      context: {
        suppressError: (): boolean => true,
      },
    })
    .then(({ data }) => ({ data: data?.login }))
}

const fetchUpdateUser = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const photo = /^http/.test(action.payload?.photo) ? undefined : action.payload?.photo // if photo starts with http, then send nothing
  return client.query({ query: userMeUpdateQuery, variables: { ...action.payload, photo } })
}

const fetchUpdateUserMenuPreferences = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const currentToken = localStorage?.userToken
  const decoded = jwtDecode<DecodedToken>(currentToken)
  const userId = decoded?.sub
  const { preferences } = action.payload

  if (userId && preferences) {
    try {
      const res = await client.query({ query: userUpdateMenuPreferencesQuery, variables: { id: userId, preferences } })

      displayToast({
        type: 'success',
        text: t({ id: 'form.alertChangesSuccessfullySaved', message: 'Changes have been successfully saved.' }),
      })
      return res
    } catch (error) {
      displayToast({
        type: 'error',
        text: t({ id: 'form.alertChangesNotSaved', message: 'Changes have not been saved.' }),
      })
    }
  }
}

const fetchPasswordResetQuery = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: userPasswordResetQuery, variables: action.payload })
}

const fetchPasswordResetConfirmQuery = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: userPasswordResetConfirmQuery, variables: action.payload })
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return // dont run on SSR
  yield takeLatest(actionTypes.load.run, createFetchSaga(actionTypes.load.run, fetchMe))
  yield takeLatest(actionTypes.login.run, createFetchSaga(actionTypes.login.run, fetchLogin))
  yield takeLatest(actionTypes.login.fetchSucceeded, onLoginSuccess)
  yield takeLatest(actionTypes.logout, onLogout)
  yield takeLatest(actionTypes.setAuth, onSetAuth)
  yield takeLatest(actionTypes.update.run, createFetchSaga(actionTypes.update.run, fetchUpdateUser))
  yield takeLatest(
    actionTypes.updateMenuPreferences.run,
    createFetchSaga(actionTypes.updateMenuPreferences.run, fetchUpdateUserMenuPreferences),
  )
  yield takeLatest(actionTypes.passwordReset.run, createFetchSaga(actionTypes.passwordReset.run, fetchPasswordResetQuery))
  yield takeLatest(
    actionTypes.passwordResetConfirm.run,
    createFetchSaga(actionTypes.passwordResetConfirm.run, fetchPasswordResetConfirmQuery),
  )
}
