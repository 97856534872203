import Alert from '@mailstep/design-system/ui/Elements/Alert'
import { t } from '@lingui/macro'
import React from 'react'

const BaselinkerAlert = (): JSX.Element => {
  return (
    <Alert
      intent="danger"
      title={t({
        id: 'form.baselinkerDataCorrupted.title',
        message: 'BaseLinker data got corrupted.',
      })}
      text={t({
        id: 'form.baselinkerDataCorrupted.text',
        message: 'Please contact support. Submitting form will overwrite old config.',
      })}
    />
  )
}

export default BaselinkerAlert
