import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import getAuthorizationToken from '@utils/getAuthorizationToken'
import { getLanguage } from '@utils/language'

const createAxiosRequest = async (config: AxiosRequestConfig) => {
  const token = getAuthorizationToken()
  const language = getLanguage()
  const headers: AxiosRequestConfig['headers'] = {
    authorization: `Bearer ${token}`,
  }
  if (language) {
    headers['Accept-Language'] = language
  }
  try {
    return await axios.request({
      ...config,
      headers: {
        ...headers,
        ...config.headers,
      },
    })
  } catch (err) {
    // common error handling
    if (err instanceof AxiosError) {
      if (err?.response?.data) {
        throw err
      }
    } else {
      // network error handling
      throw { response: { data: { message: (err as Error).message } } }
    }

    // add empty return to satisfy compiler needs of non-empty blocks
    return undefined
  }
}

export default createAxiosRequest
