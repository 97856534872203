import React from 'react'
import Button from '@mailstep/design-system/ui/Elements/Button'
import { Trans } from '@lingui/react'

type Props = {
  onCloseSettings: () => void
  isMobile?: boolean
}

const CloseSettings = ({ onCloseSettings, isMobile }: Props) => {
  if (isMobile) {
    return <Button icon="close" variant="icon" appearance="secondary" onClick={onCloseSettings} />
  }

  return (
    <Button iconLeft="close" variant="default" appearance="secondary" onClick={onCloseSettings}>
      <Trans id="form.closeSettings" message="Close settings" />
    </Button>
  )
}

export default CloseSettings
