import React from 'react'
import { useCreateBreadcrumbPath } from './hooks'
import BreadcrumbItem from './components/BreadcrumbItem'

type BreadcrumbsProps = {
  eshopId?: string
  items: string
}

const Breadcrumbs = ({ eshopId, items }: BreadcrumbsProps): JSX.Element => {
  const breadcrumbPath = useCreateBreadcrumbPath(eshopId, items)

  return (
    <div>
      {breadcrumbPath?.map((item, index) => {
        const isLast = breadcrumbPath.length - 1 === index
        return <BreadcrumbItem key={item.id} link={item.link} title={item.title} isLast={isLast} />
      })}
    </div>
  )
}

export default Breadcrumbs
