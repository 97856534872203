import gql from 'graphql-tag'

const externalCarrierPickupPlacesGetQuery = gql`
  query externalCarrierPickupPlacesGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    externalCarrierPickupPlacesGet(
      input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: $nested }
    ) @rest(type: "CarrierPickupPlaces", method: "POST", path: "external-carrier-pickup-place/list") {
      results
      paging
    }
  }
`

export default externalCarrierPickupPlacesGetQuery
