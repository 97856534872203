/* eslint-disable react-perf/jsx-no-new-function-as-prop*/
import React from 'react'
import { Trans } from '@lingui/react'
import { FormikButtonFieldType } from '@hooks/useFormikButtonField'
import { ButtonOption } from './types'
import withFormikButtonField from './withFormikButtonField'
import Button from '@mailstep/design-system/ui/Elements/Button'
import styled, { x } from '@xstyled/styled-components'
import { useCheckDeviceWidth } from '@components/layouts/BaseLayout/utils'
import { ButtonsStripContainer } from '@components/forms/styles'

type ButtonsStripProps = {
  /**
   * Button which is always rendered on the left
   * and is mostly used as a logical "back"
   * If label is not supplied, translated fallback value of "Back" will be used
   */
  onBack?: () => void
  onBackLabel?: React.ReactNode

  /**
   * buttons can wrap
   */
  wrap?: boolean

  /**
   * Main buttons configuration
   */
  mainButtons: ButtonOption[]

  /**
   * Global loading state for all main buttons
   */
  isLoading?: boolean

  /**
   * Whether all buttons should be disabled
   */
  buttonsDisabled?: boolean

  /**
   * Formik callbacks
   */
  selectedButton?: FormikButtonFieldType['selectedButton']
  setSelectedButton?: FormikButtonFieldType['setSelectedButton']
}

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > :not(:last-child) {
    margin-bottom: 2;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`

const ButtonsStrip = ({
  onBack,
  onBackLabel,
  mainButtons,
  isLoading = false,
  buttonsDisabled = false,
  selectedButton,
  setSelectedButton,
  wrap,
}: ButtonsStripProps): JSX.Element => {
  const globalDisabled = buttonsDisabled || isLoading
  const { isMobile } = useCheckDeviceWidth()
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, buttonCallback: ButtonOption['onClick']): void => {
    setSelectedButton?.(e.currentTarget.name, false)
    buttonCallback(e)
  }
  return (
    <ButtonsContainer>
      {onBack && (
        <x.div mb={1} mt={1}>
          <Button type="button" onClick={onBack} disabled={globalDisabled} fullWidth={isMobile}>
            {onBackLabel ?? <Trans id="form.buttonBack" message="Back" />}
          </Button>
        </x.div>
      )}
      <x.div display="flex" flexWrap={wrap ? 'wrap' : 'unset'} justifyContent="flex-end">
        {mainButtons.map((button, idx) => {
          const buttonDisabled = button.disabled || globalDisabled
          const buttonIsSelected = selectedButton === button.name
          return button.isVisible ? (
            <ButtonsStripContainer key={idx}>
              <Button
                name={button.name}
                type="button"
                // This is why eslint rule (jsx-no-new-function-as-prop) got disabled
                // its just more readable than making a new component for it
                onClick={(e): void => handleOnClick(e, button.onClick)}
                isLoading={buttonIsSelected && (button.isLoading || isLoading)}
                disabled={buttonDisabled}
                appearance={button.appearance ?? 'primary'}
                variant="default"
                fullWidth={isMobile}
              >
                {button.label}
              </Button>
            </ButtonsStripContainer>
          ) : null
        })}
      </x.div>
    </ButtonsContainer>
  )
}

export default withFormikButtonField(ButtonsStrip)
