import React from 'react'
import { Form, Field } from 'formik'
import SelectField from '@components/forms/components/SelectField'
import CommonErrorField from '@components/forms/components/CommonErrorField'
import Button from '@mailstep/design-system/ui/Elements/Button'
import { ImportTemplateCommonFormProps } from './types'
import styled from '@xstyled/styled-components'
import { Trans } from '@lingui/react'
import { x } from '@xstyled/styled-components'

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  & > * {
    margin-right: 3;
  }
`

const StyledSelectField = styled(SelectField)`
  width: 200px;
`

export const ImportTemplateCommonForm = ({
  fileOptions,
  handleSubmit,
  isSubmitting,
}: ImportTemplateCommonFormProps): JSX.Element => {
  return (
    <x.div>
      <StyledForm onSubmit={handleSubmit}>
        <Field
          component={StyledSelectField}
          name="fileFormat"
          label={<Trans id="form.templateDownloadButton.label" message="Choose format" />}
          options={fileOptions}
          isSearchable={false}
        />
        <CommonErrorField />
        <Button type="submit" variant="default" appearance="secondary" iconLeft="download" isLoading={isSubmitting}>
          <Trans id="form.templateDownloadButton" message="Download" />
        </Button>
      </StyledForm>
    </x.div>
  )
}
