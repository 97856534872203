import React, { useCallback, useMemo } from 'react'
import styled from '@xstyled/styled-components'
import { Field } from 'formik'
import { Trans } from '@lingui/react'
import Badge from '@mailstep/design-system/ui/Elements/Badge'
import Button from '@mailstep/design-system/ui/Elements/Button'
import HighlightBox from '@components/elements/HighlightBoxReworked'
import InfoHeader from '@components/blocks/InfoHeader'
import InputField from '@components/forms/components/InputField'
import { InitialCommonErrorField } from '@components/forms/components/CommonErrorField'
import { StyledSubLabel, StyledText } from '@components/elements/Typography/lib'
import { ExpeditionDetail, ExpeditionDetailStatusTrans, FlagStatusToAppearance } from '@typings/entities/Expedition'
import { x } from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import { useCheckDeviceWidth } from '@components/layouts/BaseLayout/utils'

interface HeaderType {
  status?: ExpeditionDetail['status']
  orderNumber: ExpeditionDetail['orderNumber']
  trackingUrl?: string
  packagesCount: number | string
  isCreatingNew: boolean
  isEditable: boolean
}

const TrackingWrap = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100px;
  text-align: left;
`

const StyledContainer = styled(x.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-wrap: anywhere;
  border-bottom: 1px solid ${th('colors.lightGray3')};
  padding-bottom: 3;

  @media (min-width: 1024px) {
    border-bottom: none;
    flex-direction: row;
    padding-bottom: 0;
  }
`

const StyledPackagesWrap = styled.div`
  margin-right: 10px;
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`

const ExpeditionHeader = ({
  status,
  trackingUrl,
  orderNumber,
  packagesCount,
  isCreatingNew,
  isEditable,
}: HeaderType): JSX.Element => {
  const { isMobile } = useCheckDeviceWidth()

  const openTrackingUrl = useCallback(() => {
    window.open(trackingUrl, '_blank', 'noopener,noreferrer')
  }, [trackingUrl])

  const infoHeaderItems = useMemo(() => {
    const statusField = {
      label: <Trans id="form.status.label" message="Status" />,
      component: status && (
        <HighlightBox appearance={FlagStatusToAppearance[status]} size="default">
          {ExpeditionDetailStatusTrans[status]?.()}
        </HighlightBox>
      ),
      isSmall: true,
    }
    const packages = {
      label: <Trans id="form.packages.label" message="Packages" />,
      component: packagesCount || '0',
    }
    return [
      {
        label: <Trans id="form.orderNumber.label" message="Order number" />,
        component: isCreatingNew ? <Field name="orderNumber" component={InputField} /> : orderNumber,
      },
      ...(status ? [statusField] : []),
      ...(isMobile && !isCreatingNew ? [packages] : []),
    ]
  }, [isCreatingNew, orderNumber, packagesCount, status, isMobile])

  return (
    <>
      <StyledContainer>
        <x.div w="50%" minWidth="300px">
          <InfoHeader items={infoHeaderItems} />
        </x.div>

        {!isCreatingNew && (
          <StyledPackagesWrap>
            <Button
              type="button"
              variant="default"
              appearance="secondary"
              color="gray"
              disabled
              iconLeft={
                <x.div h="24px" display="flex" mr="8px">
                  <Badge label={packagesCount || '0'} />
                </x.div>
              }
            >
              <span style={{ marginLeft: '25px' }}>
                <Trans id="form.packages.label" message="Packages" />
              </span>
            </Button>
          </StyledPackagesWrap>
        )}

        {trackingUrl && (
          <x.div ml="10px">
            <Button variant="ghost" appearance="primary" onClick={openTrackingUrl} iconLeft="compass">
              <TrackingWrap>
                <x.div display="flex" flexDirection="column" alignItems="flex-start">
                  <StyledText>
                    <Trans id="form.tracking.label" message="Tracking" />
                  </StyledText>
                  <StyledSubLabel>
                    <Trans id="form.tracking.description" message="You will be transferred to another page" />
                  </StyledSubLabel>
                </x.div>
              </TrackingWrap>
            </Button>
          </x.div>
        )}
      </StyledContainer>

      <InitialCommonErrorField />
    </>
  )
}

export default React.memo(ExpeditionHeader)
