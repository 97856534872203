import * as yup from 'yup'
import { t } from '@lingui/macro'
import { DocumentId } from '@components/forms/utils/RegexValidation'
import { StockAdviceItemApi } from '@typings/entities/StockAdvice'
import getRuntimeConfig from '@utils/getRuntimeConfig'
import { validateFutureDate } from '@utils/validateFutureDate'
import { validateDateFormatWithTime } from '@utils/validateDateFormtWithTime'

const createValidationSchema = (isEditable: boolean) =>
  yup.object().shape({
    countOfUnits: yup
      .number()
      .min(1, () => t({ id: 'form.positiveNumber', message: 'Please enter a number greater than or equal to 1' }))
      .required(() => t({ id: 'form.countOfUnits.errorEmpty', message: 'Please, enter count of unit.' })),
    productPcs: yup
      .number()
      .min(1, () => t({ id: 'form.positiveNumber', message: 'Please enter a number greater than or equal to 1' })),
    internalId: yup
      .string()
      .max(64, () => t({ id: 'form.internalId.errorTooLong', message: 'Maximal length is 64 characters.' }))
      .required(() => t({ id: 'form.internalId.errorEmpty', message: 'Please, enter internal ID.' }))
      .matches(DocumentId, () =>
        t({
          id: 'form.internalId.errorInvalid',
          message: 'Value can contain only digits, letters, underscore, slash and minus.',
        }),
      ),
    warehouse: yup.string().required(() =>
      t({
        id: 'form.warehouse.errorEmpty',
        message: 'Please, select the virtual warehouse.',
      }),
    ),
    supplier: yup
      .string()
      .nullable()
      .required(() => t({ id: 'form.supplier.errorSelectEmpty', message: 'Please select a supplier.' })),
    note: yup.string().max(500, () => t({ id: 'form.note.errorTooLong', message: 'Maximal length is 500 characters.' })),
    wms: yup.string().required(() => t({ id: 'form.wms.errorEmpty', message: 'Please, select the physical warehouse.' })),
    packagingUnit: yup
      .string()
      .required(() => t({ id: 'form.packagingUnit.errorSelectEmpty', message: 'Please select a package unit.' })),
    expectedAt: yup
      .string()
      .typeError(() => t({ id: 'form.date.errorInvalid', message: 'Please enter a valid date and time.' }))
      .required(() => t({ id: 'form.date.errorEmpty', message: 'Please enter a date and time.' }))
      .test(
        'isValidDateFormat',
        t({ id: 'form.date.errorInvalidFormatWithTime', message: 'Invalid date format. Please use dd.MM.yyyy HH:mm' }),
        validateDateFormatWithTime,
      )
      .test(
        'isValidDate',
        t({ id: 'form.requiredExpeditionDate.errorTooEarly', message: 'Please select a future date.' }),
        function (value) {
          if (isEditable) {
            return validateFutureDate(value, false)
          } else return true
        },
      ),
    items: yup
      .array()
      .required(() => t({ id: 'form.items.errorEmpty', message: 'Please, enter at least one item.' }))
      .test(
        'is-active',
        '', // we are using custom error
        function (value: StockAdviceItemApi[]) {
          if (!value) return false
          const problematicItem = value.find((item) => !item.product.active)
          if (problematicItem)
            return this.createError({
              message: t({
                id: 'form.items.errorNotActive',
                message: `Can not have deactivated product (product: ${problematicItem.product.name})`,
              }),
            })
          return true
        },
      )
      .of(
        yup.object().shape({
          quantity: yup
            .number()
            .min(1, () => t({ id: 'form.positiveNumber', message: 'Please enter a number greater than or equal to 1' }))
            .required(() => t({ id: 'form.countOfUnits.errorEmpty', message: 'Please, enter count of unit.' })),
          ...(getRuntimeConfig('FRONTEND__SHOW_PAIRING') && {
            bookAdviceTotal: yup.number().test(
              'maxQuantity',
              () => t({ id: 'form.bookAdviceAdv.errorMax', message: 'Entered quantity exceeds total quantity' }),
              function (value) {
                return !value || this.parent.quantity >= value
              },
            ),
          }),
          unitPurchasePrice: yup.number().moreThan(0).nullable(),
          unitPurchasePriceCurrency: yup
            .string()
            .nullable()
            .test(
              'requiredCurrency',
              () => t({ id: 'form.currency.errorEmpty', message: 'Please, enter currency.' }),
              function (value) {
                return !this.parent.unitPurchasePrice || value
              },
            ),
        }),
      ),
    ref1: yup
      .string()
      .min(2, () => t({ id: 'form.general.min2Chars', message: 'Minimal length is 2 characters.' }))
      .max(255, () => t({ id: 'form.general.max255Chars', message: 'Maximal length is 255 characters.' }))
      .nullable(),
    ref2: yup
      .string()
      .min(2, () => t({ id: 'form.general.min2Chars', message: 'Minimal length is 2 characters.' }))
      .max(255, () => t({ id: 'form.general.max255Chars', message: 'Maximal length is 255 characters.' }))
      .nullable(),
    ref3: yup
      .string()
      .min(2, () => t({ id: 'form.general.min2Chars', message: 'Minimal length is 2 characters.' }))
      .max(255, () => t({ id: 'form.general.max255Chars', message: 'Maximal length is 255 characters.' }))
      .nullable(),
  })

export default createValidationSchema
