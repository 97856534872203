import React, { useCallback } from 'react'
import { FieldArray } from 'formik'
import SetItemsField from './SetItemsField'
import { GridSelectorsType, GridActionsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { ProductApiRead } from '@typings/entities/Product'
import { formikCallback } from '@typings/Formik'

interface SetItemsProps {
  isEditable?: boolean
  isLoading?: boolean
  loadProducts: (fulltext: string) => Promise<ProductApiRead[]>
  itemsGridSelectors: GridSelectorsType
  itemsGridActions: GridActionsType
  setFieldValue: formikCallback
}

const SetItems = ({
  isEditable,
  isLoading,
  loadProducts,
  itemsGridSelectors,
  itemsGridActions,
  setFieldValue,
}: SetItemsProps): JSX.Element => {
  return (
    <FieldArray
      name="childrenProducts"
      render={useCallback(
        (props) => (
          <SetItemsField
            {...props}
            isEditable={isEditable}
            loadProducts={loadProducts}
            isLoading={isLoading}
            gridSelectors={itemsGridSelectors}
            gridActions={itemsGridActions}
            setFieldValue={setFieldValue}
          />
        ),
        [isLoading, isEditable, loadProducts, setFieldValue, itemsGridSelectors, itemsGridActions],
      )}
    />
  )
}

export default SetItems
