import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@xstyled/styled-components'
import Logo from '@mailstep/design-system/ui/Elements/Logo'

export const HeaderWrap = styled.div`
  text-align: center;
  margin-right: 14px;
  @media (min-width: 1024px) {
    margin-right: 24px;
  }
`

const LogoFlipper = styled(Logo)<{
  $isVisible: boolean
}>`
  display: ${(props: any): string => (props.$isVisible ? 'inherit' : 'none')};
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  & > :nth-child(1) {
    display: block;
    @media (min-width: 1024px) {
      display: none;
    }
  }
  & > :nth-child(2) {
    display: none;
    @media (min-width: 1024px) {
      display: block;
    }
  }
`

type Props = {
  logoLink: string
  isCompact: boolean
}

const BrandRow = ({ logoLink, isCompact }: Props): JSX.Element => {
  return (
    <HeaderWrap>
      <StyledLink to={logoLink}>
        <LogoFlipper variant="m" $isVisible={isCompact} size="small" width="30px" />
        <LogoFlipper variant="s" $isVisible={!isCompact} size="small" width="107" />
      </StyledLink>
    </HeaderWrap>
  )
}

export default BrandRow
