import { nanoid } from 'nanoid'
import { ProductApiRead } from '@typings/entities/Product'
import { StockChangeDetailItem } from '@typings/entities/StockChange'
import { TransferDetailItem } from '@typings/entities/Transfer'

interface ItemWithQuantity {
  quantity: number
}

type EmptyItem = StockChangeDetailItem | TransferDetailItem

export const duplicateItemMerge = <T extends ItemWithQuantity>(oldItem: T, newItem: T): T => ({
  ...oldItem,
  quantity: oldItem.quantity + newItem.quantity,
})

export const createEmptyItem = (qty: number, product: ProductApiRead): EmptyItem => ({
  id: `${nanoid()}`,
  quantity: qty,
  product: product,
  lot: '',
  lifo: false,
  book: 0,
  booked: 0,
  availableStock: null,
})

export const createEmptyItemWithLot = (qty: number, product: ProductApiRead): EmptyItem => ({
  id: `${nanoid()}`,
  quantity: qty,
  product: product,
  lot: '__oldest',
  lifo: false,
  book: 0,
  booked: 0,
  availableStock: null,
})
