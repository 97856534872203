import React, { ChangeEvent } from 'react'
import SpaceAroundWrap, { SpaceAround } from '@mailstep/design-system/ui/Elements/SpaceAround'
import withFormikToProps from '@utils/formik/withFormikToProps'
import Checkbox from '@mailstep/design-system/ui/Forms/Checkbox'
type Props = {
  value?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  spaceAround?: SpaceAround
}

const CheckboxField = ({ value, onChange, spaceAround = false, ...rest }: Props): JSX.Element => (
  <SpaceAroundWrap spaceAround={spaceAround}>
    <Checkbox checked={!!value} onChange={onChange} {...rest} />
  </SpaceAroundWrap>
)

export default withFormikToProps(CheckboxField)
