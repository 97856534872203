import { useQuery } from '@apollo/client'
import exchangeRatesGetQuery from '@queries/exchangeRatesGetQuery'
import { ExchangeRate } from '@typings/entities/ExchangeRate'
import { ApiHookResult } from '@typings/ApiHooks'

export const useGetExchangeRatesQuery = (): ApiHookResult<ExchangeRate[]> => {
  const { data, loading, error } = useQuery(exchangeRatesGetQuery, { variables: { criteria: {} } })

  return { data: data?.exchangeRatesGet?.results || [], isLoading: loading, error }
}
