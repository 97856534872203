import gql from 'graphql-tag'

const transferUpdateQuery = gql`
  query transferUpdate(
    $id: String!
    $internalNumber: String
    $note: String
    $type: String
    $requiredTransferDate: String
    $organisation: String
    $warehouseFrom: String
    $warehouseTo: String
    $wmsFrom: String
    $wmsTo: String
    $items: [String]!
  ) {
    transferUpdate(
      id: $id
      input: {
        internalNumber: $internalNumber
        note: $note
        type: $type
        requiredTransferDate: $requiredTransferDate
        organisation: $organisation
        warehouseFrom: $warehouseFrom
        warehouseTo: $warehouseTo
        wmsFrom: $wmsFrom
        wmsTo: $wmsTo
        items: $items
      }
    ) @rest(type: "TransferUpdateResult", method: "PUT", path: "transfer/{args.id}") {
      id
      user
      organisation
      warehouseFrom
      warehouseTo
      wmsFrom
      wmsTo
      internalNumber
      note
      requiredTransferDate
      type
      status
      items
      countOfItems
      countOfSku
      sumOfQuantity
      createdAt
      changedAt
    }
  }
`

export default transferUpdateQuery
