import { matchPath } from 'react-router'
import { selectors as routerSelectors } from '@store/connectedRouter'
import { Section } from '@components/blocks/LeftMenu/types'
import { getFilteredMenuItems } from '@constants/MenuItems'
import { removeOptionalEnd } from '@components/forms/utils/RegexValidation'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectors as adminSelectors } from '@store/admin'
import { selectors as userSelectors } from '@store/user'
import useUser from './useUser'
import { getSettingsMenuItems } from '@constants/SettingsMenuItems'

const getCurrentPage = (items: Section['items'], pathname: string) => {
  const matchCurrent = (path = '') => matchPath(pathname, { path })
  let page = items.find((parent) => {
    return parent?.link === pathname
  })

  if (page?.items) {
    return (page = page?.items[0])
  }

  if (!page) {
    for (const parent of items) {
      parent.items?.forEach((item) => {
        if (item?.subItems) {
          page = item?.subItems?.find((child) => {
            return matchCurrent(child.link?.replace(removeOptionalEnd, ''))
          })
        }
      })
      if (page) {
        break
      } else {
        page = parent.items?.find((child) => {
          return matchCurrent(child.link?.replace(removeOptionalEnd, ''))
        })
      }
    }
  }

  return page
}
export const useCurrentPage = (eshopId?: string) => {
  const { isUser } = useUser()
  const pathname = useSelector(routerSelectors.pathnameSelector)
  const layoutType = isUser ? 'User' : 'Admin'
  const menuSettingsItems = getSettingsMenuItems(layoutType, eshopId)

  const adminMenuPreferences: string[] = useSelector(adminSelectors.getMenuPreferences)
  const userMenuPreferences: string[] = useSelector(userSelectors.getMenuPreferences)
  const menuPreferences = layoutType === 'Admin' ? adminMenuPreferences : userMenuPreferences

  const menuItems = getFilteredMenuItems(layoutType, menuPreferences)

  const currentPage = useMemo(() => {
    const allSections = [...menuItems, ...menuSettingsItems]
    const page = getCurrentPage(allSections, pathname)

    return page
  }, [menuItems, menuSettingsItems, pathname])

  return currentPage
}
