import React from 'react'
import { Formik, FormikBag } from 'formik'
import GridExportForm from './components/GridExportForm'
import { GridSelectorsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { Trans } from '@lingui/react'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'

const defaultInitialValues = { format: 'csv', range: 'filtered' }
const headersOptionOnlyIV = { format: 'csv', range: 'filtered', headersOnly: true }

type Props = {
  onExport: (
    range: 'filtered' | 'selected',
    format: string,
    extraProps?: { [name: string]: string },
    exportWithLot?: boolean,
  ) => void
  onClose: () => void
  gridSelectors: GridSelectorsType
  headersOnlyOption?: boolean
  onlyHeadersOnly?: boolean // todo remove when not needed anymore
  exportWithLot?: boolean
}

const GridExport = ({
  onExport,
  onClose,
  gridSelectors,
  headersOnlyOption,
  onlyHeadersOnly,
  exportWithLot,
}: Props): JSX.Element => {
  const handleOnSubmit = React.useCallback(
    (formValues: object) => {
      if (onExport) {
        const extraProps = {}
        if (headersOnlyOption || onlyHeadersOnly) extraProps['hideItems'] = formValues.headersOnly ? '1' : '0'
        if (exportWithLot) extraProps['withLot'] = 'true'
        // wait short moment, than close modal
        setTimeout(onClose, 1000)
        return onExport(formValues.range, formValues.format, extraProps)
      }
    },
    [onExport, headersOnlyOption, onlyHeadersOnly, onClose, exportWithLot],
  )

  const anyRowsSelected: boolean = React.useMemo(() => {
    const checkedRows = gridSelectors?.uxState?.checkedRows
    return Boolean(checkedRows && Object.values(checkedRows)?.find(Boolean))
  }, [gridSelectors])

  return (
    <Formik onSubmit={handleOnSubmit} initialValues={onlyHeadersOnly ? headersOptionOnlyIV : defaultInitialValues}>
      {({ submitForm, isSubmitting }: FormikBag): JSX.Element => (
        <Modal
          title={
            exportWithLot ? (
              <Trans id="form.heading.exportWithLot" message="Export with LOT" />
            ) : (
              <Trans id="form.heading.export" message="Export" />
            )
          }
          cancelLabel={<Trans id="form.buttonCancel" message="Cancel" />}
          confirmLabel={<Trans id="form.buttonConfirm" message="Confirm" />}
          titleIcon="export"
          onCancel={onClose}
          onConfirm={submitForm}
          isLoading={isSubmitting}
          hasFooter={false}
          width="narrow"
        >
          <GridExportForm
            modeSelection={!!headersOnlyOption && !onlyHeadersOnly}
            anyRowsSelected={anyRowsSelected}
            submitForm={submitForm}
            isSubmitting={isSubmitting}
          />
        </Modal>
      )}
    </Formik>
  )
}

export default GridExport
