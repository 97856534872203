import { AxiosInstance } from 'axios'
import { BatchDetail, LastBatchSyncId } from '@typings/entities/BaseLinkerBatch'
import { getBaselinkerRoutes } from '@services/BaselinkerApi/baselinkerRoutes'
import { WithData } from '@typings/generic'

export const BatchApi = (instance: AxiosInstance) => ({
  async batchDetailProductStock(id: string) {
    return instance.get<undefined, WithData<BatchDetail>>(getBaselinkerRoutes({ id }).batch.productStock)
  },
  async batchDetailProduct(id: string) {
    return instance.get<undefined, WithData<BatchDetail>>(getBaselinkerRoutes({ id }).batch.product)
  },
  async lastBatchsyncId() {
    return instance.post<undefined, WithData<LastBatchSyncId[]>>(getBaselinkerRoutes().batch.lastBatchId)
  },
  async seenBatchProductStock(id: string) {
    return instance.post<undefined, BatchDetail>(getBaselinkerRoutes({ id }).batch.seenBatchProductStock)
  },
  async seenBatchProduct(id: string) {
    return instance.post<undefined, BatchDetail>(getBaselinkerRoutes({ id }).batch.seenBatchProduct)
  },
})
