import React, { useCallback, useMemo } from 'react'
import languageItems from '@constants/LanguageItems'
import styled, { css } from '@xstyled/styled-components'
import { LanguageItem } from '@designSystem/LanguageSwitch/types'
import FaIcon from '@mailstep/design-system/ui/Elements/Icon'
import Dropdown from '@mailstep/design-system/ui/Elements/Dropdown'
import { th } from '@xstyled/system'
import { useClickOutside } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useClickOutside'

const LanguageSwitchWrap = styled.div`
  position: relative;
`

const flagBlock = css`
  display: flex;
  align-items: center;
  width: 60px;
  height: 25px;
  padding: 0 1px;
  cursor: pointer;
`

const Trigger = styled.div<{
  $open: boolean
}>`
  ${flagBlock}
  justify-content: space-between;
  background-color: ${({ $open }) => ($open ? th.color('bgLightGray1') : 'inherit')};
  border-radius: md md 0 0;

  svg {
    height: auto;
    border-radius: sm;
  }

  .fa-chevron-down,
  .fa-chevron-up {
    width: 12px;
    height: auto;
    margin-right: 5px;
  }
`

const Item = styled.div`
  ${flagBlock}
  background-color: bgLightGray1;
  svg {
    height: auto;
    border-radius: sm;
  }

  :hover {
    background-color: white;
    svg {
      width: 27px;
    }
  }

  &:last-child {
    border-radius: 0 0 md md;
  }
`

type Props = {
  activeLanguage: string
  onChange?: Function
}

const MenuLanguageSwitch = ({ activeLanguage, onChange }: Props): JSX.Element | null => {
  const [flagDropdownVisible, setFlagDropdownVisible] = React.useState(false)
  const onClose = useCallback(() => {
    setFlagDropdownVisible(false)
  }, [])
  const flagMenuRef = useClickOutside({ onClose })
  const handleFlagClick = React.useCallback(() => {
    setFlagDropdownVisible(!flagDropdownVisible)
  }, [flagDropdownVisible])

  const activeLanguageObj: LanguageItem | undefined = languageItems.find((language) => language.languageCode === activeLanguage)

  const toggleLanguage = useCallback(
    (languageCode: string) => (): void => {
      onChange && onChange(languageCode)
    },
    [onChange],
  )
  const formattedLanguages = useMemo(() => {
    return languageItems
      .filter((language) => language.languageCode !== activeLanguage)
      .map((language) => ({
        icon: language.icon,
        onClick: toggleLanguage(language.languageCode),
      }))
  }, [toggleLanguage, activeLanguage])

  return (
    <LanguageSwitchWrap ref={flagMenuRef}>
      <Trigger onClick={handleFlagClick} $open={flagDropdownVisible}>
        {activeLanguageObj?.icon}
        <div>
          <FaIcon icon={flagDropdownVisible ? 'goUp' : 'goDown'} fixedWidth={false} fill="blue2" />
        </div>
      </Trigger>
      <Dropdown show={flagDropdownVisible}>
        <div>
          {formattedLanguages?.map((item, i) => (
            <Item key={i} onClick={item?.onClick}>
              {item?.icon}
            </Item>
          ))}
        </div>
      </Dropdown>
    </LanguageSwitchWrap>
  )
}

export default MenuLanguageSwitch
