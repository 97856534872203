/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Dispatch } from 'react'
import { Trans } from '@lingui/react'
import { actions as uiActions } from '@store/ui'
import { Ability } from '@components/blocks/Acl'

export type QuickMenuItem = {
  title?: string | JSX.Element
  link?: string | { pathname: string }
  onClick?: () => void
  disabled?: boolean
}

export const createQuickMenuItems = (dispatch: Dispatch, ability: Ability, organisationSelected: boolean): QuickMenuItem[] => [
  {
    title: <Trans id="menu.new.product" message="New Product" />,
    onClick: (): void => {
      dispatch(uiActions.openQuickAccessDialog('product'))
    },
    disabled: !organisationSelected || !ability.can('edit', 'Product'),
  },
  {
    title: <Trans id="menu.new.expedition" message="New expedition" />,
    onClick: (): void => {
      dispatch(uiActions.openQuickAccessDialog('expedition'))
    },
    disabled: !organisationSelected || !ability.can('edit', 'Expedition'),
  },
  {
    title: <Trans id="menu.new.stockAdvice" message="New stock advice" />,
    onClick: (): void => {
      dispatch(uiActions.openQuickAccessDialog('advice'))
    },
    disabled: !organisationSelected || !ability.can('edit', 'StockAdvice'),
  },
]
