import React from 'react'

import { ButtonsDefinition } from '@components/blocks/ButtonsStrip/types'

import { Trans } from '@lingui/react'
import { TransferStatus } from '@typings/entities/Transfer'

const canSendStatuses = ['created', 'waiting_for_goods']

export const createButtonsDefinition = (
  handleSubmit: (event: any) => void,
  onCancel: () => void,
  isFormEditable: boolean,
  transferStatus: TransferStatus,
): ButtonsDefinition => {
  const canSave = isFormEditable
  const canSend = isFormEditable && canSendStatuses?.includes(transferStatus)
  const canCancel = isFormEditable && !!transferStatus
  return [
    {
      name: 'cancel',
      label: <Trans id="form.buttonCancel" message="Cancel" />,
      appearance: 'secondary',
      isVisible: canCancel,
      onClick: onCancel,
    },
    {
      name: 'save',
      label: <Trans id="form.buttonSave" message="Save" />,
      isVisible: canSave,
      appearance: 'secondary',
      onClick: handleSubmit,
    },
    {
      name: 'saveAndSend',
      label: <Trans id="form.buttonSaveAndSend" message="Save &amp; send" />,
      isVisible: canSave && canSend,
      onClick: handleSubmit,
    },
  ]
}
