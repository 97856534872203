import styled, { x } from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import shutterstock from './shutterstock.jpg'

export const LoggedOutWrapper = styled(x.div)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  font-family: ${th('fonts.primary')};
  flex-grow: 1;
  background: ${th('colors.bgLightGray')};
  opacity: 1;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  @media (min-width: 1024px) {
    background: transparent url(${shutterstock}) 50% 0% no-repeat padding-box;
    background-size: cover;
  }
`

export const SocialLinksWrapper = styled(x.div)`
  height: 600px;
  margin: auto;
  display: none;
  @media (min-height: 520px) {
    display: block;
  }
  @media (min-width: 1024px) {
    position: relative;
    width: 500px;
  }
`

export const SocialLinksBody = styled(x.div)`
  display: flex;
  position: absolute;
  bottom: 30px;
  right: 0;
  padding: 0 10px;
  justify-content: center;
  width: -webkit-fill-available;
  @media (min-width: 1024px) {
    width: initial;
  }
`

export const LinkTo = styled.a`
  color: typoPrimary;
  margin: 0 15px;
  @media (min-width: 1024px) {
    margin-left: 20px;
    margin-rigt: 0;
  }
`

export const SocialFooter = styled(x.div)`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    color: typoPrimary;
    font-weight: 600;
    bottom: 0;
    right: 0;
  }
`
