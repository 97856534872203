import { t } from '@lingui/macro'
import { getCommonErrorText } from '@utils/formik/formatApiToFormikErrors'
import { CornerDialogState } from '@typings/UI'
import { ApiError } from '@typings/entities/Error'

export const getSuccessDialog = (text: string, title?: string): CornerDialogState => ({
  title: title || t({ id: 'cornerDialog.success.title', message: 'Success' }),
  text,
  confirmLabel: t({ id: 'cornerDialog.buttonOkThankYou', message: 'Ok, thank you' }),
  intent: 'success',
})

export const getFailDialog = (text: string, title?: string): CornerDialogState => ({
  title: title || t({ id: 'cornerDialog.failed.title', message: 'Failed' }),
  text,
})

export const getErrorDialog = (error: ApiError): CornerDialogState => {
  const errorText = getCommonErrorText(error)
  return getFailDialog(errorText || '', error?.title || undefined)
}
