import { useCallback } from 'react'
import { ChainedActionSubmit, getFailHandler } from '@utils/containers/advancedFormHelpers'
import { t } from '@lingui/macro'
import { TypedDocumentNode } from '@apollo/client'
import { ApolloClient } from '@apollo/client'
import { displayToast } from '@utils/toast'

const savedMessage = () => t({ id: 'cornerDialog.saveSuccess.text', message: 'Data has been successfully saved.' })

type ActionExtraOptions = {
  setErrorsToFormik?: boolean
}

export const useActionSubmit = <ENT extends { id?: string }>(
  client: ApolloClient<any>,
  query: TypedDocumentNode,
  onSuccess: () => void,
  successMessage: string,
  options?: ActionExtraOptions,
): ChainedActionSubmit<ENT> => {
  const setErrorsToFormik = !!options?.setErrorsToFormik
  return useCallback(
    ({ id }, formikHelpers, isChained) => {
      formikHelpers.setSubmitting(true)
      const handleFail = getFailHandler(formikHelpers, setErrorsToFormik)

      return client
        .query({ query, variables: { id } })
        .then(() => {
          formikHelpers.setSubmitting(false)
          displayToast({ type: 'success', text: isChained ? savedMessage() : successMessage })
          onSuccess && onSuccess()
        })
        .catch(handleFail)
    },
    [client, query, onSuccess, setErrorsToFormik, successMessage],
  )
}
