/**
 * @file Utilities for calculation of sorting/filtering/pagination of rows.
 * For use where we locally store all row values and do not send sorting/filtering request to server/database
 */
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { GridSelectorsType, Settings } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { calculatePaginator } from '@utils/pagination'

const filterRows = <T>(rows: T[], filter: Settings['filter']): T[] => {
  let result: T[] = rows

  if (!filter) {
    return rows
  }
  // Filter per partes
  Object.entries(filter).forEach(([key, { filterProps, value: filterValue }]) => {
    if (!filterValue || (Array.isArray(filterValue) && filterValue.length == 0)) {
      return
    }

    result = result.filter((row) => {
      const rowValue = get(row, key)
      const { type: filteringType, filterExtraProps } = filterProps

      if (filteringType === 'options') {
        if (filterExtraProps?.isMulti) {
          return filterValue.includes(rowValue)
        } else {
          return filterValue === rowValue
        }
      } else if (filteringType === 'flag') {
        return filterValue == '1' ? !!rowValue : !rowValue
      } else if (filteringType === 'number') {
        const comparator = get(filterValue, 'comparator')
        const comparatorValue = get(filterValue, 'value')

        if (comparatorValue === '') {
          return true
        }
        switch (comparator) {
          case 'eq':
            return Number(rowValue) === Number(comparatorValue)
          case 'neq':
            return Number(rowValue) !== Number(comparatorValue)
          case 'lt':
            return Number(rowValue) < Number(comparatorValue)
          case 'lte':
            return Number(rowValue) <= Number(comparatorValue)
          case 'gt':
            return Number(rowValue) > Number(comparatorValue)
          case 'gte':
            return Number(rowValue) >= Number(comparatorValue)
        }
        return false
      } else if (filteringType === 'date') {
        const rowValueDate = new Date(rowValue)

        return (
          (filterValue[0] ? rowValueDate >= filterValue[0] : true) && (filterValue[1] ? rowValueDate <= filterValue[1] : true)
        )
      }

      if (filteringType === 'text' || !filteringType) {
        const comparator = get(filterValue, 'comparator')
        const comparatorValueLc = get(filterValue, 'value').toLowerCase()
        const safeRowValue = Array.isArray(rowValue) ? rowValue.join(', ') : rowValue
        const rowValueLc = String(safeRowValue || '').toLowerCase()

        if (comparatorValueLc === '') {
          return true
        }

        switch (comparator) {
          case 'like':
            return rowValueLc.includes(comparatorValueLc)
          case 'notLike':
            return !rowValueLc.includes(comparatorValueLc)
          case 'startsWith':
            return rowValueLc.startsWith(comparatorValueLc)
          case 'endsWith':
            return rowValueLc.endsWith(comparatorValueLc)
          case 'eq':
            return rowValueLc == comparatorValueLc
          case 'neq':
            return rowValueLc != comparatorValueLc
        }
        return false
      }

      if (Array.isArray(rowValue)) {
        return rowValue.includes(filterValue.toLowerCase())
      }

      return true
    })
  })
  return result
}

const sortRows = <T>(rows: T[], sorting: Settings['sorting']): T[] => {
  let result: T[] = rows

  if (!sorting || !sorting.length) {
    return rows
  }

  sorting.forEach(({ column, direction }) => {
    result = sortBy(result, column)
    if (direction === 'desc') {
      result = result.reverse()
    }
  })
  return result
}

export function filterSortRowsBySettings<T>(rows: T[] = [], gridSelectors: GridSelectorsType): T[] {
  const { filter = {}, sorting = [] } = gridSelectors
  const result = sortRows(filterRows(rows, filter), sorting)
  return result
}

export function sliceDataBySettings<T>(rows: T[], gridSelectors: GridSelectorsType): T[] {
  const { page = 1, rowsPerPage = 10 } = gridSelectors
  const { offset, limit } = calculatePaginator(page, rowsPerPage)
  const result = rows.slice(offset, offset + limit)
  return result
}

export function getItemPage<T>(items: T[], gridSelectors: GridSelectorsType, itemFindCheck: (item: T) => boolean): number | null {
  const filteredItems = filterSortRowsBySettings<T>(items, gridSelectors)
  const newItemPosition = filteredItems.findIndex(itemFindCheck)
  if (newItemPosition >= 0) {
    const rowsPerPage = gridSelectors.rowsPerPage || 10
    return Math.ceil((newItemPosition + 1) / rowsPerPage)
  } else {
    return null // item not included in filters
  }
}
