import React, { memo } from 'react'
import PageOverlay from '@components/elements/Overlay/PageOverlay'
import styled from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import { Spinner } from '@mailstep/design-system/ui/Elements/Spinner'

type Props = {
  isLoading?: boolean
  error?: string
  children?: React.ReactNode
}

const SpinnerBox = styled.div`
  background-color: ${th.color('white')};
  padding: 40px;
  border-radius: lg;
  box-shadow: ${th.shadow('tooltipShadow')};
`

const LoadingOverlay = ({ children, isLoading, error }: Props): JSX.Element => {
  if (isLoading) {
    return (
      <PageOverlay>
        <SpinnerBox>
          <Spinner />
        </SpinnerBox>
      </PageOverlay>
    )
  }
  if (error) {
    return <PageOverlay>{error}</PageOverlay>
  }

  return <>{children}</>
}

export default memo(LoadingOverlay)
