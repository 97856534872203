import React, { useCallback } from 'react'
import { DataCellProps } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { Trans } from '@lingui/react'
import { ProductSynchronization } from '@typings/entities/ProductsSynchronization'
import { OrderSynchronization } from '@typings/entities/OrderSynchronization'
import { LinkInCell } from '@mailstep/design-system/ui/Blocks/CommonGrid'

type Props = DataCellProps<ProductSynchronization | OrderSynchronization> & {
  type: 'sync_order' | 'sync_product'
  isSyncRunning: boolean
}

const productPaths = ['lastProductSyncResult', 'lastStockSyncResult']
const orderPaths = ['lastCarrierInfoSyncResult', 'lastOrderSyncResult', 'lastStatusSyncResult']

const SyncCell = ({ rowData, onRowAction, type = 'sync_product', isSyncRunning }: Props): JSX.Element | null => {
  const isProductSync = 'baseLinkerProductId' in rowData
  const handleSyncClick = useCallback(() => {
    if (onRowAction)
      onRowAction(isProductSync ? String(rowData.baseLinkerProductId) : String(rowData.baseLinkerOrderId), type, rowData)
  }, [isProductSync, onRowAction, rowData, type])

  const arr = isProductSync ? productPaths : orderPaths
  const hasError = arr.some((path) => rowData[path as keyof typeof rowData] === 'error')

  return (
    <LinkInCell variant="medium" as="span" disabled={!hasError || isSyncRunning} onClick={handleSyncClick}>
      <Trans id="dataGrid.columnSyncAction.action" message="Sync" />
    </LinkInCell>
  )
}

export default SyncCell
