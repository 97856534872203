import { useCallback } from 'react'
import { useModal } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useModal'

// TODO all of this needs refactored too, too repetetive

export type FormDialogReturnProps = {
  dialogVisible: boolean
  onCreateClick: () => void
  onRowEditClick: (id: string) => void
  hideDialog: () => void
  entityId: string | null
}

export const useFormDialog = (): FormDialogReturnProps => {
  const { isOpen, onOpen, onClose, data } = useModal<string | null>(null)

  const onCreateClick = useCallback(() => onOpen(null), [onOpen])
  const onRowEditClick = useCallback((id: string) => onOpen(id), [onOpen])

  return { dialogVisible: isOpen, onCreateClick, onRowEditClick, hideDialog: onClose, entityId: data || null }
}
export type GridDialogReturnProps<T> = {
  dialogVisible: boolean
  onCreateClick: () => void
  onRowEditClick: (id: string, props: any) => void
  hideDialog: () => void
  dialogData: T | null
}

export const useGridDialog = <T>(): GridDialogReturnProps<T> => {
  const { isOpen, onOpen, onClose, data } = useModal<T | null>(null)

  const onCreateClick = useCallback(() => onOpen(null), [onOpen])
  const onRowEditClick = useCallback((id: string, props: any) => onOpen({ id, ...props }), [onOpen])

  return { dialogVisible: isOpen, onCreateClick, onRowEditClick, hideDialog: onClose, dialogData: data || null }
}
