import gql from 'graphql-tag'

const warehousesGetQuery = gql`
  query warehousesGet($offset: Int, $limit: Int, $criteria: Object) {
    warehousesGet(input: { limit: $limit, offset: $offset, criteria: $criteria, select: $select, nested: true })
      @rest(type: "WarehousesResult", method: "POST", path: "warehouse/list") {
      results
      paging
    }
  }
`

export default warehousesGetQuery
