import React, { useCallback } from 'react'
import styled, { css } from '@xstyled/styled-components'
import { ThemeGetterFunc, th } from '@xstyled/system'
import { MenuItems } from '@mailstep/design-system/ui/Elements/Icon/icons/MenuItems'
import { Paragraph4 } from '@mailstep/design-system/ui/Elements/Typography'
import { Trans } from '@lingui/react'

export const FooterWrap = styled.div<{
  isCompact: boolean
}>`
  display: flex;
  align-items: center;
  height: 24px;
  width: 100%;
  margin-bottom: 24px;
  justify-content: ${(props): string => (props.isCompact ? 'center' : 'flex-start')};
  padding-left: ${(props): string | ThemeGetterFunc => (props.isCompact ? '0' : '22px')};
`

export const SizeToggle = styled.div<{
  isCompact: boolean
  $lightMode?: boolean
}>`
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${({ $lightMode }) => ($lightMode ? th.color('typoPrimary') : th.color('gray'))};
    font-size: 11px;
    & svg {
      transform: ${(props: any): string => (props.isCompact ? 'rotate(180deg)' : 'rotate(0deg)')};
      ${({ $lightMode }) =>
        $lightMode &&
        css`
          fill: ${th.color('neutral20')};
        `}

      & > path {
        stroke: ${th.color('lightGray5')};
      }
    }
  }
`

type Props = {
  isCompact: boolean
  setIsCompact?: (compact: boolean) => void
  lightMode?: boolean
}

const MenuFooter = ({ isCompact, setIsCompact, lightMode }: Props): JSX.Element => {
  const toggleIsCompact = useCallback(() => {
    setIsCompact && setIsCompact(!isCompact)
  }, [setIsCompact, isCompact])

  return (
    <FooterWrap isCompact={isCompact}>
      <SizeToggle onClick={toggleIsCompact} isCompact={isCompact} $lightMode={lightMode}>
        <MenuItems stroke="none" width="24px" />
        {!isCompact && (
          <Paragraph4 ml={2} variant="semiBold">
            <Trans id="footer.collapse" message="Collapse" />
          </Paragraph4>
        )}
      </SizeToggle>
    </FooterWrap>
  )
}

export default MenuFooter
