import React from 'react'
import loadable from '@loadable/component'
import * as yup from 'yup'
import Loading from './Loading'
import createReactRoute, { ReactRoute } from '@utils/createReactRoute'

const LoadingPage = <Loading />
const AdminHome = loadable(() => import('./AdminModule/AdminHome'), { fallback: LoadingPage })
const Login = loadable(() => import('./SharedModule/Login'), { fallback: LoadingPage })
const OverviewCarriers = loadable(() => import('./SharedModule/Carriers'), { fallback: LoadingPage })
const GridAudits = loadable(() => import('./SharedModule/Audits'), { fallback: LoadingPage })
const DetailAudit = loadable(() => import('./SharedModule/Audit'), { fallback: LoadingPage })
const GridOrganisations = loadable(() => import('./SharedModule/Organisations'), { fallback: LoadingPage })
const AdminPasswordForgotten = loadable(() => import('./AdminModule/PasswordForgotten'), { fallback: LoadingPage })
const AdminPasswordNew = loadable(() => import('./AdminModule/PasswordNewSetting'), { fallback: LoadingPage })
const AdminUsers = loadable(() => import('./AdminModule/Users'), { fallback: LoadingPage })
const InternalStockChanges = loadable(() => import('./SharedModule/InternalStockChanges'), { fallback: LoadingPage })
const InternalStockChange = loadable(() => import('./SharedModule/InternalStockChange'), { fallback: LoadingPage })
const AdminEditAdmin = loadable(() => import('./AdminModule/EditAdmin'), { fallback: LoadingPage })
const AdminEditUserSecurity = loadable(() => import('./AdminModule/EditAdminSecurity'), { fallback: LoadingPage })
const UserEditUser = loadable(() => import('./UserModule/EditUser'), { fallback: LoadingPage })
const UserEditUserSecurity = loadable(() => import('./UserModule/EditUserSecurity'), { fallback: LoadingPage })
const MenuSettings = loadable(() => import('./SharedModule/MenuSettings'), { fallback: LoadingPage })
const Baselinker = loadable(() => import('./SharedModule/Baselinker'), { fallback: LoadingPage })
const BaselinkerConfiguration = loadable(() => import('./SharedModule/Baselinker/configuration'), { fallback: LoadingPage })
const BaselinkerWarehouses = loadable(() => import('./SharedModule/Baselinker/warehouses'), { fallback: LoadingPage })
const BaselinkerExpeditionStatuses = loadable(() => import('./SharedModule/Baselinker/expedition-statuses'), {
  fallback: LoadingPage,
})
const BaselinkerCarriers = loadable(() => import('./SharedModule/Baselinker/carriers'), { fallback: LoadingPage })
const BaselinkerSynchronization = loadable(() => import('./SharedModule/Baselinker/synchronization'), { fallback: LoadingPage })
const GridExpeditions = loadable(() => import('./SharedModule/Expeditions'), { fallback: LoadingPage })
const UserHome = loadable(() => import('./UserModule/UserHome'), { fallback: LoadingPage })
const GridStockAdvices = loadable(() => import('./SharedModule/StockAdvices'), { fallback: LoadingPage })
const GridInboundReceipts = loadable(() => import('./SharedModule/InboundReceipts'), { fallback: LoadingPage })
const DetailInboundReceipt = loadable(() => import('./SharedModule/InboundReceipt'), { fallback: LoadingPage })
const GridOutboundReceipts = loadable(() => import('./SharedModule/OutboundReceipts'), { fallback: LoadingPage })
const DetailOutboundReceipt = loadable(() => import('./SharedModule/OutboundReceipt'), { fallback: LoadingPage })
const UserInvited = loadable(() => import('./UserModule/UserInvited'), { fallback: LoadingPage })
const UserPasswordForgotten = loadable(() => import('./UserModule/PasswordForgotten'), { fallback: LoadingPage })
const UserPasswordNew = loadable(() => import('./UserModule/PasswordNewSetting'), { fallback: LoadingPage })
const GridReturnReceipts = loadable(() => import('./SharedModule/ReturnReceipts'), { fallback: LoadingPage })
const DetailReturnReceipt = loadable(() => import('./SharedModule/ReturnReceipt'), { fallback: LoadingPage })
const GridStockMovements = loadable(() => import('./SharedModule/StockMovements'), { fallback: LoadingPage })
const GridStockChanges = loadable(() => import('./SharedModule/StockChanges'), { fallback: LoadingPage })
const DetailStockChange = loadable(() => import('./SharedModule/StockChange'), { fallback: LoadingPage })
const GridReservations = loadable(() => import('./SharedModule/Reservations'), { fallback: LoadingPage })
const SharedSuppliers = loadable(() => import('./SharedModule/Suppliers'), { fallback: LoadingPage })
const GridMissingStock = loadable(() => import('./SharedModule/MissingStock'), { fallback: LoadingPage })
const GridProducts = loadable(() => import('./SharedModule/Products'), { fallback: LoadingPage })
const GridPartners = loadable(() => import('./SharedModule/Partners'), { fallback: LoadingPage })
const GridStocks = loadable(() => import('./SharedModule/Stocks'), { fallback: LoadingPage })
const GridEshops = loadable(() => import('./SharedModule/Eshops'), { fallback: LoadingPage })
const DetailComplaint = loadable(() => import('./SharedModule/Complaint'), { fallback: LoadingPage })
const GridComplaints = loadable(() => import('./SharedModule/Complaints'), { fallback: LoadingPage })
const GridTransfers = loadable(() => import('./SharedModule/Transfers'), { fallback: LoadingPage })
const Notifications = loadable(() => import('./SharedModule/Notifications'), { fallback: LoadingPage })
const Webhooks = loadable(() => import('./SharedModule/Webhooks'), { fallback: LoadingPage })
const Byznys = loadable(() => import('./SharedModule/Byznys'), { fallback: LoadingPage })

type ReactRoutes = {
  [key: string]: ReactRoute
}

const choosePath = (path: string | string[], pathParams = {}, accountType?: string): string => {
  if (Array.isArray(path)) {
    let ret = path
    let isAdmin
    if (accountType === 'user' || accountType === 'admin') {
      isAdmin = accountType === 'admin'
    } else if (typeof window !== 'undefined') {
      isAdmin = window?.location?.pathname.includes('/admin')
    }
    return (ret = isAdmin && ret.length > 1 ? ret[1] : ret[0])
  }
  return path
}

export const routesWithoutToken = {
  adminLogin: createReactRoute('/admin/login', null, Login, { exact: true }),
  userLogin: createReactRoute('/user/login', null, Login, { exact: true }),
  adminPasswordForgotten: createReactRoute('/admin/password/forgotten', null, AdminPasswordForgotten, { exact: true }),
  userPasswordForgotten: createReactRoute('/user/password/forgotten', null, UserPasswordForgotten, { exact: true }),
  adminPasswordNew: createReactRoute('/admin/password/new/:token', null, AdminPasswordNew, { exact: true }),
  userPasswordNew: createReactRoute('/user/password/new/:token', null, UserPasswordNew, { exact: true }),
  userInvite: createReactRoute('/user/activate/:token', null, UserInvited, { exact: true }),
}

const routes: ReactRoutes = {
  ...routesWithoutToken,
  adminHome: createReactRoute('/admin', null, AdminHome, { exact: true }),
  adminUsers: createReactRoute('/admin/settings/users', null, AdminUsers, { exact: true }),
  adminEditUser: createReactRoute('/admin/settings/personalSettings', null, AdminEditAdmin, { exact: true }),
  adminEditUserSecurity: createReactRoute('/admin/settings/security', null, AdminEditUserSecurity, { exact: true }),
  userEditUser: createReactRoute('/user/settings/personalSettings', null, UserEditUser, { exact: true }),
  userEditUserSecurity: createReactRoute('/user/settings/security', null, UserEditUserSecurity, { exact: true }),
  userHome: createReactRoute('/user', null, UserHome, { exact: true }),
  sharedInternalStockChanges: createReactRoute(
    ['/user/internal-inventory-movements', '/admin/internal-inventory-movements'],
    null,
    InternalStockChanges,
    { exact: true },
    { choosePath },
  ),
  sharedInternalStockChangesStatus: createReactRoute(
    ['/user/internal-inventory-movements/:status', '/admin/internal-inventory-movements/:status'],
    { status: yup.string() },
    InternalStockChanges,
    { exact: true },
    { choosePath },
  ),
  sharedInternalStockChangeDetail: createReactRoute(
    ['/user/internal-inventory-movement/:id?', '/admin/internal-inventory-movement/:id?'],
    { id: yup.string() },
    InternalStockChange,
    { exact: true },
    { choosePath },
  ),
  sharedMenuSettings: createReactRoute(
    ['/user/settings/menuSettings', '/admin/settings/menuSettings'],
    null,
    MenuSettings,
    { exact: true },
    { choosePath },
  ),
  sharedAudits: createReactRoute(['/user/audits', '/admin/audits'], null, GridAudits, { exact: true }, { choosePath }),
  sharedAudit: createReactRoute(
    ['/user/audit/:id?', '/admin/audit/:id?'],
    { id: yup.string() },
    DetailAudit,
    { exact: true },
    { choosePath },
  ),
  sharedBaselinker: createReactRoute(
    ['/user/settings/baselinker/:eshopId?', '/admin/settings/baselinker/:eshopId?'],
    { eshopId: yup.string() },
    Baselinker,
    { exact: true },
    { choosePath },
  ),
  sharedBaselinkerConfiguration: createReactRoute(
    ['/user/settings/baselinker/:eshopId/configuration', '/admin/settings/baselinker/:eshopId/configuration'],
    { eshopId: yup.string() },
    BaselinkerConfiguration,
    { exact: true },
    { choosePath },
  ),
  sharedBaselinkerWarehouses: createReactRoute(
    ['/user/settings/baselinker/:eshopId/warehouses', '/admin/settings/baselinker/:eshopId/warehouses'],
    { eshopId: yup.string() },
    BaselinkerWarehouses,
    { exact: true },
    { choosePath },
  ),
  sharedBaselinkerExpeditionStatuses: createReactRoute(
    ['/user/settings/baselinker/:eshopId/expeditionStatuses', '/admin/settings/baselinker/:eshopId/expeditionStatuses'],
    { eshopId: yup.string() },
    BaselinkerExpeditionStatuses,
    { exact: true },
    { choosePath },
  ),
  sharedBaselinkerCarriers: createReactRoute(
    ['/user/settings/baselinker/:eshopId/carriers', '/admin/settings/baselinker/:eshopId/carriers'],
    { eshopId: yup.string() },
    BaselinkerCarriers,
    { exact: true },
    { choosePath },
  ),
  sharedBaselinkerSynchronization: createReactRoute(
    ['/user/settings/baselinker/:eshopId/synchronization', '/admin/settings/baselinker/:eshopId/synchronization'],
    { eshopId: yup.string() },
    BaselinkerSynchronization,
    { exact: true },
    { choosePath },
  ),
  sharedSuppliers: createReactRoute(
    ['/user/settings/suppliers', '/admin/settings/suppliers'],
    null,
    SharedSuppliers,
    { exact: true },
    { choosePath },
  ),
  sharedCarriers: createReactRoute(
    ['/user/settings/carriers', '/admin/settings/carriers'],
    null,
    OverviewCarriers,
    { exact: true },
    { choosePath },
  ),
  sharedComplaint: createReactRoute(
    ['/user/claim/:id', '/admin/claim/:id'],
    { mode: yup.string(), id: yup.string() },
    DetailComplaint,
    { exact: true },
    { choosePath },
  ),
  sharedComplaintsMine: createReactRoute(
    ['/user/claims/mine/:status', '/admin/claims/mine/:status'],
    { status: yup.string() },
    GridComplaints,
    { exact: true },
    { choosePath },
  ),
  sharedComplaints: createReactRoute(['/user/claims', '/admin/claims'], null, GridComplaints, { exact: true }, { choosePath }),
  sharedComplaintsExpedition: createReactRoute(
    ['/user/claims/expedition/:expeditionId', '/admin/claims/expedition/:expeditionId'],
    { expeditionId: yup.string().required() },
    GridComplaints,
    { exact: true },
    { choosePath },
  ),
  sharedExpeditions: createReactRoute(
    ['/user/expeditions', '/admin/expeditions'],
    null,
    GridExpeditions,
    { exact: true },
    { choosePath },
  ),
  sharedExpeditionsStatus: createReactRoute(
    ['/user/expeditions/:status', '/admin/expeditions/:status'],
    { status: yup.string() },
    GridExpeditions,
    { exact: true },
    { choosePath },
  ),
  sharedStockAdvices: createReactRoute(
    ['/user/stock-advices', '/admin/stock-advices'],
    null,
    GridStockAdvices,
    { exact: true },
    { choosePath },
  ),
  sharedStockAdvicesProduct: createReactRoute(
    ['/user/stock-advices/:productId', '/admin/stock-advices/:productId'],
    { productId: yup.string().required() },
    GridStockAdvices,
    { exact: true },
    { choosePath },
  ),
  sharedInboundReceipts: createReactRoute(
    ['/user/inbound-receipts', '/admin/inbound-receipts'],
    null,
    GridInboundReceipts,
    { exact: true },
    { choosePath },
  ),
  sharedInboundReceipt: createReactRoute(
    ['/user/inbound-receipt/:id', '/admin/inbound-receipt/:id'],
    { id: yup.string() },
    DetailInboundReceipt,
    { exact: true },
    { choosePath },
  ),
  sharedOutboundReceipts: createReactRoute(
    ['/user/outbound-receipts', '/admin/outbound-receipts'],
    null,
    GridOutboundReceipts,
    { exact: true },
    { choosePath },
  ),
  sharedOutboundReceipt: createReactRoute(
    ['/user/outbound-receipt/:id', '/admin/outbound-receipt/:id'],
    { id: yup.string() },
    DetailOutboundReceipt,
    { exact: true },
    { choosePath },
  ),
  sharedReturnReceipts: createReactRoute(
    ['/user/return-receipts', '/admin/return-receipts'],
    null,
    GridReturnReceipts,
    { exact: true },
    { choosePath },
  ),
  sharedReturnReceipt: createReactRoute(
    ['/user/return-receipt/:id', '/admin/return-receipt/:id'],
    { id: yup.string() },
    DetailReturnReceipt,
    { exact: true },
    { choosePath },
  ),
  sharedStockMovements: createReactRoute(
    ['/user/stock-movements', '/admin/stock-movements'],
    null,
    GridStockMovements,
    { exact: true },
    { choosePath },
  ),
  sharedStockMovementsProduct: createReactRoute(
    ['/user/stock-movements/:productId', '/admin/stock-movements/:productId'],
    { productId: yup.string().required() },
    GridStockMovements,
    { exact: true },
    { choosePath },
  ),
  sharedStockChange: createReactRoute(
    ['/user/inventory-movement/:id', '/admin/inventory-movement/:id'],
    { id: yup.string() },
    DetailStockChange,
    { exact: true },
    { choosePath },
  ),
  sharedStockChanges: createReactRoute(
    ['/user/inventory-movements', '/admin/inventory-movements'],
    null,
    GridStockChanges,
    { exact: true },
    { choosePath },
  ),
  sharedReservations: createReactRoute(
    ['/user/reservations/:status?', '/admin/reservations/:status?'],
    { status: yup.string() },
    GridReservations,
    { exact: true },
    { choosePath },
  ),
  sharedReservationsProduct: createReactRoute(
    ['/user/reservations/product/:productId', '/admin/reservations/product/:productId'],
    { productId: yup.string().required() },
    GridReservations,
    { exact: true },
    { choosePath },
  ),
  sharedPartners: createReactRoute(
    ['/user/settings/partners', '/admin/settings/partners'],
    null,
    GridPartners,
    { exact: true },
    { choosePath },
  ),
  sharedProducts: createReactRoute(['/user/products', '/admin/products'], null, GridProducts, { exact: true }, { choosePath }),
  sharedStocks: createReactRoute(['/user/stocks', '/admin/stocks'], null, GridStocks, { exact: true }, { choosePath }),
  sharedStocksProduct: createReactRoute(
    ['/user/stocks/:productId', '/admin/stocks/:productId'],
    { productId: yup.string().required() },
    GridStocks,
    { exact: true },
    { choosePath },
  ),
  sharedTransfers: createReactRoute(
    ['/user/transfers', '/admin/transfers'],
    null,
    GridTransfers,
    { exact: true },
    { choosePath },
  ),
  sharedOrganisations: createReactRoute(
    ['/user/settings/organizations', '/admin/settings/organizations'],
    null,
    GridOrganisations,
    { exact: true },
    { choosePath },
  ),
  sharedMissingStock: createReactRoute(
    ['/user/reservations-missing-stock', '/admin/reservations-missing-stock'],
    null,
    GridMissingStock,
    { exact: true },
    { choosePath },
  ),
  sharedMissingStockProduct: createReactRoute(
    ['/user/reservations-missing-stock/:productId', '/admin/reservations-missing-stock/:productId'],
    { productId: yup.string().required() },
    GridMissingStock,
    { exact: true },
    { choosePath },
  ),
  sharedEshops: createReactRoute(
    ['/user/settings/eshops', '/admin/settings/eshops'],
    null,
    GridEshops,
    { exact: true },
    { choosePath },
  ),
  sharedNotifications: createReactRoute(
    ['/user/notifications', '/admin/notifications'],
    null,
    Notifications,
    { exact: true },
    { choosePath },
  ),
  sharedWebhooks: createReactRoute(
    ['/user/settings/webhooks/:eshopId?', '/admin/settings/webhooks/:eshopId?'],
    { eshopId: yup.string() },
    Webhooks,
    { exact: true },
    { choosePath },
  ),
  sharedByznys: createReactRoute(
    ['/user/settings/byznys', '/admin/settings/byznys'],
    null,
    Byznys,
    { exact: true },
    { choosePath },
  ),
}

export const PSEUDO_ID_NEW = 'create' // route 'id' value for creating new entity (used only in transfers so far)

export default routes
