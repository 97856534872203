import React from 'react'
import { Trans } from '@lingui/react'
import SyncProductStocksDialog from './SyncProductStocksDialog'
import SyncProductsDialog, { SyncProductsDialogValues } from './SyncProductsDialog'

interface Props {
  eshopId?: string
  eshopName?: string
  inventoriesList: SyncProductsDialogValues[]
  syncProductsDialogVisible: boolean
  closeSyncProductsDialog: () => void
  syncProductStocksDialogVisible: boolean
  closeSyncProductStocksDialog: () => void
}

export const BaselinkerSyncDialogs = ({
  eshopId,
  eshopName,
  inventoriesList,
  syncProductsDialogVisible,
  closeSyncProductsDialog,
  syncProductStocksDialogVisible,
  closeSyncProductStocksDialog,
}: Props): JSX.Element | null => {
  return eshopId ? (
    <>
      {syncProductsDialogVisible && (
        <SyncProductsDialog
          onClose={closeSyncProductsDialog}
          title={<Trans id="form.baselinker.buttonSynchronizeProducts" message="Synchronize products" />}
          eshopId={eshopId}
          eshopName={eshopName}
          inventoriesList={inventoriesList}
        />
      )}

      {syncProductStocksDialogVisible && (
        <SyncProductStocksDialog
          onClose={closeSyncProductStocksDialog}
          title={<Trans id="form.baselinker.buttonSynchronizeProductStocks" message="Synchronize product stocks" />}
          eshopId={eshopId}
          eshopName={eshopName}
        />
      )}
    </>
  ) : null
}
