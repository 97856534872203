import React from 'react'
import * as yup from 'yup'
import { t } from '@lingui/macro'
import { Text } from '@mailstep/design-system/ui/Elements/Typography'
import { Sku } from '@components/forms/utils/RegexValidation'
import { ProductType, ProductDetailItem } from '@typings/entities/Product'

export const transformRefNumbers = (values: string[]): string[] => {
  if (!(values?.length > 0)) return values
  return values.map((value) => value?.trim())
}

export const createValidationSchema = (type: ProductType, checkSku: boolean): object => {
  let validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(100, () => t({ id: 'form.productName.errorTooLong', message: 'Maximal length is 100 characters.' }))
      .required(() => t({ id: 'form.productName.errorEmpty', message: 'Please, enter name of the product.' })),
    weight: yup
      .number()
      .nullable()
      .max(10000000, () => t({ id: 'form.weight.errorTooHeavy', message: 'Maximal weight is 10.000.000 g' }))
      .typeError(() => t({ id: 'form.weight.errorInvalid', message: 'Weight must be a number.' }))
      .positive(() => t({ id: 'form.weight.errorNegative', message: 'Number must be positive' })),
    height: yup
      .number()
      .nullable()
      .max(20000, () => t({ id: 'form.height.errorTooHigh', message: 'Maximal height is 20.000 mm.' }))
      .typeError(() => t({ id: 'form.height.errorInvalid', message: 'Height must be a number.' }))
      .positive(() => t({ id: 'form.height.errorNegative', message: 'Number must be positive.' })),
    width: yup
      .number()
      .nullable()
      .max(20000, () => t({ id: 'form.height.errorTooWidth', message: 'Maximal width is 20.000 mm.' }))
      .typeError(() => t({ id: 'form.width.errorInvalid', message: 'Width must be a number.' }))
      .positive(() => t({ id: 'form.width.errorNegative', message: 'Number must be positive.' })),
    length: yup
      .number()
      .nullable()
      .max(20000, () => t({ id: 'form.height.errorTooLong', message: 'Maximal length is 20.000 mm.' }))
      .typeError(() => t({ id: 'form.length.errorInvalid', message: 'Length must be a number.' }))
      .positive(() => t({ id: 'form.length.errorNegative', message: 'Number must be positive.' })),
    referenceNumbers: yup
      .array()
      .transform(transformRefNumbers)
      .of(
        yup
          .string()
          .trim()
          .required(t({ id: 'form.productReferenceNumbers.errorEmpty', message: 'Please, enter reference numbers.' }))
          .matches(Sku, () => (
            <>
              {
                (t({
                  id: 'form.productReferenceNumbers.errorInvalid',
                  message: `The input can contain only numbers, letters and following characters: `,
                }),
                (<Text fontFamily={'monospace'}>{`+-?/\\#$*&()@_"':,.`}</Text>))
              }
            </>
          ))
          .max(36, () => t({ id: 'form.general.max36Chars', message: 'Maximal length is 36 characters.' })),
      ),
    productGroup: yup
      .string()
      .nullable()
      .max(50, () => t({ id: 'form.productGroup.errorTooLong', message: 'Maximal length is 50 characters.' })),
    eshops: yup
      .array()
      .nullable()
      .required(() => t({ id: 'form.eshops.errorEmpty', message: 'Please, choose at least one eshop.' })),
    description: yup
      .string()
      .nullable()
      .max(255, () => t({ id: 'form.general.max255Chars', message: 'Maximal length is 255 characters.' })),
  })

  if (type == 'physical') {
    validationSchema = validationSchema.shape({
      packagingType: yup
        .string()
        .trim()
        .required(() => t({ id: 'form.packagingType.errorEmpty', message: 'Please, enter packaging type.' })),
      hsCode: yup.string().trim().nullable(),
      countryOfOrigin: yup
        .string()
        .min(2, () => t({ id: 'form.general.min2Chars', message: 'Minimal length is 2 characters.' }))
        .nullable(),
      images: yup.array().of(
        yup.object().shape({
          image: yup
            .mixed()
            .test(
              'filename',
              () => t({ id: 'form.general.max124Chars', message: 'Maximal length is 124 characters.' }),
              (value: File | undefined) => {
                return !value || value?.name?.length <= 128
              },
            )
            .test(
              'fileType',
              () => t({ id: 'form.productImages.errorNotImage', message: 'File is not image' }),
              (value: File | undefined) => {
                return !value || value.type?.split('/')[0] == 'image'
              },
            )
            .test(
              'fileSize',
              () => t({ id: 'form.productImages.errorTooLarge', message: 'File size is too large' }),
              (value: File | undefined) => {
                return !value || value.size <= 5 * 1024 * 1024
              },
            ),
        }),
      ),
    })
  }

  if (type == 'bundle') {
    validationSchema = validationSchema.shape({
      childrenProducts: yup
        .array()
        .nullable()
        .required(() => t({ id: 'form.items.errorEmpty', message: 'Please, enter at least one item.' }))
        .test(
          'is-in-eshop',
          '', // we are using custom error
          function (value: ProductDetailItem[]) {
            const eshopsValue = this.parent.eshops || []
            if (!value || !eshopsValue) return false
            const problematicItem = value.find((item) => {
              const missingEshop = eshopsValue.find((eshopId) => {
                return !(item.product.eshops || []).some((eshop) => eshop.id || eshop === eshopId)
              })
              return missingEshop ? item.product : false
            })
            if (problematicItem)
              return this.createError({
                message: t({
                  id: 'form.items.errorProductSetItemsEshop',
                  message: `Item missing in one or more selected Eshops (product: ${problematicItem.product.name})`,
                }),
              })

            return true
          },
        )
        .of(
          yup.object().shape({
            quantity: yup
              .number()
              .min(1, () => t({ id: 'form.quantity.errorMin', message: 'Quantity must be greater than or equal to 1.' })),
          }),
        ),
    })
  }

  if (checkSku) {
    return validationSchema.shape({
      productSku: yup
        .string()
        .max(60, () => t({ id: 'form.general.max60Chars', message: 'Maximal length is 60 characters.' }))
        .matches(Sku, () => (
          <>
            {t({
              id: 'form.productSku.errorInvalid',
              message: 'The input can contain only numbers, letters and following characters: ',
            })}
            <Text fontFamily={'monospace'}>{`+-?/\\#$*&()@_"':,.`}</Text>
          </>
        ))
        .required(() => t({ id: 'form.productSku.errorEmpty', message: 'Please, enter product SKU number.' })),
      internalSku: yup
        .string()
        .max(40, () => t({ id: 'form.general.max40Chars', message: 'Maximal length is 40 characters.' }))
        .matches(Sku, () => (
          <>
            {t({
              id: 'form.internalSku.errorInvalid',
              message: 'The input can contain only numbers, letters and following characters: ',
            })}
            <Text fontFamily={'monospace'}>{`+-?/\\#$*&()@_"':,.`}</Text>
          </>
        ))
        .required(() => t({ id: 'form.internalSku.errorEmpty', message: 'Please, enter internal SKU number.' })),
    })
  }

  return validationSchema
}

export default createValidationSchema
