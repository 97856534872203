export type FlagAppearanceType =
  | 'neutralBold'
  | 'neutralSubtle'
  | 'blueBold'
  | 'blueSubtle'
  | 'tealBold'
  | 'tealSubtle'
  | 'greenBold'
  | 'greenSubtle'
  | 'orangeBold'
  | 'orangeSubtle'
  | 'yellowBold'
  | 'yellowSubtle'
  | 'pinkBold'
  | 'pinkSubtle'
  | 'purpleBold'
  | 'purpleSubtle'
  | 'redBold'
  | 'redSubtle'

export const flagAppearanceToColorMap = {
  neutralBold: 'neutral300',
  neutralSubtle: 'neutral20',
  blueBold: 'blue70',
  blueSubtle: 'blue20',
  tealBold: 'teal60',
  tealSubtle: 'teal20',
  greenBold: 'green60',
  greenSubtle: 'green20',
  orangeBold: 'orange60',
  orangeSubtle: 'orange20',
  yellowBold: 'yellow60',
  yellowSubtle: 'yellow20',
  pinkBold: 'magenta60',
  pinkSubtle: 'magenta20',
  purpleBold: 'purple60',
  purpleSubtle: 'purple20',
  redBold: 'red60',
  redSubtle: 'red20',
}

export const flagAppearanceToFontColorMap = {
  neutralBold: 'white',
  neutralSubtle: 'typoPrimary',
  blueBold: 'white',
  blueSubtle: 'blue80',
  tealBold: 'white',
  tealSubtle: 'teal90',
  greenBold: 'white',
  greenSubtle: 'green80',
  orangeBold: 'white',
  orangeSubtle: 'orange80',
  yellowBold: 'typoPrimary',
  yellowSubtle: 'typoPrimary',
  pinkBold: 'white',
  pinkSubtle: 'magenta80',
  purpleBold: 'white',
  purpleSubtle: 'purple80',
  redBold: 'white',
  redSubtle: 'red80',
}
