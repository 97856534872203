import React, { useCallback } from 'react'
import { FieldArray } from 'formik'
import AdviceItemsField from './AdviceItemsField'
import { GridSelectorsType, GridActionsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { formikCallback } from '@typings/Formik'
import { ExpeditionItemNested } from '@typings/entities/Expedition'
import { ProductApiRead } from '@typings/entities/Product'
import { FileSubmit, OnDownloadTemplate } from '@typings/file'

interface AdviceItemsProps {
  isEditable?: boolean
  isPairingEditable?: boolean
  isLoading?: boolean
  loadProducts: (fulltext: string) => Promise<any>
  itemsGridSelectors: GridSelectorsType
  itemsGridActions: GridActionsType
  setFieldValue: formikCallback
  pairingGridSelectors: GridSelectorsType
  pairingGridActions: GridActionsType
  loadExpeditionItems: (productId: string) => void
  expeditionItems: ExpeditionItemNested[]
  expeditionItemsIsLoading: boolean
  onOpenExpedition: (id: string) => void
  onImportItems: FileSubmit<{ product: ProductApiRead; quantity: number }[]>
  onDownloadImportTemplate: OnDownloadTemplate
}

const AdviceItems = ({
  isEditable,
  isPairingEditable,
  isLoading,
  loadProducts,
  itemsGridSelectors,
  itemsGridActions,
  setFieldValue,
  pairingGridSelectors,
  pairingGridActions,
  loadExpeditionItems,
  expeditionItems,
  expeditionItemsIsLoading,
  onOpenExpedition,
  onImportItems,
  onDownloadImportTemplate,
}: AdviceItemsProps): JSX.Element => {
  const AdviceItemsFieldRenderer = useCallback(
    (props) => (
      <AdviceItemsField
        {...props}
        isEditable={isEditable}
        isPairingEditable={isPairingEditable}
        loadProducts={loadProducts}
        isLoading={isLoading}
        gridSelectors={itemsGridSelectors}
        gridActions={itemsGridActions}
        setFieldValue={setFieldValue}
        pairingGridSelectors={pairingGridSelectors}
        pairingGridActions={pairingGridActions}
        loadExpeditionItems={loadExpeditionItems}
        expeditionItems={expeditionItems}
        expeditionItemsIsLoading={expeditionItemsIsLoading}
        onOpenExpedition={onOpenExpedition}
        onImportItems={onImportItems}
        onDownloadImportTemplate={onDownloadImportTemplate}
      />
    ),
    [
      isLoading,
      isEditable,
      isPairingEditable,
      loadProducts,
      setFieldValue,
      itemsGridSelectors,
      itemsGridActions,
      pairingGridSelectors,
      pairingGridActions,
      loadExpeditionItems,
      expeditionItems,
      expeditionItemsIsLoading,
      onOpenExpedition,
      onImportItems,
      onDownloadImportTemplate,
    ],
  )
  return <FieldArray name="items" render={AdviceItemsFieldRenderer} />
}

export default AdviceItems
