import React, { useCallback } from 'react'
import { ButtonContent, ButtonsWrap, StyledSquareButton, ButtonLabel } from './styles'

export type SquareButton = {
  content: React.ReactNode
  label: string | JSX.Element
  value?: string | number
}

export type SquareButtonProps = {
  button: SquareButton
  hasBorder: boolean
  onClick?: (value: SquareButton['value']) => void
}

export type SquareButtonsProps = {
  onClick: SquareButtonProps['onClick']
  buttons: SquareButton[]
}

const SquareButton = ({ onClick, button, hasBorder }: SquareButtonProps) => {
  const { content, label, value } = button
  const handleClick = useCallback(() => value && onClick?.(value), [onClick, value])
  return (
    <StyledSquareButton onClick={handleClick} hasBorder={hasBorder} hasValue={!!value}>
      <ButtonContent>{content}</ButtonContent>
      <ButtonLabel>{label}</ButtonLabel>
    </StyledSquareButton>
  )
}

export const SquareButtons = ({ onClick, buttons = [] }: SquareButtonsProps): JSX.Element => {
  return (
    <ButtonsWrap>
      {buttons.map((button, index) => {
        const key = `${index}-${button.value || button.label}`
        const isLast = index === buttons.length - 1
        return <SquareButton key={key} button={button} onClick={onClick} hasBorder={!isLast} />
      })}
    </ButtonsWrap>
  )
}
