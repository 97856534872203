import React, { useCallback } from 'react'
import { FieldArray } from 'formik'
import TransferItemsField from './TransferItemsField'
import { ProductApiRead } from '@typings/entities/Product'
import { ProductStockApi } from '@typings/entities/ProductStock'
import { GridSelectorsType, GridActionsType } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { formikCallback } from '@typings/Formik'
import { FileSubmit, OnDownloadTemplate } from '@typings/file'

interface TransferItemsProps {
  isEditable?: boolean
  loadProducts: (fulltext: string) => Promise<ProductApiRead[]>
  loadProductStocks: (productIds: string[], warehouse: string, wms: string) => Promise<ProductStockApi[]>
  warehouse?: string
  wms?: string
  itemsGridSelectors: GridSelectorsType
  itemsGridActions: GridActionsType
  setFieldValue: formikCallback
  onImportItems: FileSubmit<{ product: ProductApiRead; quantity: number }[]>
  onDownloadImportTemplate: OnDownloadTemplate
}

const TransferItems = ({
  isEditable,
  loadProducts,
  loadProductStocks,
  warehouse,
  wms,
  itemsGridSelectors,
  itemsGridActions,
  setFieldValue,
  onImportItems,
  onDownloadImportTemplate,
}: TransferItemsProps): JSX.Element => {
  const TransferItemsFieldRenderer = useCallback(
    (props) => (
      <TransferItemsField
        {...props}
        isEditable={isEditable}
        loadProducts={loadProducts}
        loadProductStocks={loadProductStocks}
        warehouse={warehouse}
        wms={wms}
        gridSelectors={itemsGridSelectors}
        gridActions={itemsGridActions}
        setFieldValue={setFieldValue}
        onImportItems={onImportItems}
        onDownloadImportTemplate={onDownloadImportTemplate}
      />
    ),
    [
      isEditable,
      loadProducts,
      loadProductStocks,
      warehouse,
      wms,
      itemsGridSelectors,
      itemsGridActions,
      setFieldValue,
      onImportItems,
      onDownloadImportTemplate,
    ],
  )
  return <FieldArray name="items" render={TransferItemsFieldRenderer} />
}

export default TransferItems
