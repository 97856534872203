import { useCallback } from 'react'
import { formatApiErrorToFormikErrors } from '@utils/formik/formatApiToFormikErrors'
import { ApiError } from '@typings/entities/Error'
import { FormikHelpers } from 'formik'
import { FormikHandleSubmit } from '@typings/Formik'
import { ApolloQueryResult, TypedDocumentNode, useApolloClient } from '@apollo/client'
import { t } from '@lingui/macro'
import { displayToast } from '@utils/toast'

export const useOnSubmit = <ENT extends object>(
  onSuccess:
    | null
    | ((formikHelpers: FormikHelpers<ENT>) => void)
    | ((variables?: Partial<{ criteria: { organisation: { eq: string } } }> | undefined) => Promise<ApolloQueryResult<any>>),
  createAction: null | TypedDocumentNode<any, { values: ENT }>,
  editAction: null | TypedDocumentNode<any, { values: ENT }>,
  onError?: null | (() => void),
): FormikHandleSubmit<ENT> => {
  const client = useApolloClient()
  return useCallback(
    async (values, formikHelpers) => {
      const query = !values?.id ? createAction : editAction

      const trimValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [key, typeof value == 'string' ? value.trim() : value]),
      )

      if (query) {
        await client
          .query({ query, variables: { ...trimValues } })
          .then(() => {
            formikHelpers.setSubmitting(false)
            onSuccess && onSuccess(formikHelpers)
            displayToast({
              type: 'success',
              text: t({ id: 'form.alertChangesSuccessfullySaved', message: 'Changes have been successfully saved.' }),
            })
          })
          .catch((error: Error) => {
            formikHelpers.setErrors(formatApiErrorToFormikErrors(error as ApiError))
            formikHelpers.setSubmitting(false)

            if (typeof onError === 'function') {
              onError()
            }
          })
      }
    },
    [createAction, editAction, client, onSuccess, onError],
  )
}
