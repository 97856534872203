import React, { useCallback, useMemo, useState } from 'react'
import styled, { x } from '@xstyled/styled-components'
import { Field, FieldArrayRenderProps } from 'formik'
import getOr from 'lodash/fp/getOr'
import uniqBy from 'lodash/uniqBy'
import InputField from '@components/forms/components/InputField'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import FileDropZone from '@components/elements/FileDropZone'
import FaIcon from '@mailstep/design-system/ui/Elements/Icon'
import { Trans } from '@lingui/react'
import { StyledSubTitle } from '@components/elements/Typography/lib'
import { t } from '@lingui/macro'
import { displayToast } from '@utils/toast'

type Props = {
  dropdownLayout?: 'top' | 'bottom'
  button?: JSX.Element
  fileSizeLimit?: number
} & FieldArrayRenderProps

const StyledBox = styled(x.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 2 3;
  width: 100%;

  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: lightGray3;

  & input {
    border: none;
    background: none;
    color: blue2;
    padding-left: 4;
    padding-bottom: 3;
    font-weight: 700;

    &:disabled {
      background: none;
    }
  }
`

const IconWrapper = styled.div`
  cursor: pointer;
  color: blue2;
  padding: 5px;
  font-size: 16px;

  &:hover {
    color: red1;
  }
`

const FileDropBox = styled.div`
  height: 200px;
  width: 100%;

  & > * {
    height: 100%;
  }

  margin-bottom: 2;
`

const DropZoneErrorsWrapper = styled.div`
  width: 100%;
  margin-top: 3;
  margin-bottom: 3;
`

const DropZoneError = styled.div`
  margin-bottom: 2;
  color: red1;
  font-weight: bold;
`

const DEFAULT_FILE_SIZE_LIMIT = 5 // MB

type DropzoneProps = {
  onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => void
  multiple?: boolean
  button?: JSX.Element
  fileSizeLimit: number
}

const Dropzone = ({ onDrop, multiple = false, fileSizeLimit }: DropzoneProps): JSX.Element => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: multiple ? undefined : 1,
    maxSize: fileSizeLimit * 1000000,
    multiple: multiple,
    onDrop,
    accept: [
      '.png',
      '.gif',
      '.jpeg',
      '.jpg',
      '.pdf',
      '.csv',
      '.xls',
      '.txt',
      '.xlsx',
      '.doc',
      '.docx',
      '.rtf',
      '.ods',
      '.ppt',
      '.xml',
    ],
  })

  return (
    <FileDropBox>
      <FileDropZone
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        isDragActive={isDragActive}
        onOpen={open}
        type="attachment"
      />
    </FileDropBox>
  )
}

const AttachmentField = ({
  remove,
  name,
  form,
  button,
  dropdownLayout = 'top',
  fileSizeLimit = DEFAULT_FILE_SIZE_LIMIT,
}: Props): JSX.Element => {
  const [dropZoneErrors, setDropZoneErrors] = useState<string[] | null>(null)
  const attachments = useMemo(() => getOr([], name, form?.values), [name, form?.values])

  const handleRemoveClick = useCallback(
    (index: number) => (): void => {
      remove(index)
    },
    [remove],
  )

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      const merged = [...attachments, ...acceptedFiles]
      form.setFieldValue(name, uniqBy(merged, 'name'))

      if (Array.isArray(rejectedFiles) && rejectedFiles.length) {
        const errors = rejectedFiles.map(
          (rejectedFile) =>
            `${rejectedFile.file?.name} - ${
              rejectedFile.errors[0]?.code === 'file-too-large'
                ? `${t({ id: 'form.fileUpload.fileTooLarge', message: 'file is larger than' })} ${fileSizeLimit}MB.`
                : t({ id: 'form.fileUpload.fileRejected', message: 'file was rejected' })
            }`,
        )
        displayToast({
          type: 'error',
          text: t({ id: 'form.fileUpload.generalError', message: 'Some of the selected files were rejected' }),
        })
        setDropZoneErrors(errors)
      } else {
        if (dropZoneErrors) {
          setDropZoneErrors(null)
        }
      }
    },
    [attachments, name, form, fileSizeLimit, dropZoneErrors],
  )

  const onDownload = useCallback(
    (attachment: Blob, id: string) => (): void => {
      const file = URL.createObjectURL(attachments[id])

      const tempLink = document.createElement('a')
      tempLink.href = file
      tempLink.setAttribute('download', `${attachment?.path || `attachedFile${id}`}`)
      tempLink.click()
    },
    [attachments],
  )

  return (
    <StyledBox>
      {dropdownLayout == 'top' && <Dropzone onDrop={onDrop} multiple button={button} fileSizeLimit={fileSizeLimit} />}
      {attachments.length !== 0 && (
        <x.div w="100%" mt={4}>
          <StyledSubTitle>
            <Trans id="form.attachmentFiles.label" message="Attachment files" />
          </StyledSubTitle>
        </x.div>
      )}
      {attachments.map((attachment: Blob | { name: string }, index: number) => (
        <StyledRow key={`row${index}`}>
          <Field component={InputField} name={`${name}[${index}].name`} disabled />
          <x.div mr={2}>
            <IconWrapper onClick={onDownload(attachment, index)}>
              <FaIcon icon="download" />
            </IconWrapper>
          </x.div>
          <IconWrapper onClick={handleRemoveClick(index)}>
            <FaIcon icon="deleteX" />
          </IconWrapper>
        </StyledRow>
      ))}
      {dropdownLayout == 'bottom' && <Dropzone onDrop={onDrop} multiple button={button} fileSizeLimit={fileSizeLimit} />}
      {Array.isArray(dropZoneErrors) && (
        <DropZoneErrorsWrapper>
          {dropZoneErrors.map((item: any, index: number) => (
            <DropZoneError key={index}>{item}</DropZoneError>
          ))}
        </DropZoneErrorsWrapper>
      )}
    </StyledBox>
  )
}

export default AttachmentField
