import { AccountAsNested } from '@typings/entities/User'
import { Option } from '@mailstep/design-system/ui/Elements/Select/types'

const getAccountOption = ({ id, firstName, lastName }: AccountAsNested): Option => ({
  value: id,
  label: `${firstName} ${lastName}`,
})

export const getAccountLongOption = ({ id, firstName, lastName, email }: AccountAsNested): Option => ({
  value: id,
  label: `${firstName} ${lastName} (${email})`,
})

export default getAccountOption
