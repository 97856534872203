import React from 'react'
import getFieldError from './getFieldError'

type Props = {
  field: any
  children?: React.ReactNode
}

const withFormikToProps =
  (Comp: React.ComponentType): React.FC<Props> =>
  (props: Props): JSX.Element =>
    <Comp {...props.field} {...props} error={getFieldError(props)} />

export default withFormikToProps
