import { t } from '@lingui/macro'
import { FlagAppearanceType } from '@constants/FlagAppearance'

export const PackagingUnit = {
  pallet: 'pallet',
  package: 'package',
}

export const Status = {
  on_hold: 'on hold',
  new: 'new',
  waiting: 'waiting',
  in_progress: 'in progress',
  partially_finished: 'partially finished',
  finished: 'finished',
  canceled: 'canceled',
  closed: 'closed',
}

// note: definitions restored form git:
export const statusToColor: { [status: string]: FlagAppearanceType } = {
  incorrect: 'redSubtle',
  waiting: 'orangeSubtle',
  on_hold: 'neutralSubtle',
  in_progress: 'blueSubtle',
  finished: 'greenSubtle',
  partially_finished: 'purpleSubtle',
  new: 'tealSubtle',
  canceled: 'redBold',
  closed: 'pinkSubtle',
}

export const EditableStatuses = ['new', 'on_hold', 'waiting', 'incorrect']
export const PairingStatuses = ['waiting', 'in_progress', 'partially_finished']

export const StatusTrans = {
  on_hold: () => t({ id: 'stockAdviceStatus.onHold', message: `on hold` }),
  new: () => t({ id: 'stockAdviceStatus.new', message: `new` }),
  waiting: () => t({ id: 'stockAdviceStatus.waiting', message: `waiting` }),
  in_progress: () => t({ id: 'stockAdviceStatus.inProgress', message: `in progress` }),
  partially_finished: () => t({ id: 'stockAdviceStatus.partiallyFinished', message: `partially finished` }),
  finished: () => t({ id: 'stockAdviceStatus.finished', message: `finished` }),
  canceled: () => t({ id: 'stockAdviceStatus.canceled', message: `canceled` }),
  closed: () => t({ id: 'stockAdviceStatus.closed', message: `closed` }),
  incorrect: () => t({ id: 'stockAdviceStatus.incorrect', message: `incorrect` }),
}

export type Statuses = keyof typeof StatusTrans
