export const getLanguage = (): string => {
  if (typeof window === 'undefined') return 'en'
  const storedLang = localStorage.getItem('@@language')
  if (storedLang) return storedLang
  const isOnAdmin = window?.location?.pathname.includes('/admin')
  return isOnAdmin ? 'en' : 'cs'
}

export const setLanguage = (language: string): void => {
  if (typeof window === 'undefined') return
  document.cookie = `lang=${language}`
  // to localStorage for Accept-Language header when making XHR
  window.localStorage.setItem('@@language', language)
  window.location.reload()
}
