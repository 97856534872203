import gql from 'graphql-tag'

const complaintsGetQuery = gql`
  query complaintsGet($pagination: Object, $filters: Object, $orderBy: Object) {
    complaintsGet(input: { pagination: $pagination, filters: $filters, orderBy: $orderBy })
      @rest(type: "ComplaintsResult", method: "POST", path: "fe-complaints/list", endpoint: "gateway") {
      results
      paging
    }
  }
`

export const complaintsGetCarriersQuery = gql`
  query complaintsGetCarrier($criteria: Object, $select: Array, $nested: boolean) {
    complaintsGetCarries(input: { criteria: $criteria, select: $select, nested: $nested })
      @rest(type: "ComplaintsCarrriers", method: "POST", path: "carrier/list") {
      results
      paging
    }
  }
`

export default complaintsGetQuery
