import TextArea, { Props as TextAreaProps } from '@mailstep/design-system/ui/Forms/TextArea/TextArea'
import withFormikToProps from '@utils/formik/withFormikToProps'
import React, { ChangeEvent } from 'react'

type Props = TextAreaProps & {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const TextAreaField = ({ value, onChange, resize, ...rest }: Props): JSX.Element => {
  const controlledValue = value ? value : ''
  return <TextArea value={controlledValue} onChange={onChange} {...rest} resize={resize || 'vertical'} />
}

export default withFormikToProps(TextAreaField)
