import React, { useCallback } from 'react'
import styled, { css, x } from '@xstyled/styled-components'
import { Field, FieldArray, Form, FormikProps } from 'formik'
import InputField from '@components/forms/components/InputField'
import SelectField from '@components/forms/components/SelectField'
import SwitchField from '@components/forms/components/SwitchField'
import CommonErrorField from '@components/forms/components/CommonErrorField'
import TextAreaField from '@components/forms/components/TextAreaField'
import ImageArrayField from './components/ImageArrayField'
import RefNumbersField from './components/RefNumbersField'
import { PackagingTypeTrans, Product, ProductType } from '@typings/entities/Product'
import { Trans } from '@lingui/react'
import { t } from '@lingui/macro'
import { Ability, withAcl } from '@components/blocks/Acl'
import { Option as OptionType } from '@mailstep/design-system/ui/Elements/Select/types'
import DimensionsField from './components/DimensionsField'
import { Col } from '@designSystem/Grid'
import { ExternalFormProps } from './types'
import StocksTabWrap from './components/StocksTabWrap'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'
import SwitchWithDialogField from '../components/SwitchWithDialogField'
import { ResponsiveRowWrap, ResponsiveSectionWrap, SwitchFieldContainer } from '../styles'
import { localisationOptions } from '@constants/localisation'

type Props = ExternalFormProps &
  FormikProps<Product> & {
    ability: Ability
    setType: React.Dispatch<React.SetStateAction<ProductType>>
    onDownloadAttachment: (id: string, fileName?: string) => Promise<void>
  }

const wrapper = css`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;

  & > *:not(.wrap) {
    margin-top: 1;
    margin-bottom: 2;
  }
`

const StyledOuterBox = styled(x.div)<{ $topGutter: boolean }>`
  ${wrapper};
  ${({ $topGutter }): string => ($topGutter ? 'margin-top: 29px' : '')};
`

const getPackagingTypeOptions = (): OptionType[] => [
  { value: 'A', label: PackagingTypeTrans.A() },
  { value: 'B', label: PackagingTypeTrans.B() },
]

const ProductEditForm = ({
  handleSubmit,
  isSubmitting,
  createView = false,
  productGroupOptions,
  createProductGroup,
  onDownloadAttachment,
  ability,
  countryOptions,
  eshopOptions,
  isSecondaryLoading,
  productStocks,
  title,
  titleIcon,
  onCancel,
  openLightbox,
  setFieldValue,
  setType,
  isLotEvidenceEditable,
}: Props): JSX.Element => {
  const hasQuantityStocks = !createView && !!productStocks.filter((productStock) => productStock.quantity > 0)?.length
  // all fields are enabled in create view
  const isAdminDisabled = !createView && !ability.can('adminEdit', 'Product') // assuming at least 'edit' is granted, since we are on this page
  const isSupervisorDisabled = !createView && !ability.can('supervisorEdit', 'Product')

  return (
    <Modal
      title={title}
      titleIcon={titleIcon}
      onCancel={onCancel}
      cancelLabel={<Trans id="form.buttonCancel" message="Cancel" />}
      hasFooter={false}
      width="wide"
      isLoading={isSubmitting}
    >
      <StocksTabWrap
        createView={createView}
        productStocks={productStocks}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        setType={setType}
        setFieldValue={setFieldValue}
      >
        <Form onSubmit={handleSubmit}>
          <CommonErrorField />
          <ResponsiveSectionWrap>
            <Col mt="32px" mr={3}>
              <ResponsiveRowWrap>
                <Field
                  component={InputField}
                  spaceAround
                  label={<Trans id="form.productName.label" message="Name" />}
                  name="name"
                />
              </ResponsiveRowWrap>
              <ResponsiveRowWrap>
                <Field
                  component={InputField}
                  label={<Trans id="form.productSku.label" message="Product SKU" />}
                  name="productSku"
                  spaceAround
                  disabled={isSupervisorDisabled || hasQuantityStocks}
                />
                <Field
                  component={InputField}
                  label={<Trans id="form.internalSku.label" message="Internal SKU" />}
                  name="internalSku"
                  spaceAround
                  disabled={isSupervisorDisabled}
                />
              </ResponsiveRowWrap>
              <ResponsiveRowWrap>
                <Field
                  component={InputField}
                  spaceAround
                  label={<Trans id="form.hsCode.label" message="HS Code" />}
                  name="hsCode"
                />
                <Field
                  component={SelectField}
                  label={<Trans id="form.countryOfOrigin.label" message="Country of origin" />}
                  name="countryOfOrigin"
                  spaceAround
                  options={countryOptions}
                  isClearable
                  isLoading={isSecondaryLoading}
                />
              </ResponsiveRowWrap>

              <ResponsiveRowWrap>
                <Field
                  component={SelectField}
                  label={<Trans id="form.packagingType.label" message="Packaging type" />}
                  name="packagingType"
                  options={getPackagingTypeOptions()}
                  disabled={isAdminDisabled}
                  isSearchable={false}
                  spaceAround
                  placeholder={t({
                    id: 'form.packagingType.emptyOption',
                    message: 'Choose packaging type',
                  })}
                />
                <Field
                  component={SelectField}
                  onCreateOption={createProductGroup}
                  options={productGroupOptions}
                  label={<Trans id="form.productGroup.label" message="Product group" />}
                  name="productGroup"
                  isClearable
                  spaceAround
                  isLoading={isSecondaryLoading}
                />
              </ResponsiveRowWrap>
              <ResponsiveRowWrap>
                <Field
                  component={SelectField}
                  label={<Trans id="form.eshops.label" message="Eshops" />}
                  name="eshops"
                  options={eshopOptions}
                  isMulti
                  spaceAround
                  isSearchable={false}
                  isLoading={isSecondaryLoading}
                />
              </ResponsiveRowWrap>
              <FieldArray
                name="referenceNumbers"
                render={useCallback(
                  (props) => (
                    <RefNumbersField {...props} disabled={isAdminDisabled} />
                  ),
                  [isAdminDisabled],
                )}
              />
              <ResponsiveRowWrap>
                <SwitchFieldContainer>
                  <StyledOuterBox mt={2} minW="180px">
                    <Field
                      component={SwitchWithDialogField}
                      label={<Trans id="form.workAroundSnIn.label" message="Serial number evidence" />}
                      name="workAroundSnIn"
                      spaceBetween
                      dialogText={
                        <Trans
                          id="cornerDialog.workAroundSnIn.text"
                          message="If the product has been already expedited without an serial number evidence, it may cause problems with his return."
                        />
                      }
                    />
                    <Field
                      component={SwitchField}
                      label={<Trans id="form.workAroundLot.label" message="LOT evidence" />}
                      name="workAroundLot"
                      disabled={!isLotEvidenceEditable && !createView}
                      spaceBetween
                    />
                    <Field
                      component={SwitchField}
                      label={<Trans id="form.active.label" message="Active" />}
                      name="active"
                      disabled={createView}
                      spaceBetween
                    />
                    <Field
                      component={SwitchField}
                      label={<Trans id="form.additionalWork.label" message="Additional work on receiving" />}
                      name="requiresAdditionalWork"
                      spaceBetween
                    />
                    <Field
                      component={SwitchField}
                      label={<Trans id="form.qualitativeReceiving.label" message="Qualitative receiving" />}
                      name="requiresQualitativeReceiving"
                      spaceBetween
                    />
                  </StyledOuterBox>
                </SwitchFieldContainer>
                <Col>
                  <Field
                    component={TextAreaField}
                    label={<Trans id="form.description.label" message="Description" />}
                    name="description"
                    spaceAround
                    rows={5}
                  />
                </Col>
                <Col>
                  <Field
                    component={TextAreaField}
                    label={<Trans id="form.customsDescription.label" message="Customs description" />}
                    name="customsDescription"
                    spaceAround
                    rows={5}
                  />
                </Col>
              </ResponsiveRowWrap>
            </Col>
            <Col>
              <ResponsiveRowWrap>
                <DimensionsField title={<Trans id="form.dimensions.heading" message="Dimensions" />} />
                <DimensionsField
                  name="int"
                  isDisabled={true}
                  title={<Trans id="form.measurements.heading" message="Measurements" />}
                />
              </ResponsiveRowWrap>
              <ResponsiveRowWrap>
                <Field
                  component={SelectField}
                  label={<Trans id="form.languagesOnTheCoverLabel" message="Languages on the cover" />}
                  name="localisation"
                  options={localisationOptions}
                  isClearable
                  isMulti
                  spaceAround
                  isSearchable
                />
              </ResponsiveRowWrap>
              <ResponsiveRowWrap>
                <Col size={6}>
                  <FieldArray
                    name="images"
                    render={useCallback(
                      (props) => (
                        <ImageArrayField
                          {...props}
                          onDownload={onDownloadAttachment}
                          openLightbox={openLightbox}
                          spaceAround
                          label={<Trans id="form.productImages.label" message="Your images" />}
                        />
                      ),
                      [openLightbox, onDownloadAttachment],
                    )}
                  />
                </Col>
                <Col size={6}>
                  <FieldArray
                    name="intImages"
                    render={useCallback(
                      (props) => (
                        <ImageArrayField
                          {...props}
                          onDownload={onDownloadAttachment}
                          openLightbox={openLightbox}
                          spaceAround
                          label={<Trans id="form.productWmsImages.label" message="WMS images" />}
                          disabled
                        />
                      ),
                      [openLightbox, onDownloadAttachment],
                    )}
                  />
                </Col>
              </ResponsiveRowWrap>
            </Col>
          </ResponsiveSectionWrap>
        </Form>
      </StocksTabWrap>
    </Modal>
  )
}

export default withAcl(ProductEditForm)
