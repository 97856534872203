import InputField from '@components/forms/components/InputField'
import React, { useCallback } from 'react'
import { InputProps } from '@mailstep/design-system/ui/Forms/Input/types'

// todo refactor, worst case to onValueChange, as in selectField

type Props = InputProps & {
  customOnChange: () => void
}

const PartnerInputField = (props: Props): JSX.Element => {
  const partnerValue = props?.form?.values?.partner
  const onChangeHandler = useCallback(
    (e): void => {
      props?.field?.onChange(e)
      if (partnerValue) {
        props.form.setFieldValue('partner', null, false)
      }
    },
    [props?.field, props.form, partnerValue],
  )

  return <InputField onChange={onChangeHandler} {...props} />
}

export default PartnerInputField
