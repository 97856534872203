import { useCallback } from 'react'
import { BaselinkerApi } from '@services/BaselinkerApi'
import { useBaselinkerData } from '@pages/SharedModule/Baselinker/hooks/useBaselinkerData'
import { ProductSyncRetryData } from '@typings/entities/ProductsSynchronization'

type HookType = () => (dto: ProductSyncRetryData) => Promise<void>

export const useSyncProductRetry: HookType = () => {
  const [{ data: credentials }] = useBaselinkerData()
  return useCallback(
    async ({ baseLinkerProductId, inventoryId }: ProductSyncRetryData) => {
      if (!credentials?.syncDetailToken) return

      await BaselinkerApi(credentials.syncDetailToken)
        .product.productSyncRetry({
          baseLinkerProductId,
          inventoryId,
        })
        .catch(console.error)
    },
    [credentials?.syncDetailToken],
  )
}
