import gql from 'graphql-tag'

const auditsGetQuery = gql`
  query auditsGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    auditsGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting })
      @rest(type: "auditsResult", method: "POST", path: "audit") {
      results
      paging
    }
  }
`

export default auditsGetQuery
