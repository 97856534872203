import React from 'react'
import { toast } from 'react-toastify'
import Toast, { ToastType } from '@mailstep/design-system/ui/Elements/Toast'

type Props = {
  title?: string
  text?: string
  type: ToastType
}

export const displayToast = ({ title, text, type }: Props): void => {
  toast(<Toast title={title} text={text} type={type} />, {
    type,
    icon: () => null,
    hideProgressBar: true,
    toastId: `${title}${text}`,
  })
}
