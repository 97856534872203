import { DateTime } from 'luxon'
import { DatePattern } from '@components/forms/utils/RegexValidation'

export const validatePastDate = (value?: string | null): boolean => {
  if (!value) {
    return true
  }

  let date
  const today = new Date()

  if (DatePattern.test(value)) {
    date = DateTime.fromFormat(value, 'dd.MM.yyyy').toJSDate()
  } else {
    date = new Date(value)
  }

  date.setHours(0, 0, 0, 0)
  today.setHours(0, 0, 0, 0)

  return date <= today
}
