import React from 'react'
import styled from '@xstyled/styled-components'
import { Paragraph3 } from '@mailstep/design-system/ui/Elements/Typography'

type Item = {
  icon: JSX.Element
  title: string | JSX.Element
  count?: number
}

const Label = styled(Paragraph3)`
  line-height: 22.4px;
`

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
`

const Count = styled.div`
  font-weight: bold;
  margin-left: 16px;
`

const ItemIcon = styled.div`
  display: flex;
  font-size: 14px;
  margin-right: 16px;
`

const DropdownItem = ({ icon, title, count }: Item): JSX.Element => {
  return (
    <StyledListItem>
      <ItemIcon>{icon}</ItemIcon>
      <Label margin={0} variant="normal">
        {title}
      </Label>
      {count != undefined && <Count>{count}</Count>}
    </StyledListItem>
  )
}

export default DropdownItem
