import { TransferApiRead, TransferDetail } from '@typings/entities/Transfer'
import { formatEmptyValuesToRead, formatValuesToWrite } from '@utils/formik/formatValues'
import { ProductApiRead } from '@typings/entities/Product'
import { convertToISO } from '@utils/date'

const transferProperties = [
  'id',
  'internalNumber',
  'note',
  'type',
  'requiredTransferDate',
  'organisation',
  'warehouseFrom',
  'warehouseTo',
  'wmsFrom',
  'wmsTo',
  'items',
]

const transferItemProperties = ['product', 'quantity', 'book', 'lot', 'lifo', 'ref1', 'ref2', 'ref3']

export const formatTransferToWrite = (values) => {
  const formattedTransfer = formatValuesToWrite(values, transferProperties)
  formattedTransfer.items = formattedTransfer.items.map((item) => {
    const writeItem = formatValuesToWrite(item, transferItemProperties)
    writeItem.product = writeItem.product.id
    return writeItem
  })
  if (formattedTransfer.requiredTransferDate) {
    formattedTransfer.requiredTransferDate = convertToISO(formattedTransfer.requiredTransferDate)
  }
  return formattedTransfer
}

export type TransferIn = { items: { product: ProductApiRead }[] } & TransferApiRead

export const formatTransferToRead = (transfer: TransferIn): TransferDetail => {
  if (!transfer) return {}
  const formattedTransfer = formatEmptyValuesToRead(transfer)
  formattedTransfer.items = formattedTransfer.items?.map((item) => {
    const newItem = formatEmptyValuesToRead(item)
    newItem.bookedDetail = {
      // temporary simple workaround, will be replace with more robust solution with addition of lot
      booked: newItem.booked || 0,
      warehouse: formattedTransfer.warehouseFrom,
      wms: formattedTransfer.wmsFrom,
    }
    newItem.book = newItem.booked
    newItem.availableStock = null
    return newItem
  })
  return formattedTransfer
}
