import styled from '@xstyled/styled-components'

export const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  text-align: center;
`

export const Error = styled.code`
  background-color: lightGray2;
  padding: 10px;
  margin-top: 30px;
  display: block;
`
