import createFetchSubmodule from '@utils/store/createFetchSubmodule'

export const moduleName = 'expeditionImport'

const importExpeditionsSubmodule = createFetchSubmodule('@store/Expeditions', 'IMPORT_EXPEDITIONS', moduleName)
const downloadImportTemplateSubmodule = createFetchSubmodule('@store/Expeditions', 'DOWNLOAD_IMPORT_TEMPLATE', moduleName)

export const actionTypes = {
  downloadImportTemplate: downloadImportTemplateSubmodule.actionTypes,
  importExpeditions: importExpeditionsSubmodule.actionTypes,
}

export const actions = {
  downloadImportTemplate: downloadImportTemplateSubmodule.actions.run,
  importExpeditions: importExpeditionsSubmodule.actions.run,
}
