import gql from 'graphql-tag'

const adminPasswordResetConfirmQuery = gql`
  query adminPasswordResetConfirm($token: String!, $newPassword: String!) {
    adminPasswordResetConfirm(input: { token: $token, newPassword: $newPassword })
      @rest(type: "AdminPasswordResetConfirm", method: "POST", path: "reset-password/admin") {
      token
    }
  }
`

export default adminPasswordResetConfirmQuery
