import React from 'react'
import ErrorMessage from '@mailstep/design-system/ui/Elements/ErrorMessage'
import { StyledSubTitle } from '@components/elements/Typography/lib'
import styled from '@xstyled/styled-components'

// todo do better
const Title = styled.div`
  display: flex;
  flex-direction: row;
  ${StyledSubTitle} {
    margin-right: 1rem;
    margin-bottom: 0;
    align-self: center;
  }
  margin-bottom: 1rem;
`

type Props = {
  title: string | JSX.Element
  error: string | null
}

const FormGridTitle = ({ title, error }: Props): JSX.Element | null => {
  return (
    <Title>
      <StyledSubTitle>{title}</StyledSubTitle>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Title>
  )
}

export default FormGridTitle
