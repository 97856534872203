import React from 'react'
import getOr from 'lodash/fp/getOr'
import { RouteProps } from 'react-router'
import { LoadableComponent } from '@loadable/component'
import createRoute, { RouteDefinition } from './createRoute'

export type ReactRouteComponent = LoadableComponent<any>

export type ReactRoute = {
  route: RouteDefinition
  component: ReactRouteComponent
  render: (props: RouteProps) => React.ReactNode
  extraProps?: RouteProps
}

const getInitialProps = (props: any, key: string): object => getOr({}, 'staticContext.initialProps', props)[key]

const createReactRoute = (
  path: string | string[],
  pathParams: object | null,
  component: ReactRouteComponent,
  extraProps?: RouteProps,
  options?: object,
): ReactRoute => {
  const route = createRoute(path, pathParams, options)
  const render = (props: any): JSX.Element => {
    const Component = component
    const initialProps = getInitialProps(props, route.key)
    return <Component {...props} {...initialProps} />
  }
  return {
    route,
    component,
    render,
    extraProps,
  }
}

export default createReactRoute
