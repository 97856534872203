import routes from '@pages/index'
import { formatApiErrorToFormikErrors } from '@utils/formik/formatApiToFormikErrors'
import { t } from '@lingui/macro'
import { IComplaint } from '@typings/entities/Complaint'
import { formatValuesToWrite } from '@utils/formik/formatValues'
import { formatPriceForApi } from '@utils/price'
import { bindActionCreators, Dispatch } from 'redux'
import { getSuccessDialog } from '@utils/cornerDialogs'
import { actions as uiActions } from '@store/ui'
import { FormikHandleSubmit } from '@typings/Formik'
import { ApolloClient } from '@apollo/client'
import complaintCreateQuery from '@queries/complaintCreateQuery'
import { fileFormUpload } from '@queries/multipartFormDataUpload'

const complaintProperties = [
  'reporter',
  'organisation',
  'order',
  'reason',
  'resolution',
  'description',
  'internalId',
  'attachments',
  'currency',
  'value',
]

export const onComplaintSubmit =
  (client: ApolloClient<any>, dispatch: Dispatch, hideDialog: () => void): FormikHandleSubmit<IComplaint> =>
  async (values, formikHelpers): Promise<void> => {
    const pushDialog = bindActionCreators(uiActions.pushDialog, dispatch)

    const formattedValues = formatValuesToWrite(values, complaintProperties)
    if (formattedValues.value) {
      formattedValues.value = formatPriceForApi(formattedValues.value)
    }

    const successMessage = t({
      id: 'cornerDialog.complaintCreateSuccess.text',
      message: 'Complaint has been successfully created.',
    })

    return client
      .query({ query: complaintCreateQuery, variables: formattedValues })
      .then(async ({ data }) => {
        const attachments = formattedValues?.attachments || []

        for (const attachment of attachments) {
          if (!attachment) continue
          const formData = new FormData()
          formData.append('description', '')
          await fileFormUpload(`complaints/${data?.complaintCreate?.id}/attachments/form`, attachment, formData, 'gateway')
        }

        // Todo toast message with action buttons
        pushDialog({
          ...getSuccessDialog(successMessage),
          actionLabel: t({ id: 'cornerDialog.buttonOkRedirect', message: 'Ok, show me' }),
          redirectTo: routes.sharedComplaint.route.toUrl({ id: data?.complaintCreate?.id }),
          notimeout: true,
        })
        hideDialog()
      })
      .catch((error: Error) => {
        formikHelpers.setErrors(formatApiErrorToFormikErrors(error))
      })
      .finally(() => formikHelpers.setSubmitting(false))
  }
