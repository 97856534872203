/* eslint-disable react/display-name */
import React from 'react'
import { Field } from 'formik'
import InputField from '@components/forms/components/InputField'
import { ColumnDefinition, RowValueType, DataCellProps, ActionColumn } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { Trans } from '@lingui/react'
import { StockAdviceDetailItem } from '@typings/entities/StockAdvice'
import ConnectedInternalSkuCell from '@containers/ConnectedInternalSkuCell'
import getRuntimeConfig from '@utils/getRuntimeConfig'
import { IconButtonInCell, RemoveCell } from '@mailstep/design-system/ui/Blocks/CommonGrid'

const PairComponent = ({ rowData, onRowAction }: DataCellProps<StockAdviceDetailItem>): JSX.Element | null => {
  const onPairClick = React.useCallback(() => {
    if (onRowAction) onRowAction(rowData.id, 'pairExpedition', rowData)
  }, [rowData, onRowAction])

  // TODO MF-2809 pairing on advice temporarily hidden
  // => meanwhile we are displaying form in read only view => only alow modal if anything is paired
  if (!rowData.bookedAdviceTotal || rowData.bookedAdviceTotal == 0) return null

  return <IconButtonInCell onClick={onPairClick} icon="link" />
}

export const createActionColumnDefinition = (): ActionColumn => {
  return {
    flexBasis: 40,
    addRowNumbers: true,
  }
}

export const createColumnDefinitions = (
  isEditable: boolean,
  isPairingEditable: boolean,
  getIndex: (item: StockAdviceDetailItem) => number,
): ColumnDefinition[] => {
  const columns: ColumnDefinition[] = [
    {
      name: 'product.productSku',
      title: <Trans id="dataGrid.columnProductSku.title" message="Product SKU" />,
      filtering: true,
      sorting: true,
      flexBasis: 160,
    },
    {
      name: 'product.internalSku',
      cellComponent: ConnectedInternalSkuCell,
      title: <Trans id="dataGrid.columnInternalSku.title" message="Internal SKU" />,
      filtering: true,
      sorting: true,
      flexBasis: 160,
    },
    {
      name: 'product.name',
      title: <Trans id="dataGrid.columnProductName.title" message="Product name" />,
      filtering: true,
      sorting: true,
      flexBasis: 240,
    },
    {
      name: 'product.referenceNumbers',
      title: <Trans id="dataGrid.columnReferenceNumbers.title" message="Reference numbers" />,
      filtering: true,
      sorting: true,
      flexBasis: 240,
    },
    {
      name: 'suppliedQuantity',
      title: <Trans id="dataGrid.columnReceivedQty.title" message="Received QTY" />,
      filtering: true,
      sorting: true,
      filteringType: 'number',
      flexBasis: 120,
      flexShrink: 0,
      flexGrow: 0,
    },
    {
      name: 'quantity',
      title: <Trans id="dataGrid.columnTotalQty.title" message="Total quantity" />,
      formatRowValue: (row: StockAdviceDetailItem): RowValueType => {
        return isEditable ? (
          <Field
            name={`items[${getIndex(row)}][quantity]`}
            type="number"
            component={InputField}
            min={1}
            errorAppearance="tooltip"
            appearance="grid"
          />
        ) : (
          row.quantity
        )
      },
      sorting: true,
      filtering: true,
      filteringType: 'number',
      fixedSize: isEditable,
      flexBasis: 120,
      flexShrink: 0,
      flexGrow: 0,
    },
    {
      name: 'awaitingQuantity',
      title: <Trans id="dataGrid.columnAwaitingQty.title" message="Awaiting quantity" />,
      filtering: true,
      filteringType: 'number',
      sorting: true,
      flexBasis: 140,
      flexShrink: 0,
      flexGrow: 0,
    },
    {
      name: 'unitPurchasePrice',
      title: <Trans id="dataGrid.columnUnitPurchasePrice.title" message="Unit purchase price" />,
      filtering: true,
      filteringType: 'number',
      sorting: true,
      flexBasis: 150,
      flexShrink: 0,
      flexGrow: 0,
      formatRowValue: (row: StockAdviceDetailItem): RowValueType => {
        return isEditable ? (
          <Field
            name={`items[${getIndex(row)}][unitPurchasePrice]`}
            type="number"
            component={InputField}
            errorAppearance="tooltip"
            appearance="grid"
            min={0.00001}
          />
        ) : (
          row.unitPurchasePrice
        )
      },
    },
    {
      name: 'unitPurchasePriceCurrency',
      title: <Trans id="dataGrid.columnUnitPurchasePriceCurrencyy.title" message="Unit purchase price currency" />,
      filtering: true,
      sorting: true,
      flexBasis: 160,
      flexShrink: 0,
      flexGrow: 0,
      formatRowValue: (row: StockAdviceDetailItem): RowValueType => {
        return isEditable ? (
          <Field
            name={`items[${getIndex(row)}][unitPurchasePriceCurrency]`}
            component={InputField}
            errorAppearance="tooltip"
            appearance="grid"
            maxLength={3}
          />
        ) : (
          row.unitPurchasePriceCurrency
        )
      },
    },
  ]
  if (getRuntimeConfig('FRONTEND__SHOW_PAIRING')) {
    columns.push(
      // TODO MF-2809 pairing on advice temporarily hidden
      // {
      //   name: 'bookAdviceTotal',
      //   title: <Trans id="dataGrid.columnPair.title" message="Pair" />,
      //   sorting: true,
      //   filtering: true,
      //   filteringType: 'number',
      //   flexBasis: 120,
      //   flexGrow: 0,
      //   fixedSize: isEditable,
      //   formatRowValue: (row: StockAdviceDetailItem): RowValueType => {
      //     return isEditable ? (
      //       <Field
      //         name={`items[${getIndex(row)}][bookAdviceTotal]`}
      //         type="number"
      //         component={InputField}
      //         errorAppearance="tooltip"
      //         disabled
      //       />
      //     ) : (
      //       row.bookAdviceTotal
      //     )
      //   },
      // },
      {
        name: 'bookedAdviceTotal',
        title: <Trans id="dataGrid.columnPaired.title" message="Paired" />,
        sorting: true,
        filtering: true,
        filteringType: 'number',
        flexBasis: 120,
        flexGrow: 0,
      },
    )
  }
  if (getRuntimeConfig('FRONTEND__SHOW_PAIRING') && isPairingEditable) {
    columns.push({
      name: 'buttons2',
      title: '',
      cellComponent: PairComponent,
      alwaysOn: true,
      flexBasis: 60,
      flexShrink: 0,
      flexGrow: 0,
    })
  }
  if (isEditable) {
    columns.push({
      name: 'buttons',
      title: '',
      cellComponent: RemoveCell,
      alwaysOn: true,
      flexBasis: 80,
      flexShrink: 0,
      flexGrow: 0,
    })
  }
  return columns
}
