import React from 'react'
import { StatusUpload, StatusWrap } from '../styles'
import { Trans } from '@lingui/react'
import { Text } from '@mailstep/design-system/ui/Elements/Typography'
import isArray from 'lodash/isArray'
import FaIcon from '@mailstep/design-system/ui/Elements/Icon'
import Spinner from '@mailstep/design-system/ui/Elements/Spinner'

type Props = {
  file: File | null
  isUploading?: boolean
  error?: string | string[] | null
}
const Status = ({ file, isUploading, error }: Props) => {
  return (
    <StatusWrap>
      {isUploading ? (
        <StatusUpload>
          <Spinner variant="sm" />
          <Text>
            <Trans id="form.import.uploading" message="Uploading file..." />
          </Text>
        </StatusUpload>
      ) : error ? (
        <Text color="red1">
          {isArray(error)
            ? error.map((err) => (
                <>
                  {err}
                  <br />
                </>
              ))
            : error}
        </Text>
      ) : file ? (
        <Text>
          <Trans
            id="form.import.selectedFile"
            message={'Selected file: {fileName}'}
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            values={{ fileName: file.name }}
          />
        </Text>
      ) : (
        <Text>
          <FaIcon icon="warningDual" fill="black" secondaryColor="yellow2" />
          <Trans id="form.import.placeholder" message="No file selected. Select file to upload." />
        </Text>
      )}
    </StatusWrap>
  )
}

export default Status
