import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { selectors as uiSelectors, actions as uiActions } from '@store/ui'
import { LightBoxType, LightBoxActionType } from '@typings/UI'
import LightBox from './LightBox'

type DispatchProps = {
  setLightBox: (data: Partial<LightBoxType>) => void
  resetLightBox: () => void
}

const mapStateToProps = (state: State): LightBoxType => ({
  isOpen: uiSelectors.lightbox(state).isOpen,
  images: uiSelectors.lightbox(state).images,
  photoIndex: uiSelectors.lightbox(state).photoIndex,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setLightBox: (data: Partial<LightBoxType>): void => dispatch(uiActions.setLightBox(data)),
  resetLightBox: (): void => dispatch(uiActions.resetLightBox()),
})

const mergeProps = (stateProps: LightBoxType, dispatchProps: DispatchProps): LightBoxType & LightBoxActionType => ({
  ...stateProps,
  onClose: (): void => dispatchProps.resetLightBox(),
  onMovePrev: (): void =>
    dispatchProps.setLightBox({
      photoIndex: (stateProps.photoIndex + stateProps.images.length - 1) % stateProps.images.length,
    }),
  onMoveNext: (): void =>
    dispatchProps.setLightBox({
      photoIndex: (stateProps.photoIndex + 1) % stateProps.images.length,
    }),
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LightBox)
