import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import { fieldNameForCommonError } from './formatApiToFormikErrors'
import { ApiInitialError } from '@typings/entities/Error'

const getFieldError = (props: any): string | undefined => {
  const fieldName = get('field.name', props)
  const errors = getOr([], 'form.errors', props)
  const touched = getOr([], 'form.touched', props)
  const error = get('error', props) || get(fieldName, errors)
  const submitCount = get('form.submitCount', props)
  return get(fieldName, touched) || submitCount > 0 ? error : undefined
}

export default getFieldError

export const getCommonError = (props: any): { message: string; code: number } | undefined => {
  return getOr(undefined, `form.errors.${fieldNameForCommonError}`, props)
}

export const getInitialCommonErrors = (props: any): ApiInitialError[] | undefined => {
  return getOr(undefined, `form.initialErrors.${fieldNameForCommonError}`, props)
}

export const getFormErrors = (props: any): object | undefined => {
  return getOr(undefined, 'form.errors', props)
}
