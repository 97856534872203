import React, { useCallback, useMemo } from 'react'
import Select from '@mailstep/design-system/ui/Elements/Select'
import { t } from '@lingui/macro'
import styled from '@xstyled/styled-components'
import { Option } from '@mailstep/design-system/ui/Elements/Select/types'
import { Organisation } from '@typings/entities/Organisation'
import toSelectOption from '@utils/toSelectOption'

export type Props = {
  organisations?: Organisation[]
  activeOrganisation?: string
  onChangeOrganisation?: (value: string) => void
  label?: string
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0;
  width: 271px;
  :hover {
    color: typoPrimary;
  }
  @media (min-width: 1024px) {
    width: 172px;
  }
`

const OrganisationSelect = ({ organisations, onChangeOrganisation, activeOrganisation = '', label }: Props): JSX.Element => {
  const handleOrganisationChange = useCallback((option: Option) => onChangeOrganisation?.(option?.value), [onChangeOrganisation])

  const organisationOptions = useMemo(
    () => [
      { value: '', label: t({ id: 'heading.orgPick.emptyOption', message: '-- Organization --' }), index: 0 },
      ...(organisations || []).map(toSelectOption('id', 'name')),
    ],
    [organisations],
  )

  return (
    <Wrap>
      <Select
        name="organisation"
        options={organisationOptions}
        value={activeOrganisation || ''}
        onChange={handleOrganisationChange}
        disabled={!organisations?.length}
        label={label}
      />
    </Wrap>
  )
}

export default OrganisationSelect
