import React from 'react'
import styled from '@xstyled/styled-components'
import { Trans } from '@lingui/react'
import { Text } from '@mailstep/design-system/ui/Elements/Typography'
import { DropzoneState } from 'react-dropzone'
import Button from '@mailstep/design-system/ui/Elements/Button'

export const FileDropBox = styled.div<{
  $isCompact?: boolean
}>`
  width: 100%;
  min-width: 220px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2;
  border-width: 1px;
  border-color: gray;
  border-style: solid;
  border-radius: ${({ $isCompact }): string => ($isCompact ? '8px' : '15px')};
  background-color: lightGray1;
  color: gray;
  text-align: center;
`

interface Props {
  getRootProps: DropzoneState['getRootProps']
  getInputProps: DropzoneState['getInputProps']
  isDragActive: boolean
  onOpen: () => void
  type?: 'image' | 'attachment'
  isCompact?: boolean
}

const CompactDesc = styled.span`
  font-size: 11px;
  font-weight: bold;
  margin: 0 3 3;
`

// TODO add to attachment field / merge attachment field with invoice filed / ?? maybe with

const FileDropZone = ({ getRootProps, getInputProps, isDragActive, onOpen, type, isCompact }: Props): JSX.Element => {
  const descLine = <Trans id="form.import.dropZoneTextLabel" message="You can upload attachment by dropping file in this field" />
  const descSubLine = (
    <Trans id="form.import.dropZoneManualText" message="Or you can pick manually by clicking on the button below:" />
  )
  return (
    <FileDropBox {...getRootProps({ className: 'dropzone' })} $isCompact={isCompact}>
      <input {...getInputProps()} name="fileInput" />

      {isDragActive ? (
        <Trans id="form.import.dropNowText" message="Drop now" />
      ) : (
        <>
          {isCompact ? (
            <CompactDesc>{descLine}</CompactDesc>
          ) : (
            <>
              <Text fontSize={5} mb={4} width="65%" fontWeight="medium">
                {descLine}
              </Text>
              <Text fontSize={2} mb={3}>
                {descSubLine}
              </Text>
            </>
          )}

          <Button iconLeft="plus" variant="outline" appearance="primary" onClick={onOpen} type="button">
            {type == 'image' ? (
              <Trans id="form.import.dropZoneButtonImg" message="Choose image" />
            ) : type == 'attachment' ? (
              <Trans id="form.import.dropZoneButtonAttachment" message="Choose attachment" />
            ) : (
              <Trans id="form.import.dropZoneButtonFile" message="Choose file" />
            )}
          </Button>
        </>
      )}
    </FileDropBox>
  )
}

export default FileDropZone
