type Sorting = {
  column: string
  direction: 'asc' | 'desc' | null
}

export type ApiSorting = {
  field: string | null
  order: Sorting['direction']
}

const buildSorting = (sorting?: Sorting[]): ApiSorting[] | undefined => {
  if (!sorting) {
    return undefined // because an empty array completely removes default sorting on BE
  }

  const sanitizedSorting = sorting.filter(({ direction }) => direction !== null)

  if (sanitizedSorting.length < 1) {
    return undefined // because an empty array completely removes default sorting on BE
  }

  return sanitizedSorting.map(({ column, direction }) => ({ field: column, order: direction }))
}

export default buildSorting
