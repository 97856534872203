import gql from 'graphql-tag'

const inboundReceiptsGetQuery = gql`
  query inboundReceiptsGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object, $nested: Boolean) {
    inboundReceiptsGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: $nested })
      @rest(type: "InboundReceiptsListResult", method: "POST", path: "inbound-receipt/list") {
      results
      paging
    }
  }
`

export default inboundReceiptsGetQuery
