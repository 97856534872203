import { useEffect, useState } from 'react'
import { Eshop } from '@typings/entities/Eshop'
import { BaselinkerOrderState, BaselinkerOrderStateListApi } from '@typings/entities/OrderSynchronization'
import { BaselinkerApi } from '@services/BaselinkerApi'

export const useBaselinkerConfig = (selectedEshop?: Eshop, baselinkerApiToken?: string): BaselinkerOrderStateListApi => {
  const [baselinkerStatesConfig, setBaselinkerStatesConfig] = useState<BaselinkerOrderState[]>([])

  useEffect(() => {
    if (baselinkerApiToken && selectedEshop) {
      BaselinkerApi(baselinkerApiToken)
        .order.orderStatesListSync()
        .then((response) => setBaselinkerStatesConfig(response.data.statuses))
        .catch((error) => console.error(error?.response?.data?.message || error?.message || error))
    } else {
      setBaselinkerStatesConfig([])
    }
  }, [selectedEshop, baselinkerApiToken])

  return {
    statuses: baselinkerStatesConfig,
  }
}
