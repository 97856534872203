import { Field } from 'formik'
import InputField from '@components/forms/components/InputField'
import { Trans } from '@lingui/react'
import React from 'react'
import { Col } from '@designSystem/Grid'
import { StyledCol } from '../styles'
import BorderedBox from '@mailstep/design-system/ui/Elements/BorderedBox'
import SelectField from '@components/forms/components/SelectField'
import { Option as SelectOption } from '@mailstep/design-system/ui/Elements/Select/types'
import CheckboxField from '@components/forms/components/CheckboxField'
import TextAreaField from '@components/forms/components/TextAreaField'
import { FieldLabel } from '@mailstep/design-system/ui/Elements/Label'
import { ResponsiveRowWrap } from '@components/forms/styles'

type Props = {
  carriers?: SelectOption[]
  carrierServices?: SelectOption[]
  carrierServiceIsLoading: boolean
  searchPickupPlaces: (text: string, byId: boolean) => Promise<SelectOption[]>
  searchExternalPickupPlaces: (text: string, byId: boolean) => Promise<SelectOption[]>
  hasDeliveryCountry?: boolean
  hasCarrier?: boolean
  hasCarrierService?: boolean
  hasCarrierPickupPlace?: boolean
  canSelectExternalPickupPoint?: boolean
  cod: boolean
  currency?: string
  codCurrency?: string
  onCarrierServiceChange?: Function
  isEditable?: boolean
  isWithoutPickupPlace?: boolean
  foreignPrice?: {
    amount: number
    currency: string
  }
  canOrganisationAddressValidate: boolean
  canCarrierAddressValidate: boolean
  carrierOptions?: {
    recipientIdentificationNumber: string
  }
}

const ShippingOptions = ({
  carriers,
  carrierServices,
  carrierServiceIsLoading,
  searchPickupPlaces,
  searchExternalPickupPlaces,
  hasDeliveryCountry,
  hasCarrier,
  hasCarrierService,
  hasCarrierPickupPlace,
  canSelectExternalPickupPoint,
  cod,
  currency,
  codCurrency,
  onCarrierServiceChange,
  isEditable = false,
  isWithoutPickupPlace,
  foreignPrice,
  canOrganisationAddressValidate,
  canCarrierAddressValidate,
}: Props): JSX.Element => {
  return (
    <ResponsiveRowWrap>
      <StyledCol size={6}>
        <Field
          component={SelectField}
          name="carrier"
          options={carriers}
          label={<Trans id="form.carrier.label" message="Shipping carrier" />}
          spaceAround
          disabled={!isEditable}
          isLocked={!hasDeliveryCountry}
          lockedText={<Trans id="form.carrier.notReady" message="Select a delivery country first..." />}
        />
        <Field
          component={SelectField}
          name="carrierService"
          options={carrierServices}
          label={<Trans id="form.carrierService.label" message="Shipping service" />}
          spaceAround
          onValueChange={onCarrierServiceChange}
          disabled={!isEditable}
          isLoading={carrierServiceIsLoading}
          isLocked={!hasCarrier}
          lockedText={<Trans id="form.carrierService.notReady" message="Select a carrier first..." />}
        />
        <Field
          name="carrierPickupPlace"
          component={SelectField}
          loadOptions={searchPickupPlaces}
          preload
          label={<Trans id="form.carrierPickupPlace.label" message="Pickup place" />}
          spaceAround
          isClearable
          disabled={!isEditable || isWithoutPickupPlace}
          isLocked={!hasCarrierService}
          lockedText={
            <Trans id="form.carrierPickupPlace.notReady" message="Select carrier service and delivery country first..." />
          }
        />
        {canSelectExternalPickupPoint && (
          <Field
            name="externalCarrierPickupPlace"
            component={SelectField}
            loadOptions={searchExternalPickupPlaces}
            preload
            label={<Trans id="form.externalCarrierPickupPlace.label" message="External pickup place" />}
            spaceAround
            isClearable
            disabled={!isEditable || isWithoutPickupPlace || !canSelectExternalPickupPoint}
            isLocked={!hasCarrierPickupPlace}
            lockedText={<Trans id="form.externalCarrierPickupPlace.notReady" message="Select carrier pickup place..." />}
          />
        )}
        <ResponsiveRowWrap>
          <Field component={CheckboxField} name="b2b" label={<Trans id="form.b2b.label" message="B2B" />} />
          <Field component={CheckboxField} name="fragile" label={<Trans id="form.fragile.label" message="Fragile" />} />
        </ResponsiveRowWrap>

        <FieldLabel>
          <Trans id="form.addressValidation.label" message="Address validation" />
        </FieldLabel>
        {!canOrganisationAddressValidate ? (
          <Trans
            id="form.ignoreAddressValidation.unavailableOrganisation"
            message="Your organisation has address validation disabled"
          />
        ) : !canCarrierAddressValidate ? (
          <Trans
            id="form.ignoreAddressValidation.unavailableCarrier"
            message="Address validation no available for selected carrier"
          />
        ) : (
          <Field
            component={CheckboxField}
            label={<Trans id="form.ignoreAddressValidation.label" message="Ignore address validation" />}
            name="ignoreAddressValidation"
            disabled={!isEditable || !canOrganisationAddressValidate}
          />
        )}
      </StyledCol>
      <StyledCol size={6}>
        <Field component={CheckboxField} name="cod" label={<Trans id="form.cod.label" message="COD" />} />
        {cod && (
          <>
            <BorderedBox mb="11px">
              <ResponsiveRowWrap>
                <Col size={6}>
                  <Field
                    component={InputField}
                    name="codVariableSymbol"
                    label={<Trans id="form.codVariableSymbol.label" message="Variable symbol" />}
                    spaceAround
                  />
                </Col>
                <Col size={6}>
                  <Field
                    component={InputField}
                    name="codValue"
                    label={<Trans id="form.codValue.label" message="COD value" />}
                    spaceAround
                    suffix={codCurrency}
                  />
                </Col>
              </ResponsiveRowWrap>
            </BorderedBox>
          </>
        )}
        <ResponsiveRowWrap>
          <Col size={6}>
            {foreignPrice && foreignPrice.currency !== currency ? (
              <Field
                component={InputField}
                name="foreignPrice.amount"
                label={<Trans id="form.packageValue.label" message="Package value" />}
                spaceAround
                suffix={foreignPrice.currency}
              />
            ) : (
              <Field
                component={InputField}
                name="value"
                label={<Trans id="form.packageValue.label" message="Package value" />}
                spaceAround
                suffix={currency}
              />
            )}
            <Field
              component={InputField}
              name="carrierOptions.recipientIdentificationNumber"
              label={<Trans id="form.recipientId.label" message="Recipient ID" />}
              spaceAround
            />
          </Col>
          <Col size={6}>
            <Field
              component={TextAreaField}
              name="carrierNote"
              label={<Trans id="form.carrierNote.label" message="Note for carrier" />}
              spaceAround
            />
          </Col>
        </ResponsiveRowWrap>
      </StyledCol>
    </ResponsiveRowWrap>
  )
}

ShippingOptions.defaultProps = {
  carriers: [],
  carrierServices: [],
}

export default React.memo(ShippingOptions)
