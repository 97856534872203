import { ProductApiWrite, ProductDetail } from '@typings/entities/Product'
import { LightBoxType } from '@typings/UI'
import { Dispatch } from 'redux'
import { actions as uiActions } from '@store/ui'
import { ApolloClient } from '@apollo/client'
import productGroupCreateQuery from '@queries/productGroupCreateQuery'
import toSelectOption from '@utils/toSelectOption'

export const formatValuesToWrite = (product: ProductDetail): ProductApiWrite => {
  const productOut = {
    ...product,
    weight: product?.weight || null,
    width: product?.width || null,
    height: product?.height || null,
    length: product?.length || null,
  }

  // TOOD fix types
  productOut.childrenProducts =
    product.type == 'bundle'
      ? (product.childrenProducts || []).map((item) => ({
          quantity: item.quantity,
          productId: item.product.id,
        }))
      : null

  return productOut
}

export const onOpenLightbox =
  (dispatch: Dispatch) =>
  (images: LightBoxType['images'], index: LightBoxType['photoIndex']): void => {
    dispatch(uiActions.setLightBox({ images, photoIndex: index, isOpen: true }))
  }

export const onCreateProductGroup =
  (client: ApolloClient<any>, usedOrganisation?: string, reload: () => void) => (name: string) => {
    return client
      .query({
        query: productGroupCreateQuery,
        variables: { name, organisation: usedOrganisation },
      })
      .then(({ data }) => {
        reload()
        const res = [data?.productGroupCreate].map(toSelectOption('id', 'name'))
        return res[0]
      })
  }
