import React from 'react'
import FaIcon from '@mailstep/design-system/ui/Elements/Icon'
import { Products } from '@mailstep/design-system/ui/Elements/Icon/icons/Products'
import { Inventory } from '@mailstep/design-system/ui/Elements/Icon/icons/Inventory'
import { Expeditions } from '@mailstep/design-system/ui/Elements/Icon/icons/Expeditions'
import { Return } from '@mailstep/design-system/ui/Elements/Icon/icons/Return'
import { Transfer } from '@mailstep/design-system/ui/Elements/Icon/icons/Transfer'
import { Income } from '@mailstep/design-system/ui/Elements/Icon/icons/Income'
import { Complaint } from '@mailstep/design-system/ui/Elements/Icon/icons/Complaint'
import { t } from '@lingui/macro'
import routes from '@pages/index'
import { Item } from '@components/blocks/LeftMenu/types'

type MenuVariant = 'Admin' | 'User'

export const getMenuItems = (type: MenuVariant): Item[] => [
  {
    title: t({ id: 'user.mainMenu.products', message: 'Products' }),
    icon: <Products />,
    link: routes.sharedProducts.route.toUrl(),
    id: 'product',
  },
  {
    title: t({ id: 'user.mainMenu.inventory', message: 'Inventory' }),
    icon: <Inventory />,
    link: routes.sharedStocks.route.toUrl(),
    id: 'inventory',
    items: [
      {
        title: t({ id: 'user.mainMenu.stocks', message: 'Stocks' }),
        link: routes.sharedStocks.route.toUrl(),
        id: 'stock',
      },
      {
        title: t({ id: 'user.mainMenu.reservations', message: 'Reservations' }),
        link: routes.sharedReservations.route.toUrl(),
        id: 'reservations',
      },
      {
        title: t({ id: 'user.mainMenu.movements', message: 'Movements' }),
        link: routes.sharedStockMovements.route.toUrl(),
        id: 'movements',
      },
      ...(type === 'Admin'
        ? [
            {
              title: t({ id: 'user.mainMenu.stockChanges', message: 'Inventory movements' }),
              link: routes.sharedStockChanges.route.toUrl(),
              id: 'inventoryMovements',
            },
          ]
        : []),
      {
        title: t({ id: 'user.mainMenu.internalStockChanges', message: 'Internal inv. movements' }),
        link: routes.sharedInternalStockChanges.route.toUrl(),
        id: 'internalInvMovements',
      },
    ],
  },
  {
    title: t({ id: 'user.mainMenu.expeditions', message: 'Expeditions' }),
    icon: <Expeditions />,
    link: routes.sharedExpeditions.route.toUrl(),
    id: 'expeditions',
    items: [
      {
        title: t({ id: 'user.mainMenu.expeditions', message: 'Expeditions' }),
        link: routes.sharedExpeditions.route.toUrl(),
        id: 'expeditionsChild',
      },
      {
        title: t({ id: 'user.mainMenu.outboundReceipts', message: 'Outbound receipts' }),
        link: routes.sharedOutboundReceipts.route.toUrl(),
        icon: <FaIcon icon="complaint" />,
        id: 'outboundReceipts',
      },
      {
        title: t({ id: 'user.mainMenu.missingGoods', message: 'Missing goods' }),
        link: routes.sharedMissingStock.route.toUrl(),
        icon: <FaIcon icon="flatBox" />,
        id: 'missingGoods',
      },
    ],
  },
  {
    title: t({ id: 'user.mainMenu.inbound', message: 'Inbound' }),
    icon: <Income />,
    link: routes.sharedStockAdvices.route.toUrl(),
    id: 'inbound',
    items: [
      {
        title: t({ id: 'user.mainMenu.stockAdvices', message: 'Stock advices' }),
        link: routes.sharedStockAdvices.route.toUrl(),
        id: 'stockAdvices',
      },
      {
        title: t({ id: 'user.mainMenu.inboundReceipts', message: 'Inbound receipts' }),
        link: routes.sharedInboundReceipts.route.toUrl(),
        icon: <FaIcon icon="complaint" />,
        id: 'inboundReceipts',
      },
    ],
  },
  {
    title: t({ id: 'user.mainMenu.returns', message: 'Returns' }),
    icon: <Return />,
    link: routes.sharedReturnReceipts.route.toUrl(),
    id: 'returns',
  },
  {
    title: t({ id: 'user.mainMenu.transfers', message: 'Transfers' }),
    icon: <Transfer />,
    link: routes.sharedTransfers.route.toUrl(),
    id: 'transfers',
  },
  {
    title: t({ id: 'user.mainMenu.complaints', message: 'Claims' }),
    icon: <Complaint />,
    link: routes.sharedComplaints.route.toUrl(),
    id: 'claims',
  },
]

export const getFilteredMenuItems = (type: MenuVariant, menuPreferences: string[]) =>
  getMenuItems(type).filter((item) => menuPreferences?.includes(item.id))
