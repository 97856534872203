import gql from 'graphql-tag'

const eshopUpdateQuery = gql`
  query eshopUpdate($id: String!, $name: String!, $defaultWarehouseId: String) {
    organisationsGet(id: $id, input: { name: $name, defaultWarehouse: $defaultWarehouseId })
      @rest(type: "EshopUpdateResult", method: "PUT", path: "eshop/{args.id}") {
      result
    }
  }
`

export default eshopUpdateQuery
