import React, { ReactElement, cloneElement, useCallback, useState } from 'react'
import styled, { x } from '@xstyled/styled-components'
import { useAcl } from '@components/blocks/Acl'
import { useClickOutside } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useClickOutside'
import NotificationsList from './NotificationsList'
import WarningsList, { WarningItem } from './WarningsList'
import Tabs from '@mailstep/design-system/ui/Blocks/Tabs'
import { TabContent } from '@mailstep/design-system/ui/Blocks/Tabs/TabContent'
import { useTabs } from '@mailstep/design-system/ui/Blocks/Tabs/hooks/useTabs'
import { tabsDefinitions } from './utils/tabsDefinitions'

type Props = {
  children: ReactElement
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  warningItems?: WarningItem[]
  notificationCount?: number
  warningsCount?: number
}

const PopupWrap = styled(x.div)`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`

const ContentWrap = styled.div`
  position: absolute;
  top: 100%;
  right: -50px;
  width: 95vw;
  max-width: 440px;
  min-width: 300px;
  height: fit-content;
  display: block;
  padding: 16px;
  background-color: white;
  border-radius: 10px;
  filter: drop-shadow(0px 5px 40px rgba(0, 0, 0, 0.1));
  z-index: 3;

  @media (min-width: 768px) {
    width: 100vw;
    right: 0;
  }
`

const TabsWrap = styled(x.div)`
  width: fit-content;
  margin-bottom: 20px;
`

const NotificationsPopup = ({ setIsOpen, children, warningItems, notificationCount, warningsCount }: Props): JSX.Element => {
  const [displayPopup, setDisplayPopup] = useState(false)

  const canReadNotifications = useAcl().can('read', 'Notifications')
  const { activeTab, onTabSwitch } = useTabs(tabsDefinitions({ canReadNotifications, notificationCount, warningsCount }))

  const toggleDropdownMenu = useCallback(() => {
    setDisplayPopup((prev) => !prev)
    setIsOpen && setIsOpen((prev) => !prev)
  }, [setIsOpen])

  const onClose = useCallback(() => {
    setDisplayPopup(false)
    setIsOpen && setIsOpen(false)
  }, [setIsOpen])

  const ref = useClickOutside({ onClose })

  return (
    <PopupWrap ref={ref}>
      {cloneElement(children, { onClick: toggleDropdownMenu })}
      {displayPopup && (
        <ContentWrap>
          <TabsWrap>
            <Tabs
              tabsDefinition={tabsDefinitions({ canReadNotifications, notificationCount, warningsCount })}
              activeTab={activeTab}
              onTabSwitch={onTabSwitch}
            />
          </TabsWrap>

          {/* only for user */}
          {canReadNotifications && (
            <TabContent activeTab={activeTab} value={0}>
              <NotificationsList onClose={onClose} />
            </TabContent>
          )}

          {/* for users and admins  */}
          <TabContent activeTab={activeTab} value={!canReadNotifications ? 0 : 1}>
            <WarningsList warningItems={warningItems} onClose={onClose} />
          </TabContent>
        </ContentWrap>
      )}
    </PopupWrap>
  )
}

export default NotificationsPopup
